function sandbox(
  state = {
    isSandbox: false,
  },
  action
) {
  switch (action.type) {
    case 'SWITCH_TO_SANDBOX':
      return {
        ...state,
        isSandbox: true,
      }
    default:
      return state
  }
}

export default sandbox
