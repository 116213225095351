const ActionConstants = {
  aliases: {
    selectItem: 'SELECT_ALIAS',
    invalidateItems: 'INVALIDATE_ALIASES',
    invalidateTempItems: 'INVALIDATE_ALIASES_TEMP',
    sendDeleteItem: 'SEND_DELETE_ALIAS',
    confirmDeleteItem: 'CONFIRM_DELETE_ALIAS',
    sendEditItem: 'SEND_EDIT_ALIAS',
    confirmEditItem: 'CONFIRM_EDIT_ALIAS',
    sendSwitchAlias: 'SEND_SWITCH_ALIAS',
    confirmSwitchAlias: 'CONFIRM_SWITCH_ALIAS',
    sendAddItem: 'SEND_ADD_ALIAS',
    sendAddItems: 'SEND_ADD_ALIASES',
    confirmAddItem: 'CONFIRM_ADD_ALIAS',
    confirmAddItems: 'CONFIRM_ADD_ALIAS',
    confirmAddTempItems: 'CONFIRM_ADD_ALIAS_TEMP',
    requestItem: 'REQUEST_ALIAS',
    requestItems: 'REQUEST_ALIASES',
    receiveItem: 'RECEIVE_ALIAS',
    receiveItems: 'RECEIVE_ALIASES',
    receiveTempItems: 'RECEIVE_ALIASES_TEMP',
    duplicateAliasStage: 'DUPLICATE_ALIAS_STAGE',
    sendDuplicateAlias: 'SEND_DUPLICATE_ALIAS',
    confirmDuplicateAlias: 'CONFIRM_DUPLICATE_ALIAS',
    itemFailed: 'DOOR_FAILED',
    itemsFailed: 'DOORS_FAILED',
  },
  doors: {
    selectItem: 'SELECT_DOOR',
    invalidateItems: 'INVALIDATE_DOORS',
    invalidateTempItems: 'INVALIDATE_DOORS_TEMP',
    sendDeleteItem: 'SEND_DELETE_DOOR',
    confirmDeleteItem: 'CONFIRM_DELETE_DOOR',
    sendEditItem: 'SEND_EDIT_DOOR',
    confirmEditItem: 'CONFIRM_EDIT_DOOR',
    sendAddItem: 'SEND_ADD_DOOR',
    sendAddItems: 'SEND_ADD_DOORS',
    confirmAddItem: 'CONFIRM_ADD_DOOR',
    confirmAddItems: 'CONFIRM_ADD_DOORS',
    confirmAddTempItems: 'CONFIRM_ADD_DOOR_TEMP',
    requestItem: 'REQUEST_DOOR',
    requestItems: 'REQUEST_DOORS',
    receiveItem: 'RECEIVE_DOOR',
    receiveItems: 'RECEIVE_DOORS',
    receiveTempItems: 'RECEIVE_DOORS_TEMP',
    itemFailed: 'DOOR_FAILED',
    itemsFailed: 'DOORS_FAILED',
    requestRecommendations: 'REQUEST_DOORS_RECOMMENDATIONS',
    receiveRecommendations: 'RECEIVE_DOORS_RECOMMENDATIONS',
    sendDoorLoading: 'SEND_DOOR_LOADING',
    confirmDoorLoaded: 'CONFIRM_DOOR_LOADED',
    doorLoadingFailed: 'DOOR_LOADING_FAILED',
    sendDoorEventLoading: 'DOOR_EVENT_LOADING',
    confirmDoorEventLoaded: 'DOOR_EVENT_LOADED',
  },
  doorBans: {
    selectItem: 'SELECT_DOOR_BAN',
    invalidateItems: 'INVALIDATE_DOOR_BANS',
    invalidateTempItems: 'INVALIDATE_DOOR_BANS_TEMP',
    sendDeleteItem: 'SEND_DELETE_DOOR_BAN',
    confirmDeleteItem: 'CONFIRM_DELETE_DOOR_BAN',
    sendEditItem: 'SEND_EDIT_DOOR_BAN',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_BAN',
    sendAddItem: 'SEND_ADD_DOOR_BAN',
    sendAddItems: 'SEND_ADD_DOOR_BANS',
    confirmAddItem: 'CONFIRM_ADD_DOOR_BAN',
    confirmAddItems: 'CONFIRM_ADD_DOOR_BANS',
    confirmAddTempItems: 'CONFIRM_ADD_DOOR_BANS_TEMP',
    requestItem: 'REQUEST_DOOR_BAN',
    requestItems: 'REQUEST_DOOR_BANS',
    receiveItem: 'RECEIVE_DOOR_BAN',
    receiveItems: 'RECEIVE_DOOR_BANS',
    receiveTempItems: 'RECEIVE_DOOR_BANS_TEMP',
    itemFailed: 'DOOR_BAN_FAILED',
    itemsFailed: 'DOOR_BANS_FAILED',
  },
  doorBlacklists: {
    selectItem: 'SELECT_DOOR_BLACKLIST',
    invalidateItems: 'INVALIDATE_DOOR_BLACKLISTS',
    invalidateTempItems: 'INVALIDATE_DOOR_BLACKLISTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_DOOR_BLACKLIST',
    confirmDeleteItem: 'CONFIRM_DELETE_DOOR_BLACKLIST',
    sendEditItem: 'SEND_EDIT_DOOR_BLACKLIST',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_BLACKLIST',
    sendAddItem: 'SEND_ADD_DOOR_BLACKLIST',
    sendAddItems: 'SEND_ADD_DOOR_BLACKLISTS',
    confirmAddItem: 'CONFIRM_ADD_DOOR_BLACKLIST',
    confirmAddItems: 'CONFIRM_ADD_DOOR_BLACKLISTS',
    confirmAddTempItems: 'CONFIRM_ADD_DOOR_BLACKLISTS_TEMP',
    requestItem: 'REQUEST_DOOR_BLACKLIST',
    requestItems: 'REQUEST_DOOR_BLACKLISTS',
    receiveItem: 'RECEIVE_DOOR_BLACKLIST',
    receiveItems: 'RECEIVE_DOOR_BLACKLISTS',
    receiveTempItems: 'RECEIVE_DOOR_BLACKLISTS_TEMP',
    itemFailed: 'DOOR_BLACKLIST_FAILED',
    itemsFailed: 'DOOR_BLACKLISTS_FAILED',
  },
  doorSubscribers: {
    requestItem: 'REQUEST_DOOR_SUBSCRIBER',
    requestItems: 'REQUEST_DOOR_SUBSCRIBERS',
    receiveItem: 'RECEIVE_DOOR_SUBSCRIBER',
    receiveItems: 'RECEIVE_DOOR_SUBSCRIBERS',
    itemFailed: 'DOOR_SUBSCRIBER_FAILED',
    itemsFailed: 'DOOR_SUBSCRIBERS_FAILED',
    sendEditItem: 'SEND_EDIT_DOOR_SUBSCRIBER',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_SUBSCRIBER',
    sendDeleteItem: 'SEND_UNSUBSCRIBE_USER',
    confirmDeleteItem: 'CONFIRM_UNSUBSCRIBE_USER',
    sendAddItem: 'SEND_SUBSCRIBE_USER',
    confirmAddItem: 'CONFIRM_SUBSCRIBE_USER',
  },
  doorGuestLists: {
    requestItem: 'REQUEST_DOOR_GUEST_LIST',
    requestItems: 'REQUEST_DOOR_GUEST_LISTS',
    receiveItem: 'RECEIVE_DOOR_GUEST_LIST',
    receiveItems: 'RECEIVE_DOOR_GUEST_LISTS',
    itemFailed: 'DOOR_GUEST_LIST_FAILED',
    itemsFailed: 'DOOR_GUEST_LISTS_FAILED',
    sendEditItem: 'SEND_EDIT_DOOR_GUEST_LIST',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_GUEST_LIST',
    sendAddItem: 'SEND_ADD_DOOR_GUEST_LIST',
    confirmAddItem: 'CONFIRM_ADD_DOOR_GUEST_LIST',
  },
  doorGuestListGuests: {
    requestItem: 'REQUEST_DOOR_GUEST_LIST_GUEST',
    requestItems: 'REQUEST_DOOR_GUEST_LIST_GUESTS',
    receiveItem: 'RECEIVE_DOOR_GUEST_LIST_GUEST',
    receiveItems: 'RECEIVE_DOOR_GUEST_LIST_GUESTS',
    itemFailed: 'DOOR_GUEST_LIST_GUEST_FAILED',
    itemsFailed: 'DOOR_GUEST_LIST_GUESTS_FAILED',
    sendEditItem: 'SEND_EDIT_DOOR_GUEST_LIST_GUEST',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_GUEST_LIST_GUEST',
    sendAddItem: 'SEND_ADD_DOOR_GUEST_LIST_GUEST',
    confirmAddItem: 'CONFIRM_ADD_DOOR_GUEST_LIST_GUEST',
  },
  doorManagers: {
    requestItem: 'REQUEST_DOOR_MANAGER',
    requestItems: 'REQUEST_DOOR_MANAGERS',
    receiveItem: 'RECEIVE_DOOR_MANAGER',
    receiveItems: 'RECEIVE_DOOR_MANAGERS',
    itemFailed: 'DOOR_MANAGER_FAILED',
    itemsFailed: 'DOOR_MANAGERS_FAILED',
    sendEditItem: 'SEND_EDIT_DOOR_MANAGER',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_MANAGER',
    sendAddItem: 'SEND_ADD_DOOR_MANAGER',
    confirmAddItem: 'CONFIRM_ADD_DOOR_MANAGER',
    sendDeleteItem: 'SEND_DELETE_DOOR_MANAGER',
    confirmDeleteItem: 'CONFIRM_DELETE_DOOR_MANAGER',
    sendChangeGM: 'SEND_CHANGE_GM',
    confirmChangeGM: 'CONFIRM_CHANGE_GM',
  },
  doorManagerGroups: {
    requestItem: 'REQUEST_DOOR_MANAGER_GROUP',
    requestItems: 'REQUEST_DOOR_MANAGER_GROUPS',
    receiveItem: 'RECEIVE_DOOR_MANAGER_GROUP',
    receiveItems: 'RECEIVE_DOOR_MANAGER_GROUPS',
    itemFailed: 'DOOR_MANAGER_GROUP_FAILED',
    itemsFailed: 'DOOR_MANAGER_GROUPS_FAILED',
    sendAddItem: 'SEND_ADD_DOOR_MANAGER_GROUP',
    confirmAddItem: 'CONFIRM_ADD_DOOR_MANAGER_GROUP',
  },
  doorManagerGroupMembers: {
    requestItem: 'REQUEST_DOOR_MANAGER_GROUP_MEMBER',
    requestItems: 'REQUEST_DOOR_MANAGERS_GROUP_MEMBERS',
    receiveItem: 'RECEIVE_DOOR_MANAGER_GROUP_MEMBER',
    receiveItems: 'RECEIVE_DOOR_MANAGERS_GROUP_MEMBERS',
    itemFailed: 'DOOR_MANAGER_GROUP_MEMBER_FAILED',
    itemsFailed: 'DOOR_MANAGER_GROUP_MEMBERS_FAILED',
    sendAddItem: 'SEND_ADD_DOOR_MANAGER_GROUP_MEMBER',
    confirmAddItem: 'CONFIRM_ADD_DOOR_MANAGER_GROUP_MEMBER',
  },
  doorCategories: {
    sendAddItem: 'DOOR_CATEGORY_SEND_ADD_ITEM',
    confirmAddItem: 'DOOR_CATEGORY_CONFIRM_ADD_ITEM',
    sendDeleteItem: 'SEND_DELETE_DOOR_CATEGORY',
    confirmDeleteItem: 'CONFIRM_DELETE_DOOR_CATEGORY',
  },
  doorRoles: {
    selectItem: 'SELECT_DOOR_ROLE',
    invalidateItems: 'INVALIDATE_DOOR_ROLES',
    invalidateTempItems: 'INVALIDATE_DOOR_ROLES_TEMP',
    sendDeleteItem: 'SEND_DELETE_DOOR_ROLE',
    confirmDeleteItem: 'CONFIRM_DELETE_DOOR_ROLE',
    sendEditItem: 'SEND_EDIT_DOOR_ROLE',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_ROLE',
    sendAddItem: 'SEND_ADD_DOOR_ROLE',
    sendAddItems: 'SEND_ADD_DOOR_ROLES',
    confirmAddItem: 'CONFIRM_ADD_DOOR_ROLE',
    confirmAddItems: 'CONFIRM_ADD_DOOR_ROLES',
    confirmAddTempItems: 'CONFIRM_ADD_DOOR_ROLES_TEMP',
    requestItem: 'REQUEST_DOOR_ROLE',
    requestItems: 'REQUEST_DOOR_ROLES',
    receiveItem: 'RECEIVE_DOOR_ROLE',
    receiveItems: 'RECEIVE_DOOR_ROLES',
    requestCurrentRole: 'REQUEST_CURRENT_ROLE',
    receiveCurrentRole: 'RECEIVE_CURRENT_ROLE',
    receiveTempItems: 'RECEIVE_DOOR_ROLES_TEMP',
    itemFailed: 'DOOR_ROLE_FAILED',
    itemsFailed: 'DOOR_ROLES_FAILED',
  },
  dummy: {
    selectItem: 'SELECT_DUMMY',
    invalidateItems: 'INVALIDATE_DUMMY',
    invalidateTempItems: 'INVALIDATE_DUMMIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_DUMMY',
    confirmDeleteItem: 'CONFIRM_DELETE_DUMMY',
    sendEditItem: 'SEND_EDIT_DUMMY',
    confirmEditItem: 'CONFIRM_EDIT_DUMMY',
    sendAddItem: 'SEND_ADD_DUMMY',
    sendAddItems: 'SEND_ADD_DUMMIES',
    confirmAddItem: 'CONFIRM_ADD_DUMMY',
    confirmAddItems: 'CONFIRM_ADD_DUMMIES',
    confirmAddTempItems: 'CONFIRM_ADD_DUMMIES_TEMP',
    requestItem: 'REQUEST_DUMMY',
    requestItems: 'REQUEST_DUMMIES',
    receiveItem: 'RECEIVE_DUMMY',
    receiveItems: 'RECEIVE_DUMMIES',
    requestCurrentRole: 'REQUEST_CURRENT_ROLE',
    receiveCurrentRole: 'RECEIVE_CURRENT_ROLE',
    receiveTempItems: 'RECEIVE_DUMMIES_TEMP',
    itemFailed: 'DUMMY_FAILED',
    itemsFailed: 'DUMMIES_FAILED',
  },
  categories: {
    toggleFilterOnEmpty: 'TOGGLE_FILTER_ON_EMPTY',
    selectItem: 'SELECT_CATEGORY',
    invalidateItems: 'INVALIDATE_CATEGORIES',
    invalidateTempItems: 'INVALIDATE_CATEGORIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_CATEGORY',
    confirmDeleteItem: 'CONFIRM_DELETE_CATEGORY',
    sendEditItem: 'SEND_EDIT_CATEGORY',
    confirmEditItem: 'CONFIRM_EDIT_CATEGORY',
    sendAddItem: 'SEND_ADD_CATEGORY',
    sendAddItems: 'SEND_ADD_CATEGORIES',
    confirmAddItem: 'CONFIRM_ADD_CATEGORY',
    confirmAddItems: 'CONFIRM_ADD_CATEGORIES',
    confirmAddTempItems: 'CONFIRM_ADD_CATEGORIES_TEMP',
    requestItem: 'REQUEST_CATEGORY',
    requestItems: 'REQUEST_CATEGORIES',
    requestCategoryLinks: 'REQUEST_CATEGORY_LINKS',
    requestCategoryCounts: 'REQUEST_CATEGORY_COUNTS',
    receiveItem: 'RECEIVE_CATEGORY',
    receiveItems: 'RECEIVE_CATEGORIES',
    receiveTempItems: 'RECEIVE_CATEGORIES_TEMP',
    receiveCategoryLinks: 'RECEIVE_CATEGORY_LINKS',
    receiveCategoryCounts: 'RECEIVE_CATEGORY_COUNTS',
    clearTempItems: 'CLEAR_CATEGORIES_TEMP',
    itemFailed: 'CATEGORY_FAILED',
    itemsFailed: 'CATEGORIES_FAILED',
    sendLinkSupercategory: 'SEND_LINK_SUPER_CATEGORY',
    storeItem: 'CATEGORIES_STORE_ITEMS',
    clearStoredItems: 'CATEGORIES_CLEAR_STORED_ITEMS',
    removeStoredItem: 'CATEGORIES_REMOVE_STORED_ITEM',
    receiveLinkSupercategory: 'RECEIVE_LINK_SUPER_CATEGORY',
    sendUnlinkSupercategory: 'SEND_UNLINK_SUPER_CATEGORY',
    receiveUnlinkSupercategory: 'RECEIVE_UNLINK_SUPER_CATEGORY',
    sendAliasOf: 'SEND_ALIAS_OF',
    receiveAliasOf: 'RECEIVE_ALIAS_OF',
    toggleColumns: 'CATEGORIES_TOGGLE_COLUMNS',
    toggleRevisitFiltered: 'CATEGORIES_TOGGLE_REVISIT_FILTERED',
    highlightRow: 'CATEGORIES_HIGHLIGHT_ROW',
    highlightCell: 'CATEGORIES_HIGHLIGHT_CELL',
    sendToggleRevisit: 'CATEGORIES_SEND_TOGGLE_REVISIT',
    confirmToggleRevisit: 'CATEGORIES_CONFIRM_TOGGLE_REVISIT',
    sendToggleVisit: 'CATEGORIES_SEND_TOGGLE_VISIT',
    confirmToggleVisit: 'CATEGORIES_CONFIRM_TOGGLE_VISIT',
    sendToggleDelete: 'CATEGORIES_SEND_TOGGLE_DELETE',
    confirmToggleDelete: 'CATEGORIES_CONFIRM_TOGGLE_DELETE',
    changeItemsPerPage: 'CHANGE_ITEMS_PER_PAGE',
    changePage: 'CHANGE_PAGE',
    toggleDarkMode: 'CATEGORIES_TOGGLE_DARK_MODE',
    sendDeleteCategoryLink: 'CATEGORIES_SEND_DELETE_CATEGORY_LINK',
    confirmDeleteCategoryLink: 'CATEGORIES_CONFIRM_DELETE_CATEGORY_LINK',
    sendAddCategoryLink: 'CATEGORIES_SEND_ADD_CATEGORY_LINK',
    confirmAddCategoryLink: 'CATEGORIES_CONFIRM_ADD_CATEGORY_LINK',
  },
  catGames: {
    makeMove: 'MAKE_CAT_GAME_MOVE',
    confirmMove: 'CONFIRM_CAT_GAME_MOVE',
    selectCatGameCat: 'SELECT_CAT_GAME_CAT',
    removeCatGameCat: 'REMOVE_CAT_GAME_CAT',
    selectItem: 'SELECT_CATEGORY_GAME',
    invalidateItems: 'INVALIDATE_CATEGORY_GAMES',
    invalidateTempItems: 'INVALIDATE_CATEGORY_GAMES_TEMP',
    sendDeleteItem: 'SEND_DELETE_CATEGORY_GAME',
    confirmDeleteItem: 'CONFIRM_DELETE_CATEGORY_GAME',
    sendEditItem: 'SEND_EDIT_CATEGORY_GAME',
    confirmEditItem: 'CONFIRM_EDIT_CATEGORY_GAME',
    sendAddItem: 'SEND_ADD_CATEGORY_GAME',
    sendAddItems: 'SEND_ADD_CATEGORY_GAMES',
    confirmAddItem: 'CONFIRM_ADD_CATEGORY_GAME',
    confirmAddItems: 'CONFIRM_ADD_CATEGORY_GAMES',
    confirmAddTempItems: 'CONFIRM_ADD_CATEGORY_GAMES_TEMP',
    requestItem: 'REQUEST_CATEGORY_GAME',
    requestItems: 'REQUEST_CATEGORY_GAMES',
    receiveItem: 'RECEIVE_CATEGORY_GAME',
    receiveItems: 'RECEIVE_CATEGORY_GAMES',
    receiveTempItems: 'RECEIVE_CATEGORY_GAMES_TEMP',
    clearTempItems: 'CLEAR_CATEGORY_GAMES_TEMP',
    itemFailed: 'CATEGORY_GAME_FAILED',
    itemsFailed: 'CATEGORIES_FAILED',
  },
  externalDoors: {
    selectItem: 'SELECT_EXTERNAL_DOOR_GAME',
    invalidateItems: 'INVALIDATE_EXTERNAL_DOOR_GAMES',
    invalidateTempItems: 'INVALIDATE_EXTERNAL_DOOR_GAMES_TEMP',
    sendDeleteItem: 'SEND_DELETE_EXTERNAL_DOOR_GAME',
    confirmDeleteItem: 'CONFIRM_DELETE_EXTERNAL_DOOR_GAME',
    sendEditItem: 'SEND_EDIT_EXTERNAL_DOOR_GAME',
    confirmEditItem: 'CONFIRM_EDIT_EXTERNAL_DOOR_GAME',
    sendAddItem: 'SEND_ADD_EXTERNAL_DOOR_GAME',
    sendAddItems: 'SEND_ADD_EXTERNAL_DOOR_GAMES',
    confirmAddItem: 'CONFIRM_ADD_EXTERNAL_DOOR_GAME',
    confirmAddItems: 'CONFIRM_ADD_EXTERNAL_DOOR_GAMES',
    confirmAddTempItems: 'CONFIRM_ADD_EXTERNAL_DOOR_GAMES_TEMP',
    requestItem: 'REQUEST_EXTERNAL_DOOR_GAME',
    requestItems: 'REQUEST_EXTERNAL_DOOR_GAMES',
    receiveItem: 'RECEIVE_EXTERNAL_DOOR_GAME',
    receiveItems: 'RECEIVE_EXTERNAL_DOOR_GAMES',
    receiveTempItems: 'RECEIVE_EXTERNAL_DOOR_GAMES_TEMP',
    clearTempItems: 'CLEAR_EXTERNAL_DOOR_GAMES_TEMP',
    itemFailed: 'EXTERNAL_DOOR_GAME_FAILED',
    itemsFailed: 'EXTERNAL_DOORS_FAILED',
  },
  createdEvents: {
    selectItem: 'SELECT_CREATED_EVENT',
    invalidateItems: 'INVALIDATE_CREATED_EVENTS',
    invalidateTempItems: 'INVALIDATE_CREATED_EVENTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_CREATED_EVENT',
    confirmDeleteItem: 'CONFIRM_DELETE_CREATED_EVENT',
    sendEditItem: 'SEND_EDIT_CREATED_EVENT',
    confirmEditItem: 'CONFIRM_EDIT_CREATED_EVENT',
    sendAddItem: 'SEND_ADD_CREATED_EVENT',
    sendAddItems: 'SEND_ADD_CREATED_EVENTS',
    confirmAddItem: 'CONFIRM_ADD_CREATED_EVENT',
    confirmAddItems: 'CONFIRM_ADD_CREATED_EVENTS',
    confirmAddTempItems: 'CONFIRM_ADD_CREATED_EVENT_TEMP',
    requestItem: 'REQUEST_CREATED_EVENT',
    requestItems: 'REQUEST_CREATED_EVENTS',
    receiveItem: 'RECEIVE_CREATED_EVENT',
    receiveItems: 'RECEIVE_CREATED_EVENTS',
    receiveTempItems: 'RECEIVE_CREATED_EVENTS_TEMP',
    itemFailed: 'CREATED_EVENT_FAILED',
    itemsFailed: 'CREATED_EVENTS_FAILED',
  },
  comments: {
    clearItems: 'CLEAR_COMMENTS',
    selectItem: 'SELECT_COMMENT',
    invalidateItems: 'INVALIDATE_COMMENTS',
    invalidateTempItems: 'INVALIDATE_COMMENTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_COMMENT',
    confirmDeleteItem: 'CONFIRM_DELETE_COMMENT',
    sendEditItem: 'SEND_EDIT_COMMENT',
    confirmEditItem: 'CONFIRM_EDIT_COMMENT',
    sendAddItem: 'SEND_ADD_COMMENT',
    sendAddItems: 'SEND_ADD_COMMENTS',
    confirmAddItem: 'CONFIRM_ADD_COMMENT',
    confirmAddItems: 'CONFIRM_ADD_COMMENTS',
    confirmAddTempItems: 'CONFIRM_ADD_COMMENTS_TEMP',
    requestItem: 'REQUEST_COMMENT',
    requestItems: 'REQUEST_COMMENTS',
    requestChildItems: 'REQUEST_CHILD_COMMENTS',
    receiveChildItems: 'RECEIVE_CHILD_COMMENTS',
    requestChildItem: 'REQUEST_CHILD_COMMENT',
    receiveChildItem: 'REQUEST_CHILD_COMMENT',
    sendAddChildItem: 'SEND_ADD_CHILD_COMMENT',
    confirmAddChildItem: 'CONFIRM_ADD_CHILD_COMMENT',
    receiveItem: 'RECEIVE_COMMENT',
    receiveItems: 'RECEIVE_COMMENTS',
    receiveTempItems: 'RECEIVE_COMMENTS_TEMP',
    itemFailed: 'COMMENT_FAILED',
    itemsFailed: 'COMMENTS_FAILED',
  },
  blocks: {
    requestItems: 'BLOCKS_REQUEST_ITEMS',
    receiveItems: 'BLOCKS_RECEIVE_ITEMS',
  },
  doorComments: {
    selectItem: 'SELECT_DOOR_COMMENT',
    invalidateItems: 'INVALIDATE_DOOR_COMMENTS',
    invalidateTempItems: 'INVALIDATE_DOOR_COMMENTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_DOOR_COMMENT',
    confirmDeleteItem: 'CONFIRM_DELETE_DOOR_COMMENT',
    sendEditItem: 'SEND_EDIT_DOOR_COMMENT',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_COMMENT',
    sendAddItem: 'SEND_ADD_DOOR_COMMENT',
    sendAddItems: 'SEND_ADD_DOOR_COMMENTS',
    confirmAddItem: 'CONFIRM_ADD_DOOR_COMMENT',
    confirmAddItems: 'CONFIRM_ADD_DOOR_COMMENTS',
    confirmAddTempItems: 'CONFIRM_ADD_DOOR_COMMENTS_TEMP',
    requestItem: 'REQUEST_DOOR_COMMENT',
    requestItems: 'REQUEST_DOOR_COMMENTS',
    requestChildItems: 'REQUEST_CHILD_DOOR_COMMENTS',
    receiveChildItems: 'RECEIVE_CHILD_DOOR_COMMENTS',
    requestChildItem: 'REQUEST_CHILD_DOOR_COMMENT',
    receiveChildItem: 'REQUEST_CHILD_DOOR_COMMENT',
    sendAddChildItem: 'SEND_ADD_CHILD_DOOR_COMMENT',
    confirmAddChildItem: 'CONFIRM_ADD_CHILD_DOOR_COMMENT',
    receiveItem: 'RECEIVE_DOOR_COMMENT',
    receiveItems: 'RECEIVE_DOOR_COMMENTS',
    receiveTempItems: 'RECEIVE_DOOR_COMMENTS_TEMP',
    itemFailed: 'DOOR_COMMENT_FAILED',
    itemsFailed: 'DOOR_COMMENTS_FAILED',
  },
  connections: {
    selectItem: 'SELECT_CONNECTION',
    invalidateItems: 'INVALIDATE_CONNECTIONS',
    invalidateTempItems: 'INVALIDATE_CONNECTIONS_TEMP',
    sendDeleteItem: 'SEND_DELETE_CONNECTION',
    confirmDeleteItem: 'CONFIRM_DELETE_CONNECTION',
    sendEditItem: 'SEND_EDIT_CONNECTION',
    confirmEditItem: 'CONFIRM_EDIT_CONNECTION',
    sendAddItem: 'SEND_ADD_CONNECTION',
    sendAddItems: 'SEND_ADD_CONNECTIONS',
    confirmAddItem: 'CONFIRM_ADD_CONNECTION',
    confirmAddItems: 'CONFIRM_ADD_CONNECTIONS',
    confirmAddTempItems: 'CONFIRM_ADD_CONNECTIONS_TEMP',
    requestItem: 'REQUEST_CONNECTION',
    requestItems: 'REQUEST_CONNECTIONS',
    receiveItem: 'RECEIVE_CONNECTION',
    receiveItems: 'RECEIVE_CONNECTIONS',
    receiveTempItems: 'RECEIVE_CONNECTIONS_TEMP',
    itemFailed: 'CONNECTION_FAILED',
    itemsFailed: 'CONNECTIONS_FAILED',
  },
  concreteFeed: {
    requestItems: 'REQUEST_CONCRETE_FEED',
    receiveItems: 'RECEIVE_CONCRETE_FEED',
    itemsFailed: 'CONCRETE_FEED_FAILED',
  },
  crashFeed: {
    requestItems: 'REQUEST_CRASH_FEED',
    receiveItems: 'RECEIVE_CRASH_FEED',
    itemsFailed: 'CRASH_FEED_FAILED',
  },
  contacts: {
    selectItem: 'SELECT_CONTACT',
    selectOrder: 'SELECT_CONTACTS_ORDER',
    selectFilter: 'SELECT_CONTACTS_FILTER',
    selectProvider: 'SELECT_CONTACTS_PROVIDER',
    invalidateItems: 'INVALIDATE_CONTACTS',
    invalidateTempItems: 'INVALIDATE_CONNECTIONS_TEMP',
    sendDeleteItem: 'SEND_DELETE_CONTACT',
    confirmDeleteItem: 'CONFIRM_DELETE_CONTACT',
    sendEditItem: 'SEND_EDIT_CONTACT',
    confirmEditItem: 'CONFIRM_EDIT_CONTACT',
    sendAddItem: 'SEND_ADD_CONTACT',
    sendAddItems: 'SEND_ADD_CONTACTS',
    confirmAddItem: 'CONFIRM_ADD_CONTACT',
    confirmAddItems: 'CONFIRM_ADD_CONTACTS',
    confirmAddTempItems: 'CONFIRM_ADD_CONTACTS_TEMP',
    requestItem: 'REQUEST_CONTACT',
    requestItems: 'REQUEST_CONTACTS',
    requestInvitiableFriends: 'REQUEST_INVITABLE_FRIENDS',
    requestInvitiableManagers: 'REQEST_INVITABLE_MANAGERS',
    requestInvitiableSubscribers: 'REQUEST_INVITABLE_SUBSCRIBERS',
    receiveItem: 'RECEIVE_CONTACT',
    receiveItems: 'RECEIVE_CONTACTS',
    receiveTempItems: 'RECEIVE_CONTACTS_TEMP',
    clearTempItems: 'CLEAR_CONTACTS_TEMP',
    receiveInvitiableFriends: 'RECEIVE_INVITABLE_FRIENDS',
    receiveTempInvitiableFriends: 'RECEIVE_INVITABLE_FRIENDS_TEMP',
    receiveInvitiableManagers: 'RECEIVE_INVITABLE_MANAGERS',
    receiveTempInvitiableManagers: 'RECEIVE_INVITABLE_MANAGERS_TEMP',
    receiveInvitiableSubscribers: 'RECEIVE_INVITABLE_SUBSCRIBERS',
    receiveTempInvitiableSubscribers: 'RECEIVE_INVITABLE_SUBSCRIBERS_TEMP',
    itemFailed: 'CONTACT_FAILED',
    itemsFailed: 'CONTACTS_FAILED',
    sendAddToList: 'SEND_ADD_CONTACT_TO_LIST',
    confirmAddToList: 'CONFIRM_ADD_CONTACT_TO_LIST',
    sendStarContact: 'SEND_STAR_CONTACT',
    confirmStarContact: 'CONFIRM_STAR_CONTACT',
    sendFlagContact: 'SEND_FLAG_CONTACT',
    confirmFlagContact: 'CONFIRM_FLAG_CONTACT',
    toggleSelectContact: 'TOGGLE_SELECT_CONTACT',
    sendBlockUser: 'SEND_BLOCK_USER',
    confirmBlockUser: 'CONFIRM_BLOCK_USER',
    sendUnBlockUser: 'SEND_UNBLOCK_USER',
    confirmUnBlockUser: 'CONFIRM_UNBLOCK_USER',
  },
  contactRequests: {
    selectItem: 'SELECT_CONTACT_REQUEST',
    invalidateItems: 'INVALIDATE_CONTACT_REQUESTS',
    invalidateTempItems: 'INVALIDATE_CONTACT_REQUESTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_CONTACT_REQUEST',
    confirmDeleteItem: 'CONFIRM_DELETE_CONTACT_REQUEST',
    sendEditItem: 'SEND_EDIT_CONTACT_REQUEST',
    confirmEditItem: 'CONFIRM_EDIT_CONTACT_REQUEST',
    sendAddItem: 'SEND_ADD_CONTACT_REQUEST',
    sendAddItems: 'SEND_ADD_CONTACT_REQUESTS',
    confirmAddItem: 'CONFIRM_ADD_CONTACT_REQUEST',
    confirmAddItems: 'CONFIRM_ADD_CONTACT_REQUESTS',
    confirmAddTempItems: 'CONFIRM_ADD_CONTACT_REQUESTS_TEMP',
    requestItem: 'REQUEST_CONTACT_REQUEST',
    requestItems: 'REQUEST_CONTACT_REQUESTS',
    receiveItem: 'RECEIVE_CONTACT_REQUEST',
    receiveItems: 'RECEIVE_CONTACT_REQUESTS',
    receiveTempItems: 'RECEIVE_CONTACT_REQUESTS_TEMP',
    itemFailed: 'CONTACT_REQUEST_FAILED',
    itemsFailed: 'CONTACT_REQUESTS_FAILED',
  },
  contactRequestsPending: {
    selectItem: 'SELECT_CONTACT_REQUEST_PENDING',
    invalidateItems: 'INVALIDATE_CONTACT_REQUESTS_PENDING',
    invalidateTempItems: 'INVALIDATE_CONTACT_REQUESTS_PENDING_TEMP',
    sendDeleteItem: 'SEND_DELETE_CONTACT_REQUEST_PENDING',
    confirmDeleteItem: 'CONFIRM_DELETE_CONTACT_REQUEST_PENDING',
    sendEditItem: 'SEND_EDIT_CONTACT_REQUEST_PENDING',
    confirmEditItem: 'CONFIRM_EDIT_CONTACT_REQUEST_PENDING',
    sendAddItem: 'SEND_ADD_CONTACT_REQUEST_PENDING',
    sendAddItems: 'SEND_ADD_CONTACT_REQUESTS_PENDING',
    confirmAddItem: 'CONFIRM_ADD_CONTACT_REQUEST_PENDING',
    confirmAddItems: 'CONFIRM_ADD_CONTACT_REQUESTS_PENDING',
    confirmAddTempItems: 'CONFIRM_ADD_CONTACT_REQUESTS_PENDING_TEMP',
    requestItem: 'REQUEST_CONTACT_REQUEST_PENDING',
    requestItems: 'REQUEST_CONTACT_REQUESTS_PENDING',
    receiveItem: 'RECEIVE_CONTACT_REQUEST_PENDING',
    receiveItems: 'RECEIVE_CONTACT_REQUESTS_PENDING',
    receiveTempItems: 'RECEIVE_CONTACT_REQUESTS_PENDING_TEMP',
    itemFailed: 'CONTACT_REQUEST_PENDING_FAILED',
    itemsFailed: 'CONTACT_REQUESTS_PENDING_FAILED',
    sendAcceptRequest: 'SEND_ACCEPT_CONTACT_REQUEST_PENDING',
    confirmAcceptRequest: 'CONFIRM_ACCEPT_CONTACT_REQUEST_PENDING',
    sendDeclineRequest: 'SEND_DECLINE_CONTACT_REQUEST_PENDING',
    confirmDeclineRequest: 'CONFIRM_DECLINE_CONTACT_REQUEST_PENDING',
    sendAcceptAndStar: 'SEND_ACCEPT_AND_STAR_CONTACT_REQUEST_PENDING',
    confirmAcceptAndStar: 'CONFIRM_ACCEPT_AND_STAR_CONTACT_REQUEST_PENDING',
    sendFlagRequest: 'SEND_FLAG_CONTACT_REQUEST_PENDING',
    confirmFlagRequest: 'CONFIRM_FLAG_CONTACT_REQUEST_PENDING',
    sendBlockRequest: 'SEND_BLOCK_CONTACT_REQUEST_PENDING',
    confirmBlockRequest: 'CONFIRM_BLOCK_CONTACT_REQUEST_PENDING',
    sendDeleteRequest: 'SEND_DELETE_CONTACT_REQUEST_PENDING',
    confirmDeleteRequest: 'CONFIRM_DELETE_CONTACT_REQUEST_PENDING',
    sendAcceptAsAlias: 'SEND_ACCEPT_AS_ALIAS',
    sendBulkInvite: 'SEND_BULK_INVITE',
    confirmBulkInvite: 'CONFIRM_BULK_INVITE',
  },
  contactRequestsSent: {
    selectItem: 'SELECT_CONTACT_REQUEST_SENT',
    invalidateItems: 'INVALIDATE_CONTACT_REQUESTS_SENT',
    invalidateTempItems: 'INVALIDATE_CONTACT_REQUESTS_SENT_TEMP',
    sendDeleteItem: 'SEND_DELETE_CONTACT_REQUEST_SENT',
    confirmDeleteItem: 'CONFIRM_DELETE_CONTACT_REQUEST_SENT',
    sendEditItem: 'SEND_EDIT_CONTACT_REQUEST_SENT',
    confirmEditItem: 'CONFIRM_EDIT_CONTACT_REQUEST_SENT',
    sendAddItem: 'SEND_ADD_CONTACT_REQUEST_SENT',
    sendAddItems: 'SEND_ADD_CONTACT_REQUESTS_SENT',
    confirmAddItem: 'CONFIRM_ADD_CONTACT_REQUEST_SENT',
    confirmAddItems: 'CONFIRM_ADD_CONTACT_REQUESTS_SENT',
    confirmAddTempItems: 'CONFIRM_ADD_CONTACT_REQUESTS_SENT_TEMP',
    requestItem: 'REQUEST_CONTACT_REQUEST_SENT',
    requestItems: 'REQUEST_CONTACT_REQUESTS_SENT',
    receiveItem: 'RECEIVE_CONTACT_REQUEST_SENT',
    receiveItems: 'RECEIVE_CONTACT_REQUESTS_SENT',
    receiveTempItems: 'RECEIVE_CONTACT_REQUESTS_SENT_TEMP',
    itemFailed: 'CONTACT_REQUEST_SENT_FAILED',
    itemsFailed: 'CONTACT_REQUESTS_SENT_FAILED',
    sendRequest: 'SEND_CONTACT_REQUEST_SENT',
    confirmRequest: 'CONFIRM_CONTACT_REQUEST_SENT',
  },
  eventHosts: {
    selectItem: 'SELECT_EVENT_HOST',
    invalidateItems: 'INVALIDATE_EVENT_HOSTS',
    invalidateTempItems: 'INVALIDATE_EVENT_HOSTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_EVENT_HOST',
    confirmDeleteItem: 'CONFIRM_DELETE_EVENT_HOST',
    sendEditItem: 'SEND_EDIT_EVENT_HOST',
    confirmEditItem: 'CONFIRM_EDIT_EVENT_HOST',
    sendAddItem: 'SEND_ADD_EVENT_HOST',
    sendAddItems: 'SEND_ADD_EVENT_HOSTS',
    confirmAddItem: 'CONFIRM_ADD_EVENT_HOST',
    confirmAddItems: 'CONFIRM_ADD_EVENT_HOSTS',
    confirmAddTempItems: 'CONFIRM_ADD_EVENT_HOSTS_TEMP',
    requestItem: 'REQUEST_EVENT_HOST',
    requestItems: 'REQUEST_EVENT_HOSTS',
    receiveItem: 'RECEIVE_EVENT_HOST',
    receiveItems: 'RECEIVE_EVENT_HOSTS',
    receiveTempItems: 'RECEIVE_EVENT_HOSTS_TEMP',
    itemFailed: 'EVENT_HOST_FAILED',
    itemsFailed: 'EVENT_HOSTS_FAILED',
  },
  eventInvitableFriends: {
    selectItem: 'SELECT_EVENT_INVITABLE_FRIEND',
    invalidateItems: 'INVALIDATE_EVENT_INVITABLE_FRIENDS',
    invalidateTempItems: 'INVALIDATE_EVENT_INVITABLE_FRIENDS_TEMP',
    sendDeleteItem: 'SEND_DELETE_EVENT_INVITABLE_FRIEND',
    confirmDeleteItem: 'CONFIRM_DELETE_EVENT_INVITABLE_FRIEND',
    sendEditItem: 'SEND_EDIT_EVENT_INVITABLE_FRIEND',
    confirmEditItem: 'CONFIRM_EDIT_EVENT_INVITABLE_FRIEND',
    sendAddItem: 'SEND_ADD_EVENT_INVITABLE_FRIEND',
    sendAddItems: 'SEND_ADD_EVENT_INVITABLE_FRIENDS',
    confirmAddItem: 'CONFIRM_ADD_EVENT_INVITABLE_FRIEND',
    confirmAddItems: 'CONFIRM_ADD_EVENT_INVITABLE_FRIENDS',
    confirmAddTempItems: 'CONFIRM_ADD_EVENT_INVITABLE_FRIENDS_TEMP',
    requestItem: 'REQUEST_EVENT_INVITABLE_FRIEND',
    requestItems: 'REQUEST_EVENT_INVITABLE_FRIENDS',
    receiveItem: 'RECEIVE_EVENT_INVITABLE_FRIEND',
    receiveItems: 'RECEIVE_EVENT_INVITABLE_FRIENDS',
    receiveTempItems: 'RECEIVE_EVENT_INVITABLE_FRIENDS_TEMP',
    itemFailed: 'EVENT_INVITABLE_FRIEND_FAILED',
    itemsFailed: 'EVENT_INVITABLE_FRIENDS_FAILED',
  },
  eventInvitableManagers: {
    selectItem: 'SELECT_EVENT_INVITABLE_MANAGER',
    invalidateItems: 'INVALIDATE_EVENT_INVITABLE_MANAGERS',
    invalidateTempItems: 'INVALIDATE_EVENT_INVITABLE_MANAGERS_TEMP',
    sendDeleteItem: 'SEND_DELETE_EVENT_INVITABLE_MANAGER',
    confirmDeleteItem: 'CONFIRM_DELETE_EVENT_INVITABLE_MANAGER',
    sendEditItem: 'SEND_EDIT_EVENT_INVITABLE_MANAGER',
    confirmEditItem: 'CONFIRM_EDIT_EVENT_INVITABLE_MANAGER',
    sendAddItem: 'SEND_ADD_EVENT_INVITABLE_MANAGER',
    sendAddItems: 'SEND_ADD_EVENT_INVITABLE_MANAGERS',
    confirmAddItem: 'CONFIRM_ADD_EVENT_INVITABLE_MANAGER',
    confirmAddItems: 'CONFIRM_ADD_EVENT_INVITABLE_MANAGERS',
    confirmAddTempItems: 'CONFIRM_ADD_EVENT_INVITABLE_MANAGERS_TEMP',
    requestItem: 'REQUEST_EVENT_INVITABLE_MANAGER',
    requestItems: 'REQUEST_EVENT_INVITABLE_MANAGERS',
    receiveItem: 'RECEIVE_EVENT_INVITABLE_MANAGER',
    receiveItems: 'RECEIVE_EVENT_INVITABLE_MANAGERS',
    receiveTempItems: 'RECEIVE_EVENT_INVITABLE_MANAGERS_TEMP',
    itemFailed: 'EVENT_INVITABLE_MANAGER_FAILED',
    itemsFailed: 'EVENT_INVITABLE_MANAGERS_FAILED',
  },
  eventInvitableSubscribers: {
    selectItem: 'SELECT_EVENT_INVITABLE_SUBSCRIBER',
    invalidateItems: 'INVALIDATE_EVENT_INVITABLE_SUBSCRIBERS',
    invalidateTempItems: 'INVALIDATE_EVENT_INVITABLE_SUBSCRIBERS_TEMP',
    sendDeleteItem: 'SEND_DELETE_EVENT_INVITABLE_SUBSCRIBER',
    confirmDeleteItem: 'CONFIRM_DELETE_EVENT_INVITABLE_SUBSCRIBER',
    sendEditItem: 'SEND_EDIT_EVENT_INVITABLE_SUBSCRIBER',
    confirmEditItem: 'CONFIRM_EDIT_EVENT_INVITABLE_SUBSCRIBER',
    sendAddItem: 'SEND_ADD_EVENT_INVITABLE_SUBSCRIBER',
    sendAddItems: 'SEND_ADD_EVENT_INVITABLE_SUBSCRIBERS',
    confirmAddItem: 'CONFIRM_ADD_EVENT_INVITABLE_SUBSCRIBER',
    confirmAddItems: 'CONFIRM_ADD_EVENT_INVITABLE_SUBSCRIBERS',
    confirmAddTempItems: 'CONFIRM_ADD_EVENT_INVITABLE_SUBSCRIBERS_TEMP',
    requestItem: 'REQUEST_EVENT_INVITABLE_SUBSCRIBER',
    requestItems: 'REQUEST_EVENT_INVITABLE_SUBSCRIBERS',
    receiveItem: 'RECEIVE_EVENT_INVITABLE_SUBSCRIBER',
    receiveItems: 'RECEIVE_EVENT_INVITABLE_SUBSCRIBERS',
    receiveTempItems: 'RECEIVE_EVENT_INVITABLE_SUBSCRIBERS_TEMP',
    itemFailed: 'EVENT_INVITABLE_SUBSCRIBER_FAILED',
    itemsFailed: 'EVENT_INVITABLE_SUBSCRIBERS_FAILED',
  },
  eventExternalInvites: {
    selectItem: 'SELECT_EXTERNAL_INVITE',
    invalidateItems: 'INVALIDATE_EXTERNAL_INVITES',
    invalidateTempItems: 'INVALIDATE_EXTERNAL_INVITES_TEMP',
    sendDeleteItem: 'SEND_DELETE_EXTERNAL_INVITE',
    confirmDeleteItem: 'CONFIRM_DELETE_EXTERNAL_INVITE',
    sendEditItem: 'SEND_EDIT_EXTERNAL_INVITE',
    confirmEditItem: 'CONFIRM_EDIT_EXTERNAL_INVITE',
    sendAddItem: 'SEND_ADD_EXTERNAL_INVITE',
    sendAddItems: 'SEND_ADD_EXTERNAL_INVITES',
    confirmAddItem: 'CONFIRM_ADD_EXTERNAL_INVITE',
    confirmAddItems: 'CONFIRM_ADD_EXTERNAL_INVITES',
    confirmAddTempItems: 'CONFIRM_ADD_EXTERNAL_INVITES_TEMP',
    requestItem: 'REQUEST_EXTERNAL_INVITE',
    requestItems: 'REQUEST_EXTERNAL_INVITES',
    receiveItem: 'RECEIVE_EXTERNAL_INVITE',
    receiveItems: 'RECEIVE_EXTERNAL_INVITES',
    receiveTempItems: 'RECEIVE_EXTERNAL_INVITES_TEMP',
    requestInvitesCount: 'REQUEST_EVENT_EXTERNAL_INVITES_COUNT',
    receiveInvitesCount: 'RECEIVE_EVENT_EXTERNAL_INVITES_COUNT',
    receiveTempInvitesCount: 'RECEIVE_EVENT_EXTERNAL_INVITES_COUNT_TEMP',
    itemFailed: 'EXTERNAL_INVITE_FAILED',
    itemsFailed: 'EXTERNAL_INVITES_FAILED',
  },
  events: {
    selectItem: 'SELECT_EVENT',
    setVenue: 'SET_VENUE',
    invalidateItems: 'INVALIDATE_EVENTS',
    invalidateTempItems: 'INVALIDATE_EVENTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_EVENT',
    confirmDeleteItem: 'CONFIRM_DELETE_EVENT',
    sendEditItem: 'SEND_EDIT_EVENT',
    confirmEditItem: 'CONFIRM_EDIT_EVENT',
    sendAddItem: 'SEND_ADD_EVENT',
    sendAddItems: 'SEND_ADD_EVENTS',
    confirmAddItem: 'CONFIRM_ADD_EVENT',
    confirmAddItems: 'CONFIRM_ADD_EVENTS',
    confirmAddTempItems: 'CONFIRM_ADD_EVENTS_TEMP',
    requestItem: 'REQUEST_EVENT',
    requestItems: 'REQUEST_EVENTS',
    receiveItem: 'RECEIVE_EVENT',
    receiveItems: 'RECEIVE_EVENTS',
    receiveTempItems: 'RECEIVE_EVENTS_TEMP',
    itemFailed: 'EVENT_FAILED',
    itemsFailed: 'EVENTS_FAILED',
    sendInviteMe: 'EVENT_SEND_INVITE_ME',
    confirmInviteMe: 'EVENT_CONFIRM_INVITE_ME',
    clearItem: 'EVENT_CLEAR_ITEM',
    sendEventLoading: 'SEND_EVENT_LOADING',
    confirmEventLoading: 'CONFIRM_EVENT_LOADING',
    eventLoadingFailed: 'EVENT_LOADING_FAILED',
    requestExternalInvites: 'REQUEST_EXTERNAL_INVITES',
    receiveExternalInvites: 'RECEIVE_EXTERNAL_INVITES',
  },
  eventCategories: {
    selectItem: 'SELECT_EVENT_CATEGORY',
    invalidateItems: 'INVALIDATE_EVENT_CATEGORIES',
    invalidateTempItems: 'INVALIDATE_EVENT_CATEGORIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_EVENT_CATEGORY',
    confirmDeleteItem: 'CONFIRM_DELETE_EVENT_CATEGORY',
    sendEditItem: 'SEND_EDIT_EVENT_CATEGORY',
    confirmEditItem: 'CONFIRM_EDIT_EVENT_CATEGORY',
    sendAddItem: 'SEND_ADD_EVENT_CATEGORY',
    sendAddItems: 'SEND_ADD_USER_OTHER_CATEGORIES',
    confirmAddItem: 'CONFIRM_ADD_EVENT_CATEGORY',
    confirmAddItems: 'CONFIRM_ADD_EVENT_CATEGORIES',
    requestItem: 'REQUEST_EVENT_CATEGORY',
    requestItems: 'REQUEST_EVENT_CATEGORIES',
    receiveItem: 'RECEIVE_EVENT_CATEGORY',
    receiveItems: 'RECEIVE_EVENT_CATEGORIES',
    receiveTempItems: 'RECEIVE_EVENT_CATEGORIES_TEMP',
    itemFailed: 'EVENT_CATEGORY_FAILED',
    itemsFailed: 'EVENT_CATEGORIES_FAILED',
  },
  facebookFriends: {
    selectItem: 'SELECT_FACEBOOK_FRIEND',
    invalidateItems: 'INVALIDATE_FACEBOOK_FRIENDS',
    invalidateTempItems: 'INVALIDATE_FACEBOOK_FRIENDS_TEMP',
    sendDeleteItem: 'SEND_DELETE_FACEBOOK_FRIEND',
    confirmDeleteItem: 'CONFIRM_DELETE_FACEBOOK_FRIEND',
    sendEditItem: 'SEND_EDIT_FACEBOOK_FRIEND',
    confirmEditItem: 'CONFIRM_EDIT_FACEBOOK_FRIEND',
    sendAddItem: 'SEND_ADD_FACEBOOK_FRIEND',
    sendAddItems: 'SEND_ADD_FACEBOOK_FRIENDS',
    confirmAddItem: 'CONFIRM_ADD_FACEBOOK_FRIEND',
    confirmAddItems: 'CONFIRM_ADD_FACEBOOK_FRIENDS',
    confirmAddTempItems: 'CONFIRM_ADD_FACEBOOK_FRIENDS_TEMP',
    requestItem: 'REQUEST_FACEBOOK_FRIEND',
    requestItems: 'REQUEST_FACEBOOK_FRIENDS',
    receiveItem: 'RECEIVE_FACEBOOK_FRIEND',
    receiveItems: 'RECEIVE_FACEBOOK_FRIENDS',
    receiveTempItems: 'RECEIVE_FACEBOOK_FRIENDS_TEMP',
    itemFailed: 'FACEBOOK_FRIEND_FAILED',
    itemsFailed: 'FACEBOOK_FRIENDS_FAILED',
  },
  feed: {
    clearItems: 'CLEAR_FEED',
    selectItem: 'SELECT_FEED_ITEM',
    invalidateItems: 'INVALIDATE_FEED',
    invalidateTempItems: 'INVALIDATE_FEED_TEMP',
    sendDeleteItem: 'SEND_DELETE_FEED_ITEM',
    confirmDeleteItem: 'CONFIRM_DELETE_FEED_ITEM',
    sendEditItem: 'SEND_EDIT_FEED_ITEM',
    confirmEditItem: 'CONFIRM_EDIT_FEED_ITEM',
    sendAddItem: 'SEND_ADD_FEED_ITEM',
    sendAddItems: 'SEND_ADD_FEED',
    confirmAddItem: 'CONFIRM_ADD_FEED_ITEM',
    confirmAddItems: 'CONFIRM_ADD_FEED',
    confirmAddTempItems: 'CONFIRM_ADD_FEED_TEMP',
    requestItem: 'REQUEST_FEED_ITEM',
    requestItems: 'REQUEST_FEED',
    receiveItem: 'RECEIVE_FEED_ITEM',
    receiveItems: 'RECEIVE_FEED',
    receiveTempItems: 'RECEIVE_FEED_TEMP',
    itemFailed: 'FEED_ITEM_FAILED',
    itemsFailed: 'FEED_FAILED',
    hoverEvent: 'FEED_HOVER_EVENT',
    unHoverEvent: 'FEED_UNHOVER_EVENT',
    sendRSVPToEvent: 'FEED_SEND_RSVP_TO_EVENT',
    confirmRSVPToEvent: 'FEED_CONFIRM_RSVP_TO_EVENT',
  },
  flares: {
    selectItem: 'SELECT_FLARE',
    invalidateItems: 'INVALIDATE_FLARES',
    invalidateTempItems: 'INVALIDATE_FLARES_TEMP',
    sendDeleteItem: 'SEND_DELETE_FLARE',
    confirmDeleteItem: 'CONFIRM_DELETE_FLARE',
    sendEditItem: 'SEND_EDIT_FLARE',
    confirmEditItem: 'CONFIRM_EDIT_FLARE',
    sendAddItem: 'SEND_ADD_FLARE',
    sendAddItems: 'SEND_ADD_FLARES',
    confirmAddItem: 'CONFIRM_ADD_FLARE',
    confirmAddItems: 'CONFIRM_ADD_FLARES',
    confirmAddTempItems: 'CONFIRM_ADD_FLARES_TEMP',
    requestItem: 'REQUEST_FLARE',
    requestItems: 'REQUEST_FLARES',
    receiveItem: 'RECEIVE_FLARE',
    receiveItems: 'RECEIVE_FLARES',
    receiveTempItems: 'RECEIVE_FLARES_TEMP',
    itemFailed: 'FLARE_FAILED',
    itemsFailed: 'FLARES_FAILED',
    hoverEvent: 'FLARES_HOVER_EVENT',
    unHoverEvent: 'FLARES_UNHOVER_EVENT',
    sendRSVPToEvent: 'FLARES_SEND_RSVP_TO_EVENT',
    confirmRSVPToEvent: 'FLARES_CONFIRM_RSVP_TO_EVENT',
  },
  images: {
    requestImageUrls: 'REQUEST_IMAGE_URLS',
    receiveImageUrls: 'RECEIVE_IMAGE_URLS',
    requestCoverImageUrls: 'REQUEST_COVER_IMAGE_URLS',
    receiveCoverImageUrls: 'RECEIVE_COVER_IMAGE_URLS',
    sendUploadImage: 'SEND_UPLOAD_IMAGE',
    confirmUploadImage: 'CONFIRM_UPLOAD_IMAGE',
    sendUploadCoverImage: 'SEND_UPLOAD_COVER_IMAGE',
    confirmUploadCoverImage: 'CONFIRM_UPLOAD_COVER_IMAGE',
    requestEventImageUrls: 'REQUEST_EVENT_IMAGE_URLS',
    receiveEventImageUrls: 'RECEIVE_EVENT_IMAGE_URLS',
    requestDoorImageUrls: 'REQUEST_DOOR_IMAGE_URLS',
    receiveDoorImageUrls: 'RECEIVE_DOOR_IMAGE_URLS',
    requestDoorCoverImageUrls: 'REQUEST_DOOR_COVER_IMAGE_URLS',
    receiveDoorCoverImageUrls: 'RECEIVE_DOOR_COVER_IMAGE_URLS',
    requestUserImageUrls: 'REQUEST_USER_IMAGE_URLS',
    receiveUserImageUrls: 'RECEIVE_USER_IMAGE_URLS',
    sendUploadEventImage: 'SEND_UPLOAD_EVENT_IMAGE',
    sendUploadUserImage: 'SEND_UPLOAD_USER_IMAGE',
    sendUploadDoorImage: 'SEND_UPLOAD_USER_IMAGE',
    sendUploadDoorCoverImage: 'SEND_UPLOAD_DOOR_COVER_IMAGE',
    confirmUploadDoorCoverImage: 'CONFIRM_UPLOAD_DOOR_COVER_IMAGE',
    confirmUploadDoorImage: 'CONFIRM_UPLOAD_DOOR_IMAGE',
    confirmUploadEventImage: 'CONFIRM_UPLOAD_EVENT_IMAGE',
    confirmUploadUserImage: 'CONFIRM_UPLOAD_USER_IMAGE',
    stageImage: 'STAGE_IMAGE',
    stageCoverImage: 'STAGE_COVER_IMAGE',
    stageEventImage: 'STAGE_EVENT_IMAGE',
    clearStagedImage: 'CLEAR_STAGED_IMAGE',
    clearStagedCoverImage: 'CLEAR_STAGED_COVER_IMAGE',
    imageUrlsFailed: 'IMAGE_URLS_FAILED',
    coverImageUrlsFailed: 'COVER_IMAGE_URLS_FAILED',
    uploadImageFailed: 'UPLOAD_IMAGE_FAILED',
    uploadCoverImageFailed: 'UPLOAD_COVER_IMAGE_FAILED',
    imagesUrlGetFail: 'IMAGES_URL_GET_FAIL',
    uploadImageFail: 'UPLOAD_IMAGE_FAIL',
  },
  invites: {
    selectItem: 'SELECT_INVITE',
    invalidateItems: 'INVALIDATE_INVITES',
    invalidateTempItems: 'INVALIDATE_INVITES_TEMP',
    sendDeleteItem: 'SEND_DELETE_INVITE',
    confirmDeleteItem: 'CONFIRM_DELETE_INVITE',
    sendEditItem: 'SEND_EDIT_INVITE',
    confirmEditItem: 'CONFIRM_EDIT_INVITE',
    sendAddItem: 'SEND_ADD_INVITE',
    sendAddItems: 'SEND_ADD_INVITES',
    confirmAddItem: 'CONFIRM_ADD_INVITE',
    confirmAddItems: 'CONFIRM_ADD_INVITES',
    confirmAddTempItems: 'CONFIRM_ADD_INVITES_TEMP',
    requestItem: 'REQUEST_INVITE',
    requestItems: 'REQUEST_INVITES',
    receiveItem: 'RECEIVE_INVITE',
    receiveItems: 'RECEIVE_INVITES',
    receiveTempItems: 'RECEIVE_INVITES_TEMP',
    sendInviteFriend: 'SEND_INVITE_FRIEND',
    confirmInviteFriend: 'CONFIRM_INVITE_FRIEND',
    requestInvitesCount: 'REQUEST_EVENT_INVITES_COUNT',
    receiveInvitesCount: 'RECEIVE_EVENT_INVITES_COUNT',
    receiveTempInvitesCount: 'RECEIVE_EVENT_INVITES_COUNT_TEMP',
    sendRespondToInvite: 'SEND_RESPOND_TO_INVITE',
    confirmRespondToInvite: 'CONFIRM_RESPOND_TO_INVITE',
    itemFailed: 'INVITE_FAILED',
    itemsFailed: 'INVITES_FAILED',
  },
  notifications: {
    selectItem: 'SELECT_NOTIFICATION',
    invalidateItems: 'INVALIDATE_NOTIFICATIONS',
    invalidateTempItems: 'INVALIDATE_NOTIFICATIONS_TEMP',
    sendDeleteItem: 'SEND_DELETE_NOTIFICATION',
    confirmDeleteItem: 'CONFIRM_DELETE_NOTIFICATION',
    sendEditItem: 'SEND_EDIT_NOTIFICATION',
    confirmEditItem: 'CONFIRM_EDIT_NOTIFICATION',
    sendAddItem: 'SEND_ADD_NOTIFICATION',
    sendAddItems: 'SEND_ADD_NOTIFICATIONS',
    confirmAddItem: 'CONFIRM_ADD_NOTIFICATION',
    confirmAddItems: 'CONFIRM_ADD_NOTIFICATIONS',
    requestItem: 'REQUEST_NOTIFICATION',
    requestItems: 'REQUEST_NOTIFICATIONS',
    receiveItem: 'RECEIVE_NOTIFICATION',
    receiveItems: 'RECEIVE_NOTIFICATIONS',
    receiveTempItems: 'RECEIVE_NOTIFICATIONS_TEMP',
    itemFailed: 'NOTIFICATION_FAILED',
    itemsFailed: 'NOTIFICATIONS_FAILED',
    sendMarkAll: 'NOTIFICATIONS_SEND_MARK_ALL',
    confirmMarkAll: 'NOTIFICATIONS_CONFIRM_MARK_ALL',
    requestNotificationTallies: 'REQUEST_NOTIFICATION_TALLIES',
    receiveNotificationTallies: 'RECEIVE_NOTIFICATION_TALLIES',
  },
  doorSeries: {
    requestItems: 'REQUEST_DOOR_SERIES',
    receiveItems: 'RECEIVE_DOOR_SERIES',
    itemFailed: 'DOOR_SERIES_FAILED',
    sendItemLoading: 'SEND_DOOR_SERIES_LOADING',
    confirmItemLoading: 'CONFIRM_DOOR_SERIES_LOADING',
    itemLoadingFailed: 'DOOR_SERIES_LOADING_FAILED',
    sendDeleteItem: 'SEND_DELETE_DOOR_SERIE',
    confirmDeleteItem: 'CONFIRM_DELETE_DOOR_SERIE',
    sendEditItem: 'SEND_EDIT_DOOR_SERIE',
    confirmEditItem: 'CONFIRM_EDIT_DOOR_SERIE',
  },
  doorSeriesCategories: {
    selectItem: 'SELECT_SERIES_CATEGORY',
    invalidateItems: 'INVALIDATE_SERIES_CATEGORIES',
    invalidateTempItems: 'INVALIDATE_SERIES_CATEGORIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_SERIES_CATEGORY',
    confirmDeleteItem: 'CONFIRM_DELETE_SERIES_CATEGORY',
    sendEditItem: 'SEND_EDIT_SERIES_CATEGORY',
    confirmEditItem: 'CONFIRM_EDIT_SERIES_CATEGORY',
    sendAddItem: 'SEND_ADD_SERIES_CATEGORY',
    sendAddItems: 'SEND_ADD_USER_OTHER_CATEGORIES',
    confirmAddItem: 'CONFIRM_ADD_SERIES_CATEGORY',
    confirmAddItems: 'CONFIRM_ADD_SERIES_CATEGORIES',
    requestItem: 'REQUEST_SERIES_CATEGORY',
    requestItems: 'REQUEST_SERIES_CATEGORIES',
    receiveItem: 'RECEIVE_SERIES_CATEGORY',
    receiveItems: 'RECEIVE_SERIES_CATEGORIES',
    receiveTempItems: 'RECEIVE_SERIES_CATEGORIES_TEMP',
    itemFailed: 'SERIES_CATEGORY_FAILED',
    itemsFailed: 'SERIES_CATEGORIES_FAILED',
  },
  doorSeriesHosts: {
    selectItem: 'SELECT_SERIES_HOST',
    invalidateItems: 'INVALIDATE_SERIES_HOSTS',
    invalidateTempItems: 'INVALIDATE_SERIES_HOSTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_SERIES_HOST',
    confirmDeleteItem: 'CONFIRM_DELETE_SERIES_HOST',
    sendEditItem: 'SEND_EDIT_SERIES_HOST',
    confirmEditItem: 'CONFIRM_EDIT_SERIES_HOST',
    sendAddItem: 'SEND_ADD_SERIES_HOST',
    sendAddItems: 'SEND_ADD_USER_OTHER_HOSTS',
    confirmAddItem: 'CONFIRM_ADD_SERIES_HOST',
    confirmAddItems: 'CONFIRM_ADD_SERIES_HOSTS',
    requestItem: 'REQUEST_SERIES_HOST',
    requestItems: 'REQUEST_SERIES_HOSTS',
    receiveItem: 'RECEIVE_SERIES_HOST',
    receiveItems: 'RECEIVE_SERIES_HOSTS',
    receiveTempItems: 'RECEIVE_SERIES_HOSTS_TEMP',
    itemFailed: 'SERIES_HOST_FAILED',
    itemsFailed: 'SERIES_HOSTS_FAILED',
  },
  photos: {
    requestAlbums: 'REQUEST_ALBUMS',
    receiveAlbums: 'RECEIVE_ALBUMS',
    requestAlbum: 'REQUEST_ALBUM',
    receiveAlbum: 'RECEIVE_ALBUM',
    requestPhotos: 'REQUEST_PHOTOS',
    receivePhotos: 'RECEIVE_PHOTOS',
    requestPhoto: 'REQUEST_PHOTO',
    receivePhoto: 'RECEIVE_PHOTO',
    sendAddAlbum: 'SEND_ADD_ALBUM',
    confirmAddAlbum: 'CONFIRM_ADD_ALBUM',
    sendAddPhoto: 'SEND_ADD_PHOTO',
    confirmAddPhoto: 'CONFIRM_ADD_PHOTO',
    sendEditAlbum: 'SEND_EDIT_ALBUM',
    confirmEditAlbum: 'CONFIRM_EDIT_ALBUM',
    sendEditPhoto: 'SEND_EDIT_PHOTO',
    confirmEditPhoto: 'CONFIRM_EDIT_PHOTO',
    sendDeleteAlbum: 'SEND_DELETE_ALBUM',
    confirmDeleteAlbum: 'CONFIRM_DELETE_ALBUM',
    sendDeletePhoto: 'SEND_DELETE_PHOTO',
    confirmDeletePhoto: 'CONFIRM_DELETE_PHOTO',
    sendMovePhoto: 'SEND_MOVE_PHOTO',
    confirmMovePhoto: 'CONFIRM_MOVE_PHOTO',
    albumsFailed: 'ALBUMS_FAILED',
    albumFailed: 'ALBUM_FAILED',
    photosFailed: 'PHOTOS_FAILED',
    photoFailed: 'PHOTO_FAILED',
  },
  series: {
    selectItem: 'SELECT_SERIE',
    invalidateItems: 'INVALIDATE_SERIES',
    invalidateTempItems: 'INVALIDATE_SERIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_SERIE',
    confirmDeleteItem: 'CONFIRM_DELETE_SERIE',
    sendEditItem: 'SEND_EDIT_SERIE',
    confirmEditItem: 'CONFIRM_EDIT_SERIE',
    sendSwitchAlias: 'SEND_SWITCH_SERIE',
    confirmSwitchAlias: 'CONFIRM_SWITCH_SERIE',
    sendAddItem: 'SEND_ADD_SERIE',
    sendAddItems: 'SEND_ADD_SERIES',
    confirmAddItem: 'CONFIRM_ADD_SERIE',
    confirmAddItems: 'CONFIRM_ADD_SERIE',
    confirmAddTempItems: 'CONFIRM_ADD_SERIE_TEMP',
    requestItem: 'REQUEST_SERIE',
    requestItems: 'REQUEST_SERIES',
    receiveItem: 'RECEIVE_SERIE',
    receiveItems: 'RECEIVE_SERIES',
    receiveTempItems: 'RECEIVE_SERIES_TEMP',
    itemFailed: 'DOOR_FAILED',
    itemsFailed: 'DOORS_FAILED',
    sendSeriesEventLoading: 'SEND_SERIES_EVENT_LOADING',
    confirmSeriesEventLoaded: 'CONFIRM_SERIES_EVENT_LOADED',
    confirmDeleteEvent: 'CONFIRM_DELETE_SERIES_EVENT',
    confirmEditSeriesEvent: 'CONFIRM_EDIT_SERIES_EVENT',
  },
  subscriptions: {
    selectItem: 'SELECT_SUBSCRIPTION',
    invalidateItems: 'INVALIDATE_SUBSCRIPTIONS',
    invalidateTempItems: 'INVALIDATE_SUBSCRIPTIONS_TEMP',
    sendDeleteItem: 'SEND_DELETE_SUBSCRIPTION',
    confirmDeleteItem: 'CONFIRM_DELETE_SUBSCRIPTION',
    sendEditItem: 'SEND_EDIT_SUBSCRIPTION',
    confirmEditItem: 'CONFIRM_EDIT_SUBSCRIPTION',
    sendAddItem: 'SEND_ADD_SUBSCRIPTION',
    sendAddItems: 'SEND_ADD_SUBSCRIPTIONS',
    confirmAddItem: 'CONFIRM_ADD_SUBSCRIPTION',
    confirmAddItems: 'CONFIRM_ADD_SUBSCRIPTIONS',
    requestItem: 'REQUEST_SUBSCRIPTION',
    requestItems: 'REQUEST_SUBSCRIPTIONS',
    receiveItem: 'RECEIVE_SUBSCRIPTION',
    receiveItems: 'RECEIVE_SUBSCRIPTIONS',
    receiveTempItems: 'RECEIVE_SUBSCRIPTIONS_TEMP',
    itemFailed: 'SUBSCRIPTION_FAILED',
    itemsFailed: 'SUBSCRIPTIONS_FAILED',
    requestSeriesSubscriptions: 'REQUEST_SERIES_SUBSCRIPTIONS',
    receiveSeriesSubscriptions: 'RECEIVE_SERIES_SUBSCRIPTIONS',
  },
  seriesSubscriptions: {
    requestItems: 'REQUEST_SERIES_SUBSCRIPTIONS',
    receiveItems: 'RECEIVE_SERIES_SUBSCRIPTIONS',
  },
  superCategories: {
    selectItem: 'SELECT_SUPER_CATEGORY',
    invalidateItems: 'INVALIDATE_SUPER_CATEGORIES',
    invalidateTempItems: 'INVALIDATE_SUPER_CATEGORIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_SUPER_CATEGORY',
    confirmDeleteItem: 'CONFIRM_DELETE_SUPER_CATEGORY',
    sendEditItem: 'SEND_EDIT_SUPER_CATEGORY',
    confirmEditItem: 'CONFIRM_EDIT_SUPER_CATEGORY',
    sendAddItem: 'SEND_ADD_SUPER_CATEGORY',
    sendAddItems: 'SEND_ADD_SUPER_CATEGORIES',
    confirmAddItem: 'CONFIRM_ADD_SUPER_CATEGORY',
    confirmAddItems: 'CONFIRM_ADD_SUPER_CATEGORIES',
    requestItem: 'REQUEST_SUPER_CATEGORY',
    requestItems: 'REQUEST_SUPER_CATEGORIES',
    receiveItem: 'RECEIVE_SUPER_CATEGORY',
    receiveItems: 'RECEIVE_SUPER_CATEGORIES',
    receiveTempItems: 'RECEIVE_SUPER_CATEGORIES_TEMP',
    itemFailed: 'SUPER_CATEGORY_FAILED',
    itemsFailed: 'SUPER_CATEGORIES_FAILED',
    clearTempItems: 'CLEAR_SELECTED_SUPER_CATEGORIES',
  },
  superSubCategories: {
    selectItem: 'SELECT_SUPER_SUB_CATEGORY',
    invalidateItems: 'INVALIDATE_SUPER_SUB_CATEGORIES',
    invalidateTempItems: 'INVALIDATE_SUPER_SUB_CATEGORIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_SUPER_SUB_CATEGORY',
    confirmDeleteItem: 'CONFIRM_DELETE_SUPER_SUB_CATEGORY',
    sendEditItem: 'SEND_EDIT_SUPER_SUB_CATEGORY',
    confirmEditItem: 'CONFIRM_EDIT_SUPER_SUB_CATEGORY',
    sendAddItem: 'SEND_ADD_SUPER_SUB_CATEGORY',
    sendAddItems: 'SEND_ADD_SUPER_SUB_CATEGORIES',
    confirmAddItem: 'CONFIRM_ADD_SUPER_SUB_CATEGORY',
    confirmAddItems: 'CONFIRM_ADD_SUPER_SUB_CATEGORIES',
    clearCategoryLinks: 'CLEAR_CATEGORY_LINKS',
    requestItem: 'REQUEST_SUPER_SUB_CATEGORY',
    requestItems: 'REQUEST_SUPER_SUB_CATEGORIES',
    requestCategoryLinks: 'REQUEST_CATEGORY_LINKS',
    receiveItem: 'RECEIVE_SUPER_SUB_CATEGORY',
    receiveItems: 'RECEIVE_SUPER_SUB_CATEGORIES',
    receiveTempItems: 'RECEIVE_SUPER_SUB_CATEGORIES_TEMP',
    receiveCategoryLinks: 'RECEIVE_CATEGORY_LINKS',
    itemFailed: 'SUPER_SUB_CATEGORY_FAILED',
    itemsFailed: 'SUPER_SUB_CATEGORIES_FAILED',
  },
  trips: {
    selectItem: 'SELECT_TRIP',
    invalidateItems: 'INVALIDATE_TRIPS',
    invalidateTempItems: 'INVALIDATE_TRIPS_TEMP',
    sendDeleteItem: 'SEND_DELETE_TRIP',
    confirmDeleteItem: 'CONFIRM_DELETE_TRIP',
    sendEditItem: 'SEND_EDIT_TRIP',
    confirmEditItem: 'CONFIRM_EDIT_TRIP',
    sendAddItem: 'SEND_ADD_TRIP',
    sendAddItems: 'SEND_ADD_TRIPS',
    confirmAddItem: 'CONFIRM_ADD_TRIP',
    confirmAddItems: 'CONFIRM_ADD_TRIPS',
    requestItem: 'REQUEST_TRIP',
    requestItems: 'REQUEST_TRIPS',
    requestViewableTrips: 'REQUEST_VIEWABLE_TRIPS',
    receiveViewableTrips: 'RECEIVE_VIEWABLE_TRIPS',
    requestTraveledTrips: 'REQUEST_TRAVELED_TRIPS',
    receiveTraveledTrips: 'RECEIVE_TRAVELED_TRIPS',
    receiveItem: 'RECEIVE_TRIP',
    receiveItems: 'RECEIVE_TRIPS',
    receiveTempItems: 'RECEIVE_TRIPS_TEMP',
    itemFailed: 'TRIP_FAILED',
    itemsFailed: 'TRIPS_FAILED',
    toggleAddingTravelers: 'TRIPS_TOGGLE_ADDING_TRAVELERS',
    sendDeleteTraveler: 'TRIPS_SEND_DELETE_TRAVELER',
    confirmDeleteTraveler: 'TRIPS_CONFIRM_DELETE_TRAVELER',
    sendDeleteViewer: 'TRIPS_SEND_DELETE_VIEWER',
    confirmDeleteViewer: 'TRIPS_CONFIRM_DELETE_VIEWER',
    toggleNewDestingation: 'TRIPS_TOGGLE_NEW_DESTINATION',
    sendAddTravelers: 'TRIPS_SEND_ADD_TRAVELERS',
    confirmAddTravelers: 'TRIPS_CONFIRM_ADD_TRAVELERS',
    sendAddViewers: 'TRIPS_SEND_ADD_VIEWERS',
    confirmAddViewers: 'TRIPS_CONFIRM_ADD_VIEWERS',
  },
  tripLegs: {
    selectItem: 'SELECT_TRIP_LEG',
    invalidateItems: 'INVALIDATE_TRIP_LEGS',
    invalidateTempItems: 'INVALIDATE_TRIP_LEGS_TEMP',
    sendDeleteItem: 'SEND_DELETE_TRIP_LEG',
    confirmDeleteItem: 'CONFIRM_DELETE_TRIP_LEG',
    sendEditItem: 'SEND_EDIT_TRIP_LEG',
    confirmEditItem: 'CONFIRM_EDIT_TRIP_LEG',
    sendAddItem: 'SEND_ADD_TRIP_LEG',
    sendAddItems: 'SEND_ADD_TRIP_LEGS',
    confirmAddItem: 'CONFIRM_ADD_TRIP_LEG',
    confirmAddItems: 'CONFIRM_ADD_TRIP_LEGS',
    requestItem: 'REQUEST_TRIP_LEG',
    requestItems: 'REQUEST_TRIP_LEGS',
    receiveItem: 'RECEIVE_TRIP_LEG',
    receiveItems: 'RECEIVE_TRIP_LEGS',
    receiveTempItems: 'RECEIVE_TRIP_LEGS_TEMP',
    itemFailed: 'TRIP_LEG_FAILED',
    itemsFailed: 'TRIP_LEGS_FAILED',
    toggleSelectLeg: 'TRIP_LEGS_TOGGLE_SELECT_LEG',
    toggleSelectAllLegs: 'TRIP_LEGS_TOGGLE_SELECT_ALL_LEGS',
    requestLegEvents: 'TRIP_LEGS_REQUEST_EVENTS',
    receiveLegEvents: 'TRIP_LEGS_RECEIVE_EVENTS',
    sendDeleteTraveler: 'TRIP_LEGS_SEND_DELETE_TRAVELER',
    sendDeleteViewer: 'TRIP_LEGS_SEND_DELETE_VIEWER',
  },
  tripViewers: {
    selectItem: 'SELECT_TRIP_VIEWER',
    invalidateItems: 'INVALIDATE_TRIP_VIEWERS',
    invalidateTempItems: 'INVALIDATE_TRIP_VIEWERS_TEMP',
    sendDeleteItem: 'SEND_DELETE_TRIP_VIEWER',
    confirmDeleteItem: 'CONFIRM_DELETE_TRIP_VIEWER',
    sendEditItem: 'SEND_EDIT_TRIP_VIEWER',
    confirmEditItem: 'CONFIRM_EDIT_TRIP_VIEWER',
    sendAddItem: 'SEND_ADD_TRIP_VIEWER',
    sendAddItems: 'SEND_ADD_TRIP_VIEWERS',
    confirmAddItem: 'CONFIRM_ADD_TRIP_VIEWER',
    confirmAddItems: 'CONFIRM_ADD_TRIP_VIEWERS',
    requestItem: 'REQUEST_TRIP_VIEWER',
    requestItems: 'REQUEST_TRIP_VIEWERS',
    receiveItem: 'RECEIVE_TRIP_VIEWER',
    receiveItems: 'RECEIVE_TRIP_VIEWERS',
    receiveTempItems: 'RECEIVE_TRIP_VIEWERS_TEMP',
    itemFailed: 'TRIP_VIEWER_FAILED',
    itemsFailed: 'TRIP_VIEWERS_FAILED',
  },
  tripTravelers: {
    selectItem: 'SELECT_TRIP_TRAVELER',
    invalidateItems: 'INVALIDATE_TRIP_TRAVELERS',
    invalidateTempItems: 'INVALIDATE_TRIP_TRAVELERS_TEMP',
    sendDeleteItem: 'SEND_DELETE_TRIP_TRAVELER',
    confirmDeleteItem: 'CONFIRM_DELETE_TRIP_TRAVELER',
    sendEditItem: 'SEND_EDIT_TRIP_TRAVELER',
    confirmEditItem: 'CONFIRM_EDIT_TRIP_TRAVELER',
    sendAddItem: 'SEND_ADD_TRIP_TRAVELER',
    sendAddItems: 'SEND_ADD_TRIP_TRAVELERS',
    confirmAddItem: 'CONFIRM_ADD_TRIP_TRAVELER',
    confirmAddItems: 'CONFIRM_ADD_TRIP_TRAVELERS',
    requestItem: 'REQUEST_TRIP_TRAVELER',
    requestItems: 'REQUEST_TRIP_TRAVELERS',
    receiveItem: 'RECEIVE_TRIP_TRAVELER',
    receiveItems: 'RECEIVE_TRIP_TRAVELERS',
    receiveTempItems: 'RECEIVE_TRIP_TRAVELERS_TEMP',
    itemFailed: 'TRIP_TRAVELER_FAILED',
    itemsFailed: 'TRIP_TRAVELERS_FAILED',
  },
  tripLegTravelers: {
    selectItem: 'SELECT_TRIP_LEG_TRAVELER',
    invalidateItems: 'INVALIDATE_TRIP_LEG_TRAVELERS',
    invalidateTempItems: 'INVALIDATE_TRIP_LEG_TRAVELERS_TEMP',
    sendDeleteItem: 'SEND_DELETE_TRIP_LEG_TRAVELER',
    confirmDeleteItem: 'CONFIRM_DELETE_TRIP_LEG_TRAVELER',
    sendEditItem: 'SEND_EDIT_TRIP_LEG_TRAVELER',
    confirmEditItem: 'CONFIRM_EDIT_TRIP_LEG_TRAVELER',
    sendAddItem: 'SEND_ADD_TRIP_LEG_TRAVELER',
    sendAddItems: 'SEND_ADD_TRIP_LEG_TRAVELERS',
    confirmAddItem: 'CONFIRM_ADD_TRIP_LEG_TRAVELER',
    confirmAddItems: 'CONFIRM_ADD_TRIP_LEG_TRAVELERS',
    requestItem: 'REQUEST_TRIP_LEG_TRAVELER',
    requestItems: 'REQUEST_TRIP_LEG_TRAVELERS',
    receiveItem: 'RECEIVE_TRIP_LEG_TRAVELER',
    receiveItems: 'RECEIVE_TRIP_LEG_TRAVELERS',
    receiveTempItems: 'RECEIVE_TRIP_LEG_TRAVELERS_TEMP',
    itemFailed: 'TRIP_LEG_TRAVELER_FAILED',
    itemsFailed: 'TRIP_LEG_TRAVELERS_FAILED',
  },
  tripLegViewers: {
    selectItem: 'SELECT_TRIP_LEG_VIEWER',
    invalidateItems: 'INVALIDATE_TRIP_LEG_VIEWERS',
    invalidateTempItems: 'INVALIDATE_TRIP_LEG_VIEWERS_TEMP',
    sendDeleteItem: 'SEND_DELETE_TRIP_LEG_VIEWER',
    confirmDeleteItem: 'CONFIRM_DELETE_TRIP_LEG_VIEWER',
    sendEditItem: 'SEND_EDIT_TRIP_LEG_VIEWER',
    confirmEditItem: 'CONFIRM_EDIT_TRIP_LEG_VIEWER',
    sendAddItem: 'SEND_ADD_TRIP_LEG_VIEWER',
    sendAddItems: 'SEND_ADD_TRIP_LEG_VIEWERS',
    confirmAddItem: 'CONFIRM_ADD_TRIP_LEG_VIEWER',
    confirmAddItems: 'CONFIRM_ADD_TRIP_LEG_VIEWERS',
    requestItem: 'REQUEST_TRIP_LEG_VIEWER',
    requestItems: 'REQUEST_TRIP_LEG_VIEWERS',
    receiveItem: 'RECEIVE_TRIP_LEG_VIEWER',
    receiveItems: 'RECEIVE_TRIP_LEG_VIEWERS',
    receiveTempItems: 'RECEIVE_TRIP_LEG_VIEWERS_TEMP',
    itemFailed: 'TRIP_LEG_VIEWER_FAILED',
    itemsFailed: 'TRIP_LEG_VIEWERS_FAILED',
  },
  otherUserCategories: {
    selectItem: 'SELECT_OTHER_USER_CATEGORY',
    invalidateItems: 'INVALIDATE_OTHER_USER_CATEGORIES',
    invalidateTempItems: 'INVALIDATE_OTHER_USER_CATEGORIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_OTHER_USER_CATEGORY',
    confirmDeleteItem: 'CONFIRM_DELETE_OTHER_USER_CATEGORY',
    sendEditItem: 'SEND_EDIT_OTHER_USER_CATEGORY',
    confirmEditItem: 'CONFIRM_EDIT_OTHER_USER_CATEGORY',
    sendAddItem: 'SEND_ADD_OTHER_USER_CATEGORY',
    sendAddItems: 'SEND_ADD_USER_OTHER_CATEGORIES',
    confirmAddItem: 'CONFIRM_ADD_OTHER_USER_CATEGORY',
    confirmAddItems: 'CONFIRM_ADD_OTHER_USER_CATEGORIES',
    requestItem: 'REQUEST_OTHER_USER_CATEGORY',
    requestItems: 'REQUEST_OTHER_USER_CATEGORIES',
    receiveItem: 'RECEIVE_OTHER_USER_CATEGORY',
    receiveItems: 'RECEIVE_OTHER_USER_CATEGORIES',
    receiveTempItems: 'RECEIVE_OTHER_USER_CATEGORIES_TEMP',
    itemFailed: 'OTHER_USER_CATEGORY_FAILED',
    itemsFailed: 'OTHER_USER_CATEGORIES_FAILED',
  },
  otherUserSubscriptions: {
    selectItem: 'SELECT_OTHER_USER_SUBSCRIPTION',
    invalidateItems: 'INVALIDATE_OTHER_USER_SUBSCRIPTIONS',
    invalidateTempItems: 'INVALIDATE_OTHER_USER_SUBSCRIPTIONS_TEMP',
    sendDeleteItem: 'SEND_DELETE_OTHER_USER_SUBSCRIPTION',
    confirmDeleteItem: 'CONFIRM_DELETE_OTHER_USER_SUBSCRIPTION',
    sendEditItem: 'SEND_EDIT_OTHER_USER_SUBSCRIPTION',
    confirmEditItem: 'CONFIRM_EDIT_OTHER_USER_SUBSCRIPTION',
    sendAddItem: 'SEND_ADD_OTHER_USER_SUBSCRIPTION',
    sendAddItems: 'SEND_ADD_OTHER_USER_SUBSCRIPTIONS',
    confirmAddItem: 'CONFIRM_ADD_OTHER_USER_SUBSCRIPTION',
    confirmAddItems: 'CONFIRM_ADD_OTHER_USER_SUBSCRIPTIONS',
    requestItem: 'REQUEST_OTHER_USER_SUBSCRIPTION',
    requestItems: 'REQUEST_OTHER_USER_SUBSCRIPTIONS',
    receiveItem: 'RECEIVE_OTHER_USER_SUBSCRIPTION',
    receiveItems: 'RECEIVE_OTHER_USER_SUBSCRIPTIONS',
    receiveTempItems: 'RECEIVE_OTHER_USER_SUBSCRIPTIONS_TEMP',
    itemFailed: 'OTHER_USER_SUBSCRIPTION_FAILED',
    itemsFailed: 'OTHER_USER_SUBSCRIPTIONS_FAILED',
  },
  userCategories: {
    selectItem: 'SELECT_USER_CATEGORY',
    invalidateItems: 'INVALIDATE_USER_CATEGORIES',
    invalidateTempItems: 'INVALIDATE_USER_CATEGORIES_TEMP',
    sendDeleteItem: 'SEND_DELETE_USER_CATEGORY',
    confirmDeleteItem: 'CONFIRM_DELETE_USER_CATEGORY',
    sendEditItem: 'SEND_EDIT_USER_CATEGORY',
    confirmEditItem: 'CONFIRM_EDIT_USER_CATEGORY',
    sendAddItem: 'SEND_ADD_USER_CATEGORY',
    sendAddItems: 'SEND_ADD_USER_CATEGORIES',
    confirmAddItem: 'CONFIRM_ADD_USER_CATEGORY',
    confirmAddItems: 'CONFIRM_ADD_USER_CATEGORIES',
    requestItem: 'REQUEST_USER_CATEGORY',
    requestItems: 'REQUEST_USER_CATEGORIES',
    receiveItem: 'RECEIVE_USER_CATEGORY',
    receiveItems: 'RECEIVE_USER_CATEGORIES',
    receiveTempItems: 'RECEIVE_USER_CATEGORIES_TEMP',
    itemFailed: 'USER_CATEGORY_FAILED',
    itemsFailed: 'USER_CATEGORIES_FAILED',
  },
  userSubscriptions: {
    selectItem: 'SELECT_USER_SUBSCRIPTION',
    invalidateItems: 'INVALIDATE_USER_SUBSCRIPTIONS',
    invalidateTempItems: 'INVALIDATE_USER_SUBSCRIPTIONS_TEMP',
    sendDeleteItem: 'SEND_DELETE_USER_SUBSCRIPTION',
    confirmDeleteItem: 'CONFIRM_DELETE_USER_SUBSCRIPTION',
    sendEditItem: 'SEND_EDIT_USER_SUBSCRIPTION',
    confirmEditItem: 'CONFIRM_EDIT_USER_SUBSCRIPTION',
    sendAddItem: 'SEND_ADD_USER_SUBSCRIPTION',
    sendAddItems: 'SEND_ADD_USER_SUBSCRIPTIONS',
    confirmAddItem: 'CONFIRM_ADD_USER_SUBSCRIPTION',
    confirmAddItems: 'CONFIRM_ADD_USER_SUBSCRIPTIONS',
    requestItem: 'REQUEST_USER_SUBSCRIPTION',
    requestItems: 'REQUEST_USER_SUBSCRIPTIONS',
    receiveItem: 'RECEIVE_USER_SUBSCRIPTION',
    receiveItems: 'RECEIVE_USER_SUBSCRIPTIONS',
    receiveTempItems: 'RECEIVE_USER_SUBSCRIPTIONS_TEMP',
    itemFailed: 'USER_SUBSCRIPTION_FAILED',
    itemsFailed: 'USER_SUBSCRIPTIONS_FAILED',
  },
  users: {
    sendLogOut: 'SEND_LOG_OUT',
    sendLogOutEverywhere: 'SEND_LOG_OUT_EVERYWHERE',
    confirmLogOut: 'CONFIRM_LOG_OUT',
    confirmLogOutEverywhere: 'CONFIRM_LOG_OUT_EVERYWHERE',
    sendLogIn: 'SEND_LOG_IN',
    confirmLogIn: 'CONFIRM_LOG_IN',
    loginFailed: 'LOGIN_FAILED',
    registerFailed: 'REGISTER_FAILED',
    sendRegister: 'SEND_REGISTER_USER',
    confirmRegister: 'CONFIRM_REGISTER_USER',
    requestCurrentUser: 'REQUEST_CURRENT_USER',
    receiveCurrentUser: 'RECEIVE_CURRENT_USER',
    sendRequestResetPasswordEmail: 'SEND_REQUEST_RESET_PASSWORD_EMAIL',
    confirmRequestResetPasswordEmail: 'CONFIRM_REQUEST_RESET_PASSWORD_EMAIL',
    sendResetPassword: 'SEND_RESET_PASSWORD',
    confirmResetPassword: 'CONFIRM_RESET_PASSWORD',
    sendConfirmEmail: 'SEND_CONFIRM_EMAIL',
    confirmConfirmEmail: 'CONFIRM_CONFIRM_EMAIL',
    selectItem: 'SELECT_USER',
    invalidateItems: 'INVALIDATE_USERS',
    invalidateTempItems: 'INVALIDATE_USERS_TEMP',
    sendDeleteItem: 'SEND_DELETE_USER',
    confirmDeleteItem: 'CONFIRM_DELETE_USER',
    sendEditItem: 'SEND_EDIT_USER',
    confirmEditItem: 'CONFIRM_EDIT_USER',
    sendEditSettings: 'SEND_EDIT_USER_SETTINGS',
    confirmEditSettings: 'CONFIRM_EDIT_USER_SETTINGS',
    sendEditInfo: 'SEND_EDIT_USER_INFO',
    confirmEditInfo: 'CONFIRM_EDIT_USER_INFO',
    sendAddItem: 'SEND_ADD_USER',
    sendAddItems: 'SEND_ADD_USERS',
    confirmAddItem: 'CONFIRM_ADD_USER',
    confirmAddItems: 'CONFIRM_ADD_USERS',
    requestItem: 'REQUEST_USER',
    requestItems: 'REQUEST_USERS',
    receiveItem: 'RECEIVE_USER',
    receiveItems: 'RECEIVE_USERS',
    receiveTempItems: 'RECEIVE_USERS_TEMP',
    requestAliases: 'REQUEST_ALIASES',
    receiveAliases: 'RECEIVE_ALIASES',
    receiveTempAliases: 'RECEIVE_ALIASES_TEMP',
    requestSettings: 'REQUEST_USER_SETTINGS',
    receiveSettings: 'RECEIVE_USER_SETTINGS',
    receiveTempSettings: 'RECEIVE_USER_SETTINGS_TEMP',
    requestInvite: 'REQUEST_USER_INVITE',
    receiveInvite: 'RECEIVE_USER_INVITE',
    toggleSettings: 'TOGGLE_USER_SETTINGS',
    settingsFailed: 'USER_SETTINGS_FAILED',
    itemFailed: 'USER_FAILED',
    itemsFailed: 'USERS_FAILED',
    showLoginModal: 'SHOW_LOGIN_MODAL',
    hideLoginModal: 'HIDE_LOGIN_MODAL',
    sendEditEmail: 'SEND_EDIT_USER_EMAIL',
    confirmEditEmail: 'CONFIRM_EDIT_USER_INFO',
    sendItemLoading: 'SEND_USER_LOADING',
    confirmItemLoading: 'CONFIRM_USER_LOADING',
    itemLoadingFailed: 'USER_LOADING_FAILED',
    updateLoadedItem: 'USER_UPDATE_LOADED_ITEM',
    requestUserInfo: 'USER_REQUEST_INFO',
    receiveUserInfo: 'USER_RECEIVE_INFO',
    requestUserProfile: 'USER_REQUEST_PROFILE',
    receiveUserProfile: 'USER_RECEIVE_PROFILE',
    sendEditUserProfile: 'USER_SEND_EDIT_PROFILE',
    confirmEditUserProfile: 'USER_CONFIRM_EDIT_PROFILE',
  },
  userGuestLists: {
    selectItem: 'SELECT_USER_GUEST_LIST',
    invalidateItems: 'INVALIDATE_USER_GUEST_LISTS',
    invalidateTempItems: 'INVALIDATE_USER_GUEST_LISTS_TEMP',
    sendDeleteItem: 'SEND_DELETE_USER_GUEST_LIST',
    confirmDeleteItem: 'CONFIRM_DELETE_USER_GUEST_LIST',
    sendEditItem: 'SEND_EDIT_USER_GUEST_LIST',
    confirmEditItem: 'CONFIRM_EDIT_USER_GUEST_LIST',
    sendAddItem: 'SEND_ADD_USER_GUEST_LIST',
    sendAddItems: 'SEND_ADD_USER_GUEST_LISTS',
    confirmAddItem: 'CONFIRM_ADD_USER_GUEST_LIST',
    confirmAddItems: 'CONFIRM_ADD_USER_GUEST_LISTS',
    requestItem: 'REQUEST_USER_GUEST_LIST',
    requestItems: 'REQUEST_USER_GUEST_LISTS',
    receiveItem: 'RECEIVE_USER_GUEST_LIST',
    receiveItems: 'RECEIVE_USER_GUEST_LISTS',
    receiveTempItems: 'RECEIVE_USER_GUEST_LISTS_TEMP',
    itemFailed: 'USER_GUEST_LIST_FAILED',
    itemsFailed: 'USER_GUEST_LISTS_FAILED',
  },
  userGuestListGuests: {
    requestItems: 'REQUEST_USER_GUEST_LIST_GUESTS',
    receiveItems: 'RECEIVE_USER_GUEST_LIST_GUESTS',
    sendAddItem: 'SEND_ADD_USER_GUEST_LIST_GUEST',
    sendAddItems: 'SEND_ADD_USER_GUEST_LIST_GUESTS',
    confirmAddItem: 'CONFIRM_ADD_USER_GUEST_LIST_GUEST',
    confirmAddItems: 'CONFIRM_ADD_USER_GUEST_LIST_GUESTS',
    sendDeleteItem: 'SEND_DELETE_USER_GUEST_LIST_GUEST',
    confirmDeleteItem: 'CONFIRM_DELETE_USER_GUEST_LIST_GUEST',
    itemsFailed: 'USER_GUEST_LIST_GUESTS_FAILED',
  },
  venues: {
    selectItem: 'SELECT_VENUE',
    invalidateItems: 'INVALIDATE_VENUES',
    invalidateTempItems: 'INVALIDATE_VENUES_TEMP',
    sendDeleteItem: 'SEND_DELETE_VENUE',
    confirmDeleteItem: 'CONFIRM_DELETE_VENUE',
    sendEditItem: 'SEND_EDIT_VENUE',
    confirmEditItem: 'CONFIRM_EDIT_VENUE',
    sendAddItem: 'SEND_ADD_VENUE',
    sendAddItems: 'SEND_ADD_VENUES',
    sendVenueLoading: 'SEND_VENUE_LOADING',
    confirmVenueLoaded: 'CONFIRM_VENUE_LOADED',
    sendFloorLoading: 'SEND_FLOOR_LOADING',
    confirmFloorLoaded: 'CONFIRM_FLOOR_LOADED',
    sendRoomLoading: 'SEND_ROOM_LOADING',
    confirmRoomLoaded: 'CONFIRM_ROOM_LOADED',
    confirmAddItem: 'CONFIRM_ADD_VENUE',
    confirmAddItems: 'CONFIRM_ADD_VENUES',
    requestItem: 'REQUEST_VENUE',
    requestItems: 'REQUEST_VENUES',
    receiveItem: 'RECEIVE_VENUE',
    receiveItems: 'RECEIVE_VENUES',
    receiveTempItems: 'RECEIVE_VENUES_TEMP',
    itemFailed: 'VENUE_FAILED',
    itemsFailed: 'VENUES_FAILED',
    sendAddFloorPlan: 'SEND_ADD_FLOOR_PLAN',
    confirmAddFloorPlan: 'CONFIRM_ADD_FLOOR_PLAN',
  },
  floors: {
    sendAddItem: 'SEND_ADD_FLOOR_PLAN',
    confirmAddItem: 'CONFIRM_ADD_FLOOR_PLAN',
    sendDeleteItem: 'SEND_DELETE_FLOOR_PLAN',
    confirmDeleteItem: 'CONFIRM_DELETE_FLOOR_PLAN',
    sendEditItem: 'SEND_EDIT_FLOOR_PLAN',
    confirmEditItem: 'CONFIRM_EDIT_FLOOR_PLAN',
  },
  rooms: {
    requestItems: 'REQUEST_FLOOR_ROOMS',
    receiveItems: 'RECEIVE_FLOOR_ROOMS',
    sendAddItem: 'SEND_ADD_FLOOR_ROOM',
    confirmAddItem: 'CONFIRM_ADD_FLOOR_ROOM',
    sendDeleteItem: 'SEND_DELETE_FLOOR_ROOM',
    confirmDeleteItem: 'CONFIRM_DELETE_FLOOR_ROOM',
    sendEditItem: 'SEND_EDIT_FLOOR_ROOM',
    confirmEditItem: 'CONFIRM_EDIT_FLOOR_ROOM',
  }
}

export default ActionConstants
