import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'
import { removeItem } from '../utils/dataUtils'

const categoryConst = ActionConstants.categories

function categories(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    tempItems: [],
    storedItems: [],
    categoryLinks: [],
    totalItems: 0,
    currentPage: 1,
    savedPage: 1,
    touchedItems: 0,
    itemsPerPage: 1000,
    hasNextPage: true,
    errorMessage: null,
    columnsExpanded: true,
    revisitFiltered: false,
    darkModeEnabled: true,
    filterOnEmpty: false,
  },
  action
) {
  let items
  let index
  let touchedItems = 0
  switch (action.type) {
    case categoryConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case categoryConst.requestItem:
    case categoryConst.requestItems:
    case categoryConst.sendAddItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case categoryConst.storeItem:
      return {
        ...state,
        storedItems: [...state.storedItems, action.response],
      }
    case categoryConst.clearStoredItems:
      return {
        ...state,
        storedItems: [],
      }
    case ActionConstants.superSubCategories.receiveItems:
      return {
        ...state,
        storedItems: action.items,
      }
    case categoryConst.removeStoredItem:
      index = _.findIndex(state.storedItems, c => {
        const cat = c.category ? c.category : c
        const actionCat = action.response.category ? action.response.category : action.response
        return cat.id === actionCat.id
      })
      return {
        ...state,
        storedItems: dataUtils.removeItem(state.storedItems, index),
      }
    case categoryConst.receiveItems:
      touchedItems = 0
      for (let i = 0; i < action.items.length; i++) {
        if (action.items[i].superCategories && action.items[i].superCategories.length > 0) {
          touchedItems += 1
        }
        action.items[i].rowNumber = i + 1
      }
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        touchedItems: touchedItems,
        currentPage: state.savedPage,
        tempItems: action.items,
        hasNextPage: action.hasNextPage,
        lastUpdated: action.receivedAt,
      }
    case categoryConst.receiveCategoryCounts:
      return {
        ...state,
        totalItems: action.response,
      }
    case categoryConst.receiveItem:
    case categoryConst.confirmEditItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case categoryConst.sendAddItem:
    case categoryConst.sendDeleteItem:
    case categoryConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case categoryConst.confirmDeleteItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case categoryConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case categoryConst.receiveAliasOf:
    case categoryConst.confirmToggleRevisit:
    case categoryConst.confirmToggleDelete:
    case categoryConst.confirmToggleVisit:
      index = _.findIndex(state.tempItems, cat => {
        return cat.id === action.response.id
      })
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        tempItems: dataUtils.updateObjectInArray(state.tempItems, action.response, index),
        lastUpdated: action.receivedAt,
      }
    case categoryConst.confirmAddItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: dataUtils.appendArray(state.items, action.items),
        hasNextPage: action.hasNextPage,
        lastUpdated: action.receivedAt,
      }
    case categoryConst.itemFailed:
    case categoryConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case categoryConst.toggleColumns:
      return {
        ...state,
        columnsExpanded: !state.columnsExpanded,
      }
    case categoryConst.toggleRevisitFiltered:
      return {
        ...state,
        revisitFiltered: !state.revisitFiltered,
      }
    case categoryConst.toggleDarkMode:
      return {
        ...state,
        darkModeEnabled: !state.darkModeEnabled,
      }
    case categoryConst.highlightCell:
      items = state.items
      items = dataUtils.updateObjectInArray(items, action.response.row, action.response.rowIndex)
      if (action.response.lastRow) {
        items = dataUtils.updateObjectInArray(items, action.response.lastRow, action.response.lastIndex)
      }
      return {
        ...state,
        items: items,
      }
    case categoryConst.receiveTempItems:
      touchedItems = 0
      for (let i = 0; i < action.items.length; i++) {
        if (action.items[i].superCategories && action.items[i].superCategories.length > 0) {
          touchedItems += 1
        }
        action.items[i].rowNumber = i + 1
      }
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        tempItems: action.items,
        savedPage: state.currentPage,
        currentPage: 0,
        touchedItems: touchedItems,
        hasNextPage: action.hasNextPage,
        lastUpdated: action.receivedAt,
      }
    case categoryConst.receiveCategoryLinks:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        categoryLinks: action.items,
        lastUpdated: action.receivedAt,
      }
    case categoryConst.clearTempItems:
      return {
        ...state,
        tempItems: [],
      }
    case categoryConst.changePage:
      return {
        ...state,
        currentPage: action.page,
        savedPage: action.page,
      }
    case categoryConst.changeItemsPerPage:
      return {
        ...state,
        itemsPerPage: action.itemsPerPage,
      }
  case categoryConst.sendAddCategoryLink:
    return {
      ...state,
      categoryLinks: [action.item, ...state.categoryLinks]
    }
  case categoryConst.sendDeleteCategoryLink:
    index = _.findIndex(state.categoryLinks, {
      id: action.item.id,
    })
    return {
      ...state,
      categoryLinks: removeItem(state.categoryLinks, index),
    }
  case categoryConst.toggleFilterOnEmpty:
    return {
      ...state,
      filterOnEmpty: !state.filterOnEmpty,
    }
    default:
      return state
  }
}

export default categories
