import axios from 'axios'
import history from './history'
import { Globals } from './constants/Global'
const CryptoJS = require('crypto-js')

const MAX_REQUESTS_COUNT = 5
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

//calculate the hmac signature of the given jquery ajax request, with the given extra data
export function hmacSignature(request, token, timestamp, secretKey) {
  if (request.params) {
    let params = ''
    let isFirst = true

    // eslint-disable-next-line no-unused-vars
    for (const i in request.params) {
      if (request.params.hasOwnProperty(i)) {
        params += `${isFirst ? '?' : '&'}${i}=${request.params[i]}`
        isFirst = false
      }
    }
    params = encodeURI(params)
    params = params.replace('#', '%23')
    params = params.replace("'", '%27')
    params = params.replace('*', '%2a')
    params = params.replace(',', '%2c')
    request.url += params
  }
  let message = request.method + '\n' + request.url + '\n' + token + '\n' + timestamp + '\n'
  if (request.data && Object.entries(request.data).length > 0 && request.data.constructor === Object) {
    //if the payload is absent, don't use the 'null' converted equivalent, simply omit it
    request.data = JSON.stringify(request.data)
    message += request.data
  } else if (request.data && request.data.length && request.data.length > 0) {
    message += request.data
  }
  message += '\n'
  const key = secretKey || localStorage.getItem('secretKey')
  return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA512(message, key))
}

//Adds an hmac auth header onto any jquery ajax request
export function withHmacAuth(request, secretKey, secretToken) {
  const token = secretToken || localStorage.getItem('secretToken')
  const timestamp = new Date()
  const time = timestamp.getTime()
  const signature = hmacSignature(request, token, time, secretKey)
  if (!request.headers) {
    request.headers = {}
  }
  request.headers.Authorization = 'HmacSHA512 ' + token + ':' + time + ':' + signature
  return request
}

const request = axios.create({
  baseURL: Globals.api.base,
  timeout: 30000,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  responseType: 'json',
})

const publicRoutes = ['/login', '/register']

request.interceptors.request.use(function (config) {
    let path = config.url.replace(config.baseURL, '')
    const secret = localStorage.getItem('secretToken')
    if (path === 'login' || path === 'register' || !secret) {
      return config
    }
    return new Promise((resolve) => {
      let interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++
          clearInterval(interval)
          let newRequest = withHmacAuth(config)
          newRequest.params = {}
          resolve(newRequest)
        }
      }, INTERVAL_MS)
    })
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return response
  },
  error => {
    if (
      error.response &&
      error.response.status === 401 &&
      publicRoutes.indexOf(history.location.pathname) < 0 &&
      history.location.pathname !== '/'
    ) {
      history.push('/login')
    }
    return Promise.reject(error)
  }
)

export default request
