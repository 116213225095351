import request from '../request'
import ActionConstants from '../constants/ActionConstants'
import history from '../history'
import { Globals } from '../constants/Global'
import { formatUrl } from '../utils/RESTUtils'

const userConst = ActionConstants.users

function reqFetchImageUrls() {
  return request.get(formatUrl(Globals.api.meImageUrls)).then(
    response => {
      return response.data
    },
    error => {
      return false
    }
  )
}

function reqUserSettings() {
  return request.get(Globals.api.settings).then(
    response => {
      return response.data
    },
    error => {
      return false
    }
  )
}

function reqUserAliases() {
  return request.get(Globals.api.aliases).then(
    response => {
      return response.data
    },
    error => {
      return false
    }
  )
}

function reqMe() {
  return request.get('/me').then(
    response => {
      return response.data
    },
    error => {
      return false
    }
  )
}

function reqSwitchAlias(userId) {
  let path = { userId: userId }
  return request.put(formatUrl(Globals.api.switchUser, path)).then(
    response => {
      return request.get('/me').then(response => {
        return response.data
      })
    },
    error => {
      return false
    }
  )
}

function reqFetchItems(payload) {
  let { url, path, params, data, query } = payload
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  return request.get(formatUrl(url, path), options).then(
    response => {
      return response.data.items
    },
    error => {
      return false
    }
  )
}

export const register = (payload) => dispatch => {
  const {
    displayName,
    loginName,
    password,
    captcha,
    email,
    homeLat,
    homeLong,
    defaultRadius,
  } = payload
  dispatch({
    type: userConst.sendRegister,
  })
  let body = { loginName: loginName, name: displayName, password: password }
  return request.post(Globals.api.users + '?recaptchaCode=' + captcha, body).then(
    response => {
      if (email) {
        request.put(Globals.api.email, { email, loginName, password })
      }
      if (homeLat && homeLong && defaultRadius) {
        request.put(Globals.api.settings, { homeLong, homeLat, defaultRadius: defaultRadius * 1609.344 })
      }
      localStorage.setItem('secretToken', response.request.getResponseHeader('x-dm-secret-token'))
      localStorage.setItem('secretKey', response.request.getResponseHeader('x-dm-secret-key'))
      dispatch({
        type: userConst.confirmRegister,
        nextRoute: '/',
        response: response.data.item,
      })
      return 'success'
    },
    error => {
      dispatch({
        type: userConst.loginFailed,
        message:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Something went wrong.',
      })
      return error
    }
  )
}

export const login = (loginName, password) => (dispatch) => {
  dispatch({
    type: userConst.sendLogIn,
  })
  return request.get(Globals.api.login, { auth: { username: loginName, password: password } }).then(
    async response => {
      localStorage.setItem('secretToken', response.request.getResponseHeader('x-dm-secret-token'))
      localStorage.setItem('secretKey', response.request.getResponseHeader('x-dm-secret-key'))
      let path = history.location.pathname
      const settings = await reqUserSettings()
      const aliases = await reqUserAliases()
      const me = await reqMe()
      dispatch({
        type: userConst.confirmLogIn,
        info: me,
        settings,
        aliases: aliases.items || [],
      })

      dispatch({
        type: userConst.hideLoginModal,
      })
      if (path === '/login' || path === '/register') {
        if (settings && !settings.emailVerified) {
          history.push('/verifyEmail')
        } else {
          history.push('/')
        }
      }
    },
    error => {
      let message = error.message.indexOf('401') > -1 ? 'Incorrect username or password' : 'something went wrong'
      dispatch({
        type: userConst.loginFailed,
        message: message,
      })
    }
  )
}

export const fetchUserInfo = () => async dispatch => {
  const me = await reqMe()
  dispatch({
    type: userConst.receiveUserInfo,
    info: me,
  })
}

export const logout = () => dispatch => {
  dispatch({
    type: userConst.sendLogOut,
  })
  return request.post(Globals.api.logout).then(
    response => {
      dispatch({
        type: userConst.confirmLogOut,
      })
      history.push('/login')
    },
    error => {
      dispatch({
        type: userConst.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const logoutEverywhere = () => dispatch => {
  dispatch({
    type: userConst.sendLogOutEverywhere,
  })
  return request.post(Globals.api.logoutEverywhere).then(
    response => {
      dispatch({
        type: userConst.confirmLogOutEverywhere,
      })
      history.push('/login')
    },
    error => {
      dispatch({
        type: userConst.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchAliases = () => (dispatch, getState) => {
  if (getState().sandbox.isSandbox) {
    return
  }
  dispatch({
    type: userConst.requestAliases,
  })
  return request.get(Globals.api.aliases).then(
    response => {
      dispatch({
        type: userConst.receiveAliases,
        items: response.data.items,
      })
      return response.data.items
    },
    error =>
      dispatch({
        type: userConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
  )
}

export const addAlias = (loginName, displayName, password, captcha) => (dispatch) => {
  dispatch({
    type: ActionConstants.aliases.sendAddItem,
  })
  let body = { loginName: loginName, name: displayName, password: password }
  return request.post(Globals.api.subUser + '?recaptchaCode=' + captcha, body).then(
    response => {
      dispatch({
        type: ActionConstants.aliases.confirmAddItem,
        response: response,
      })
      return {
        state: 'success',
        response: response.data,
      }
    },
    error => {
      dispatch({
        type: ActionConstants.aliases.itemFailed,
        message: error.message || 'Something went wrong.',
      })
      return {
        state: 'error',
        response: error.message,
      }
    }
  )
}

export const deleteAlias = (payload) => (dispatch, getState) => {
  const userId = getState().user.info.id
  const { alias: { id }, password, loginName, isPrimary } = payload
  let body = { loginName: loginName, password: password }
  return request.put(formatUrl(Globals.api.switchUser, { userId: id })).then(() => {
    return request.post(Globals.api.deleteMyAccount, body).then(() => {
      if (isPrimary) {
        history.push('/login')
        return dispatch({
          type: 'USER_DELETED'
        })
      }
      return request.get(Globals.api.login, { auth: { username: loginName, password: password } }).then(
        response => {
          localStorage.setItem('secretToken', response.request.getResponseHeader('x-dm-secret-token'))
          localStorage.setItem('secretKey', response.request.getResponseHeader('x-dm-secret-key'))
          dispatch({
            type: ActionConstants.aliases.confirmDeleteItem,
            response: payload.alias,
          })
        },
        error => {
          let message = error.message.indexOf('401') > -1 ? 'Incorrect username or password' : 'something went wrong'
          dispatch({
            type: userConst.loginFailed,
            message: message,
          })
          return message
        }
      )
    }, () => {
      return request.put(formatUrl(Globals.api.switchUser, { userId })).then(() => {
        return 'Incorrect password'
      })
    })
  })
}

export const unlinkAccount = path => dispatch => {
  dispatch({
    type: ActionConstants.connections.sendDeleteItem,
  })
  return request.delete(formatUrl(Globals.api.connectionsProviderUser, path)).then(response => {
    dispatch({
      type: ActionConstants.connections.confirmDeleteItem,
      response: path,
    })
  })
}

export const sendResetPasswordEmail = userName => dispatch => {
  dispatch({
    type: ActionConstants.users.sendRequestResetPasswordEmail,
  })
  return request.post(formatUrl(Globals.api.resendPasswordEmail, userName)).then(response => {
    dispatch({
      type: ActionConstants.users.confirmRequestResetPasswordEmail,
    })
  })
}

export const resetPassword = token => dispatch => {
  dispatch({
    type: ActionConstants.users.sendResetPassword,
  })
  return request.post(Globals.api.resetPassword, token).then(
    response => {
      dispatch({
        type: ActionConstants.users.confirmResetPassword,
      })
      return 'confirmed'
    },
    error => {
      return 'error'
    }
  )
}

export const confirmEmail = token => dispatch => {
  dispatch({
    type: ActionConstants.users.sendConfirmEmail,
  })
  return request.post(Globals.api.confirmEmail, token).then(
    response => {
      dispatch({
        type: ActionConstants.users.confirmConfirmEmail,
      })
      return 'confirmed'
    },
    error => {
      return 'error'
    }
  )
}

export const stageDuplicateAlias = alias => dispatch => {
  dispatch({
    type: ActionConstants.aliases.duplicateAliasStage,
    response: alias,
  })
}

export const copyAliasData = payload => (dispatch) => {
  const {
    categories,
    friends,
    imageUrl,
    latitude,
    longitude,
    radius,
    subscriptions,
  } = payload

  dispatch({
    type: ActionConstants.aliases.sendDuplicateAlias,
  })

  const copyPayload = {
    categories: categories || [],
    friends: friends || [] ,
    imageUrl: imageUrl || null,
    latitude: latitude || null,
    longitude: longitude || null,
    radius: radius || null,
    subscriptions: subscriptions || [],
  }
  console.log(subscriptions)

  return request.put(Globals.api.copyAliasData, copyPayload).then(
    response => {
      dispatch({
        type: ActionConstants.aliases.confirmDuplicateAlias,
        response: response,
      })
      return 'success'
    },
    error => {
      dispatch({
        type: ActionConstants.aliases.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const duplicateAlias = payload => async (dispatch) => {
  let {
    name,
    userId,
    connectionsChecked,
    categoriesChecked,
    locationChecked,
    subscriptionsChecked,
    imageUrlChecked,
    friendsChecked,
  } = payload
  dispatch({
    type: ActionConstants.aliases.sendDuplicateAlias,
  })
  let alias = await reqSwitchAlias(userId)
  let connections = []
  let categories = []
  let userSettings = {}
  let latitude = 0
  let longitude = 0
  let radius = 0
  let friends = []
  let subscriptions = []
  let imageUrl = null
  if (alias) {
    if (connectionsChecked) {
      connections = await reqFetchItems({
        dataType: 'connections',
        url: Globals.api.connections,
      })
    }
    if (categoriesChecked) {
      categories = await reqFetchItems({
        dataType: 'userCategories',
        url: Globals.api.userCategories,
      })
    }
    if (locationChecked) {
      userSettings = await reqUserSettings()
      latitude = userSettings.homeLat || 0
      longitude = userSettings.homeLong || 0
      radius = userSettings.defaultRadius || 0
    }
    if (subscriptionsChecked) {
      subscriptions = await reqFetchItems({
        dataType: 'subscriptions',
        url: Globals.api.subscriptions,
      })
    }
    if (friendsChecked) {
      friends = await reqFetchItems({
        dataType: 'contacts',
        url: Globals.api.contacts,
      })
    }
    if (imageUrlChecked) {
      imageUrl = await reqFetchImageUrls()
      if (imageUrl) {
        imageUrl = imageUrl.downloadUrl
      }
    }
  }
  payload = {
    connections,
    categories,
    latitude,
    longitude,
    radius,
    subscriptions,
    friends,
    name,
    imageUrl,
  }
  return request.put(Globals.api.copyAliasData, payload).then(
    response => {
      dispatch({
        type: ActionConstants.aliases.confirmDuplicateAlias,
        response: response,
      })
    },
    error => {
      dispatch({
        type: ActionConstants.aliases.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const switchAlias = (path, newUrl, silent) => dispatch => {
  dispatch({
    type: ActionConstants.aliases.sendSwitchAlias,
  })
  return request.put(formatUrl(Globals.api.switchUser, path)).then(
    () => {
      dispatch({
        type: ActionConstants.aliases.confirmSwitchAlias,
        receivedAt: Date.now(),
      })
      if (!silent) {
        dispatch({
          type: 'START_REFRESH_STORE',
        })
        if (newUrl) {
          history.push(newUrl)
        } else {
          history.push('/')
        }
        return request.get('/me').then(response => {
          dispatch({
            type: 'END_REFRESH_STORE',
          })
          dispatch({
            type: userConst.confirmLogIn,
            info: response.data,
          })
          return response.data
        })
      }
      return 'success'
    },
    error => {
      dispatch({
        type: ActionConstants.aliases.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchUserSettings = () => (dispatch, getState) => {
  if (getState().sandbox.isSandbox) {
    return
  }
  dispatch({
    type: userConst.requestSettings,
  })
  return request.get(Globals.api.settings).then(
    response =>
      dispatch({
        type: userConst.receiveSettings,
        response: response.data,
      }),
    error =>
      dispatch({
        type: userConst.settingsFailed,
        message: error.message || 'Something went wrong',
      })
  )
}

export const editUserSettings = settings => dispatch => {
  dispatch({
    type: userConst.sendEditSettings,
  })
  return request.put(Globals.api.settings, settings).then(response => {
    dispatch({
      type: userConst.confirmEditSettings,
      response: settings,
    })
  })
}

export const editUserProfile = profile => dispatch => {
  dispatch({
    type: userConst.sendEditUserProfile,
  })
  return request.put(Globals.api.profile, profile).then(() => {
    dispatch({
      type: userConst.confirmEditUserProfile,
      response: profile,
    })
  })
}

export const editUserInfo = (info, tempData = {}) => dispatch => {
  dispatch({
    type: userConst.sendEditInfo,
  })
  return request.put(Globals.api.me, info).then(response => {
    dispatch({
      type: userConst.confirmEditInfo,
      response: {
        ...info,
        ...tempData
      },
    })
    return info
  })
}

export const editUserEmail = info => dispatch => {
  dispatch({
    type: userConst.sendEditEmail,
  })
  return new Promise((resolve, reject) => {
    request.put(Globals.api.email, info).then(() => {
      dispatch({
        type: userConst.confirmEditEmail,
        response: info.email,
      })
      resolve(info)
    }, (e) => {
      reject(e.response.data.message)
    })
  })
}

export const toggleLoginModal = () => {
  return {
    type: userConst.toggleLoginModal,
  }
}

export const fetchUserInvite = path => (dispatch, getState) => {
  if (getState().sandbox.isSandbox) {
    return
  }
  dispatch({
    type: userConst.requestInvite,
  })
  return request.get(formatUrl(Globals.api.eventMyInvite, path)).then(request => {
    dispatch({
      type: userConst.receiveInvite,
      response: request.data,
      eventId: path.eventId,
    })
    return request.data
  })
}

export const fetchUserProfile = () => dispatch => {
  dispatch({
    type: userConst.requestUserProfile,
  })
  return request.get(Globals.api.profile).then(response => {
    dispatch({
      type: userConst.receiveUserProfile,
      response: response.data,
    })
  })
}
