import ActionConstants from '../constants/ActionConstants'
import { LoggedIn } from '../constants/Global'
import { removeItem } from '../utils/dataUtils'
import _ from 'underscore'

const userConst = ActionConstants.users

function user(
  state = {
    userLoggedIn: false,
    info: {
      created: null,
      deactivated: false,
      id: null,
      imageUrl: null,
      imageUrls: {},
      loginName: '',
      name: null,
    },
    settings: {
      user: null,
      email: null,
      emailVerified: true,
      homeLat: null,
      homeLong: null,
      defaultRadius: 1609.34,
      eventCommentNotification: false,
      hostAddedNotification: false,
      managerAddedNotification: false,
      birthday: null,
      seeNsfw: false,
      nsfwInFeed: false,
      calendarVisibility: null,
      language: null,
      eventCommentNotificationEnabled: false,
      managerAddedNotificationEnabled: false
    },
    profile: {
      altEmail: null,
      altName: null,
      birthday: null,
      city: null,
      email: null,
      marketingGender: null,
      phone: null,
      showAltEmail: true,
      showAltName: true,
      showBirthday: true,
      showCity: true,
      showEmail: true,
      showMarketingGender: true,
      showPhone: true,
    },
    stagedAlias: null,
    aliases: [],
    infoLoaded: false,
    settingsLoaded: false,
    isFetching: false,
    isFetchingImageUrls: false,
    isFetchingSettings: false,
    lastUpdated: null,
    errorMessage: null,
    appRefreshed: false,
  },
  action
) {
  switch (action.type) {
    case userConst.sendLogIn:
      return {
        ...state,
        isFetching: true,
      }
    case userConst.confirmLogIn:
    case userConst.confirmRegister:
      LoggedIn.toggleLoggedInOn()
      return {
        ...state,
        info: {
          ...action.info,
          imageUrl: action.info && action.info.imageUrl ? action.info.imageUrl : null,
          coverImageUrl: action.info && action.info.coverImageUrl ? action.info.coverImageUrl : null,
        },
        settings: { ...state.settings, ...action.settings },
        aliases: action.aliases || [],
        infoLoaded: true,
        userLoggedIn: true,
        errorMessage: null,
        isFetching: false,
      }
    case userConst.receiveUserInfo:
      return {
        ...state,
        info: {
          ...action.info,
          imageUrl: action.info && action.info.imageUrl ? action.info.imageUrl + '?' + new Date().getTime() : null,
          coverImageUrl: action.info && action.info.coverImageUrl ? action.info.coverImageUrl + '?' + new Date().getTime() : null,
        },
      }
    case userConst.loginFailed:
      return {
        ...state,
        userLoggedIn: false,
        errorMessage: action.message,
        isFetching: false,
      }
    case userConst.confirmLogOut:
      LoggedIn.toggleLoggedInOff()
      return {
        ...state,
        userLoggedIn: false,
        info: {
          created: null,
          deactivated: false,
          id: null,
          imageUrl: null,
          imageUrls: {
            uploadUrl: null,
            downloadUrl: null,
          },
          loginName: null,
          name: null,
        },
        errorMessage: null,
      }
    case ActionConstants.images.requestUserImageUrls:
    case ActionConstants.images.sendUploadUserImage:
      return {
        ...state,
        isFetchingImageUrls: true,
      }
    case ActionConstants.images.receiveUserImageUrls:
    case ActionConstants.images.confirmUploadUserImage:
      return {
        ...state,
        isFetchingImageUrls: false,
        info: {
          ...state.info,
          imageUrls: action.response,
        },
      }
    case userConst.requestSettings:
      return {
        ...state,
        settingsLoaded: false,
        isFetchingSettings: true,
      }
    case userConst.receiveSettings:
    case userConst.confirmEditSettings:
      return {
        ...state,
        isFetchingSettings: false,
        settingsLoaded: true,
        settings: {
          ...state.settings,
          ...action.response,
        },
      }
  case userConst.receiveUserProfile:
  case userConst.confirmEditUserProfile:
    return {
      ...state,
      profile: {
        ...state.profile,
        ...action.response,
      }
    }
    case userConst.confirmEditInfo:
      return {
        ...state,
        isFetching: false,
        info: {
          ...state.info,
          ...action.response,
          imageUrl: action.response.imageUrl ? action.response.imageUrl + '?' + new Date().getTime() : state.info.imageUrl,
          coverImageUrl: action.response.coverImageUrl ? action.response.coverImageUrl + '?' + new Date().getTime() : state.info.coverImageUrl,
        },
      }
    case userConst.receiveAliases:
      return {
        ...state,
        aliases: action.items,
      }
    case ActionConstants.aliases.confirmDeleteItem:
      let aliasIndex = _.findIndex(state.aliases, alias => {
        return alias.id === action.response.id
      })
      return {
        ...state,
        aliases: removeItem(state.aliases, aliasIndex),
      }
    case ActionConstants.aliases.duplicateAliasStage:
      return {
        ...state,
        stagedAlias: action.response,
      }
    case 'START_REFRESH_STORE':
      return {
        ...state,
        appRefreshed: true,
      }
    case 'END_REFRESH_STORE':
      return {
        ...state,
        appRefreshed: false,
      }
  case userConst.confirmEditEmail:
    return {
      ...state,
      settings: {
        ...state.settings,
        email: action.response,
      }
    }
    default:
      return state
  }
}

export default user
