import { schema } from 'normalizr'
import { door } from './DoorSchemas'

const externalDoor = new schema.Entity('externalDoor')
const externalEvent = new schema.Entity('externalEvent')
const externalHost = new schema.Entity('externalHost')
const externalInvite = new schema.Entity('externalInvite')
const externalUser = new schema.Entity('externalUser')
const event = new schema.Entity('events')

externalDoor.define({
  door: door,
})

externalEvent.define({
  event: event,
})

externalInvite.define({
  externalUser: externalUser,
  event: event,
})

export {
  externalDoor,
  externalEvent,
  externalHost,
  externalInvite,
  externalUser,
}
