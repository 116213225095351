import ActionConstants from '../constants/ActionConstants'
import * as dataUtils from '../utils/dataUtils'

const categoryConst = ActionConstants.superCategories
const subCategoryConst = ActionConstants.superSubCategories

function superCategories(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItems: [],
    items: [],
    errorMessage: null,
  },
  action
) {
  switch (action.type) {
    case categoryConst.selectItem:
      return {
        ...state,
        selectedItems: dataUtils.insertItem(state.selectedItems, action.item, state.selectedItems.length),
      }
    case categoryConst.clearTempItems:
      return {
        ...state,
        selectedItems: [],
      }
    case categoryConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case categoryConst.requestItem:
    case categoryConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case categoryConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case categoryConst.sendAddItem:
    case categoryConst.sendDeleteItem:
    case categoryConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case categoryConst.itemFailed:
    case categoryConst.itemsFailed:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function superSubCategories(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItems: [],
    items: [],
    storedItems: [],
    categoryLinks: [],
    catGames: [],
    errorMessage: null,
    startCategory: null,
    goalCategory: null,
    currentCategory: null,
    nextCategories: [],
    catGameId: null,
    score: null,
    health: null,
  },
  action
) {
  let index
  switch (action.type) {
    case subCategoryConst.selectItem:
      return {
        ...state,
        selectedItems: dataUtils.insertItem(state.selectedItems, action.item, state.selectedItems.length),
      }
    case subCategoryConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case subCategoryConst.requestItem:
    case subCategoryConst.requestItems:
    case subCategoryConst.requestCategoryLinks:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case subCategoryConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        storedItems: action.items,
        lastUpdated: action.receivedAt,
      }
    case subCategoryConst.sendAddItem:
    case subCategoryConst.sendDeleteItem:
    case subCategoryConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case subCategoryConst.confirmAddItem:
    case ActionConstants.doorCategories.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case subCategoryConst.confirmDeleteItem:
    case ActionConstants.doorCategories.confirmDeleteItem:
      state.items.forEach((item, i) => {
        const category = item.category ? item.category : item
        if (category.id === action.response.id) {
          index = i
        }
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case subCategoryConst.itemFailed:
    case subCategoryConst.itemsFailed:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.catGames.selectCatGameCat:
      return {
        ...state,
        [action.number]: action.payload,
      }
    case ActionConstants.catGames.removeCatGameCat:
      return {
        ...state,
        [action.number]: null,
      }
    case ActionConstants.catGames.receiveItems:
      return {
        ...state,
        catGames: dataUtils.appendArray(state.catGames, action.items),
      }
    case ActionConstants.catGames.confirmAddItem:
    case ActionConstants.catGames.receiveItem:
      return {
        ...state,
        catGames: dataUtils.insertItem(state.catGames, action.response, 0),
        catGameId: action.response.id,
        startCategory: action.response.startCategory,
        goalCategory: action.response.goalCategory,
        currentCategory: action.response.currentCategory,
        nextCategories: action.response.nextCategories,
        score: action.response.score,
        health: action.response.health,
      }
    case subCategoryConst.clearCategoryLinks:
      return {
        ...state,
        categoryLinks: [],
      }
    case ActionConstants.catGames.selectItem:
    case ActionConstants.catGames.confirmMove:
      return {
        ...state,
        catGameId: action.response.id,
        startCategory: action.response.startCategory,
        goalCategory: action.response.goalCategory,
        currentCategory: action.response.currentCategory,
        nextCategories: action.response.nextCategories,
        score: action.response.score,
        health: action.response.health,
      }
    default:
      return state
  }
}

export { superCategories, superSubCategories }
