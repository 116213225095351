import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const doorSeriesConst = ActionConstants.doorSeries

export default function doorSeries(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    errorMessage: null,
    loadedItems: {},
  },
  action
) {
  let index
  switch (action.type) {
    case doorSeriesConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case doorSeriesConst.requestItem:
    case doorSeriesConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case doorSeriesConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case doorSeriesConst.receiveItem:
    case doorSeriesConst.confirmEditItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case doorSeriesConst.sendAddItem:
    case doorSeriesConst.sendDeleteItem:
    case doorSeriesConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case doorSeriesConst.confirmDeleteItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case doorSeriesConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case doorSeriesConst.itemFailed:
    case doorSeriesConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
  case doorSeriesConst.sendItemLoading:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: false, isError: false } },
    }
  case doorSeriesConst.confirmItemLoading:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: true, isFullyLoaded: action.fullyLoaded, isError: false } },
    }
    default:
      return state
  }
}
