import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const userConst = ActionConstants.users

function users(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: {
      deactivated: false,
      id: null,
      imageUrl: null,
      name: null,
      subscriptions: [],
    },
    items: [],
    allIds: [],
    byId: {},
    errorMessage: null,
    loadedItems: {},
  },
  action
) {
  let index
  switch (action.type) {
    case userConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case userConst.requestItem:
    case userConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case userConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case userConst.receiveItem:
    case userConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: {
          ...state.selectedItem,
          ...action.response,
        },
      }
    case userConst.sendAddItem:
    case userConst.sendDeleteItem:
    case userConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case userConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case userConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case userConst.itemFailed:
    case userConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.comments.receiveItems:
    case ActionConstants.comments.receiveChildItems:
      let items = action.response.entities.users
      if (items) {
        return {
          ...state,
          byId: {
            ...state.byId,
            ...items,
          },
          allIds: dataUtils.updateArrayFromObjects(state.allIds, items),
        }
      } else {
        return state
      }
  case ActionConstants.users.sendItemLoading:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: false, isError: false } },
    }
  case ActionConstants.users.confirmItemLoading:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: true, isError: false } },
    }
  case ActionConstants.users.itemLoadingFailed:
    return state
  case ActionConstants.users.updateLoadedItem:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: action.response, }
    }
  case userConst.confirmLogIn:
  case userConst.confirmRegister:
    if (action.info) {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.info.id]: {
            ...action.info,
            imageUrl: action.info && action.info.imageUrl ? action.info.imageUrl : null,
            coverImageUrl: action.info && action.info.coverImageUrl ? action.info.coverImageUrl : null,
          },
        },
        allIds: [
          ...state.allIds,
          action.info.id,
        ]
      }
    }
    return state
    default:
      return state
  }
}

export default users
