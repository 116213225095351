import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectFullScreenDialog } from '../../selectors/AppSelectors'
import { closeFullScreenDialog } from '../../actions/UI/AppActions'

const NewEventDialog = loadable(() => import('../Events/NewEventDialog'))
const NewVenueDialog = loadable(() => import('../Venues/NewVenueDialog'))
const VenueDetailsDialog = loadable(() => import('../Venues/VenueDetailsDialog'))
const EditVenueDialog = loadable(() => import('../Venues/EditVenueDialog'))
const EditEventDetailsDialog = loadable(() => import('../Events/EditEventDetailsDialog'))
const EditEventSettingsDialog = loadable(() => import('../Events/EditEventSettingsDialog'))
const EditEventCategoriesDialog = loadable(() => import('../Events/EditEventCategoriesDialog'))
const NewDoorDialog = loadable(() => import('../Doors/NewDoorDialog'))
const EditFloorPlanDetailsDialog = loadable(() => import('../Venues/EditFloorPlanDetailsDialog'))
const NewSeriesDialog = loadable(() => import('../Series/NewSeriesDialog'))
const SetVenueDialog = loadable(() => import('../Venues/SetVenueDialog'))
const NewAliasDialog = loadable(() => import('../Profile/NewAliasDialog'))
const InterestsDialog = loadable(() => import('../Interests/InterestsDialog'))
const SearchFiltersDialog = loadable(() => import('../Search/SearchFiltersDialog'))
const SearchDialog = loadable(() => import('../Search/SearchDialog'))

export default function FullScreenDialogContainer() {
  const history = useHistory()
  const fullScreenDialogs = useSelector(selectFullScreenDialog)
  const dispatch = useDispatch()
  const [openDialogs, setOpenDialogs] = useState([])
  const [, setLocationKeys] = useState([])

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
        if (location && location.hash) {
          setOpenDialogs((dialogs) => [...dialogs, location.hash.replace('#', '')])
        }
      }

      if (history.action === 'POP') {
        setLocationKeys(([_, ...keys]) => keys)
        setLocationKeys((keys) => [location.key, ...keys])
        let lastOpened
        if (openDialogs.length > 0) {
          lastOpened = openDialogs[openDialogs.length - 1]
        }
        dispatch(closeFullScreenDialog({ dialog: lastOpened }))
        setOpenDialogs(openDialogs.slice(0, openDialogs.length - 1))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialogs])

  const handleOnClose = () => {
    history.goBack()
  }

  return (
    <>
      {(fullScreenDialogs.venueDetails && fullScreenDialogs.venueDetails.mounted && fullScreenDialogs.venueDetails.venue) && (
        <VenueDetailsDialog
          onClose={() => handleOnClose('venueDetails')}
          venue={fullScreenDialogs && fullScreenDialogs.venueDetails && fullScreenDialogs.venueDetails.venue}
          onSubmit={fullScreenDialogs && fullScreenDialogs.venueDetails && fullScreenDialogs.venueDetails.onSubmit}
          onForgetVenue={fullScreenDialogs && fullScreenDialogs.venueDetails && fullScreenDialogs.venueDetails.onForgetVenue}
        />
      )}
      {(fullScreenDialogs.newEvent && fullScreenDialogs.newEvent.mounted) && (
        <NewEventDialog
          onClose={() => handleOnClose('newEvent')}
          open={fullScreenDialogs.newEvent.open}
          onSubmit={fullScreenDialogs.newEvent.onSubmit}
          stopEventDetails={fullScreenDialogs.newEvent.stopEventDetails}
          showGoLive={fullScreenDialogs.newEvent.showGoLive}
        />
      )}
      {(fullScreenDialogs.editFloorPlanDetails && fullScreenDialogs.editFloorPlanDetails.mounted) && (
        <EditFloorPlanDetailsDialog
          floor={fullScreenDialogs.editFloorPlanDetails.floor}
          venue={fullScreenDialogs.editFloorPlanDetails.venue}
          onClose={() => handleOnClose('editFloorPlanDetails')}
          open={fullScreenDialogs.editFloorPlanDetails.open}
        />
      )}
      {(fullScreenDialogs.newVenue && fullScreenDialogs.newVenue.mounted) && (
        <NewVenueDialog
          onClose={() => handleOnClose('newVenue')}
          open={fullScreenDialogs.newVenue.open}
          onSubmit={fullScreenDialogs.newVenue.onSubmit}
          venue={fullScreenDialogs.newVenue.venue}
        />
      )}
      {(fullScreenDialogs.editVenue && fullScreenDialogs.editVenue.mounted) && (
        <EditVenueDialog
          venue={fullScreenDialogs.editVenue.venue}
          onClose={() => handleOnClose('editVenue')}
          open={fullScreenDialogs.editVenue.open}
        />
      )}
      {(fullScreenDialogs.editEventDetails && fullScreenDialogs.editEventDetails.mounted) && (
        <EditEventDetailsDialog
          event={fullScreenDialogs.editEventDetails.event}
          onClose={() => handleOnClose('editEventDetails')}
          open={fullScreenDialogs.editEventDetails.open}
        />
      )}
      {(fullScreenDialogs.editEventSettings && fullScreenDialogs.editEventSettings.mounted) && (
        <EditEventSettingsDialog
          event={fullScreenDialogs.editEventSettings.event}
          onClose={() => handleOnClose('editEventSettings')}
          open={fullScreenDialogs.editEventSettings.open}
        />
      )}
      {(fullScreenDialogs.editEventCategories && fullScreenDialogs.editEventCategories.mounted) && (
        <EditEventCategoriesDialog
          event={fullScreenDialogs.editEventCategories.event}
          onClose={() => handleOnClose('editEventCategories')}
          open={fullScreenDialogs.editEventCategories.open}
        />
      )}
      {(fullScreenDialogs.newDoor && fullScreenDialogs.newDoor.mounted) && (
        <NewDoorDialog
          onClose={() => handleOnClose('newDoor')}
          open={fullScreenDialogs.newDoor.open}
        />
      )}
      {(fullScreenDialogs.newSeries && fullScreenDialogs.newSeries.mounted) && (
        <NewSeriesDialog
          onClose={() => handleOnClose('newSeries')}
          open={fullScreenDialogs.newSeries.open}
          door={fullScreenDialogs.newSeries.door}
        />
      )}
      {(fullScreenDialogs.setVenue && fullScreenDialogs.setVenue.mounted) && (
        <SetVenueDialog
          open={fullScreenDialogs.setVenue.open}
          onClose={() => handleOnClose('setVenue')}
          onSubmit={fullScreenDialogs.setVenue.onSubmit}
          location={fullScreenDialogs.setVenue.location}
          setPinSubmit={fullScreenDialogs.setVenue.setPinSubmit}
        />
      )}
      {(fullScreenDialogs.newAlias && fullScreenDialogs.newAlias.mounted) && (
        <NewAliasDialog
          open={fullScreenDialogs.newAlias.open}
          onClose={() => handleOnClose('newAlias')}
        />
      )}
      {(fullScreenDialogs.interests && fullScreenDialogs.interests.mounted) && (
        <InterestsDialog
          onClose={() => handleOnClose('interests')}
          open={fullScreenDialogs.interests.open}
        />
      )}
      {(fullScreenDialogs.searchFilters && fullScreenDialogs.searchFilters.mounted) && (
        <SearchFiltersDialog
          open={fullScreenDialogs.searchFilters.open}
          onClose={() => handleOnClose('searchFilters')}
          filterContent={fullScreenDialogs.searchFilters.filterContent}
        />
      )}
      {(fullScreenDialogs.search && fullScreenDialogs.search.mounted) && (
        <SearchDialog open={fullScreenDialogs.search.open} onClose={() => handleOnClose('search')} />
      )}
    </>
  )
}
