import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ViewList from '@material-ui/icons/ViewList'
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'
import { Globals } from '../../constants/Global'
import useDebounce from '../../hooks/useDebounce'
import request from '../../request'
import { formatUrl } from '../../utils/RESTUtils'
import SearchBar from '../../components/Search/SearchBar'
import { MobileGrid } from '../../components/layout/layout'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserGuestListHash, selectUserGuestLists } from '../../selectors/ContactsSelectors'
import { getGuestListUsers } from '../../actions/ContactsActions'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'

const styles = makeStyles(() => ({
  input: {
    cursor: 'pointer !important',
    color: `${Globals.colors.standard} !important`,

    '&.MuiInputBase-input.Mui-disabled': {
      cursor: 'pointer !important',
    },

    '&.MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    }
  }
}))

const StyledFriendItem = styled.div`
  display: inline-flex;
  padding: 5px 16px;
  cursor: pointer;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const FriendName = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: ${Globals.colors.standard};
`

const FriendAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
  margin-right: 5px;
`

const FilterHeader = styled(Typography)`
  opacity: 0.4;
  font-size: 16px;
  font-family: 'RobotoSlab', serif;
  color: ${Globals.colors.standard};
`

const StyledCheckButton = styled(Checkbox)`
  margin-right: -24px;
`

const StyledButton = styled(Button)`
  text-transform: capitalize;
`

const LastGridItem = styled(Grid)`
  padding-bottom: 0 !important;
`

const FilterContent = ({
  guestLists,
  onApplyFilters,
  onlyShowChecked,
  selectedList,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [stateOnlyShowChecked, setOnlyShowChecked] = useState(onlyShowChecked)
  const [stateSelectedList, setSelectedList] = useState(selectedList.name)
  const classes = styles()
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedList(selectedList)
    setOnlyShowChecked(onlyShowChecked)
  }, [onlyShowChecked, selectedList])

  useEffect(() => {
    if (guestLists && guestLists.length > 0) {
      guestLists.forEach(list => {
        if (list.id !== 'allContacts') {
          dispatch(getGuestListUsers({ listId: list.id }))
        }
      })
    }
  }, [dispatch, guestLists])

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSelectList = (list) => {
    handleCloseMenu()
    setSelectedList(list)
  }

  const handleCheckOnlyShow = (e) => {
    setOnlyShowChecked(e.target.checked)
  }

  const handleApplyFilters = () => {
    onApplyFilters({
      selectedListId: stateSelectedList.id,
      onlyShowChecked: stateOnlyShowChecked,
    })
  }

  return (
    <MobileGrid childStyle={{ position: 'relative' }} paperStyle={{ position: 'relative', height: '100%' }} fullHeight paper>
      <Grid container direction="column" spacing={2} alignItems="stretch" style={{ height: '100%' }}>
        <Grid item>
          <ListItem disableGutters>
            <ListItemIcon>
              <AssignmentTurnedIn />
            </ListItemIcon>
            <ListItemText primary="Only Show Selected" />
            <ListItemSecondaryAction>
              <StyledCheckButton
                edge="end"
                checked={stateOnlyShowChecked}
                onChange={handleCheckOnlyShow}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <FilterHeader gutterBottom>Show friends on list</FilterHeader>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item>
              <ViewList />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <FormControl fullWidth>
                <Input
                  disabled
                  className={classes.input}
                  classes={{
                    disabled: classes.input,
                  }}
                  value={stateSelectedList && stateSelectedList.name}
                  onClick={handleOpenMenu}
                  endAdornment={
                    <KeyboardArrowDown style={{ color: Globals.colors.primary['500'] }} />
                  }
                />
              </FormControl>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                {guestLists && guestLists.map(list => (
                  <MenuItem key={list.id} onClick={() => handleSelectList(list)}>{list.name}</MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
        <LastGridItem item>
          <StyledButton fullWidth onClick={handleApplyFilters} variant="outlined" color="secondary">Apply Filters</StyledButton>
        </LastGridItem>
      </Grid>
    </MobileGrid>
  )
}

const FriendItem = ({ friend, onClick }) => {
  return (
    <StyledFriendItem onClick={() => onClick(friend)}>
      <FriendAvatar src={friend.imageUrl} alt={friend.name} />
      <FriendName>{friend.name}</FriendName>
    </StyledFriendItem>
  )
}

async function searchInvites(searchValue, event) {
  const contacts = await request.get(formatUrl(Globals.api.eventInvitableFriends, { eventId: event.id }), { params: { name: searchValue, limit: 5 } })
  if (contacts && contacts.data && contacts.data.items) {
    return contacts.data.items
  }
  return []
}

export default function SearchInvites({
  onApplyFilters,
  onClickFriend,
  onlyShowChecked,
  selectedListId,
  event,
}) {
  const [results, setResults] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const debouncedSearchTerm = useDebounce(searchValue)
  const friendsLists = useSelector(selectUserGuestLists)
  const friendsListsHash = useSelector(selectUserGuestListHash)
  const selectedList = friendsListsHash[selectedListId || 'allContacts']
  const history = useHistory()

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true)
        searchInvites(debouncedSearchTerm, event).then((results) => {
          setIsSearching(false)
          setResults(results)
        })
      } else {
        setResults([])
        setIsSearching(false)
      }
    },
    [debouncedSearchTerm, event]
  )

  const handleOnChange = (value) => {
    setSearchValue(value)
  }

  const handleClearSearch = () => {
    setSearchValue('')
    setResults([])
  }

  const handleClickFriend = (friend) => {
    setResults([])
    setSearchValue('')
    onClickFriend(friend)
  }

  const handleApplyFilters = (payload) => {
    onApplyFilters(payload)
    history.goBack()
  }

  const friendResults = results.map(friend => {
    return <FriendItem friend={friend} key={friend.id} onClick={handleClickFriend} />
  })

  return (
    <MobileGrid enablePadding>
      <SearchBar
        filterContent={
          <FilterContent
            guestLists={friendsLists}
            onApplyFilters={handleApplyFilters}
            onlyShowChecked={onlyShowChecked}
            selectedList={selectedList}
          />
        }
        isSearching={isSearching}
        onClearSearch={handleClearSearch}
        onChange={handleOnChange}
        results={friendResults}
        searchValue={searchValue}
      />
    </MobileGrid>
  )
}