import React from 'react'
import PropTypes from 'prop-types'
import BubbleChart from '@weknow/react-bubble-chart-d3'
import ReactWordcloud from 'react-wordcloud'
import { Globals } from '../../constants/Global'

export const DMWords = props => {
  const { data } = props
  const options = {
    colors: [Globals.colors.error['500'], Globals.colors.warning['500'], Globals.colors.primary['500']],
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'Roboto',
    fontSizes: [20, 60],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 10,
    rotations: 0,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 300,
  }
  return <ReactWordcloud words={data} options={options} />
}

const DMBubbleChart = props => {
  const { data } = props

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <BubbleChart
        graph={{
          zoom: 1,
          offsetX: -0.05,
          offsetY: -0.01,
        }}
        width={1000}
        height={800}
        padding={0} // optional value, number that set the padding between bubbles
        showLegend={false} // optional value, pass false to disable the legend.
        legendPercentage={20} // number that represent the % of with that legend going to use.
        legendFont={{
          family: 'Arial',
          size: 12,
          color: Globals.colors.black,
          weight: 'bold',
        }}
        valueFont={{
          family: 'Arial',
          size: 12,
          color: Globals.colors.gray['A100'],
          weight: 'bold',
        }}
        labelFont={{
          family: 'Arial',
          size: 16,
          color: Globals.colors.gray['A100'],
          weight: 'bold',
        }}
        //Custom bubble/legend click functions such as searching using the label, redirecting to other page
        bubbleClickFunc={() => {}}
        legendClickFun={() => {}}
        data={data}
        overflow={true}
      />
    </div>
  )
}

BubbleChart.propTypes = {
  data: PropTypes.array,
}

BubbleChart.defaultProps = {
  data: [],
}

export default DMBubbleChart
