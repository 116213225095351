import { combineReducers } from 'redux'
import categories from './CategoriesReducers'
import comments from './CommentsReducer'
import connections from './ConnectionsReducers'
import contacts from './ContactsReducers'
import { contactRequests, contactRequestsPending, contactRequestsSent } from './ContactRequestsReducers'
import events from './EventsReducers'
import facebookFriends from './FacebookFriendsReducers'
import feed from './FeedReducer'
import {
  eventHosts,
  eventInvitableFriends,
  eventInvitableManagers,
  eventInvitableSubscribers,
} from './EventInvitablesReducer'
import series from './SeriesReducers'
import externalInvites from './ExternalInvitesReducers'
import flares from './FlaresReducers'
import invites from './InvitesReducer'
import images from './ImagesReducer'
import doors from './DoorsReducer'
import { doorManagers, doorManagerGroups, doorManagerGroupMembers } from './DoorManagersReducers'
import doorSeries from './DoorSeriesReducers'
import doorRoles from './DoorRolesReducer'
import doorSubscribers from './DoorSubscribersReducers'
import doorGuestLists from './DoorGuestListsReducers'
import doorGuestListGuests from './DoorGuestListGuestsReducers'
import users from './UsersReducer'
import user from './UserReducer'
import userCategories from './UserCategoriesReducers'
import userGuestLists from './UserGuestListsReducers'
import notifications from './NotificationsReducers'
import photos from './PhotosReducers'
import subscriptions from './SubscriptionsReducers'
import { superCategories, superSubCategories } from './SuperCategoriesReducers'
import { trips, tripLegs, tripTravelers, tripViewers, tripLegTravelers, tripLegViewers } from './TripsReducers'
import venues from './VenuesReducers'
import sandbox from './SandboxReducer'
import doorBans from './DoorBansReducers'
import doorBlacklists from './DoorBlackListsReducer'
import UI from './UI/index'
import ActionConstants from '../constants/ActionConstants'
import { sandboxData } from '../constants/SandBox'

import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
  doors,
  doorBans,
  doorBlacklists,
  doorGuestLists,
  doorGuestListGuests,
  doorManagers,
  doorManagerGroups,
  doorManagerGroupMembers,
  doorRoles,
  doorSeries,
  doorSubscribers,
  categories,
  comments,
  connections,
  contacts,
  contactRequests,
  contactRequestsPending,
  contactRequestsSent,
  events,
  eventHosts,
  eventInvitableFriends,
  eventInvitableManagers,
  eventInvitableSubscribers,
  externalInvites,
  facebookFriends,
  feed,
  flares,
  images,
  invites,
  notifications,
  photos,
  sandbox,
  series,
  subscriptions,
  superCategories,
  superSubCategories,
  trips,
  tripLegs,
  tripTravelers,
  tripViewers,
  tripLegTravelers,
  tripLegViewers,
  user,
  users,
  userCategories,
  userGuestLists,
  venues,
  UI,
  form: formReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'START_REFRESH_STORE' || action.type === ActionConstants.users.confirmLogOut) {
    state = undefined
  } else if (action.type === 'SWITCH_TO_SANDBOX') {
    state = sandboxData
  }
  return appReducer(state, action)
}

export default rootReducer
