import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'
import { removeItem } from '../utils/dataUtils'

const feedConst = ActionConstants.feed

function feed(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    concreteFeed: [],
    crashFeed: [],
    createdEvents: [],
    errorMessage: null,
  },
  action
) {
  let index
  let invite
  switch (action.type) {
    case feedConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
  case feedConst.cleatItems:
    return {
      ...state,
      items: [],
    }
    case feedConst.requestItem:
    case feedConst.requestItems:
    case ActionConstants.concreteFeed.requestItems:
    case ActionConstants.crashFeed.requestItems:
    case ActionConstants.createdEvents.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case feedConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case ActionConstants.concreteFeed.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        concreteFeed: action.items,
        lastUpdated: action.receivedAt,
      }
    case ActionConstants.crashFeed.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        crashFeed: action.items,
        lastUpdated: action.receivedAt,
      }
    case ActionConstants.createdEvents.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        createdEvents: action.items,
        lastUpdated: action.receivedAt,
      }
    case feedConst.receiveItem:
    case feedConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case feedConst.sendAddItem:
    case feedConst.sendDeleteItem:
    case feedConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case feedConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case feedConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case feedConst.confirmAddItems:
      let newItems = dataUtils.appendArray(state.items, action.items)
      newItems = _.uniq(newItems, item => {
        return item.event.id
      })
      return {
        ...state,
        items: newItems,
        isFetching: false,
        lastUpdated: action.receivedAt,
      }
    case feedConst.itemFailed:
    case feedConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case feedConst.hoverEvent:
      invite = action.response
      invite.hovered = true
      index = _.findIndex(state.items, e => {
        return e.event.id === invite.event.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, invite, index),
      }
    case feedConst.unHoverEvent:
      invite = action.response
      invite.hovered = false
      index = _.findIndex(state.items, e => {
        return e.event.id === invite.event.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, invite, index),
      }
  case ActionConstants.events.confirmDeleteItem:
    index = _.findIndex(state.items, e => {
      return e.event.id === action.response.id
    })
    if (index > -1) {
      return {
        ...state,
        items: removeItem(state.items, index),
      }
    }
    return state
  case ActionConstants.feed.confirmRSVPToEvent:
    index = _.findIndex(state.items, e => {
      return e.event.id === action.path.eventId
    })
    if (index > -1) {
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, { ...state.items[index], status: action.response.status }, index),
      }
    }
    return state
    default:
      return state
  }
}

export default feed
