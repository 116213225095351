import React from 'react'
import { DMSideMenu } from '../components/menus/MenuComponents'

const SideMenuContainer = () => {
  return (
    <DMSideMenu />
  )
}

export default SideMenuContainer
