import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const eventConst = ActionConstants.events

const defaultEvent = {
  venue: null,
  externalInvites: [],
  locationName: null,
  externalEvents: [],
  hosts: [],
  timezone: null,
  created: null,
  description: null,
  goLiveTime: null,
  unlisted: false,
  series: null,
  presenters: [],
  imageUrl: null,
  imageUrls: {},
  name: null,
  invites: [],
  id: null,
  guestsCanInvite: false,
  providerLastUpdateTime: null,
  updated: null,
  externalHosts: [],
  visibility: 'publik',
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  isFetchingImageUrls: false,
}

function events(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: defaultEvent,
    stagedImage: {
      file: {
        preview: null,
      },
      fileName: '',
      fileType: null,
    },
    items: [],
    loadedItems: {},
    errorMessage: null,
    categories: [],
    templates: [],
  },
  action
) {
  let index
  let eventId
  let items
  let prevItem = {}
  let loadedItems
  switch (action.type) {
    case eventConst.selectItem:
      return {
        ...state,
        selectedItem: action.item,
      }
    case eventConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case eventConst.requestItem:
    case eventConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case ActionConstants.events.receiveItems:
      loadedItems = {}
      action.items.forEach(item => {
        loadedItems[item.id] = {
          ...item,
          invites: item.invites || [],
          externalInvites: item.externalInvites || [],
          inviteCounts: item.inviteCounts || {},
          externalInviteCounts: item.externalInviteCounts || {},
          userInvite: item.userInvite || {
            status: 'WUT',
          },
        }
      })
      return {
        ...state,
        loadedItems,
      }
  case ActionConstants.feed.receiveItems:
    loadedItems = { ...state.loadedItems }
    action.items.forEach(item => {
      if (!loadedItems[item.event.id]) {
        loadedItems[item.event.id] = {
          ...item.event,
          userInvite: {
            status: item.status,
          }
        }
      }
    })
    return {
      ...state,
      loadedItems,
    }
  case eventConst.receiveExternalInvites:
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.response.eventId]: {
          ...state.loadedItems[action.response.eventId],
          externalInviteCounts: action.response.counts,
        }
      }
    }
    case eventConst.receiveItem:
    case eventConst.confirmEditItem:
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [action.response.id]: action.response },
        isFetching: false,
        selectedItem: action.response,
      }
    case eventConst.sendAddItem:
    case eventConst.sendDeleteItem:
    case eventConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case eventConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case eventConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case eventConst.itemFailed:
    case eventConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.images.requestEventImageUrls:
    case ActionConstants.images.sendUploadEventImage:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          isFetchingImageUrls: true,
        },
      }
    case ActionConstants.images.receiveEventImageUrls:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          isFetchingImageUrls: false,
          imageUrls: action.response,
        },
      }
    case ActionConstants.images.confirmUploadEventImage:
      return {
        ...state,
        isFetchingImageUrls: false,
        selectedItem: {
          ...state.selectedItem,
          imageUrl: action.response,
          isFetchingImageUrls: false,
        },
      }
    case ActionConstants.images.stageEventImage:
      return {
        ...state,
        stagedImage: {
          file: action.response.file,
          fileName: action.response.fileName,
          fileType: action.response.fileType,
        },
      }
    case ActionConstants.images.clearStagedImage:
      return {
        ...state,
        stagedImage: {
          file: null,
          fileName: '',
          fileType: null,
          preview: null,
        },
      }
    case eventConst.setVenue:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          venue: action.location,
        },
      }
    case eventConst.clearItem:
      return {
        ...state,
        selectedItem: defaultEvent,
      }
    case ActionConstants.eventCategories.confirmDeleteItem:
      state.categories.forEach((item, i) => {
        const category = item.category ? item.category : item
        if (category.id === action.response.id) {
          index = i
        }
      })
      return {
        ...state,
        categories: dataUtils.removeItem(state.categories, index),
      }
    case ActionConstants.eventCategories.confirmAddItem:
      return {
        ...state,
        categories: dataUtils.insertItem(state.categories, action.response, 0),
      }
  case ActionConstants.invites.receiveItems:
    eventId = action.eventId
    items = action.items
    if (state.loadedItems[eventId]) {
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], invites: items } }
      }
    }
    return state
  case ActionConstants.eventExternalInvites.receiveItems:
    eventId = action.eventId
    items = action.items
    if (state.loadedItems[eventId]) {
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], externalInvites: items } }
      }
    }
    return state
  case ActionConstants.invites.receiveInvitesCount:
    eventId = action.eventId
    if (state.loadedItems[eventId]) {
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], inviteCounts: action.response } }
      }
    }
    return state
  case ActionConstants.eventExternalInvites.receiveInvitesCount:
    eventId = action.eventId
    if (state.loadedItems[eventId]) {
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], externalInviteCounts: action.response } }
      }
    }
    return state
  case ActionConstants.users.receiveInvite:
    eventId = action.eventId
    if (state.loadedItems[eventId]) {
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], userInvite: action.response } }
      }
    }
    return state
  case ActionConstants.eventInvitableFriends.receiveItems:
    eventId = action.eventId
    items = action.items
    if (state.loadedItems[eventId]) {
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], invitableFriends: items } }
      }
    }
    return state
  case ActionConstants.eventHosts.receiveItems:
    eventId = action.eventId
    items = action.items
    if (state.loadedItems[eventId]) {
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], hosts: items } }
      }
    }
    return state
  case ActionConstants.invites.confirmInviteFriend:
    eventId = action.eventId
    if (state.loadedItems[eventId]) {
      index = _.findIndex(state.loadedItems[eventId].invitableFriends, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], invitableFriends: dataUtils.updateObjectInArray(state.loadedItems[eventId].invitableFriends, action.response, index) } },
      }
    }
    return state
  case ActionConstants.eventCategories.receiveItems:
    eventId = action.eventId
    items = action.items
    if (state.loadedItems[eventId]) {
      return {
        ...state,
        loadedItems: { ...state.loadedItems, [eventId]: { ...state.loadedItems[eventId], categories: items } }
      }
    }
    return state
  case eventConst.sendEventLoading:
    if (state.loadedItems[action.response.id]) {
      prevItem = state.loadedItems[action.response.id]
    }
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.response.id]: {
          ...action.response,
          ...prevItem,
          isLoaded: false,
          isError: false,
        },
      },
    }
  case eventConst.confirmEventLoading:
    if (state.loadedItems[action.response.id]) {
      prevItem = state.loadedItems[action.response.id]
    }
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.response.id]: {
          ...action.response,
          ...prevItem,
          isLoaded: true,
          isFullyLoaded: action.fullyLoaded,
          isError: false,
        },
      },
    }
  case eventConst.eventLoadingFailed:
    return state
  case ActionConstants.doors.sendDoorEventLoading:
    if (state.loadedItems[action.response.eventId]) {
      prevItem = {
        ...state.loadedItems[action.response.eventId],
        inviteCounts: false,
        externalInviteCounts: false,
        venue: false,
        revenue: false,
        hosts: false,
      }

      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.response.eventId]: prevItem,
        }
      }
    }
    return state
  case ActionConstants.doors.confirmDoorEventLoaded:
    if (state.loadedItems[action.response.event.id]) {
      prevItem = {
        ...state.loadedItems[action.response.event.id],
        ...action.response.event,
      }

      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.response.event.id]: prevItem,
        }
      }
    }
    return state
  default:
    return state
  }
}

export default events
