import { schema } from 'normalizr'
import { event } from './EventSchemas'

const comment = new schema.Entity('comments')
const user = new schema.Entity('users')

comment.define({
  event: event,
  user: user,
  comment: comment,
})

export { comment }
