import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const banConst = ActionConstants.doorBans

function doorBans(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
  case banConst.invalidateItems:
    return {
      ...state,
      didInvalidate: true,
    }
  case banConst.requestItem:
  case banConst.requestItems:
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    }
  case banConst.receiveItems:
    return {
      ...state,
      isFetching: false,
      didInvalidate: false,
      items: action.items,
      lastUpdated: action.receivedAt,
    }
  case banConst.receiveItem:
  case banConst.confirmEditItem:
    index = _.findIndex(state.items, {
      id: action.response.id,
    })
    return {
      ...state,
      items: dataUtils.updateObjectInArray(state.items, action.response, index),
      isFetching: false,
      selectedItem: action.response,
    }
  case banConst.sendAddItem:
  case banConst.sendDeleteItem:
  case banConst.sendEditItem:
    return {
      ...state,
      isFetching: true,
    }
  case banConst.confirmDeleteItem:
    index = _.findIndex(state.items, {
      id: action.response.id,
    })
    return {
      ...state,
      items: dataUtils.removeItem(state.items, index),
      isFetching: false,
    }
  case banConst.confirmAddItem:
    return {
      ...state,
      items: dataUtils.insertItem(state.items, action.response, 0),
      isFetching: false,
    }
  case banConst.itemFailed:
  case banConst.itemsFailed:
    return {
      ...state,
      errorMessage: action.errorMessage,
    }
  default:
    return state
  }
}

export default doorBans
