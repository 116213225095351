import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import _ from 'underscore'
import { Globals } from '../../constants/Global'
import styled from 'styled-components'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import CalendarToday from '@material-ui/icons/CalendarToday'
import DateRangeIcon from '@material-ui/icons/DateRange'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { removeItem } from '../../utils/dataUtils'
import { formatDate } from '../../utils/formatUtils'

const useStyles = makeStyles((theme) => ({
  dateDialog: {
    height: '400px',
  }
}))

const DateRangeTitle = styled(DialogTitle)`
  background-color: ${Globals.colors.primary['500']};
  color: ${Globals.colors.gray['A100']};

  .MuiTypography-root {
    color: ${Globals.colors.gray['A100']};
  }
`

const localizer = momentLocalizer(moment)

function calcCircle(calendarHeight) {
  const dim = Math.floor((calendarHeight - 38 ) / 7 - 4)
  const half = dim / 2
  const halfPlus2 = half + 2
  return {
    top: halfPlus2,
    left: half,
    height: dim,
    width: dim,
    radius: half,
  }
}

const DayOfWeekContainer = styled.div`
  padding: 10px;
  background-color: ${Globals.colors.bgColor};
`

const GridContainer = styled.div`
  width: 100%;
  overflow-x: visible;
  height: 100%;
  padding: ${props => props.padding};
  box-sizing: border-box;
`

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`

const DaysOfWeekContainer = styled.div`
  display: inline-flex;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const DayOfWeek = styled.div`
  height: 30px;
  width: 30px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: ${props => (props.focused ? Globals.colors.highlight[1] : 'transparent')};
  color: ${props => (props.focused ? Globals.colors.gray['A100'] : Globals.colors.textColor)};
`

const BigCalendarWrapper = styled.div`
  height: 100%;
  width: 100%;

  .rbc-header {
    border-bottom: none !important;
    text-align: left !important;
  }

  .rbc-off-range-bg {
    background-color: transparent !important;
  }

  .rbc-date-cell {
    text-align: left !important;
    padding: 0 0 0 5px !important;
  }
`

const DMCalendarContainer = styled.div`
  background-color: ${Globals.colors.bgColor};
  position: relative;
  height: 100%;
  color: ${Globals.colors.textColor};
`
const DMCalendarHeader = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-width: 350px;
  background-color: ${props => props.accentColor || Globals.colors.secondary['500']};
  padding: 60px;
  color: ${Globals.colors.gray['A100']};
  display: flex;
  flex-direction: column;
`
const DMCalendarHeaderMonth = styled.p`
  font-size: 1.8em;
  font-weight: lighter;
`
const DMCalendarHeaderDay = styled.p`
  font-size: 4em;
  font-weight: bold;
`

const DMCalendarToolbarTitle = styled.div`
  grid-column: col 2 / span 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const DMCalendarToolbarArrow = styled.div`
  cursor: pointer;
  grid-column: ${props => props.dir === 'left' ? 'col' : 'col 7'};
  text-align: center;
`

const DMCalendarDay = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.5vh;
  position: relative;
  cursor: ${props => props.current || !props.displayDay ? 'default' : 'pointer'};
  color: ${props => props.selected ? Globals.colors.gray['A100'] : 'inherit'};
`
const NumberContainer = styled.div`
  cursor: pointer;
  margin: 0;
`
const DMCalendarCurrent = styled.div`
  position: absolute;
  box-sizing: border-box;
  ${props => {
    const dims = calcCircle(props.calendarHeight)
    const { left, top, width, height, radius } = dims
    return (
      `top: calc(50% - ${top}px);
       left: calc(50% - ${left}px);
       width: ${width}px;
       height: ${height}px;
       border-radius: ${radius}px;
      `
    )
  }}
  border: 2px solid ${props => props.accentColor || 'rgb(10, 48, 73)'};
  z-index: -80;
`
const DMCalendarSelected = styled.div`
  position: absolute;
  ${props => {
    const dims = calcCircle(props.calendarHeight)
    const { left, top, width, height, radius } = dims
    return (
      `top: calc(50% - ${top}px);
       left: calc(50% - ${left}px);
       width: ${width}px;
       height: ${height}px;
       border-radius: ${radius}px;
      `
    )
  }}
  background-color: ${props => props.accentColor || 'rgb(10, 48, 73)'};
  z-index: -100;
`
const DMDayEvents = styled.span`
  display: inline-flex;
  justify-content: space-around;
  height: 5px;
  margin-top: -2px;
  width: 100%;
`
const DMCalendarDayHeaders = styled.div`
  text-align: center;
  font-size: 2.5vh;
  font-weight: bold;
  grid-column: ${props => 'col ' + props.cellNum};
`

const DMEventDot = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${props => props.color || Globals.colors.standard};
  border-radius: 4px;
  display: block;
  margin: 0 1px;
`

const DMEventBar = styled.div`
  height: 5px;
  background-color: ${props =>
    props.disabled
      ? Globals.colors.standard
      : props.wentLive
      ? Globals.colors.orange1
      : Globals.colors.green['500']};
  display: inline-block;
  margin-top: 7px;
  opacity: 0.3;

  &.event-bar-right {
    float: right;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.event-bar-left,
  &.event-bar-middle {
    float: left;
  }

  &.event-bar-left {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`

const DMDateRange = styled.div`
  ${props => {
    const { calendarHeight, isAtStart, isAtEnd } = props
    const dims = calcCircle(calendarHeight)
    const { left, top, height, radius } = dims
    return (
      `
      border-top-left-radius: ${isAtStart ? radius + 'px' : '0'};
      border-top-right-radius: ${isAtEnd ? radius + 'px' : '0'};
      border-bottom-left-radius: ${isAtStart ? radius + 'px' : '0'};
      border-bottom-right-radius: ${isAtEnd ? radius + 'px' : '0'};
      height: ${height}px;
      top: calc(50% - ${top}px);
      left: ${isAtStart ? 'calc(50% - ' + left + 'px)' : '0'};
      width: ${isAtEnd || isAtStart ? 'calc(50% + ' + left + 'px)' : '100%'};
      `
    )
  }}
  position: absolute;
  z-index: -1000;
  opacity: 0.50;
  ${props => {
    let color
    if (props.isOldRange) {
      color = props.inRange <= 9 ? props.inRange * 100 : 900
      return `background-color: ${Globals.colors.highlightPast[color.toString()]};`
    }
    if (props.isCurrentRange) {
      return `background-color: ${Globals.colors.highlight[1]}`
    }
    return `background-color: ${Globals.colors.highlight[props.colorInt]};`
  }}
`

export const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

const config = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  month_subs: ['Jan', 'Feb', 'Apr', 'Mar', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
  weeks: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  week_subs: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
  today: function() {
    return new Date()
  },
}
const TODAY = config.today()
const formats = {
  dateFormat: (date, culture, localizer) => {
    let form = 'D'
    if (
      moment(date)
        .startOf('month')
        .isSame(date)
    ) {
      form = 'MMM D'
    }
    return localizer.format(date, form, culture)
  },
}

const StyledEventWrapper = styled.div`
  border-radius: 5px;
  background-color: ${props => {
    let backGround
    switch (props.status) {
      case 'YES':
        backGround = Globals.colors.primary['500']
        break
      case 'MAYBE':
        backGround = Globals.colors.error['500']
        break
      case 'WUT':
        backGround = Globals.colors.secondary['500']
        break
      default:
        backGround = Globals.colors.secondary['500']
        break
    }
    return backGround
  }};
  cursor: pointer;

  .rbc-event {
    background: transparent !important;
  }
`

const DMToolbar = props => {
  const { onNavigate, onViewChange, messages, label, view, views } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const handleNavigate = action => {
    onNavigate(action)
  }

  const handleView = v => {
    setAnchorEl(null)
    onViewChange(v)
  }

  const handleOpenViewMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseViewMenu = () => {
    setAnchorEl(null)
  }

  const viewNamesGroup = messages => {
    if (views.length > 1) {
      return (
        <div style={{ flexGrow: 1 }}>
          <Button
            style={{ float: 'right' }}
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={handleOpenViewMenu}
          >
            {view}
            <Icon style={{ marginLeft: '8px' }} className="fa fa-chevron-down" />
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseViewMenu}>
            {views.map(name => {
              return (
                <MenuItem key={name} onClick={() => handleView(name)}>
                  {messages[name]}
                </MenuItem>
              )
            })}
          </Menu>
        </div>
      )
    }
  }
  return (
    <Toolbar style={{ padding: '0' }}>
      <Tooltip title={messages.today}>
        <Button style={{ marginRight: 8 }} color="primary" onClick={() => handleNavigate(navigate.TODAY)}>
          {messages.today}
        </Button>
      </Tooltip>
      <Tooltip title={messages.previous}>
        <Fab
          size="small"
          color="primary"
          aria-label="edit"
          style={{ marginRight: 8 }}
          onClick={() => handleNavigate(navigate.PREVIOUS)}
        >
          <Icon>
            <ChevronLeft />
          </Icon>
        </Fab>
      </Tooltip>
      <Tooltip title={messages.next}>
        <Fab size="small" color="primary" style={{ marginRight: 8 }} onClick={() => handleNavigate(navigate.NEXT)}>
          <Icon>
            <ChevronRight />
          </Icon>
        </Fab>
      </Tooltip>
      <Typography variant="h5">{label}</Typography>
      {viewNamesGroup(messages)}
    </Toolbar>
  )
}

DMToolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  messages: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
}

const EventWrapper = props => {
  const { event, children } = props
  return <StyledEventWrapper status={event.status}>{children}</StyledEventWrapper>
}

const DMCalendar = props => {
  const { date, onDatePicked, onUpdateMonth, showMini, events } = props
  return (
    <Grid container style={{ height: '100%' }}>
      {showMini ? (
        <Grid item xs={3}>
          <MiniCalendar
            events={events}
            accentColor={Globals.colors.secondary['500']}
            current={date}
            displayEvents={true}
            showHeader={false}
            onDatePicked={onDatePicked}
            onUpdateMonth={onUpdateMonth}
          />
        </Grid>
      ) : null}
      <Grid item xs={showMini ? 9 : 12} style={{ height: '100%' }}>
        <BigCalendarWrapper>
          <Calendar
            {...props}
            formats={formats}
            components={{
              eventWrapper: EventWrapper,
              toolbar: DMToolbar,
            }}
            localizer={localizer}
          />
        </BigCalendarWrapper>
      </Grid>
    </Grid>
  )
}

DMCalendar.propTypes = {
  showMini: PropTypes.bool,
  date: PropTypes.object,
  onUpdateMonth: PropTypes.func,
  onDatePicked: PropTypes.func,
  events: PropTypes.arrayOf(PropTypes.object),
}

DMCalendar.defaultProps = {
  showMini: true,
  date: new Date(),
  onUpdateMonth: () => {},
  onDatePicked: () => {},
  events: [],
}

const MiniCalendar = props => {
  const {
    accentColor,
    constrictRange,
    current,
    dateRanges,
    displayEvents,
    goLive,
    events,
    multiSelect,
    onDatePicked,
    onUpdateMonth,
    padding,
    selected,
    multi,
    selectOnMount,
    showHeader,
    showMultiEvents,
    showOffset,
    allowCollapse,
    onWeekViewToggle,
  } = props
  const [currentState, setCurrentState] = useState(current || config.today())
  const [selectedState, setSelectedState] = useState(selected || config.today())
  const [selectedMulti, setSelectedMulti] = useState(multi || [])
  const [calendarHeight, setCalendarHeight] = useState(null)
  const [weekView, setWeekView] = useState(false)
  const gridContainer = useRef()
  const tableContainer = useRef()

  const handleCalendarSizing = () => {
    const containerBox = gridContainer.current.getBoundingClientRect()
    const tableBox = tableContainer.current.getBoundingClientRect()
    if (containerBox) {
      setCalendarHeight(tableBox.height)
    }
  }

  useEffect(() => {
    handleCalendarSizing()
  }, [gridContainer])

  useEffect(() => {
    function onResize() {
      handleCalendarSizing()
    }

    const ranges = []
    if (dateRanges) {
      const date = moment(current)
      const startOfRanges = moment(dateRanges[0].startTime)
      const endOfRanges = moment(dateRanges[dateRanges.length - 1].endTime)
      if (date.isBetween(startOfRanges, endOfRanges, 'day', '[]')) {
        dateRanges.forEach((range, i) => {
          if (date.isBetween(moment(range.startTime), moment(range.endTime), 'day', '[]')) {
            ranges.push(i)
          }
        })
      }
    }
    if (selectOnMount) {
      onDatePicked(current, ranges)
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [current, dateRanges, onDatePicked, selectOnMount])

  useEffect(() => {
    if (constrictRange && dateRanges) {
      const newArray = []
      for (let i=0; i<selectedMulti.length; i+=1) {
        const selected = selectedMulti[i]
        let isInRange = false
        for (let c=0; c<dateRanges.length; c+=1) {
          const range = dateRanges[c]
          if (moment(selected).isSame(range.startTime, 'day') || moment(selected).isSame(range.endTime, 'day')) {
            isInRange = true
          }
        }
        if (isInRange) {
          newArray.push(selected)
        }
      }
      setSelectedMulti(newArray)
    }
  }, [constrictRange, dateRanges, selectedMulti])

  useEffect(() => {
    const ranges = []
    if (dateRanges) {
      const date = moment(selected)
      const startOfRanges = moment(dateRanges[0].startTime)
      const endOfRanges = moment(dateRanges[dateRanges.length - 1].endTime)
      if (date.isBetween(startOfRanges, endOfRanges, 'day', '[]')) {
        dateRanges.forEach((range, i) => {
          if (date.isBetween(moment(range.startTime), moment(range.endTime), 'day', '[]')) {
            ranges.push(i)
          }
        })
      }
    }
    setSelectedState(selected)
    if (selectOnMount) {
      onDatePicked(selected, ranges)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const updateMonth = add => {
    let d = currentState
    d.setMonth(d.getMonth() + add)
    onUpdateMonth(d)
    setCurrentState(new Date(d))
  }

  const prev = () => {
    updateMonth(-1)
  }

  const next = () => {
    updateMonth(1)
  }

  const buildDate = (month, day) => {
    let d = new Date(currentState.getTime())
    d.setMonth(d.getMonth() + month)
    d.setDate(day)
    return d
  }

  const checkMonthChange = d => {
    if (moment(d).isBefore(moment(currentState), 'month')) {
      prev()
    } else if (moment(d).isAfter(moment(currentState), 'month')) {
      next()
    }
    setSelectedState(d)
  }

  const _onDatePicked = (month, day) => {
    let d = buildDate(month, day)
    if (multi) {
      let idx = _.findIndex(selectedMulti, d$ => {
        return moment(d$).isSame(moment(d), 'day')
      })
      if (idx > -1 && multi.length === 1) {
        return
      }
    }
    const ranges = []
    if (dateRanges) {
      const date = moment(d)
      const startOfRanges = moment(dateRanges[0].startTime)
      const endOfRanges = moment(dateRanges[dateRanges.length - 1].endTime)
      if (date.isBetween(startOfRanges, endOfRanges, 'day', '[]')) {
        dateRanges.forEach((range, i) => {
          if (date.isBetween(moment(range.startTime), moment(range.endTime), 'day', '[]')) {
            ranges.push(i)
          }
        })
      }
    }
    onDatePicked(d, ranges)
    checkMonthChange(d)
    if (multiSelect) {
      _onMultiDatePicked(d)
    } else {
      setSelectedState(d)
    }
  }

  const _onMultiDatePicked = day => {
    const newSelectedMulti = selectedMulti
    let idx = _.findIndex(newSelectedMulti, d => {
      return moment(d).isSame(moment(day), 'day')
    })
    if (idx < 0) {
      newSelectedMulti.push(day)
      setSelectedMulti(newSelectedMulti)
    } else {
      setSelectedMulti(removeItem(selectedMulti, idx))
    }
  }

  const renderEvents = days => {
    let i
    let c
    let dayListings
    let startDate
    let endDate
    let day
    if (events && events.length > 0) {
      for (i = 0; i < events.length; i++) {
        for (c = 0; c < days.length; c++) {
          day = moment(new Date(days[c].date))
          let event = { ...events[i] }
          if (event.event) {
            event = event.event
          }
          startDate = moment(
            goLive && event.goLiveTime ? event.goLiveTime : event.startTime
          )
          endDate = moment(goLive && event.goLiveTime ? event.startTime : event.endTime)
          if (
            day.isBetween(startDate, endDate, null, '[]') ||
            day.isSame(startDate, 'day') ||
            day.isSame(endDate, 'day')
          ) {
            days[c].events.push(events[i])
          }
        }
      }
    }
    dayListings = days.map((day, i) => {
      return renderDay(day, i)
    })
    return dayListings
  }

  const renderDay = (opts = {}, i) => {
    const cellNum = (i + 7) % 7 + 1
    const fullDate = moment(new Date(opts.date))
    const day = fullDate.date()
    const today = moment(new Date())
    const endOfRanges = dateRanges && moment(dateRanges[dateRanges.length - 1].endTime)
    let dayEvents = []
    let inRange = 0
    let colorInt = 2
    let isAtStart = false
    let isAtEnd = false
    let isOldRange = false
    let isCurrentRange = false
    let isEndOfCurrent = false
    let showRange = false
    let dotColor = Globals.colors.primary['500']
    if (fullDate.isBefore(today)) {
      dotColor = Globals.colors.standard
    }
    if (dateRanges) {
      for (let r=0; r<dateRanges.length; r+=1) {
        const range = dateRanges[r]
        const startOfRange = moment(range.startTime)
        const endOfRange = moment(range.endTime)
        if (fullDate.isSame(startOfRange, 'day')) {
          isAtStart = true
        }
        if (fullDate.isSame(endOfRange, 'day')) {
          isAtEnd = true
        }
        if (fullDate.isBetween(startOfRange, endOfRanges, 'day', '[]')) {
          inRange += 1
        }
        if (fullDate.isBetween(startOfRange, endOfRange, 'day', '[]')) {
          showRange = true
          isOldRange = endOfRange.isSameOrBefore(today, 'day')
          isCurrentRange = today.isBetween(startOfRange, endOfRange, 'day', '[]')
          isEndOfCurrent = (fullDate.isSame(startOfRange, 'day') && fullDate.isBefore(endOfRange, 'day')) && isCurrentRange
          if (!isCurrentRange) {
            colorInt = (3 + colorInt + 1) % 3
          }
        }
      }
    }
    if (displayEvents) {
      if (!showMultiEvents) {
        if (opts.events.length > 0) {
          dayEvents.push(
            <DMEventDot key={opts.events[0].event ? opts.events[0].event.id : opts.events[0].id} color={dotColor} />
          )
        }
      } else {
        dayEvents = opts.events.map(event => {
          let dayEvent = { ...event }
          if (dayEvent.event) {
            dayEvent = dayEvent.event
          }
          const startDate = moment(goLive && dayEvent.goLiveTime ? dayEvent.goLiveTime : dayEvent.startTime)
          const endDate = moment(goLive && dayEvent.goLiveTime ? dayEvent.startTime : dayEvent.endTime)
          const disabled = endDate.isBefore(today, 'day') && goLive
          const startTime = startDate.hour()
          const endTime = endDate.hour()
          const wentLive = goLive && startDate.isBefore(today, 'day')
          dotColor = Globals.colors.warning['500']
          let width
          if (fullDate.isBetween(startDate, endDate, 'day')) {
            return (
              <DMEventBar
                className={`event-bar-middle ${wentLive ? 'went-live' : ''}`}
                disabled={disabled}
                key={dayEvent.id}
                style={{ width: 100 + '%' }}
                wentLive={wentLive}
              />
            )
          } else if (endDate.isAfter(fullDate, 'day')) {
            width = 100 - ((startTime + 1) / 24) * 100
            return (
              <DMEventBar
                className={`event-bar-right ${wentLive ? 'went-live' : ''}`}
                disabled={disabled}
                key={dayEvent.id}
                style={{ width: width + '%' }}
                wentLive={wentLive}
              />
            )
          } else if (startDate.isBefore(fullDate, 'day')) {
            width = 100 - ((endTime + 1) / 24) * 100
            if (goLive && dayEvent.goLiveTime) {
              return (
                <span key={dayEvent.id}>
                  <DMEventBar
                    className={`event-bar-left ${wentLive ? 'went-live' : ''}`}
                    disabled={disabled}
                    style={{ width: width + '%' }}
                    wentLive={wentLive}
                  />
                  <DMEventDot
                    className={wentLive ? 'went-live' : ''}
                    disabled={disabled}
                    style={{ left: `calc(${width}% - 9px)`, position: 'absolute' }}
                    color={dotColor}
                  />
                </span>
              )
            }
            return (
              <DMEventBar
                className={`event-bar-left ${wentLive ? 'went-live' : ''}`}
                disabled={disabled}
                key={dayEvent.id}
                style={{ width: width + '%' }}
                wentLive={wentLive}
              />
            )
          } else {
            return (
              <DMEventDot
                className={wentLive ? 'went-live' : ''}
                disabled={disabled}
                key={dayEvent.id}
                color={dotColor}
              />
            )
          }
        })
      }
    }

    let displayDay
    if (showOffset) {
      displayDay = true
    } else {
      displayDay = opts.month === 0
    }

    return (
      <DMCalendarDay
        cellNum={cellNum}
        current={opts.current}
        displayDay={displayDay}
        selected={opts.selected}
        onClick={typeof displayEvents === 'function' ? () => displayEvents(opts.events) : null}
        key={opts.key}
      >
        {displayDay && (
          <>
            {opts.today && <DMCalendarCurrent accentColor={accentColor} calendarHeight={calendarHeight} />}
            {opts.selected && <DMCalendarSelected accentColor={accentColor} calendarHeight={calendarHeight} />}
            {showRange && (
              <DMDateRange
                calendarHeight={calendarHeight}
                colorInt={isAtStart && isAtEnd ? (3 + colorInt - 1) % 3 : colorInt}
                inRange={isAtStart && isAtEnd ? inRange - 1 : inRange}
                isAtEnd={isAtEnd}
                isAtStart={isAtStart && !isAtEnd}
                isCurrentRange={isCurrentRange || isEndOfCurrent}
                isOldRange={isOldRange || constrictRange} />
            )
            }
            {(showRange && isAtStart && isAtEnd) && (
              <DMDateRange
                calendarHeight={calendarHeight}
                colorInt={colorInt}
                inRange={inRange}
                isAtEnd={false}
                isAtStart={true}
                isCurrentRange={isCurrentRange}
                isOldRange={isOldRange || constrictRange}
              />
            )
            }
            <NumberContainer
              onClick={(ev) => {
                let d = ev.target.innerHTML
                _onDatePicked(opts.month, d)
              }}
            >
              {day}
            </NumberContainer>
            {displayEvents && <DMDayEvents>{dayEvents}</DMDayEvents>}
          </>
        )}
      </DMCalendarDay>
    )
  }

  const renderDays = copy => {
    const days = []
    // set to beginning of month
    let newCopy = copy
    newCopy.setDate(1)
    // if we are missing no offset, include the previous week
    const offset = newCopy.getDay() === 0 ? 7 : newCopy.getDay()
    newCopy.setDate(-offset)
    let startIndex = 0
    let endIndex = 42
    let sel = new Date(selectedState.getTime())
    let inMonth = false
    let lastMonth = true
    let isSelected = false
    let isToday = false
    if (weekView) {
      const startOfWeek = moment(selectedState).startOf('week')
      const startOfWeekIndex = Number(startOfWeek.clone().format('D'))
      newCopy = new Date(startOfWeek)
      startIndex = startOfWeekIndex
      endIndex = startIndex + 7
      newCopy.setDate(startOfWeekIndex - 1)
      inMonth = true
    }
    for (let i = startIndex; i < endIndex; i++) {
      // increase date
      newCopy.setDate(newCopy.getDate() + 1)

      // make sure we pass any previous month values
      if (i < 30 && newCopy.getDate() === 1) {
        inMonth = true
        lastMonth = false
      } else if (i > 30 && newCopy.getDate() === 1) {
        // if we are seeing the '1' again, we have iterated over
        // the current month
        inMonth = false
      }
      if (multiSelect) {
        for (let s = 0; s < selectedMulti.length; s++) {
          sel = new Date(selectedMulti[s].getTime())
          isSelected =
            sel.getFullYear() === copy.getFullYear() &&
            sel.getDate() === copy.getDate() &&
            sel.getMonth() === copy.getMonth()
          if (isSelected) {
            break
          }
        }
      } else {
        isSelected =
          sel.getFullYear() === newCopy.getFullYear() &&
          sel.getDate() === newCopy.getDate() &&
          sel.getMonth() === newCopy.getMonth()
      }

      isToday =
        TODAY.getFullYear() === newCopy.getFullYear() &&
        TODAY.getDate() === newCopy.getDate() &&
        TODAY.getMonth() === newCopy.getMonth()
      days.push({
        key: i,
        today: isToday,
        selected: isSelected,
        current: inMonth,
        month: inMonth ? 0 : lastMonth ? -1 : 1,
        date: newCopy.toString(),
        events: [],
      })
    }
    return renderEvents(days)
    //return days
  }

  const renderHeaders = () => {
    const header = []
    for (let i = 0; i < config.week_subs.length; i++) {
      header.push(<DMCalendarDayHeaders cellNum={i + 1} key={i}>{config.week_subs[i]}</DMCalendarDayHeaders>)
    }
    return header
  }

  const handleWeekViewToggle = () => {
    onWeekViewToggle()
    setWeekView(!weekView)
  }

  // copy our current time state
  const rCopy = new Date(currentState.getTime())
  // get the month days
  const rDays = renderDays(rCopy)

  const tMonth = config.months[selectedState.getMonth()]
  const tDate = selectedState.getDate()
  const month = config.months[currentState.getMonth()]
  const year = currentState.getFullYear()
  let upperDate = null
  if (showHeader) {
    upperDate = (
      <DMCalendarHeader accentColor={accentColor}>
        <DMCalendarHeaderMonth>{tMonth.toUpperCase()}</DMCalendarHeaderMonth>
        <DMCalendarHeaderDay>{tDate}</DMCalendarHeaderDay>
      </DMCalendarHeader>
    )
  }
  const content = (
    <GridContainer ref={gridContainer} padding={padding}>
      <TableGrid ref={tableContainer}>
        {upperDate}
        <DMCalendarToolbarArrow dir="left">
          <IconButton onClick={prev} disableRipple disableFocusRipple color="primary">
            <KeyboardArrowLeft />
          </IconButton>
        </DMCalendarToolbarArrow>
        <DMCalendarToolbarTitle>
          {month} {year}
          {allowCollapse && (
            <>
              <IconButton onClick={handleWeekViewToggle} color="primary">
                {weekView && <DateRangeIcon />}
                {!weekView && <CalendarToday />}
              </IconButton>
            </>
          )}
        </DMCalendarToolbarTitle>
        <DMCalendarToolbarArrow dir="right">
          <IconButton onClick={next} disableRipple disableFocusRipple color="primary">
            <KeyboardArrowRight />
          </IconButton>
        </DMCalendarToolbarArrow>
        {renderHeaders()}
        {rDays}
      </TableGrid>
    </GridContainer>
  )

  return (
    <DMCalendarContainer>
      {content}
    </DMCalendarContainer>
  )
}

const DateRangeSelector = ({
  dateRange,
  onClose,
  onSelect,
  open,
}) => {
  const [stateDateRange, setDateRange] = useState(dateRange || { startTime: null, endTime: null })
  const classes = useStyles()

  useEffect(() => {
    setDateRange(dateRange)
  }, [dateRange])

  const handleDatePicked = (date) => {
    if (!stateDateRange.startTime) {
      setDateRange({
        startTime: date,
        endTime: null
      })
    } else if (moment(date).isBefore(moment(stateDateRange.startTime), 'day')) {
      if (!stateDateRange.endTime) {
        setDateRange({
          startTime: date,
          endTime: stateDateRange.startTime,
        })
      } else {
        setDateRange({
          ...stateDateRange,
          startTime: date,
        })
      }
    } else if (!moment(date).isSame(moment(stateDateRange.startTime), 'day')
      && !moment(date).isSame(moment(stateDateRange.endTime), 'day')) {
      setDateRange({
        ...stateDateRange,
        endTime: date,
      })
    }
  }

  const handleSelect = () => {
    onSelect(stateDateRange)
    setDateRange({ startTime: null, endTime: null })
  }

  const handleClose = () => {
    onClose()
    setDateRange({ startTime: null, endTime: null })
  }

  return (
    <Dialog
      classes={{
        paper: classes.dateDialog,
      }}
      maxWidth={'md'}
      open={open}
      onClose={onClose}
    >
      <DateRangeTitle disableTypography>
        <Typography variant="subtitle1" style={{ color: Globals.colors.gray['A100'] }}>
          Select a date range
        </Typography>
        <Typography variant="h1">
          {stateDateRange.startTime && formatDate(stateDateRange.startTime, null, 'MMM D')} - {stateDateRange.endTime && formatDate(stateDateRange.endTime, null, 'MMM D')}
        </Typography>
      </DateRangeTitle>
      <MiniCalendar
        accentColor={Globals.colors.primary['500']}
        constrictRange
        dateRanges={stateDateRange.endTime ? [stateDateRange] : null}
        displayEvents
        fullWidth
        multiSelect
        onDatePicked={handleDatePicked}
        selectOnMount={false}
        showHeader={false}
        showMultiEvents={false}
      />
      <DialogActions>
        <Button variant="text" color="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="text" color="secondary" onClick={handleSelect}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}

DateRangeSelector.propTypes = {
  dateRange: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
}

DateRangeSelector.defaultProps = {
  dateRange: null,
  open: () => false,
  onClose: () => null,
  onSelect: () => null,
}

const DayOfWeekSelector = props => {
  const { handleSelect, selected, daysOfWeek } = props
  return (
    <DayOfWeekContainer>
      <DaysOfWeekContainer>
        {daysOfWeek.map((day, i) => {
          let index = i.toString()
          return (
            <DayOfWeek focused={selected.indexOf(index) > -1} onClick={() => handleSelect(index)}>
              {day.slice(0, 1)}
            </DayOfWeek>
          )
        })}
      </DaysOfWeekContainer>
    </DayOfWeekContainer>
  )
}

DayOfWeekSelector.defaultProps = {
  handleSelect: () => {},
  selected: [],
}

MiniCalendar.propTypes = {
  accentColor: PropTypes.string,
  allowCollapse: PropTypes.bool,
  constrictRange: PropTypes.bool,
  current: PropTypes.instanceOf(Date),
  dateRanges: PropTypes.any,
  displayEvents: PropTypes.any,
  events: PropTypes.arrayOf(PropTypes.object),
  fullWidth: PropTypes.bool,
  goLive: PropTypes.bool,
  onDatePicked: PropTypes.func,
  onUpdateMonth: PropTypes.func,
  padding: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  selectOnMount: PropTypes.bool,
  showHeader: PropTypes.bool,
  showOffset: PropTypes.bool,
  showMultiEvents: PropTypes.bool,
}

MiniCalendar.defaultProps = {
  accentColor: Globals.colors.error['500'],
  allowCollapse: false,
  constrictRange: false,
  current: new Date(),
  dateRanges: null,
  displayEvents: null,
  events: [],
  fullWidth: false,
  goLive: false,
  onDatePicked: () => {},
  onUpdateMonth: () => {},
  padding: '0',
  selected: new Date(),
  selectOnMount: true,
  showHeader: true,
  showOffset: false,
  showMultiEvents: true,
}

export { DayOfWeekSelector, DMCalendar, MiniCalendar, DateRangeSelector }
