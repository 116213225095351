import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'

const userGuestlistConst = ActionConstants.userGuestLists

function userGuestlists(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: 'allContacts',
    items: {
      allContacts: { id: 'allContacts', name: 'All Contacts', friends: {} },
    },
    errorMessage: null,
  },
  action
) {
  let hash
  let id
  let item
  switch (action.type) {
    case userGuestlistConst.selectItem:
      return {
        ...state,
        selectedItem: action.item,
      }
    case userGuestlistConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
  case ActionConstants.contacts.receiveItems:
    hash = {}
    for (let i=0; i<action.items.length; i+=1) {
      hash[action.items[i].id] = action.items[i]
    }
    return {
      ...state,
      items: {
        ...state.items,
        allContacts: {
          ...state.items['allContacts'],
          friends: hash,
        },
      }
    }
    case userGuestlistConst.requestItem:
    case userGuestlistConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case userGuestlistConst.receiveItems:
      hash = {}
      for (let i=0; i<action.items.length; i+=1) {
        item = action.items[i]
        hash[item.id] = item
      }
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: {
          ...state.items,
          ...hash,
        },
        lastUpdated: action.receivedAt,
      }
  case ActionConstants.contacts.confirmDeleteItem:
    hash = _.clone(state.items)
    Object.values(hash).forEach(list => {
      delete list.friends[action.response.id]
    })
    return {
      ...state,
      items: hash,
    }
    case userGuestlistConst.receiveItem:
    case userGuestlistConst.confirmEditItem:
    case userGuestlistConst.confirmAddItem:
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.id]: action.response,
        },
        isFetching: false,
        selectedItem: action.response,
      }
    case userGuestlistConst.sendAddItem:
    case userGuestlistConst.sendDeleteItem:
    case userGuestlistConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case userGuestlistConst.confirmDeleteItem:
      hash = { ...state.items }
      delete hash[action.response.id]
      return {
        ...state,
        items: hash,
        isFetching: false,
      }
    case userGuestlistConst.itemFailed:
    case userGuestlistConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.userGuestListGuests.confirmAddItems:
      id = Number(action.response.guestList)
      hash = {}
      for (let i=0; i<action.response.guests.length; i+=1) {
        item = action.response.guests[i]
        hash[item.id] = item
      }
      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...state.items[id],
            friends: {
              ...state.items[id].friends,
              ...hash,
            }
          }
        },
      }
  case ActionConstants.userGuestListGuests.receiveItems:
    id = Number(action.response.guestList)
    hash = {}
    for (let i=0; i<action.response.guests.length; i+=1) {
      item = action.response.guests[i]
      hash[item.id] = item
    }
    return {
      ...state,
      items: {
        ...state.items,
        [id]: {
          ...state.items[id],
          friends: hash,
        }
      },
    }
  case ActionConstants.userGuestListGuests.confirmDeleteItem:
    id = Number(action.response.listId)
    item = { ...state.items[id].friends }
    delete item[Number(action.response.userId)]
    return {
      ...state,
      items: {
        ...state.items,
        [id]: {
          ...state.items[id],
          friends: item,
        }
      }
    }
  case ActionConstants.contacts.confirmBlockUser:
    id = Number(action.response.id)
    const newLists = { ...state.items }
    Object.values(newLists).forEach(list => {
      if (list.friends && list.friends[id]) {
        delete list.friends[id]
      }
    })
    return {
      ...state,
      items: newLists,
    }
    default:
      return state
  }
}

export default userGuestlists
