import PropTypes from 'prop-types'

const UserInfo = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  created: PropTypes.number,
  imageUrl: PropTypes.string,
  deactivated: PropTypes.bool.isRequired,
}

const UserSettings = {
  user: PropTypes.string,
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  homeLat: PropTypes.number,
  homeLong: PropTypes.number,
  defaultRadius: PropTypes.number,
  eventCommentNotification: PropTypes.bool,
  hostAddedNotification: PropTypes.bool,
  managerAddedNotification: PropTypes.bool,
  birthday: PropTypes.number,
  seeNsfw: PropTypes.bool,
  nsfwInFeed: PropTypes.bool,
  calendarVisibility: PropTypes.string,
  language: PropTypes.string,
  eventCommentNotificationEnabled: PropTypes.bool,
  managerAddedNotificationEnabled: PropTypes.bool,
}

const User = {
  userLoggedIn: PropTypes.bool.isRequired,
  info: PropTypes.shape(UserInfo).isRequired,
  settings: PropTypes.shape(UserSettings).isRequired,
  stagedAlias: PropTypes.shape(UserInfo),
  aliases: PropTypes.arrayOf(PropTypes.shape(UserInfo)).isRequired,
  infoLoaded: PropTypes.bool.isRequired,
  settingsLoaded: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingImageUrls: PropTypes.bool.isRequired,
  isFetchingSettings: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  errorMessage: PropTypes.string,
  appRefreshed: PropTypes.bool.isRequired,
}

export default User

export { UserInfo, UserSettings }