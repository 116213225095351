import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const userCategoryConst = ActionConstants.userCategories

function userCategories(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    otherUserItems: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case userCategoryConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case userCategoryConst.requestItem:
    case userCategoryConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case userCategoryConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case userCategoryConst.receiveItem:
    case userCategoryConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case userCategoryConst.sendAddItem:
    case userCategoryConst.sendDeleteItem:
    case userCategoryConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case userCategoryConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.category.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case userCategoryConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case userCategoryConst.itemFailed:
    case userCategoryConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.otherUserCategories.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        otherUserItems: action.items,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}

export default userCategories
