import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Globals } from '../constants/Global'
import request from '../request'
import history from '../history'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { selectFeed } from '../selectors/FeedSelectors'
import CardGrid from '../components/cards/CardGrid'
import { selectDoorRecommendations } from '../selectors/DoorsSelectors'
import Typography from '@material-ui/core/Typography'
import {  MobileTearSheet } from '../components/decorators/DecoratorComponents'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import GridList from '@material-ui/core/GridList'
import { formatUrl } from '../utils/RESTUtils'
import Paper from '@material-ui/core/Paper'
import EventCard from './Events/EventCard'

const HappeningContainer = styled.div`
  margin-top: 16px;
`

const styles = makeStyles(() => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  presentingContent: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}))

async function getDoorEvents(door) {
  let presentedEvents = await request.get(formatUrl(Globals.api.doorPresentedEvents, { doorId: door.id }))
  let series = await request.get(formatUrl(Globals.api.doorSeries, { doorId: door.id }))
  if (presentedEvents.data && presentedEvents.data.items) {
    presentedEvents = presentedEvents.data.items
  } else {
    presentedEvents = []
  }
  if (series.data && series.data.items) {
    series = series.data.items
  } else {
    series = []
  }
  return [...presentedEvents, ...series]
}

const DoorRecommendationCard = ({ door }) => {
  const classes = styles()
  const [events, setEvents] = useState([])

  useEffect(() => {
    getDoorEvents(door).then(response => {
      setEvents(response)
    })
  }, [door])

  let eventsList = []
  events.forEach((event, i) => {
    if (i !== events.length - 1) {
      eventsList.push(<span key={`event-span-${event.id}`}>, </span>)
    }
    eventsList.push(
      <Button
        key={event.id}
        variant="text"
        color="secondary"
        onClick={() => {
          if (event.cronLine) {
            history.push(`/series/${event.id}`)
          } else {
            history.push(`/events/${event.id}`)
          }
        }}>
        {event.name}
      </Button>
    )
  })
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar onClick={() => history.push(`/doors/${door.id}`)} src={door.imageUrl} />
        }
        action={
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        }
        title={door.name}
        subheader="Local Door"
      />
      {(events && events.length > 0) && (
        <CardContent className={classes.presentingContent}>
          Presenting {eventsList}
        </CardContent>
      )}

      <CardMedia
        className={classes.media}
        image={door.coverImageUrl}
        title={door.name}
      />
      <CardActions disableSpacing>
        <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle1" color="textSecondary">
              ATTENDED RECENTLY
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              SPONSORED
            </Typography>
          </div>
          <Button variant="outlined" color="secondary">
            SUBSCRIBE
          </Button>
        </div>
      </CardActions>
    </Card>
  )
}

const EventListsContainer = ({ stateCards, type, singleColumn }) => {
  const feed = useSelector(selectFeed)
  const recommendations = useSelector(selectDoorRecommendations)
  let happeningToday = []
  let happeningThisWeek = []
  let happeningThisMonth = []
  let monthMap
  const classes = styles()
  const startOfToday = moment().startOf('day')
  const laterToday = moment().startOf('day').add(24, 'h')
  const startOfWeek = moment().startOf('week')
  const endOfWeek = moment().endOf('week').add(7, 'days')
  const startOfMonth = moment().startOf('month')
  const endOfMonth = moment().endOf('month')

  let cards = stateCards || feed[type || 'items']
  cards = cards.filter(card => (card.event && !card.event.flare) || card)
  cards.forEach(item => {
    const event = item.event || item
    if (startOfToday.valueOf() < event.startTime && event.startTime < laterToday.valueOf()) {
      happeningToday.push(
        <EventCard event={{ ...event, userInvite: { status: item.status } }} />
      )
    } else if (startOfWeek.valueOf() < event.startTime && event.startTime < endOfWeek.valueOf()) {
      happeningThisWeek.push(
        <EventCard event={{ ...event, userInvite: { status: item.status } }} />
      )
    } else if (startOfMonth.valueOf() < event.startTime && event.startTime < endOfMonth.valueOf()) {
      happeningThisMonth.push(
        <EventCard event={{ ...event, userInvite: { status: item.status } }} />
      )
    } else {
      const startOfEvent = event.startTime
      const momentStartOfEvent = moment(startOfEvent)
      const month = moment(startOfEvent).startOf('month')
      const monthValue = month.valueOf()
      let monthName = month.format('MMMM')
      if (momentStartOfEvent.isAfter(moment().endOf('year'))) {
        monthName += ` ${momentStartOfEvent.format('YYYY')}`
      }
      if (!monthMap) {
        monthMap = {}
      }
      if (!monthMap[monthValue]) {
        monthMap[monthValue] = {
          name: monthName,
          events: [],
        }
      }
      monthMap[monthValue].events.push(<EventCard event={{ ...event, userInvite: { status: item.status } }} />)
    }
  })

  return (
    <>
      {happeningToday.length > 0 && (
        <Paper square elevation={0} style={{ marginBottom: 15 }}>
          <Typography gutterBottom variant="h1" color="textSecondary">Happening Today</Typography>
          <CardGrid items={happeningToday} singleColumn={singleColumn} />
        </Paper>
      )}
      {(recommendations && recommendations.length > 0) && (
        <div style={{ marginLeft: -15, marginRight: -15 }}>
          <MobileTearSheet direction="up" />
          <MobileTearSheet>
            <div style={{ padding: '15px' }}>
              <Typography gutterBottom variant="h1" color="textSecondary">You Should Subscribe</Typography>
              <div className={classes.root}>
                <GridList className={classes.gridList} cols={2.5}>
                  {recommendations.map((reco) => <DoorRecommendationCard door={reco.business} key={reco.business.id} />)}
                </GridList>
              </div>
            </div>
          </MobileTearSheet>
        </div>
      )}
      {(happeningThisWeek.length > 0 || happeningThisMonth.length > 0) && (
        <>
          {happeningThisWeek.length > 0 && (
            <HappeningContainer>
              <Typography gutterBottom variant="h1" color="textSecondary">Happening This Week</Typography>
              <CardGrid items={happeningThisWeek} singleColumn={singleColumn} />
            </HappeningContainer>
          )}
          {happeningThisMonth.length > 0 && (
            <HappeningContainer>
              <Typography gutterBottom variant="h1" color="textSecondary">Happening This Month</Typography>
              <CardGrid items={happeningThisMonth} singleColumn={singleColumn} />
            </HappeningContainer>
          )}
        </>
      )}
      {monthMap && (
        <>
          {Object.values(monthMap).map(month => {
            return (
              <HappeningContainer key={month.name}>
                <Typography gutterBottom variant="h1" color="textSecondary">
                  {month.name}
                </Typography>
                <CardGrid items={month.events} singleColumn={singleColumn} />
              </HappeningContainer>
            )
          })}
        </>
      )}
    </>
  )
}

export default EventListsContainer
