import history from '../../history'
import UIActionConstants from '../../constants/UIActionConstants'

export const closeSideMenu = () => ({ type: UIActionConstants.sideMenu.CLOSE_MENU })
export const toggleSideMenu = () => ({ type: UIActionConstants.sideMenu.TOGGLE_MENU })
export const openSideMenu = () => ({ type: UIActionConstants.sideMenu.OPEN_MENU })
export const setCurrentView = payload => ({
  type: UIActionConstants.header.SET_CURRENT_VIEW,
  payload,
})
export const handleSearch = searchText => ({ type: UIActionConstants.header.SEARCH, searchText })
export const toggleSearch = (payload) => ({ type: UIActionConstants.header.TOGGLE_SEARCH, payload })
export const submitSearch = () => ({ type: UIActionConstants.header.SUBMIT_SEARCH })
export const toggleViewMode = () => ({ type: UIActionConstants.sideMenu.TOGGLE_VIEW_MODE })

export const setNotifications = (notifications) => ({ type: UIActionConstants.notifications.SET_NOTIFICATIONS, notifications })
export const dismissNotification = (notification) => ({ type: UIActionConstants.notifications.DISMISS_NOTIFICATION, notification })
export const clearAllNotifications = () => ({ type: UIActionConstants.notifications.CLEAR_ALL_NOTIFICATIONS })

export const openFullScreenDialog = (payload, url) => dispatch => {
  dispatch({
    type: 'OPEN_FULL_SCREEN_DIALOG',
    payload,
  })
  if (url) {
    history.push(url)
  } else {
    history.push({ pathname: `${history.location.pathname}`, hash: `#${payload.dialog}` })
  }
}

export const closeFullScreenDialog = (payload, url) => dispatch => {
  dispatch({
    type: 'CLOSE_FULL_SCREEN_DIALOG',
    payload,
  })
  if (url) {
    history.push(url)
  }
  setTimeout(() => {
    dispatch({
      type: 'DISMOUNT_FULL_SCREEN_DIALOG',
      payload,
    })
  }, [300])
}

export const setUserLocation = payload => dispatch => {
  dispatch({
    type: 'SET_USER_LOCATION',
    payload,
  })
}

export const userLocationError = () => dispatch => {
  dispatch({
    type: 'USER_LOCATION_ERROR',
  })
}
