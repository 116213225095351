import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import { MobileGrid } from '../../components/layout/layout'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { DMSubHeader } from '../../components/headers/HeaderComponents'
import { Globals } from '../../constants/Global'
import Slide from '@material-ui/core/Slide'
import { PageLoader } from '../../components/loading/Loading'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const styles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: 0,
    backgroundColor: Globals.colors.bgColor,
  }
}))

const FullscreenDialog = ({
  AppBarProps,
  children,
  closeIcon,
  disableSubheader,
  isLoading,
  keepMounted,
  loadingOverride,
  onClose,
  open,
  staticSubheader,
  subHeader,
  submitIcon,
  title,
}) => {
  const classes = styles()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog keepMounted={keepMounted} fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar} {...AppBarProps}>
        <MobileGrid enablePadding>
          <Toolbar disableGutters>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              {closeIcon || <CloseIcon />}
            </IconButton>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
            {submitIcon}
          </Toolbar>
        </MobileGrid>
        {!disableSubheader && <DMSubHeader sticky={staticSubheader} subHeader={subHeader} />}
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        {isLoading && (
          <MobileGrid paper fullHeight style={{ overflow: 'hidden' }}>
            <PageLoader />
          </MobileGrid>
        )}
        {!isLoading && (
          <>
            {children}
          </>
        )}
        {loadingOverride}
      </DialogContent>
    </Dialog>
  )
}

FullscreenDialog.propTypes = {
  AppBarProps: PropTypes.object,
  closeIcon: PropTypes.object,
  disableSubheader: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingOverride: PropTypes.any,
  keepMounted: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  subHeader: PropTypes.any,
  submitIcon: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

FullscreenDialog.defaultProps = {
  AppBarProps: {},
  closeIcon: null,
  disableSubheader: false,
  isLoading: false,
  loadingOverride: null,
  keepMounted: false,
  onClose: () => null,
  subHeader: null,
  submitIcon: null,
  title: 'DoorMouse'
}

export default FullscreenDialog
