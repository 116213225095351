import { schema } from 'normalizr'
import { door, doorGuestList} from './DoorSchemas'
import { category } from './CategorySchemas'
import { externalEvent, externalInvite, externalUser } from './ExternalSchemas'
import { invite } from './InviteSchemas'
import { user } from './UserSchemas'
import { venue } from './VenueSchemas'

const event = new schema.Entity('events')
const eventCategory = new schema.Entity('eventCategories')
const series = new schema.Entity('serieses')
const seriesDoorGuestList = new schema.Entity('seriesDoorGuestLists')
const seriesConflict = new schema.Entity('seriesConflicts') // this one is weird, it looks like it's a plural but it's actually a singular

series.define({
  owner: door,
  venue: venue,
  creator: user,
})

seriesDoorGuestList.define({
  doorGuestList: doorGuestList,
  series: series,
})

seriesConflict.define({
  conflictingEvents: [event],
})

event.define({
  venue: venue,
  hosts: [user],
  series: series,
  presenters: [door],
  invites: [invite],
  externalHosts: [externalUser],
})

eventCategory.define({
  categories: category,
  event: event,
})

externalEvent.define({
  event
})

externalInvite.define({
  event
})

event.define({
  externalInvites: [externalInvite],
  externalEvents: [externalEvent],
})

export { event, eventCategory }
