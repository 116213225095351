import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CalendarIcon from '@material-ui/icons/Event'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import DMCategoryWidget from '../../components/categories/CategoriesComponents'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import { formatDate } from '../../utils/formatUtils'
import { DateRangeSelector } from '../../components/calendars/Calendar'
import Search from '@material-ui/icons/Search'
import { Globals } from '../../constants/Global'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import ActionConstants from '../../constants/ActionConstants'
import LocationSearch from '../../components/inputs/LocationSearch'
import Chip from '@material-ui/core/Chip'
import RoomIcon from '@material-ui/icons/Room'
import Slider from '@material-ui/core/Slider'
import FormGroup from '@material-ui/core/FormGroup'

const FormContainer = styled.div`
  margin: 10px 0 10px 20px;
`

const IndentMenuItem = styled(MenuItem)`
  padding-left: 45px;
`

const SearchContainer = styled.div`
  margin-bottom: 16px;
`

const StyledTypography = styled(Typography)`
  font-size: 18px;
  color: ${Globals.colors.standard};
`

const SliderRoot = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
`

const SliderContainer = styled.div`
  flex-grow: 1;
  margin-right: 8px;
`

const SliderLabel = styled(Typography)`
  font-size: 12px;
`

const styles = makeStyles((theme) => ({
  input: {
    cursor: 'pointer !important',
    color: `${Globals.colors.standard} !important`,

    '&.MuiInputBase-input.Mui-disabled': {
      cursor: 'pointer !important',
    },

    '&.MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    }
  },
  chip: {
    backgroundColor: Globals.colors.gray['A100'],
    color: Globals.colors.textColorTear,
    marginRight: theme.spacing(0.5),
    marginTop: 8,
    border: `1px solid ${Globals.colors.textColorTear}`,
    maxWidth: '100%',

    '&.MuiChip-root.MuiChip-deletable:focus': {
      backgroundColor: Globals.colors.gray['A100'],
      color: Globals.colors.textColorTear,
      marginRight: theme.spacing(0.5),
      border: `1px solid ${Globals.colors.textColorTear}`,
    }
  },
  icon: {
    color: Globals.colors.textColorTear,

    '&:hover': {
      color: alpha(Globals.colors.textColorTear, 0.8),
    }
  },
}))

const SearchFilters = ({
  dateRange,
  dateText,
  onApply,
  onChangeDate,
  onChangeDateRange,
  onChangeRange,
  onChangeVenue,
  onChangeSearch,
  range,
  showSearch,
  showCategories,
  venue,
}) => {
  const [stateValue, setValue] = useState('')
  const [stateDateText, setDate] = useState(dateText || 'Any Date')
  const [stateVenue, setVenue] = useState(venue || null)
  const [stateDateRange, setDateRange] = useState(dateRange || {})
  const [stateRange, setRange] = useState(range || 25)
  const [dateRangeOpen, setDateRangeOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const classes = styles()

  useEffect(() => {
    setDate(dateText || 'Any Date')
    setDateRange(dateRange || {})
    if (venue) {
      setVenue(venue)
    }
    if (range) {
      setRange(range)
    }
  }, [dateText, dateRange, venue, range])

  const handleOpenDateMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const updateLocation = location => {
    if (onChangeVenue) {
      onChangeVenue(location)
    } else {
      setVenue(location)
    }
  }

  const handleOpenDateRange = () => {
    setAnchorEl(null)
    if (onChangeDateRange) {
      onChangeDateRange({})
    } else {
      setDateRange({})
    }
    setDateRangeOpen(true)
  }

  const handleCloseDateRange = () => {
    setDateRangeOpen(false)
  }

  const handleOnChange = (e) => {
    if (onChangeSearch) {
      onChangeSearch(e.target.value)
    }
    setValue(e.target.value)
  }


  const handleRangeChange = (event, newValue) => {
    if (onChangeRange) {
      onChangeRange(newValue)
    } else {
      setRange(newValue)
    }
  }

  const handleDateSelect = (newValue) => {
    setAnchorEl(null)
    let newDateRange
    switch(newValue) {
    case 'Any Date':
      newDateRange = {}
      break
    case 'Today':
      newDateRange = {
        startTime: moment().startOf('day').valueOf(),
        endTime: moment().endOf('day').valueOf(),
      }
      break
    case 'Tomorrow':
      newDateRange = {
        startTime: moment().add(1, 'day').startOf('day').valueOf(),
        endTime: moment().add(1, 'day').endOf('day').valueOf(),
      }
      break
    case 'This Week':
      newDateRange = {
        startTime: moment().startOf('week').valueOf(),
        endTime: moment().endOf('week').valueOf(),
      }
      break
    case 'This Month':
      newDateRange = {
        startTime: moment().startOf('month').valueOf(),
        endTime: moment().endOf('month').valueOf(),
      }
      break
    default:
      newDateRange = {}
      break
    }
    if (onChangeDateRange) {
      onChangeDateRange(newDateRange)
    } else {
      setDateRange(newDateRange)
    }
    if (onChangeDate) {
      onChangeDate(newValue)
    } else {
      setDate(() => newValue)
    }
  }

  const handleDateRangeSelect = (range) => {
    if (!range.startTime || !range.endTime) {
      if (onChangeDate) {
        onChangeDate('Any Date')
      } else {
        setDate(() => 'Any Date')
      }
      if (onChangeDateRange) {
        onChangeDateRange({})
      } else {
        setDateRange({})
      }
      setDateRangeOpen(false)
    } else {
      if (onChangeDate) {
        onChangeDate(`${formatDate(range.startTime, null, 'MM/D/YYYY')} - ${formatDate(range.endTime, null, 'MM/D/YYYY')}`)
      } else {
        setDate(() => `${formatDate(range.startTime, null, 'MM/D/YYYY')} - ${formatDate(range.endTime, null, 'MM/D/YYYY')}`)
      }
      if (onChangeDateRange) {
        onChangeDateRange({ startTime: moment(range.startTime).valueOf(), endTime: moment(range.endTime).valueOf() })
      } else {
        setDateRange({ startTime: moment(range.startTime).valueOf(), endTime: moment(range.endTime).valueOf() })
      }
      setDateRangeOpen(false)
    }
  }

  const handleClearFilters = () => {
    if (onChangeDate) {
      onChangeDate('Any Date')
    } else {
      setDate('Any Date')
    }
    if (onChangeSearch) {
      onChangeSearch('')
    }
    setValue('')
    if (onChangeVenue) {
      onChangeVenue(null)
    } else {
      setVenue(null)
    }
    if (onChangeDateRange) {
      onChangeDateRange({})
    } else {
      setDateRange({})
    }
    if (onChangeRange) {
      onChangeRange(1)
    } else {
      setRange(1)
    }
    dispatch({
      type: ActionConstants.categories.clearStoredItems,
    })
    onApply({
      newDateRange: {},
      newVenue: null,
      newDate: 'Any Date',
      newCategories: [],
      newRange: 1,
      newSearch: '',
    })
  }

  return (
    <>
      {showSearch && (
        <SearchContainer>
          <TextField
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon style={{ color: Globals.colors.primary['500'] }}>
                    <Search />
                  </Icon>
                </InputAdornment>
              ),
            }}
            value={stateValue}
            onChange={handleOnChange}
            placeholder="Search..."
          />
        </SearchContainer>
      )}
      <Typography gutterBottom variant="subtitle1" color="textSecondary">
        I'm looking for
      </Typography>
      <FormContainer>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item>
            <CalendarIcon />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <FormControl fullWidth>
              <Input
                disabled
                className={classes.input}
                classes={{
                  disabled: classes.input,
                }}
                value={stateDateText}
                onClick={handleOpenDateMenu}
                endAdornment={
                  <KeyboardArrowDown style={{ color: Globals.colors.primary['500'] }} />
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </FormContainer>
      <Typography gutterBottom variant="subtitle1" color="textSecondary">
        In
      </Typography>
      <FormContainer>
        <FormGroup>
          <LocationSearch
            fullWidth
            skipVenues
            updateLocation={updateLocation}
            name="venue"
            placeholder="Set your location"
          />
          {stateVenue && (
            <div className={classes.root}>
              <Chip
                classes={{
                  root: classes.chip,
                  icon: classes.icon,
                  deleteIcon: classes.icon,
                }}
                label={stateVenue.name}
                key={stateVenue.id}
                icon={<RoomIcon />}
                onDelete={() => setVenue(null)}
              />
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <StyledTypography gutterBottom variant="h1">
            Range
          </StyledTypography>
          <SliderRoot>
            <SliderContainer>
              <Slider disabled={!stateVenue} min={1} value={stateRange} onChange={handleRangeChange} />
            </SliderContainer>
            <SliderLabel>
              {`${stateRange} miles`}
            </SliderLabel>
          </SliderRoot>
        </FormGroup>
      </FormContainer>
      {showCategories && (
        <>
          <Typography gutterBottom variant="subtitle1" color="textSecondary">
            For
          </Typography>
          <FormContainer>
            <DMCategoryWidget
              placeholder="Categories..."
              allowDelete
              allowBrowse={false}
              storeItems
              type="group"
            />
          </FormContainer>
        </>
      )}
      <Button onClick={() => onApply({ newVenue: stateVenue, newDateRange: stateDateRange, newRange: stateRange })} variant="outlined" color="secondary">
        Apply
      </Button>
      <Button onClick={handleClearFilters} variant="outlined" color="secondary" style={{ marginLeft: 8 }}>
        Clear
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <IndentMenuItem onClick={() => handleDateSelect('Any Date')}>Any Date</IndentMenuItem>
        <IndentMenuItem onClick={() => handleDateSelect('Today')}>Today</IndentMenuItem>
        <IndentMenuItem onClick={() => handleDateSelect('Tomorrow')}>Tomorrow</IndentMenuItem>
        <IndentMenuItem onClick={() => handleDateSelect('This Week')}>This Week</IndentMenuItem>
        <IndentMenuItem divider onClick={() => handleDateSelect('This Month')}>This Month</IndentMenuItem>
        <ListItem button onClick={handleOpenDateRange} style={{ paddingTop: 5, paddingBottom: 5 }}>
          <ListItemIcon style={{ marginRight: -25 }}>
            <CalendarIcon />
          </ListItemIcon>
          <ListItemText primary="Choose Dates" />
        </ListItem>
      </Menu>
      <DateRangeSelector
        dateRange={stateDateRange}
        open={dateRangeOpen}
        onClose={handleCloseDateRange}
        onSelect={handleDateRangeSelect}
      />
    </>
  )
}

SearchFilters.propTypes = {
  dateRange: PropTypes.object,
  dateText: PropTypes.string,
  onApply: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func,
  onChangeDateRange: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onChangeVenue: PropTypes.func,
  onLoading: PropTypes.func,
  showCategories: PropTypes.bool,
  showSearch: PropTypes.bool,
  value: PropTypes.string,
  venue: PropTypes.object,
}

SearchFilters.defaultProps = {
  dateRange: null,
  dateText: null,
  onChangeDate: null,
  onChangeDateRange: null,
  onChangeSearch: null,
  onChangeVenue: null,
  onLoading: null,
  showCategories: true,
  showSearch: false,
  value: null,
  venue: null,
}

export default SearchFilters
