import { createSelector } from 'reselect'

export const selectContacts = createSelector(
  state => state.contacts,
  propState => propState
)

export const selectPendingContactRequests = createSelector(
  state => state.contactRequestsPending,
  propState => propState
)

export const selectSentContactRequests = createSelector(
  state => state.contactRequestsSent,
  propState => propState
)

export const selectContactRequests = createSelector(
  state => state.contactRequests,
  propState => propState
)

export const selectUserGuestLists = createSelector(
  state => state.userGuestLists,
  propState => propState.items ? Object.values(propState.items).map(item => item) : []
)

export const selectDoorGuestLists = createSelector(
  state => state.doorGuestLists,
  propState => propState.items ? Object.values(propState.items).map(item => item) : []
)

export const selectUserGuestListHash = createSelector(
  state => state.userGuestLists,
  propState => propState.items || {}
)

export const selectUserGuestListGuests = createSelector(
  state => state.userGuestListGuests,
  propState => propState
)

export const selectBulkInviteStatus = createSelector(
  state => state.contactRequestsPending,
  propState => propState.isBulkInviting
)

export const selectBlockedUsers = createSelector(
  state => state.contacts,
  propState => propState.blocks ? Object.values(propState.blocks).map(item => item) : []
)