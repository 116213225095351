import request from '../request'
import ActionConstants from '../constants/ActionConstants'
import { normalize } from 'normalizr'
import { schemas } from '../schemas/schemas'
import { formatUrl } from '../utils/RESTUtils'

export const selectItem = (dataType = 'dummy', item) => async dispatch => {
  dispatch({
    type: ActionConstants[dataType].selectItem,
    item,
  })
  return item
}

export const invalidateItems = payload => {
  let { dataType = 'dummy', passThrough } = payload
  if (!dataType) {
    console.log('please provide a dataType')
    return
  }
  return {
    type: passThrough ? ActionConstants[dataType].invalidateTempItems : ActionConstants[dataType].invalidateItems,
  }
}

export const fetchItems = payload => (dispatch, getState) => {
  let { dataType = 'dummy', url, path, params, data, query } = payload
  dispatch({
    type: ActionConstants[dataType].requestItems,
  })
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  return request.get(formatUrl(url, path), options).then(
    response => {
      if (getState().sandbox.isSandbox) {
        return
      }
      let items = response.data.items || []
      dispatch({
        type: ActionConstants[dataType].receiveItems,
        response: normalize(items, [schemas[dataType]]),
        path,
        receivedAt: Date.now(),
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchItemsNonNormal = payload => (dispatch, getState) => {
  let { dataType = 'dummy', url, path, params, data, query, passThrough, item, live } = payload
  dispatch({
    type: ActionConstants[dataType].requestItems,
  })
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  return request.get(formatUrl(url, path), options).then(
    response => {
      if (getState().sandbox.isSandbox && !live) {
        return
      }
      const items = response.data.items !== null ? response.data.items : []
      dispatch({
        type: passThrough ? ActionConstants[dataType].receiveTempItems : ActionConstants[dataType].receiveItems,
        items,
        item: item || null,
        path,
        hasNextPage: response.data.hasNextPage,
        receivedAt: Date.now(),
      })
      return items
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchItem = payload => dispatch => {
  let { dataType = 'dummy', item, url, path, params, data, query } = payload
  dispatch({
    type: ActionConstants[dataType].requestItem,
    item: item,
  })
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  return request.get(formatUrl(url, path), options).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].receiveItem,
        response: normalize(response.data, schemas[dataType]),
        path,
        receivedAt: Date.now(),
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchItemNonNormal = payload => dispatch => {
  let { dataType = 'dummy', item, url, path, params, data, query } = payload
  dispatch({
    type: ActionConstants[dataType].requestItem,
    item: item,
  })
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  return request.get(formatUrl(url, path), options).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].receiveItem,
        response: response.data,
        path,
        receivedAt: Date.now(),
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const addItem = payload => dispatch => {
  let { dataType = 'dummy', url, item, path } = payload
  dispatch({
    type: ActionConstants[dataType].sendAddItem,
    item: item,
  })
  return request.post(formatUrl(url, path), item).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].confirmAddItem,
        response: normalize(response.data, schemas[dataType]),
        path,
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const addItemNonNormal = payload => dispatch => {
  let { dataType = 'dummy', url, item, path } = payload
  dispatch({
    type: ActionConstants[dataType].sendAddItem,
    item: item,
  })
  return request.post(formatUrl(url, path), item).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].confirmAddItem,
        response: response.data || item,
        path,
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const addItemsNonNormal = payload => (dispatch) => {
  let { dataType = 'dummy', url, path, params, data, query, passThrough } = payload
  if (!dataType) {
    console.log('please provide a dataType')
    return
  } else if (!url) {
    console.log('please provide a url')
    return
  }
  dispatch({
    type: ActionConstants[dataType].sendAddItems,
  })
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  return request.get(formatUrl(url, path), options).then(
    response => {
      dispatch({
        type: passThrough ? ActionConstants[dataType].confirmAddTempItems : ActionConstants[dataType].confirmAddItems,
        items: response.data.items,
        path,
        hasNextPage: response.data.hasNextPage,
        receivedAt: Date.now(),
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const addItems = payload => (dispatch) => {
  let { dataType = 'dummy', url, path, params, data, query, passThrough } = payload
  if (!dataType) {
    console.log('please provide a dataType')
    return
  } else if (!url) {
    console.log('please provide a url')
    return
  }
  dispatch({
    type: ActionConstants[dataType].sendAddItems,
  })
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  return request.get(formatUrl(url, path), options).then(
    response => {
      dispatch({
        type: passThrough ? ActionConstants[dataType].confirmAddTempItems : ActionConstants[dataType].confirmAddItems,
        response: response.data.items ? normalize(response.data.items, [schemas[dataType]]) : null,
        receivedAt: Date.now(),
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const editItem = payload => dispatch => {
  let { dataType = 'dummy', url, item, path } = payload
  dispatch({
    type: ActionConstants[dataType].sendEditItem,
    item: item,
  })
  return request.put(formatUrl(url, path), item).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].confirmEditItem,
        response: normalize(item, schemas[dataType]),
        path,
      })
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const editItemNonNormal = payload => dispatch => {
  let { dataType = 'dummy', url, item, path } = payload
  dispatch({
    type: ActionConstants[dataType].sendEditItem,
    item: item,
  })
  return request.put(formatUrl(url, path), item).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].confirmEditItem,
        response: item,
        path,
      })
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const deleteItem = (dataType = 'dummy', item, url, path, params, data, query) => dispatch => {
  dispatch({
    type: ActionConstants[dataType].sendDeleteItem,
    item: item,
  })
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  return request.delete(formatUrl(url, path), options).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].confirmDeleteItem,
        response: normalize(response.data, schemas[dataType]),
        path,
      })
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const deleteItemNonNormal = payload => dispatch => {
  let { dataType = 'dummy', url, item, path } = payload
  dispatch({
    type: ActionConstants[dataType].sendDeleteItem,
    item: item,
  })
  return request.delete(formatUrl(url, path), item).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].confirmDeleteItem,
        response: item,
        path,
      })
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const clearItem = payload => dispatch => {
  let { dataType = 'dummy' } = payload
  dispatch({
    type: ActionConstants[dataType].clearItem,
  })
}

export const clearTempItem = payload => dispatch => {
  let { dataType = 'dummy' } = payload
  dispatch({
    type: ActionConstants[dataType].clearTempItem,
  })
}

export const clearItems = dataType => dispatch => {
  let dType = dataType || 'dummy'
  dispatch({
    type: ActionConstants[dType].clearItems,
  })
}
