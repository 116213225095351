import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TagIcon from '@material-ui/icons/LocalOffer'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/AddCircle'
import { useDispatch, useSelector } from 'react-redux'
import request from '../../request'
import {
  selectSuperSubCategories,
  selectCategories,
  selectSuperCategories,
  selectUserCategories,
} from '../../selectors/CategoriesSelectors'
import CheckedIcon from '@material-ui/icons/CheckBox'
import CheckedIconOutline from '@material-ui/icons/CheckBoxOutlineBlank'
import Cancel from '@material-ui/icons/Cancel'
import { Globals } from '../../constants/Global'
import { DMWords } from '../d3/Bubbles'
import { makeStyles } from '@material-ui/core/styles'
import { formatUrl } from '../../utils/RESTUtils'
import { DMAutosuggest } from '../inputs/InputComponents'
import Fade from '@material-ui/core/Fade'
import { addItemNonNormal, deleteItemNonNormal, fetchItemsNonNormal } from '../../actions/RESTActions'
import { fetchCategoryLinks, removeCatGameCat, storeItem, removeStoredItem } from '../../actions/CategoriesActions'
import history from '../../history'
import { selectEventCategories } from '../../selectors/EventsSelectors'

const CategoriesPaneContainer = styled.div`
  padding: 15px;
`

const styles = makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  divider: {
    margin: 15,
  },
  chipSelected: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: Globals.colors.gray['500'],
    border: `2px solid ${Globals.colors.purple['500']}`,
  },
  chipSuper: {
    height: 50,
    padding: '15px',
    fontSize: 20,
    borderRadius: 25,
  },
  chipRoot: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: Globals.colors.gray['500'],
  },
  chipRed: {
    backgroundColor: Globals.colors.red1,
    color: Globals.colors.gray['A100'],
  },
  chipOrange: {
    backgroundColor: Globals.colors.orange1,
    color: Globals.colors.gray['A100'],
  },
  chipGreen: {
    backgroundColor: Globals.colors.primary['500'],
    color: Globals.colors.gray['A100'],
  },
  chipDeleteIcon: {
    color: Globals.colors.purple['500'],
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
      color: Globals.colors.purple['500'],
    },
  },
  chipNumber: {
    color: Globals.colors.gray['A100'],
    backgroundColor: Globals.colors.purple['500'],
  },
  relatedLink: {
    cursor: 'pointer',
    padding: '8px 0 0 0',

    '& .MuiSvgIcon-root': {
      fill: Globals.colors.secondary['500'],
    },

    '&:hover': {
      '& .MuiSvgIcon-root': {
        fill: Globals.colors.secondary['500'],
      },
    },
  },
  relatedText: {
    color: Globals.colors.secondary['500'],

    '&:hover': {
      color: Globals.colors.secondary['300'],
    },
  },
}))

const CategoryChip = props => {
  const { category, deleteIcon, handleCatClick, handleOnDelete } = props
  const [relatedCategories, setRelatedCategories] = useState([])
  useEffect(() => {
    let options = {
      params: { limit: 70000 },
      data: {},
      query: {},
    }
    request.get(formatUrl(Globals.api.categoryLinks, { categoryId: category.id }), options).then(response => {
      setRelatedCategories(response.data.items)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function onCatClick() {
    handleCatClick(category)
  }
  function onDeleteClick() {
    handleOnDelete(category)
  }
  const chipHeight = 35 + relatedCategories.length * 3
  return (
    <Fade in={true}>
      <Chip
        deleteIcon={deleteIcon}
        avatar={relatedCategories.length > 0 ? <Avatar style={{ color: Globals.colors.gray['A100'] }}>{relatedCategories.length}</Avatar> : null}
        label={category.name}
        onClick={onCatClick}
        onDelete={onDeleteClick}
        style={{ height: chipHeight, borderRadius: chipHeight / 2, fontSize: 14 + 0.2 * relatedCategories.length, marginRight: 5 }}
      />
    </Fade>
  )
}

CategoryChip.defaultProps = {
  deleteIcon: null,
  handleCatClick: () => {},
  handleOnDelete: () => {},
  icon: null,
}

const CategoriesPane = ({ number, type }) => {
  const [open, setOpen] = useState(false)
  const userCategories = useSelector(selectUserCategories)
  const categories = useSelector(selectCategories)
  const categoryItems = categories.items
  const categoryLinks = categories.categoryLinks
  const userCategoryItems = userCategories.items
  const dispatch = useDispatch()
  const classes = styles()
  const relatedLimit = open ? categoryLinks.length : 4

  useEffect(() => {
    let payload = {
      dataType: 'userCategories',
      url: Globals.api.userCategories,
    }
    dispatch(fetchItemsNonNormal(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteCategory = c => {
    if (type === 'catGame') {
      dispatch(removeCatGameCat(number))
    } else {
      let payload = {
        dataType: 'userCategories',
        url: Globals.api.userCategory,
        item: c,
        path: { categoryId: c.id },
      }
      dispatch(deleteItemNonNormal(payload))
    }
  }

  const handleAddCategory = c => {
    const payload = {
      dataType: 'userCategories',
      url: Globals.api.userCategory,
      item: {
        user: null,
        visible: true,
        category: c,
      },
      path: { categoryId: c.id },
    }
    dispatch(addItemNonNormal(payload))
  }

  const handleCatClick = c => {
    const payload = {
      dataType: 'categories',
      url: Globals.api.categoryLinks,
      path: { categoryId: c.id },
      params: { limit: 70000 },
    }
    dispatch(fetchCategoryLinks(payload))
  }

  function handleClick() {
    setOpen(!open)
  }

  const relatedCategoryChips = categoryLinks.slice(0, relatedLimit).map(c => {
    return (
      <CategoryChip
        category={c}
        deleteIcon={<AddIcon />}
        handleCatClick={handleCatClick}
        handleOnDelete={handleAddCategory}
        key={c.id}
      />
    )
  })

  const categoryChips = categoryItems.slice(0, 4).map(c => {
    return (
      <CategoryChip
        category={c}
        deleteIcon={<AddIcon />}
        handleCatClick={handleCatClick}
        handleOnDelete={handleAddCategory}
        key={c.id}
      />
    )
  })

  const userCategoryChips = userCategoryItems.map(c => {
    const category = c.category ? c.category : c
    return (
      <CategoryChip
        category={category}
        handleCatClick={handleCatClick}
        handleOnDelete={handleDeleteCategory}
        key={category.id}
      />
    )
  })

  return (
    <CategoriesPaneContainer>
      <Typography variant="subtitle1" color="textPrimary">
        Categories
      </Typography>
      <div>
        {categoryChips.length === 0 && (
          <Typography gutterBottom={false} variant="body1">
            Search for some categories!
          </Typography>
        )}
        {categoryChips.length > 0 && <>{categoryChips}</>}
      </div>
      {categoryLinks.length > 0 && (
        <div style={{ marginTop: 8 }}>
          {categoryLinks.length > 0 && (
            <Typography variant="subtitle1" color="textPrimary">
              Related Categories
            </Typography>
          )}
          {categoryLinks.length > 0 && <>{relatedCategoryChips}</>}
          {categoryLinks.length > 4 && (
            <div style={{ marginTop: 16 }}>
              <Divider />
              <ListItem className={classes.relatedLink} onClick={handleClick}>
                <ListItemText
                  classes={{ primary: classes.relatedText }}
                  primary={`See all ${categoryLinks.length} related categories`}
                  style={{ color: Globals.colors.secondary['500'] }}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </div>
          )}
        </div>
      )}
      {userCategoryChips.length > 0 && (
        <div>
          <Typography style={{ marginTop: 8 }} gutterBottom variant="subtitle1" color="textPrimary">
            Your Categories
          </Typography>
          {userCategoryChips}
        </div>
      )}
    </CategoriesPaneContainer>
  )
}

const DMCategoryWidget = ({
  allowBrowse,
  allowDelete,
  autoFocus,
  browse,
  compareItems,
  filterItems = [],
  checked,
  itemId,
  items,
  itemParam,
  iconPosition,
  multiSelect,
  number,
  onAdd,
  onChecked,
  onChipClick,
  onDelete,
  placeholder,
  readOnly,
  searchLocal,
  disableSearch,
  storeItems,
  type,
}) => {
  const categories = useSelector(selectCategories)
  const superCategories = useSelector(selectSuperCategories)
  const superSubCategories = useSelector(selectSuperSubCategories)
  const eventCategories = useSelector(selectEventCategories)
  const userCategories = useSelector(selectUserCategories)
  let cats = []
  if (type === 'user') {
    cats = userCategories.items
  } else if (type === 'otherUser') {
    cats = userCategories.otherUserItems
  } else if (type === 'group' || type === 'series' || type === 'event') {
    if (storeItems) {
      cats = categories.storedItems
    } else {
      cats = superSubCategories.items
    }
  } else if (type === 'event') {
    if (storeItems) {
      cats = categories.storedItems
    } else {
      cats = eventCategories
    }
  }
  if (items) {
    cats = items
  }
  let dataType = 'userCategories'
  let url = Globals.api.userCategories
  switch (type) {
    case 'user':
      dataType = 'userCategories'
      url = Globals.api.userCategories
      break
    case 'group':
      dataType = 'superSubCategories'
      url = Globals.api.doorCategories
      break
    case 'series':
      dataType = 'superSubCategories'
      url = Globals.api.seriesCategories
      break
    case 'event':
      dataType = 'eventCategories'
      url = Globals.api.eventCategories
      break
    default:
      dataType = 'userCategories'
      url = Globals.api.userCategories
      break
  }
  const dispatch = useDispatch()
  useEffect(() => {
    if (itemId) {
      const payload = {
        dataType,
        url,
        path: itemId ? { [itemParam]: itemId } : null,
      }
      dispatch(fetchItemsNonNormal(payload))

      if (browse) {
        const superPayload = {
          dataType: 'superCategories',
          url: Globals.api.superCategories,
        }
        dispatch(fetchItemsNonNormal(superPayload))
      }
    }
    dispatch(fetchItemsNonNormal({
      dataType: 'userCategories',
      url: Globals.api.userCategories,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId])

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    let suggestions = []
    return new Promise((resolve) => {
      if (inputLength > 0) {
        const payload = {
          dataType: 'categories',
          url: Globals.api.categories,
          params: {
            q: inputValue,
          },
        }
        if (searchLocal) {
          request.get(Globals.api.categories, { params: { q: inputValue } }).then(response => {
            if (response && response.data && response.data.items) {
              resolve(
                response.data.items.filter(suggestion => {
                  const keep = count < 5 && suggestion.name.toLowerCase().indexOf(inputValue) > -1
                  if (keep) {
                    count += 1
                  }
                  const category = suggestion.category ? suggestion.category : suggestion
                  let filter = false
                  filterItems.forEach(cat => {
                    const compareCat = cat.category ? cat.category : cat
                    if (compareCat.id === category.id) {
                      filter = true
                    }
                  })
                  return keep && !filter
                })
              )
            } else {
              resolve(suggestions)
            }
          })
        } else {
          dispatch(fetchItemsNonNormal(payload)).then(data => {
            if (data) {
              resolve(
                data.filter(suggestion => {
                  const keep = count < 5 && suggestion.name.toLowerCase().indexOf(inputValue) > -1
                  if (keep) {
                    count += 1
                  }
                  return keep
                })
              )
            } else {
              resolve(suggestions)
            }
          })
        }
      } else {
        return suggestions
      }
    })
  }

  const handleAddCategory = c => {
    const cat = c.category || c
    if (onAdd) {
      onAdd(cat)
      return ''
    }
    if (storeItems) {
      dispatch(
        storeItem({
          user: null,
          visible: true,
          category: cat,
        })
      )
      return ''
    }
    let url
    switch (type) {
    case 'user':
      url = Globals.api.userCategory
      dataType = 'userCategories'
      break
    case 'group':
      url = Globals.api.doorCategory
      break
    default:
      url = Globals.api.userCategory
      dataType = 'userCategories'
      break
    }
    const payload = {
      dataType: dataType,
      url: url,
      item: {
        user: null,
        visible: true,
        category: cat,
      },
      path: { categoryId: cat.id },
    }
    if (itemId) {
      payload.path[itemParam] = itemId
    }
    dispatch(addItemNonNormal(payload))
    return ''
  }

  const getSuggestionValue = c => {
    handleAddCategory(c)
    return ''
  }

  const handleDeleteCategory = c => {
    const category = c.category ? c.category : c
    if (onDelete) {
      onDelete(c)
    } else {
      if (storeItems) {
        dispatch(removeStoredItem(category))
        return
      }
      let url
      switch (type) {
      case 'user':
        url = Globals.api.userCategory
        dataType = 'userCategories'
        break
      case 'group':
        url = Globals.api.doorCategory
        break
      default:
        url = Globals.api.userCategory
        dataType = 'userCategories'
        break
      }
      if (type === 'catGame') {
        dispatch(removeCatGameCat(number))
      } else {
        const payload = {
          dataType: dataType,
          url: url,
          item: category,
          path: { categoryId: category.id },
        }
        if (itemId) {
          payload.path[itemParam] = itemId
        }
        dispatch(deleteItemNonNormal(payload))
      }
    }
  }
  const handleCatClick = c => {
    if (onChipClick) {
      return onChipClick(c)
    }
    const payload = {
      dataType: 'categories',
      url: Globals.api.categoryLinks,
      path: { categoryId: c.id },
      params: { limit: 70000 },
    }
    dispatch(fetchCategoryLinks(payload))
  }

  const handleSuperCatClick = c => {
    const payload = {
      dataType: 'superSubCategories',
      url: Globals.api.superSubCategories,
      path: { superCategoryId: c.id },
      params: { limit: 20 },
    }
    dispatch(fetchItemsNonNormal(payload))
  }

  const subText = c => {
    return c.suggestion.inboundLinks
  }

  const renderSubItems = items => {
    if (items) {
      return `${items} Child categories`
    }
    return ''
  }

  const handleChecked = (c) => {
    let index = -1
    for (let i=0; i<checked.length; i+=1) {
      const cat = checked[i]
      if (c.category.id === cat.category.id) {
        index = i
      }
    }
    const newChecked = [...checked]

    if (index === -1) {
      newChecked.push(c)
    } else {
      newChecked.splice(index, 1)
    }
    onChecked(newChecked)
  }

  const renderChip = (c) => {
    const category = c.category ? c.category : c
    let index = -1
    let hasCategory = false
    let compareCats = userCategories.items
    if (compareItems) {
      compareCats = compareItems
    }
    for (let i=0; i<checked.length; i+=1) {
      const cat = checked[i]
      if (cat.category.id === category.id) {
        index = i
      }
    }
    compareCats.forEach(cat => {
      const compareCat = cat.category ? cat.category : cat
      if (compareCat && compareCat.id === category.id) {
        hasCategory = true
      }
    })

    const iconClickFunc = hasCategory ? () => handleDeleteCategory(c) : () => handleAddCategory(c)

    if (multiSelect) {
      return (
        <Chip
          deleteIcon={index > -1 ? <CheckedIcon /> : <CheckedIconOutline />}
          label={category.name}
          onClick={() => handleChecked(c)}
          onDelete={() => handleChecked(c)}
          style={{ marginRight: 5, marginTop: 5 }}
        />
      )
    }
    if (readOnly) {
      if (hasCategory) {
        return (
          <Chip
            deleteIcon={<Cancel />}
            label={category.name}
            onDelete={allowDelete ? iconClickFunc : null}
            style={{ marginRight: 5, marginTop: 5 }}
          />
        )
      }
      return (
        <Chip
          deleteIcon={<AddIcon />}
          label={category.name}
          onDelete={allowDelete ? iconClickFunc : null}
          style={{ marginRight: 5, marginTop: 5 }}
          variant="outlined"
        />
      )
    }
    if (hasCategory) {
      return (
        <Chip
          deleteIcon={<Cancel />}
          label={category.name}
          style={{ marginRight: 5, marginTop: 5 }}
          onClick={() => handleCatClick(category)}
          onDelete={iconClickFunc}
        />
      )
    }
    return (
      <Chip
        deleteIcon={<AddIcon />}
        label={category.name}
        style={{ marginRight: 5, marginTop: 5 }}
        onClick={() => handleCatClick(category)}
        onDelete={iconClickFunc}
        variant="outlined"
      />
    )
  }


  cats = cats.filter(c => {
    const category = c.category ? c.category : c
    let filter = false
    filterItems.forEach(cat => {
      const compareCat = cat.category ? cat.category : cat
      if (compareCat.id === category.id) {
        filter = true
      }
    })
    return !filter
  }).map(c => {
    const category = c.category ? c.category : c
    return (
      <Fade key={category.id} in={true}>
        {renderChip(c)}
      </Fade>
    )
  })

  const superCats = superCategories.items.map(superCat => {
    return (
      <Fade key={superCat.id} in={true}>
        <Chip
          label={superCat.name}
          style={{ marginRight: 5, marginTop: 5 }}
          onClick={() => handleSuperCatClick(superCat)}
          onDelete={() => handleDeleteCategory(superCat)}
        />
      </Fade>
    )
  })

  const relatedChips = superSubCategories.items.map(category => {
    return { text: category.name, value: category.inboundLinks }
  })

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Grid item style={{ width: '100%' }}>
        {(!readOnly && !disableSearch) && (
          <>
            <DMAutosuggest
              autoFocus={autoFocus}
              description="name"
              getSuggestions={getSuggestions}
              getSuggestionValue={getSuggestionValue}
              icon={<TagIcon />}
              iconPosition={iconPosition}
              placeholder={placeholder}
              renderSubItems={renderSubItems}
              subText={subText}
            />
            {(!browse && allowBrowse) && (
              <Typography style={{ marginTop: 8 }} variant="body1">
                Or
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={() => history.push(`/categories?type=${type}`)}
                >
                  Browse our categories
                </Button>
              </Typography>
            )}
          </>
        )}
        {cats}
      </Grid>
      {!readOnly && (
        <Grid item style={{ width: '100%' }}>
          {(browse && allowBrowse) && (
            <>
              {superSubCategories.items.length < 1 && superCats}
              {superSubCategories.items.length > 0 && (
                <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" style={{ height: '100%' }}>
                  <Grid item>
                    <Button variant="text" color="primary">
                      &lt; back
                    </Button>
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <DMWords data={relatedChips} />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  )
}

DMCategoryWidget.propTypes = {
  allowBrowse: PropTypes.bool,
  allowDelete: PropTypes.bool,
  browse: PropTypes.bool,
  checked: PropTypes.arrayOf(PropTypes.object),
  itemId: PropTypes.any,
  itemParam: PropTypes.string,
  iconPosition: PropTypes.string,
  multiSelect: PropTypes.bool,
  number: PropTypes.number,
  onChecked: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  storeItems: PropTypes.bool,
  type: PropTypes.string,
}

DMCategoryWidget.defaultProps = {
  allowBrowse: true,
  allowDelete: false,
  browse: false,
  checked: [],
  itemId: null,
  itemParam: null,
  iconPosition: 'left',
  multiSelect: false,
  number: 1,
  onChecked: () => null,
  placeholder: 'Search categories...',
  readOnly: false,
  storeItems: false,
  type: 'user',
}

export { CategoriesPane }

export default DMCategoryWidget
