import { createSelector } from 'reselect'

export const selectApp = createSelector(
  state => state.UI.app,
  propState => propState
)

export const selectAppNotifications = createSelector(
  state => state.UI.app,
  propState => propState.notifications
)

export const selectSearchOpen = createSelector(
  state => state.UI.app,
  propState => propState.displaySearch
)

export const selectFullScreenDialog = createSelector(
  state => state.UI.app,
  propState => propState.fullScreenDialogs
)

export const selectCurrentLocation = createSelector(
  state => state.UI.app,
  propState => propState.currentLocation
)

export const selectInstallPrompt = createSelector(
  state => state.UI.app,
  propState => propState.installPrompt
)
