import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const contactConst = ActionConstants.contacts
const initialState = {
  isFetching: false,
  didInvalidate: false,
  lastUpdated: null,
  selectedItem: null,
  items: [],
  tempItems: [],
  errorMessage: null,
  sort: {
    order: 'A/Z',
    filter: 'all',
    provider: 'DoorMouse',
  },
  selectedContacts: [],
  invitableFriends: [],
  invitableManagers: [],
  invitableSubscribers: [],
  blocks: [],
}

function contacts(state = initialState, action) {
  let index
  let hash = {}
  let id
  switch (action.type) {
    case contactConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case contactConst.requestItem:
    case contactConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case contactConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items || [],
        lastUpdated: action.receivedAt,
      }
  case ActionConstants.blocks.receiveItems:
    hash = {}
    if (action.items) {
      for (let i=0; i<action.items.length; i+=1) {
        hash[action.items[i].id] = { ...action.items[i], blocked: true }
      }
    }
    return {
      ...state,
      blocks: hash,
    }
  case ActionConstants.contacts.confirmBlockUser:
    id = Number(action.response.id)
    return {
      ...state,
      blocks: {
        ...state.blocks,
        [id]: {
          ...action.response,
          blocked: true,
        },
      },
    }
  case ActionConstants.contacts.confirmUnBlockUser:
    hash = { ...state.blocks }
    id = Number(action.response.id)
    if (hash[id]) {
      delete hash[id]
    }
    return {
      ...state,
      blocks: hash,
    }
    case contactConst.receiveTempItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        tempItems: action.items,
        lastUpdated: action.receivedAt,
      }
    case contactConst.clearTempItems:
      return {
        ...state,
        tempItems: [],
        lastUpdated: action.receivedAt,
      }
    case contactConst.receiveItem:
    case contactConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
      }
    case contactConst.sendAddItem:
    case contactConst.sendDeleteItem:
    case contactConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case contactConst.confirmDeleteItem:
    case contactConst.confirmBlockUser:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case contactConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case contactConst.itemFailed:
    case contactConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case contactConst.toggleSelectContact:
      let isSelected = _.findIndex(state.selectedContacts, item => {
        return item.id === action.item.id
      })
      index = _.findIndex(state.items, e => {
        return e.id === action.item.id
      })
      let newList
      if (isSelected > -1) {
        action.item.selected = false
        newList = dataUtils.removeItem(state.selectedContacts, isSelected)
      } else {
        action.item.selected = true
        newList = dataUtils.insertItem(state.selectedContacts, action.item, 0)
      }
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.item, index),
        selectedContacts: newList,
      }
    case contactConst.receiveInvitiableFriends:
    case ActionConstants.eventInvitableFriends.receiveItems:
      return {
        ...state,
        invitableFriends: action.items || [],
      }
    case contactConst.receiveInvitiableManagers:
    case ActionConstants.eventInvitableManagers.receiveItems:
      return {
        ...state,
        invitableManagers: action.items || [],
      }
    case contactConst.receiveInvitiableSubscribers:
    case ActionConstants.eventInvitableSubscribers.receiveItems:
      return {
        ...state,
        invitableSubscribers: action.items || [],
      }
    case ActionConstants.invites.confirmInviteFriend:
      index = _.findIndex(state.invitableFriends, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        invitableFriends: dataUtils.updateObjectInArray(state.invitableFriends, action.response, index),
      }

    case contactConst.selectOrder:
      return {
        ...state,
        sort: {
          ...state.sort,
          order: action.item,
        },
      }
    case contactConst.selectFilter:
      return {
        ...state,
        sort: {
          ...state.sort,
          filter: action.item,
        },
      }
    case contactConst.selectProvider:
      return {
        ...state,
        sort: {
          ...state.sort,
          provider: action.item,
        },
      }
    default:
      return state
  }
}

export default contacts
