export function insertItem(array, item, index) {
  return [...array.slice(0, index), item, ...array.slice(index)]
}

export function appendArray(array, newArray) {
  return [...array, ...newArray]
}

export function removeItem(array, index) {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function updateObjectInArray(array, newItem, index) {
  if (array.length === 0) {
    return [newItem]
  }
  return array.map((item, i) => {
    if (i !== index) {
      return item
    }
    return {
      ...item,
      ...newItem,
    }
  })
}

export function updateArrayFromObjects(array, object) {
  let toMergeArray = []
  Object.keys(object).forEach(function(key) {
    toMergeArray.push(key)
  })
  return [...array, ...toMergeArray]
}

export function filterEventsByDate(events, dateRang) {}

export function convertToObject(sourceArray, key = 'id') {
  const obj = {}
  for (let i=0; i<sourceArray.length; i+=1) {
    const item = sourceArray[i]
    obj[item[key]] = item
  }
  return obj
}

export function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while(n--){
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type:mime })
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
