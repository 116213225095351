import ActionConstants from '../constants/ActionConstants'
import * as dataUtils from '../utils/dataUtils'

const seriesConst = ActionConstants.series

function series(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
    loadedItems: {},
  },
  action
) {
  let newSeries
  let newHosts
  let newEvents
  switch (action.type) {
    case seriesConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case seriesConst.requestItem:
    case seriesConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case seriesConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case seriesConst.receiveItem:
    case seriesConst.confirmEditItem:
      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.response.id]: { ...state.loadedItems[action.response.id], ...action.response },
        }
      }
    case seriesConst.sendAddItem:
    case seriesConst.sendDeleteItem:
    case seriesConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case seriesConst.confirmDeleteItem:
      newSeries = { ...state.loadedItems }
      if (newSeries[action.response.id]) {
        delete newSeries[action.response.id]
      }
      return {
        ...state,
        loadedItems: newSeries,
        isFetching: false,
      }
    case seriesConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case seriesConst.itemFailed:
    case seriesConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
  case ActionConstants.doorSeries.sendItemLoading:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: false, isError: false } },
    }
  case ActionConstants.doorSeries.confirmItemLoading:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: true, isFullyLoaded: action.fullyLoaded, isError: false } },
    }
  case ActionConstants.series.confirmSeriesEventLoaded:
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.response.seriesId]: {
          ...state.loadedItems[action.response.seriesId],
          events: {
            ...state.loadedItems[action.response.seriesId].events,
            [action.response.event.id]: {
              ...action.response.event,
              isLoaded: true,
            },
          }
        }
      }
    }
  case ActionConstants.series.confirmEditSeriesEvent:
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.response.series.id]: {
          ...state.loadedItems[action.response.series.id],
          events: {
            ...state.loadedItems[action.response.series.id].events,
            [action.response.event.id]: {
              ...action.response.event,
            },
          }
        }
      }
    }
  case ActionConstants.series.confirmDeleteEvent:
    if (state.loadedItems
      && state.loadedItems[action.response.series.id]
      && state.loadedItems[action.response.series.id].events
      && state.loadedItems[action.response.series.id].events[action.response.event.id]) {
      newEvents = { ...state.loadedItems[action.response.series.id].events }
      delete newEvents[action.response.event.id]
      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.response.series.id]: {
            ...state.loadedItems[action.response.series.id],
            events: newEvents,
          },
        },
      }
    }
    return state

  case ActionConstants.doorSeriesHosts.confirmDeleteItem:
    if (state.loadedItems[action.path.seriesId]
      && state.loadedItems[action.path.seriesId].hosts
      && state.loadedItems[action.path.seriesId].hosts[action.path.hostId]) {
      newHosts = { ...state.loadedItems[action.path.seriesId].hosts }
      delete newHosts[action.path.hostId]

      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.path.seriesId]: {
            ...state.loadedItems[action.path.seriesId],
            hosts: newHosts,
          },
        },
      }
    }
    return state
  case ActionConstants.doorSeriesHosts.confirmAddItem:
  case ActionConstants.doorSeriesHosts.confirmEditItem:
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.path.seriesId]: {
          ...state.loadedItems[action.path.seriesId],
          hosts: {
            ...state.loadedItems[action.path.seriesId].hosts,
            [action.response.id]: action.response,
          }
        }
      }
    }
    default:
      return state
  }
}

export default series
