import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const flareConst = ActionConstants.flares

function flares(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case flareConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case flareConst.requestItem:
    case flareConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case flareConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case flareConst.receiveItem:
    case flareConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case flareConst.sendAddItem:
    case flareConst.sendDeleteItem:
    case flareConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case flareConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case flareConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case flareConst.itemFailed:
    case flareConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default flares
