import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { Globals } from '../../constants/Global'
import UserProps from '../../schemas/PropTypes/user'
import { switchAlias } from '../../actions/UserActions'

const styles = makeStyles(theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '1.3rem',
  },
  subHeaderSticky: {
    [`${theme.breakpoints.down('sm')}`]: {
      top: 56,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      top: 48,
    },
    [`${theme.breakpoints.up('sm')}`]: {
      top: 64,
    },
  },
}))

const SubHeader = styled.div`
  background-color: ${Globals.colors.primary['500']};
  min-height: ${props => props.tabs ? '64px' : '10px'};
  width: 100%;
  box-shadow: ${props => props.direction === 'up' ? '0 -4px 4px #0000003D' : '0 4px 4px #0000003D'};
  z-index: 1;
  position: ${props => props.sticky ? 'absolute' : 'initial'};
`

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`

const StyledAvatar = styled(Avatar)`
  margin-right: 8px;
`

const UserNameContainer = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: 'RobotoSlab', serif;
  font-size: 18px;
`

const DMSubHeader = props => {
  const { sticky, subHeader, tabs, direction } = props
  const classes = styles()
  return <SubHeader className={sticky && classes.subHeaderSticky} sticky={sticky} direction={direction} tabs={tabs}>{subHeader}</SubHeader>
}

DMSubHeader.defaultProps = {
  subHeader: null,
}

const UserTitle = ({
  user,
}) => {
  const { info: { name, imageUrl } } = user
  const classes = styles()

  return (
    <UserNameContainer>
      {imageUrl && <StyledAvatar alt={name} className={classes.small} src={imageUrl} />}
      {!imageUrl && <StyledIcon className={'icon-doormouse ' + classes.small} />}
    </UserNameContainer>
  )
}

const UserSelect = ({
  style,
  user,
}) => {
  const { aliases, info: { name, imageUrl } } = user
  const [anchorEl, setAnchorEl] = useState(null)
  const menuId = uuidv4()
  const classes = styles()
  const dispatch = useDispatch()

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSwitchAlias = (alias) => {
    dispatch(switchAlias({ userId: alias.id }))
    handleClose()
  }

  return (
    <>
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        color="primary"
        endIcon={<ExpandMore />}
        onClick={handleOpen}
        startIcon={
          <>
            {imageUrl && <Avatar alt={name} className={classes.small} src={imageUrl} />}
            {!imageUrl && <Icon className={'icon-doormouse ' + classes.small} />}
          </>
        }
        style={style}
        variant="outlined">
        {name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        onClose={handleClose}
        open={Boolean(anchorEl)}>
        {aliases.map(alias => {
          const { imageUrl, name } = alias
          return (
            <MenuItem key={alias.id} onClick={() => handleSwitchAlias(alias)}>
              <ListItemIcon>
                <>
                  {imageUrl && <Avatar alt={name} className={classes.small} src={imageUrl}/>}
                  {!imageUrl && <Icon className={'icon-doormouse ' + classes.small} />}
                </>
              </ListItemIcon>
              {alias.name}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

UserSelect.propTypes = {
  style: PropTypes.object,
  user: PropTypes.shape(UserProps).isRequired,
}

UserSelect.defaultProps = {
  style: {}
}

export { DMSubHeader, UserSelect, UserTitle }
