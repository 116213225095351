import React, { Component } from 'react'
import App from './AppContainer'
import ActionConstants from '../constants/ActionConstants'
import request from '../request'
import MainProvider from '../store/providers'
import { store } from '../store/providers'

const userConst = ActionConstants.users

class MainContainer extends Component {
  constructor() {
    super()
    this.state = {
      authChecked: false,
      user: {
        id: null,
      },
    }
  }

  componentWillMount() {
    request
      .get('me')
      .then(response => this.handleWhoamiResponse(response))
      .catch(response => this.handleWhoamiResponse(response))
  }

  handleWhoamiResponse(currentUser) {
    const user = currentUser.data ? currentUser.data : null
    if (user && user.name === 'justin1') {
      store.dispatch({
        type: 'SWITCH_TO_SANDBOX',
      })
    }
    this.setState({
      authChecked: true,
      user: user,
    })
    const actionType = user == null ? userConst.confirmLogOut : userConst.confirmLogIn
    store.dispatch({
      type: actionType,
      info: user,
    })
  }

  render() {
    if (this.state.authChecked) {
      return (
        <MainProvider>
          <App />
        </MainProvider>
      )
    } else {
      return <div />
    }
  }
}

export { MainContainer }
