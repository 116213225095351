import { schema } from 'normalizr'
import { user } from './UserSchemas'
import { venue } from './VenueSchemas'

const door = new schema.Entity('doors')
const doorComment = new schema.Entity('doorComments')
const doorGuestList = new schema.Entity('doorGuestLists')

door.define({
  owner: user,
  venue: venue,
})

doorComment.define({
  parent: doorComment,
  business: door,
  user: user,
})

doorGuestList.define({
  business: door,
  guests: [user],
})

export {
  door,
  doorComment,
  doorGuestList,
}
