import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const notificationConst = ActionConstants.notifications

function notifications(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    errorMessage: null,
    tallies: {},
  },
  action
) {
  let index
  switch (action.type) {
    case notificationConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case notificationConst.requestItem:
    case notificationConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case notificationConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items.reverse(),
        lastUpdated: action.receivedAt,
      }
    case notificationConst.confirmMarkAll:
      const newNotes = [ ...state.items ]
      for (let i=0; i<newNotes.length; i+=1) {
        const item = newNotes[i]
        item.read = action.response
      }
      return {
        ...state,
        items: newNotes,
      }
    case notificationConst.receiveItem:
    case notificationConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case notificationConst.sendAddItem:
    case notificationConst.sendDeleteItem:
    case notificationConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case notificationConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case notificationConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case notificationConst.itemFailed:
    case notificationConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
  case notificationConst.receiveNotificationTallies:
    return {
      ...state,
      tallies: action.response,
    }
    default:
      return state
  }
}

export default notifications
