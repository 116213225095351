import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const doorSubscribersConst = ActionConstants.doorSubscribers

export default function doorSubscribers(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    currentRole: null,
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case doorSubscribersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case doorSubscribersConst.requestItem:
    case doorSubscribersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case doorSubscribersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case doorSubscribersConst.receiveCurrentRole:
      return {
        ...state,
        currentRole: action.response,
      }
    case doorSubscribersConst.receiveItem:
    case doorSubscribersConst.confirmEditItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case doorSubscribersConst.sendAddItem:
    case doorSubscribersConst.sendDeleteItem:
    case doorSubscribersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case doorSubscribersConst.confirmDeleteItem:
      index = _.findIndex(state.items, {
        id: action.path.userId,
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case doorSubscribersConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, { ...action.response, managers: [] }, state.items.length),
        isFetching: false,
      }
    case doorSubscribersConst.itemFailed:
    case doorSubscribersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.doorBans.confirmAddItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
      }
      default:
        return state
  }
}
