import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'

const doorGuestListConst = ActionConstants.doorGuestLists

function doorGuestLists(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: {
      allContacts: { id: 'allContacts', name: 'All Contacts', friends: {} },
    },
    errorMessage: null,
  },
  action
) {
  let hash
  let item
  let id
  switch (action.type) {
    case doorGuestListConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case doorGuestListConst.requestItem:
    case doorGuestListConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case doorGuestListConst.receiveItems:
      hash = {}
      for (let i=0; i<action.items.length; i+=1) {
        item = action.items[i]
        hash[item.id] = item
      }
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: {
          ...state.items,
          ...hash,
        },
        lastUpdated: action.receivedAt,
      }
  case ActionConstants.contacts.receiveItems:
    hash = {}
    for (let i=0; i<action.items.length; i+=1) {
      hash[action.items[i].id] = action.items[i]
    }
    return {
      ...state,
      items: {
        ...state.items,
        allContacts: {
          ...state.items['allContacts'],
          friends: hash,
        },
      }
    }
  case ActionConstants.contacts.confirmDeleteItem:
    hash = _.clone(state.items)
    Object.values(hash).forEach(list => {
      delete list.friends[action.response.id]
    })
    return {
      ...state,
      items: hash,
    }
    case doorGuestListConst.receiveItem:
    case doorGuestListConst.confirmEditItem:
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.id]: action.response,
        },
        isFetching: false,
        selectedItem: action.response,
      }
    case doorGuestListConst.sendAddItem:
    case doorGuestListConst.sendDeleteItem:
    case doorGuestListConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case doorGuestListConst.confirmDeleteItem:
      hash = { ...state.items }
      delete hash[action.response.id]
      return {
        ...state,
        items: hash,
        isFetching: false,
      }
    case doorGuestListConst.confirmAddItem:
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.id]: action.response,
        },
        isFetching: false,
        selectedItem: action.response,
      }
    case doorGuestListConst.itemFailed:
    case doorGuestListConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }

  case ActionConstants.doorGuestListGuests.confirmAddItems:
    id = Number(action.response.guestList)
    hash = {}
    for (let i=0; i<action.response.guests.length; i+=1) {
      item = action.response.guests[i]
      hash[item.id] = item
    }
    return {
      ...state,
      items: {
        ...state.items,
        [id]: {
          ...state.items[id],
          friends: {
            ...state.items[id].friends,
            ...hash,
          }
        }
      },
    }
  case ActionConstants.doorGuestListGuests.receiveItems:
    id = Number(action.response.guestList)
    hash = {}
    for (let i=0; i<action.response.guests.length; i+=1) {
      item = action.response.guests[i]
      hash[item.id] = item
    }
    return {
      ...state,
      items: {
        ...state.items,
        [id]: {
          ...state.items[id],
          friends: hash,
        }
      },
    }
  case ActionConstants.doorGuestListGuests.confirmDeleteItem:
    id = Number(action.response.listId)
    item = { ...state.items[id].friends }
    delete item[Number(action.response.userId)]
    return {
      ...state,
      items: {
        ...state.items,
        [id]: {
          ...state.items[id],
          friends: item,
        }
      }
    }
  case ActionConstants.contacts.confirmBlockUser:
  case ActionConstants.doorBans.confirmAddItem:
    id = Number(action.response.id)
    const newLists = { ...state.items }
    Object.values(newLists).forEach(list => {
      if (list.friends && list.friends[id]) {
        delete list.friends[id]
      }
    })
    return {
      ...state,
      items: newLists,
    }
    default:
      return state
  }
}

export default doorGuestLists
