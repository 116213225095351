import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import InvitesPane from './InvitesPane'
import { MobileGrid } from '../../layout/layout'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Globals } from '../../../constants/Global'
import { useDispatch, useSelector } from 'react-redux'
import { addItemsNonNormal, fetchItemsNonNormal } from '../../../actions/RESTActions'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { selectInvites } from '../../../selectors/EventsSelectors'
import { openFullScreenDialog } from '../../../actions/UI/AppActions'
import FullscreenDialog from '../../../containers/Dialogs/FullscreenDialog'
import { selectFullScreenDialog } from '../../../selectors/AppSelectors'
import { useHistory } from 'react-router-dom'

const InvitesAvatarsContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  color: ${Globals.colors.gray['A100']};
  flex-grow: 1;
  width: 100%;
`

const useStyles = makeStyles((theme) => ({
  inlineTypography: {
    marginRight: 8,
    color: Globals.colors.gray['A100'],
    display: 'inline',
  },
  alignBottomTypography: {
    marginTop: 5,
    color: Globals.colors.gray['A100'],
    display: 'inline',
    textTransform: 'capitalize',
  },
  inviteAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    cursor: 'pointer',
    fontSize: 14,
  },
  remainderText: {
    marginLeft: 8,
    fontSize: 9,
    fontFamily: "'Roboto', sans-serif",
  },
  dialogContent: {
    display: 'flex',
    padding: 0,
    flexDirection: 'column',
    backgroundColor: Globals.colors.bgColor,
  },
  avatarAndRSVP: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  RSVP: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  tabs: {
    backgroundColor: 'transparent',
  }
}))

const InvitesList = ({ event, status }) => {
  const dispatch = useDispatch()
  const eventInvites = useSelector(selectInvites)
  const [offset, setOffset] = useState(25)
  const [endOfList, setEndOfList] = useState(false)
  const limit = 25
  const eventId = event && event.id

  const handleInfiniteLoad = () => {
    if (endOfList) {
      return
    }
    const payload = {
      dataType: 'invites',
      url: Globals.api.eventInvites,
      path: { eventId: event.id },
      params: { offset: offset, status },
    }
    dispatch(addItemsNonNormal(payload)).then(data => {
      setOffset(offset + limit)
      setEndOfList(!data.hasNextPage)
    })
  }

  useEffect(() => {
    const payload = {
      dataType: 'invites',
      url: Globals.api.eventInvites,
      path: { eventId },
      params: { offset: 0, status },
    }
    dispatch(fetchItemsNonNormal(payload)).then(data => {
      setOffset(25)
      setEndOfList(!data.hasNextPage)
    })
  }, [dispatch, eventId, status])

  const getSuggestionValue = invite => {
    const user = invite.user || invite.externalUser
    if (user) {
      dispatch(openFullScreenDialog({ dialog: 'userProfile', user }, `/users/${user.id}`))
    }
    return ''
  }

  const invitesList = eventInvites.items.map(invite => {
    const user = invite.user || invite.externalUser
    const avatar = user.imageUrl ? (
      <Avatar alt={user.name} src={user.imageUrl} />
    ) : (
      <Avatar>{user.name.slice(0, 1)}</Avatar>
    )
    return (
      <ListItem button divider key={user.id} onClick={() => getSuggestionValue(invite)}>
        <ListItemAvatar>{avatar}</ListItemAvatar>
        <ListItemText primary={user.name} />
      </ListItem>
    )
  })

  return (
    <div
      style={{
        flexGrow: 1,
        overflow: 'auto',
        }}
       id="scrollable-container"
      >
      <InfiniteScroll
        dataLength={invitesList.length}
        next={handleInfiniteLoad}
        hasMore={!endOfList}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollable-container"
      >
        {invitesList}
      </InfiniteScroll>
    </div>
  )
}

export const InvitesAvatars = ({ going, remainderText, remainderTextColor }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleOpenUserProfile = (user) => {
    dispatch(openFullScreenDialog({ dialog: 'userProfile', user }, `/users/${user.id}`))
  }
  return (
    <InvitesAvatarsContainer>
      <AvatarGroup max={5} spacing="medium" classes={{ avatar: classes.inviteAvatar}}>
        {going.map((invite) => {
          const user = invite.user || invite.externalUser
          let userId = user.id
          if (!userId && user.provider && user.providerUserId) {
            userId = `${user.provider}-${user.providerUserId}`
          }
          return (
            <Avatar
              key={userId}
              onClick={() => invite.user && handleOpenUserProfile(user)}
              className={classes.inviteAvatar}
              src={user.imageUrl}
              alt={user.name}
            />
          )
        })}
      </AvatarGroup>
      {remainderText && <div className={classes.remainderText} style={{ color: remainderTextColor || Globals.colors.textColor }}>{remainderText}</div>}
    </InvitesAvatarsContainer>
  )
}

const InvitesCounts = ({ event, showCounts, type, remainderTextColor }) => {
  const [tabValue, setTabValue] = useState(0)
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const fullScreenDialogs = useSelector(selectFullScreenDialog)
  const { inviteCounts, externalInviteCounts, invites = [], externalInvites = [] } = event
  let counts = {
    YES: 0,
    MAYBE: 0,
    WUT: 0,
    NO: 0,
  }

  const allInvites = [...invites, ...externalInvites]
  const going = allInvites.filter(invite => {
    return invite.status === 'YES'
  })
  let remainderText = ' friends are going'

  if (inviteCounts && externalInviteCounts) {
    counts = {
      YES: counts.YES += inviteCounts.YES || 0,
      MAYBE: counts.MAYBE += inviteCounts.MAYBE || 0,
      WUT: counts.WUT += inviteCounts.WUT || 0,
      NO: counts.NO += inviteCounts.NO || 0,
    }

    Object.values(externalInviteCounts).forEach(provider => {
      counts.YES += provider.YES || 0
      counts.MAYBE += provider.MAYBE || 0
      counts.WUT += provider.WUT || 0
      counts.NO += provider.NO || 0
    })
  }

  const handleClose = () => {
    history.goBack()
  }

  const handleChange = (e, newValue) => {
    setTabValue(newValue)
  }

  const handleOpen = (tab) => {
    setTabValue(tab)
    dispatch(openFullScreenDialog({ dialog: 'invited' }))
  }

  let status
  switch (tabValue) {
  case 0:
    status = 'YES'
    break
  case 1:
    status = 'MAYBE'
    break
  case 2:
    status = 'WUT'
    break
  default:
    break
  }

  return (
    <>
      {showCounts && (
        <Grid spacing={1} container style={{ padding: '14px 0' }}>
          <Grid item xs={4} style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => handleOpen(0)}>
            <Typography className={classes.inlineTypography} variant="h5">
              {counts.YES}
            </Typography>
            <Typography className={classes.alignBottomTypography} variant="subtitle1">
              Going
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleOpen(1)}>
            <Typography className={classes.inlineTypography} variant="h5">
              {counts.MAYBE}
            </Typography>
            <Typography className={classes.alignBottomTypography} variant="subtitle1">
              Maybe
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => handleOpen(2)} >
            <Typography className={classes.inlineTypography} variant="h5">
              {counts.WUT}
            </Typography>
            <Typography className={classes.alignBottomTypography} variant="subtitle1">
              Invited
            </Typography>
          </Grid>
          <FullscreenDialog
            onClose={handleClose}
            open={!!(fullScreenDialogs && fullScreenDialogs.invited && fullScreenDialogs.invited.open)}
            title="Invites"
          >
            <MobileGrid
              fullHeight
              paper
              childStyle={{
                display: 'flex',
                flexDirection: 'column',
              }}
              paperStyle={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                padding: 0,
              }}
            >
              <Tabs
                className={classes.tabs}
                variant="fullWidth"
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Going" />
                <Tab label="Maybe" />
                <Tab label="Invited" />
              </Tabs>
              <InvitesList event={event} status={status} />
            </MobileGrid>
          </FullscreenDialog>
        </Grid>
      )}
      {(going.length > 0 || type !== 'eventDetails') && (
        <div className={going.length > 0 ? classes.avatarAndRSVP : classes.RSVP}>
          {going.length > 0 && <InvitesAvatars going={going} remainderText={remainderText} remainderTextColor={remainderTextColor} />}
          {type !== 'eventDetails' && (
            <div style={{ padding: '12px 16px' }}>
              <InvitesPane event={event} type={type} />
            </div>
          )}
        </div>
      )}
    </>

  )
}

InvitesCounts.propTypes = {
  event: PropTypes.object.isRequired,
  showCounts: PropTypes.bool,
}

InvitesCounts.defaultProps = {
  showCounts: true,
}

export default InvitesCounts
