import { createSelector } from 'reselect'

export const selectNotifications = createSelector(
  state => state.notifications,
  propState => propState
)

export const selectNotificationTallies = createSelector(
  state => state.notifications,
  propState => propState.tallies
)
