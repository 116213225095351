import ActionConstants from '../constants/ActionConstants'
import request from '../request'
import { formatUrl } from '../utils/RESTUtils'
import { Globals } from '../constants/Global'

const eventConst = ActionConstants.events

function reqSwitchAlias(userId) {
  let path = { userId: userId }
  return request.put(formatUrl(Globals.api.switchUser, path)).then(
    response => {
      return request.get('/me').then(response => {
        return response.data
      })
    },
    error => {
      return false
    }
  )
}

function reqRSVPtoEvent(event) {
  let newUrl = formatUrl(Globals.api.eventInvites, { eventId: event.id }) + '?status=' + event.status
  return request.put(newUrl)
}

export const setVenue = location => {
  return {
    type: eventConst.setVenue,
    location: location,
  }
}

export const fetchInvitesCounts = path => (dispatch, getState) => {
  const { eventId } = path
  dispatch({
    type: ActionConstants.invites.requestInvitesCount,
  })
  return request.get(formatUrl(Globals.api.eventInvitesCount, path)).then(
    response => {
      if (getState().sandbox.isSandbox) {
        return
      }
      dispatch({
        type: ActionConstants.invites.receiveInvitesCount,
        response: response.data,
        receivedAt: Date.now(),
        eventId,
      })
    },
    error => {
      dispatch({
        type: eventConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchExternalInvitesCounts = path => (dispatch, getState) => {
  const { eventId } = path
  if (getState().sandbox.isSandbox) {
    return
  }
  dispatch({
    type: ActionConstants.eventExternalInvites.requestInvitesCount,
  })
  return request.get(formatUrl(Globals.api.eventExternalInviteCounts, path)).then(
    response => {
      dispatch({
        type: ActionConstants.eventExternalInvites.receiveInvitesCount,
        response: response.data,
        receivedAt: Date.now(),
        eventId,
      })
    },
    error => {
      dispatch({
        type: ActionConstants.eventExternalInvites.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const respondToInvite = payload => dispatch => {
  let { path, params } = payload
  dispatch({
    type: ActionConstants.invites.sendRespondToInvite,
  })
  return request.put(formatUrl(Globals.api.eventInvites, path) + '?status=' + params.status).then(
    response => {
      dispatch({
        type: ActionConstants.invites.confirmRespondToInvite,
        response: params.status,
      })
      return params.status
    },
    error => {
      dispatch({
        type: ActionConstants.invites.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const inviteMeToPublic = payload => dispatch => {
  let { path } = payload
  dispatch({
    type: ActionConstants.events.sendInviteMe,
  })
  return request.post(formatUrl(Globals.api.eventInviteMe, path)).then(response => {
    dispatch({
      type: ActionConstants.events.confirmInviteMe,
      response: response.data,
    })
    return response.data
  })
}

export const rsvpAs = payload => async () => {
  const { aliasId, event, userId } = payload
  await reqSwitchAlias(aliasId)
  await reqRSVPtoEvent(event)
  return await reqSwitchAlias(userId)
}

export const fetchEventInvites = payload => dispatch => {
  const { eventId } = payload
  return request.get(formatUrl(Globals.api.eventInvites, { eventId })).then(response => {
    const items = response.data.items !== null ? response.data.items : []
    dispatch({
      type: ActionConstants.invites.receiveItems,
      items,
      eventId,
    })
    return items
  })
}

export const fetchEventExternalInvites = payload => dispatch => {
  const { eventId } = payload
  return request.get(formatUrl(Globals.api.eventExternalInvites, { eventId })).then(response => {
    const items = response.data.items !== null ? response.data.items : []
    dispatch({
      type: ActionConstants.invites.receiveItems,
      items,
      eventId,
    })
    return items
  })
}

export const fetchEventInvitableFriends = payload => dispatch => {
  const { eventId } = payload
  return request.get(formatUrl(Globals.api.eventInvitableFriends, { eventId })).then(response => {
    const items = response.data.items !== null ? response.data.items : []
    dispatch({
      type: ActionConstants.eventInvitableFriends.receiveItems,
      items,
      eventId,
    })
    return items
  })
}

export const fetchEventInviteCounts = payload => dispatch => {
  const { event } = payload
  dispatch({
    type: eventConst.sendEventLoading,
    response: event,
  })
  Promise.all([
    request.get(formatUrl('events/:eventId/invites/counts', { eventId: event.id })),
    request.get(formatUrl('events/:eventId/externalInvites/counts', { eventId: event.id })),
  ]).then(values => {
    event.inviteCounts = values[0].data || {}
    event.externalInviteCounts = values[1].data || {}

    dispatch({
      type: eventConst.confirmEventLoading,
      response: event,
      fullyLoaded: true,
    })
  }, error => {
    dispatch({
      type: eventConst.eventLoadingFailed
    })
  })
}

export const checkIfEventInvitesLoaded = (event) => {
  const { inviteCounts, externalInviteCounts, userInvite, invites, externalInvites } = event
  return !!inviteCounts && !!externalInvites && !!externalInviteCounts && !!userInvite && !!invites
}

export const loadEvent = payload => dispatch => {
  const { eventId, type } = payload
  return request.get(formatUrl(Globals.api.event, { eventId })).then(response => {
    if (!response) {
      dispatch({
        type: eventConst.itemFailed,
      })
    }
    const event = { ...response.data }
    dispatch({
      type: eventConst.sendEventLoading,
      response: event,
    })
    const start = new Date(event.startTime)
    const end = new Date(event.endTime)
    event.startDate = start
    event.startTime = start
    event.endDate = end
    event.endTime = end
    if (type !== 'feedCard') {
      Promise.all([
        request.get(formatUrl('events/:eventId/invites', { eventId })),
        request.get(formatUrl('events/:eventId/externalInvites', { eventId })),
        request.get(formatUrl('events/:eventId/invites/counts', { eventId })),
        request.get(formatUrl('events/:eventId/externalInvites/counts', { eventId })),
        request.get(formatUrl('events/:eventId/invitableFriends', { eventId })),
        request.get(formatUrl('events/:eventId/myInvite', { eventId })),
        request.get(formatUrl('events/:eventId/hosts', { eventId })),
        request.get(formatUrl('events/:eventId/categories', { eventId }))
      ]).then(values => {
        event.invites = values[0].data && values[0].data.items ? values[0].data.items : []
        event.externalInvites = values[1].data && values[1].data.items ? values[1].data.items : []
        event.inviteCounts = values[2].data || {}
        event.externalInviteCounts = values[3].data || {}
        event.invitableFriends = values[4].data && values[4].data.items ? values[4].data.items : []
        event.userInvite = values[5].data || {}
        event.hosts = values[6].data && values[6].data.items ? values[6].data.items.map(host => host.user) : []
        event.categories = values[7].data && values[7].data.items ? values[7].data.items : []
        if (!event.imageUrl && event.series && event.series.imageUrl) {
          event.imageUrl = event.series.imageUrl
        }
        if (event.categories.length === 0 && event.series) {
          request.get(formatUrl(Globals.api.doorSeriesCategories, { doorId: 0, seriesId: event.series.id })).then(response => {
            if (response && response.data && response.data.items) {
              event.categories = response.data.items
              dispatch({
                type: eventConst.confirmEventLoading,
                response: event,
                fullyLoaded: true,
              })
            }
          })
        } else {
          dispatch({
            type: eventConst.confirmEventLoading,
            response: event,
            fullyLoaded: true,
          })
        }
      }, () => {
        dispatch({
          type: eventConst.eventLoadingFailed
        })
      })
    } else {
      Promise.all([
        request.get(formatUrl(Globals.api.eventInvites, { eventId })),
        request.get(formatUrl(Globals.api.eventExternalInvites, { eventId })),
        request.get(formatUrl(Globals.api.eventInvitesCount, { eventId })),
        request.get(formatUrl(Globals.api.eventExternalInviteCounts, { eventId })),
        request.get(formatUrl(Globals.api.eventMyInvite, { eventId })),
      ]).then(values => {
        event.invites = values[0].data && values[0].data.items ? values[0].data.items : []
        event.externalInvites = values[1].data && values[1].data.items ? values[1].data.items : []
        event.inviteCounts = values[2].data || {}
        event.externalInviteCounts = values[3].data || {}
        event.userInvite = values[4].data || {}
        dispatch({
          type: eventConst.confirmEventLoading,
          response: event,
          fullyLoaded: false,
        })
      }, error => {
        dispatch({
          type: eventConst.eventLoadingFailed
        })
      })
    }
  })}

export const loadExternalInvites = (payload) => dispatch => {
  const { eventId } = payload
  dispatch({
    type: eventConst.requestExternalInvites,
    response: eventId,
  })
  return request.get(formatUrl(Globals.api.eventExternalInviteCounts, { eventId })).then(response => {
    dispatch({
      type: eventConst.receiveExternalInvites,
      response: {
        eventId,
        counts: response.data,
      },
    })
  })
}

export const toggleCancelEvent = (payload) => dispatch => {
  dispatch({
    type: ActionConstants.events.sendEditItem,
  })
  return request.put(formatUrl(Globals.api.eventCancel, { eventId: payload.id }), { value: !payload.cancelled }).then(
    () => {
      dispatch({
        type: ActionConstants.events.confirmEditItem,
        response: { ...payload, cancelled: !payload.cancelled },
      })
    }, () => {
      dispatch({
        type: ActionConstants.events.itemFailed,
      })
    }
  )
}
