import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const tripsConst = ActionConstants.trips
const legsConst = ActionConstants.tripLegs
const legTravelersConst = ActionConstants.tripLegTravelers
const legViewersConst = ActionConstants.tripLegViewers
const travelersConst = ActionConstants.tripTravelers
const viewersConst = ActionConstants.tripViewers

export const defaultTrip = {
  name: '',
  id: null,
  user: null,
  mode: 'view',
  tripLegsLoaded: false,
  tripEventCount: null,
  tripLegs: [],
  viewers: null,
  travelers: null,
  startTime: null,
  endTime: null,
}

export const defaultTripLeg = {
  name: '',
  id: null,
  startTime: null,
  endTime: null,
  latitude: null,
  longitude: null,
  radius: null,
  trip: defaultTrip,
  viewers: null,
  travelers: null
}

function trips(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: defaultTrip,
    items: [],
    traveledTrips: [],
    viewableTrips: [],
    errorMessage: null,
    isNewDestinationOpen: false,
  },
  action
) {
  let index
  let legIndex
  let newTrip
  switch (action.type) {
    case tripsConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case tripsConst.selectItem:
      return {
        ...state,
        selectedItem: action.item,
      }
    case tripsConst.toggleNewDestingation:
      const trip = action.response

      return {
        ...state,
        isNewDestinationOpen: trip || false,
      }
    case tripsConst.requestItem:
    case tripsConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case legsConst.receiveItems:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.tripId
      })
      const { startTime, endTime, tripLegs, tripEventCount } = action.response
      return {
        ...state,
        items: dataUtils.updateObjectInArray(
          state.items,
          {
            ...state.items[index],
            tripLegsLoaded: true,
            tripEventCount,
            tripLegs,
            startTime,
            endTime,
          },
          index)
      }
    case tripsConst.receiveItems:
      const newTrips = action.items.map(trip => {
        return {
          ...trip,
          legsLoaded: false,
          tripEventCount: null,
          tripLegs: [],
          startTime: null,
          endTime: null,
        }
      })
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: newTrips,
        lastUpdated: action.receivedAt,
      }
    case tripsConst.receiveViewableTrips:
      const newViewableTrips = action.items.map(trip => {
        return {
          ...trip,
          legsLoaded: false,
          tripEventCount: null,
          tripLegs: [],
          startTime: null,
          endTime: null,
        }
      })
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        viewableTrips: newViewableTrips,
        lastUpdated: action.receivedAt,
      }
    case tripsConst.receiveItem:
    case tripsConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: { ...state.selectedItem, ...action.response },
      }
    case legsConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.trip.id
      })
      newTrip = { ...state.items[index] }
      legIndex = _.findIndex(newTrip.tripLegs, (leg) => {
        return leg.id === action.response.id
      })
      newTrip.tripLegs = dataUtils.updateObjectInArray(newTrip.tripLegs, action.response, legIndex)
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, newTrip, index)
      }
    case tripsConst.sendAddItem:
    case tripsConst.sendDeleteItem:
    case tripsConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case tripsConst.confirmDeleteItem:
      index = -1
      _.each(state.items, (val, i) => {
        if (action.response.id === val.id) {
          index = i
        }
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case legsConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.trip.id
      })
      newTrip = { ...state.items[index] }
      legIndex = _.findIndex(newTrip.tripLegs, (leg) => {
        return leg.id === action.response.id
      })
      newTrip.tripLegs = dataUtils.removeItem(newTrip.tripLegs, legIndex)
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, newTrip, index)
      }
    case tripsConst.confirmAddItem:
      return {
        ...state,
        items: [...state.items, { ...defaultTrip, ...action.response }],
        isFetching: false,
      }
    case tripsConst.itemFailed:
    case tripsConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function tripLegs(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: defaultTripLeg,
    selectedItems: [],
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  let newList = []
  switch (action.type) {
    case legsConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case legsConst.selectItem:
      return {
        ...state,
        selectedItem: action.item,
      }
    case legsConst.toggleSelectLeg:
      let isSelected = _.findIndex(state.selectedItems, item => {
        return item.id === action.item.id
      })
      index = _.findIndex(state.items, e => {
        return e.id === action.item.id
      })
      if (isSelected > -1) {
        action.item.selected = false
        newList = dataUtils.removeItem(state.selectedItems, isSelected)
      } else {
        action.item.selected = true
        newList = dataUtils.insertItem(state.selectedItems, action.item, 0)
      }
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.item, index),
        selectedItems: newList,
      }
    case legsConst.toggleSelectAllLegs:
      newList = []
      if ((state.items.length === state.selectedItems.length && !action.select) || action.select === false) {
        _.forEach(state.items, leg => {
          leg.selected = false
          newList.push(leg)
        })
        return {
          ...state,
          items: newList,
          selectedItems: [],
        }
      } else if (
        (state.items.length !== state.selectedItems.length && _.isUndefined(action.select)) ||
        action.select === true
      ) {
        _.forEach(state.items, leg => {
          leg.selected = true
          newList.push(leg)
        })
        return {
          ...state,
          items: newList,
          selectedItems: newList,
        }
      }
      break
    case legsConst.requestItem:
    case legsConst.requestItems:
    case legsConst.requestLegEvents:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case legsConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case legsConst.receiveLegEvents:
      index = _.findIndex(state.items, e => {
        return e.id === action.item.id
      })
      action.item.events = action.items
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: dataUtils.updateObjectInArray(state.items, action.item, index),
        lastUpdated: action.receivedAt,
      }
    case legsConst.receiveItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case legsConst.sendAddItem:
    case legsConst.sendDeleteItem:
    case legsConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case legsConst.itemFailed:
    case legsConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function tripTravelers(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case travelersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case travelersConst.requestItem:
    case travelersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case travelersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case travelersConst.receiveItem:
    case travelersConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case travelersConst.sendAddItem:
    case travelersConst.sendDeleteItem:
    case travelersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case travelersConst.confirmDeleteItem:
      index = -1
      _.each(state.items, (val, i) => {
        console.log(action.response, val)
        if (action.response.id === val.id) {
          index = i
        }
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case travelersConst.confirmAddItem:
      return {
        ...state,
        items: [...state.items, action.response],
        isFetching: false,
      }
    case travelersConst.itemFailed:
    case travelersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function tripViewers(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case viewersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case viewersConst.requestItem:
    case viewersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case viewersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case viewersConst.receiveItem:
    case viewersConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case viewersConst.sendAddItem:
    case viewersConst.sendDeleteItem:
    case viewersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case viewersConst.confirmDeleteItem:
      index = -1
      _.each(state.items, (val, i) => {
        if (action.response.id === val.id) {
          index = i
        }
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case viewersConst.confirmAddItem:
      return {
        ...state,
        items: [...state.items, action.response],
        isFetching: false,
      }
    case viewersConst.itemFailed:
    case viewersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function tripLegTravelers(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case legTravelersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case legTravelersConst.requestItem:
    case legTravelersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case legTravelersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case legTravelersConst.receiveItem:
    case legTravelersConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case legTravelersConst.sendAddItem:
    case legTravelersConst.sendDeleteItem:
    case legTravelersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case legTravelersConst.confirmDeleteItem:
      index = -1
      _.each(state.items, (val, i) => {
        if (action.response.id === val.id) {
          index = i
        }
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case legTravelersConst.confirmAddItem:
      return {
        ...state,
        items: [...state.items, action.response],
        isFetching: false,
      }
    case legTravelersConst.itemFailed:
    case legTravelersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function tripLegViewers(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case legViewersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case legViewersConst.requestItem:
    case legViewersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case legViewersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case legViewersConst.receiveItem:
    case legViewersConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case legViewersConst.sendAddItem:
    case legViewersConst.sendDeleteItem:
    case legViewersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case legViewersConst.confirmDeleteItem:
      index = -1
      _.each(state.items, (val, i) => {
        if (action.response.id === val.id) {
          index = i
        }
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case legViewersConst.confirmAddItem:
      return {
        ...state,
        items: [...state.items, action.response],
        isFetching: false,
      }
    case legViewersConst.itemFailed:
    case legViewersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export { trips, tripLegs, tripTravelers, tripViewers, tripLegTravelers, tripLegViewers }
