import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const StyledContainer = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const PageLoader = () => {
  return (
    <StyledContainer>
      <CircularProgress color="primary" size={'10rem'} />
    </StyledContainer>
  )
}

export { PageLoader }