import { schema } from 'normalizr'
import { category } from './CategorySchemas'

const user = new schema.Entity('users')
const userCategory = new schema.Entity('userCategories')
const userGuestList = new schema.Entity('userGuestLists')
const userNotification = new schema.Entity('userNotifications')
const userSettings = new schema.Entity('userSettings') // again, this one looks plural, but is actually singular

userCategory.define({
  category: category,
  user: user,
})

userGuestList.define({
  guests: [user],
  user: user,
})

userNotification.define({
  user: user,
})

userSettings.define({
  user: user,
})

export {
  user,
  userCategory,
  userGuestList,
  userNotification,
  userSettings,
}
