import { createSelector } from 'reselect'

export const selectUsers = createSelector(
  state => state.users,
  propState => propState
)

export const selectCurrentUser = createSelector(
  state => state.user,
  propState => propState
)

export const selectAliases = createSelector(
  state => state.user.aliases,
  propState => propState,
)

export const selectLoadedUser = (id) => createSelector(
  state => state.users,
  propState => propState.loadedItems[id]
)

export const selectUserErrorMessage = createSelector(
  state => state.users,
  propState => propState.errorMessage
)
