import React, { useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { ReactComponent as LogoWhite } from '../assets/images/DM_Logo_white.svg'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import history from '../history'
import { Globals } from '../constants/Global'
import { MobileGrid } from '../components/layout/layout'
import { selectCurrentUser } from '../selectors/UsersSelectors'
import FeedContainer from './FeedContainer'
import { PageLoader } from '../components/loading/Loading'
import Slide1 from '../assets/images/Slide1.jpg'
import Slide2 from '../assets/images/Slide2.jpg'
import Slide3 from '../assets/images/Slide3.jpg'
import Slide4 from '../assets/images/Slide4.jpg'
import Slide5 from '../assets/images/Slide5.jpg'
import Slide6 from '../assets/images/Slide6.jpg'
import { selectInstallPrompt } from '../selectors/AppSelectors'

const LandingParent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const HeaderContainer = styled.div`
  height: 86px;
  width: 100%;
  display: inline-flex;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
  padding: 24px;
  box-sizing: border-box;
`

const HeaderLogo = styled.div`
  width: 44px;
  height: 48px;
`

const HeaderText = styled.div`
  margin-left: 12px;
  color: ${Globals.colors.gray['A100']};
  font-size: 24px;
  font-family: 'RobotoSlab', serif;
  flex-grow: 1;
`

const CarouselContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const CarouselSlide = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  opacity: ${props => props.selected ? 1: 0};
  transition: 200ms ease all;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("${props => props.backgroundImage}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const LogInButton = styled(Button)`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: ${Globals.colors.gray['A100']};
  text-transform: initial;
`

const CarouselFooter = styled.div`
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  display: inline-flex;
  justify-content: center;
`

const CarouselFooterContent = styled.div`
  max-width: 600px;
  line-height: 1.5;
`

const FooterTitle = styled.div`
  font-size: 14px;
  color: ${Globals.colors.gray['A100']};
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`

const FooterText = styled.div`
  font-size: 12px;
  color: ${Globals.colors.gray['A100']};
  font-family: 'Roboto', sans-serif;
`

const CarouselPipsContainer = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  padding: 16px 0;
`

const CarouselPip = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${props => props.selected ? Globals.colors.gray['A100'] : 'transparent'};
  border: 1px solid ${Globals.colors.gray['A100']};
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
`

const GetStartedButton = styled(Button)`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
`

const InstallButton = styled(Button)`
  margin-top: 16px;
  color: ${Globals.colors.gray['A100']};
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  text-transform: capitalize;
  background-color: transparent;
  border-color: ${Globals.colors.gray['A100']};
`

const Gradient1 = styled.div`
  height: 200px;
  width: 100%;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 25%, rgba(234,222,239,0.5) 50%, rgba(191,144,209,0.75) 75%, rgba(90,55,105,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 25%, rgba(234,222,239,0.5) 50%, rgba(191,144,209,0.75) 75%, rgba(90,55,105,1) 100%);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 25%, rgba(234,222,239,0.5) 50%, rgba(191,144,209,0.75) 75%, rgba(90,55,105,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#5a3769",GradientType=1);
`

const Gradient3 = styled.div`
  height: 127px;
  width: 100%;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(20,38,40,0.5) 50%, rgba(20,38,40,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(20,38,40,0.5) 50%, rgba(20,38,40,1) 100%);
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(20,38,40,0.5) 50%, rgba(20,38,40,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#142628",GradientType=1);
`

const Gradient4 = styled.div`
  height: 127px;
  width: 100%;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(2,37,73,0.5) 50%, rgba(1,27,56,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(2,37,73,0.5) 50%, rgba(1,27,56,1) 100%);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(2,37,73,0.5) 50%, rgba(1,27,56,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#011b38",GradientType=1);
`

const Gradient5 = styled.div`
  height: 127px;
  width: 100%;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,39,41,0.5) 50%, rgba(1,54,56,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,39,41,0.5) 50%, rgba(1,54,56,1) 100%);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,39,41,0.5) 50%, rgba(1,54,56,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#013638",GradientType=1);
`

const Gradient6 = styled.div`
  height: 127px;
  width: 100%;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(1,20,14,0.5) 50%, rgba(3,26,20,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(1,20,14,0.5) 50%, rgba(3,26,20,1) 100%);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(1,20,14,0.5) 50%, rgba(3,26,20,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#031a14",GradientType=1);
`

const GradientBottom = styled.div`
  width: 100%;
  height: 286px;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.500437675070028) 50%, rgba(0,0,0,0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.500437675070028) 50%, rgba(0,0,0,0) 100%);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.500437675070028) 50%, rgba(0,0,0,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
`

const UserNotLoggedIn2 = () => {
  const [slide, setSlide] = useState(0)
  const installPrompt = useSelector(selectInstallPrompt)
  const dispatch = useDispatch()

  const config = {
    delta: 10,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
  }

  const handlers = useSwipeable({ onSwiped: (eventData) => handleSwiped(eventData), ...config })
  let interval

  const handleSetInterval = () => {
    /*interval = null
    interval = setInterval(() => {
      setSlide(prevSlide => (6 + prevSlide + 1) % 6)
    }, 10000)*/
  }

  const handleSwiped = (event) => {
    const delta = event.dir === 'Right' ? -1 : 1
    const nextIndex = (6 + slide + delta) % 6
    setSlide(nextIndex)
    handleSetInterval()
  }

  let footerTitle
  let footerText
  switch (slide) {
  case 0:
    footerTitle = 'Experience events that actually matter to you'
    footerText = 'Make real connections with the communities, spaces, and artists that inspire you. Never again miss something you would have loved.'
    break;
  case 1:
    footerTitle = 'Find events anywhere in the world'
    footerText = 'DoorMouse helps you discover events that match your interests, no matter where you are.'
    break
  case 2:
    footerTitle = 'Never again miss an event'
    footerText = 'If it’s happening near you, you’ll hear about it before it happens, instead of reading your friends’ posts about it later.'
    break
  case 3:
    footerTitle = 'Explore new cities like a local'
    footerText = 'Our event listings are powered by local hosts and promoters, so you can explore a new scene like an OG.'
    break
  case 4:
    footerTitle = 'Find a real world community of people like you'
    footerText = 'Real relationships grow offline. Connect with people who share your interests, and build real world communities together.'
    break
  case 5:
    footerTitle = 'Take total control over your privacy'
    footerText = 'Manage multiple identities under a single login. Share what you want with who you want, and no one else.'
    break
  default:
    break
  }

  const handlePipClick = (pip) => {
    setSlide(pip)
    handleSetInterval()
  }

  useEffect(() => {
    handleSetInterval()
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDownloadApp = async () => {
    if (installPrompt !== null) {
      installPrompt.prompt()
      const { outcome } = await installPrompt.userChoice
      if (outcome === 'accepted') {
        dispatch({
          type: 'STORE_INSTALL_PROMPT',
          payload: null,
        })
      }
    }
  }

  return (
    <MobileGrid style={{ padding: 0, height: '100%', backgroundColor: Globals.colors.bgColor }}>
      <LandingParent>
        <HeaderContainer>
          <HeaderLogo>
            <LogoWhite />
          </HeaderLogo>
          <HeaderText>
            DoorMouse
          </HeaderText>
          <LogInButton variant="text" onClick={() => history.push('/login')}>
            Log in
          </LogInButton>
        </HeaderContainer>
        <CarouselContainer {...handlers}>
          <CarouselSlide selected={slide === 0} backgroundImage={Slide1}>
            <Gradient1 />
            <GradientBottom />
          </CarouselSlide>
          <CarouselSlide selected={slide === 1} backgroundImage={Slide2}>
            <div />
            <GradientBottom />
          </CarouselSlide>
          <CarouselSlide selected={slide === 2} backgroundImage={Slide3}>
            <Gradient3 />
          </CarouselSlide>
          <CarouselSlide selected={slide === 3} backgroundImage={Slide4}>
            <Gradient4 />
            <GradientBottom />
          </CarouselSlide>
          <CarouselSlide selected={slide === 4} backgroundImage={Slide5}>
            <Gradient5 />
            <GradientBottom />
          </CarouselSlide>
          <CarouselSlide selected={slide === 5} backgroundImage={Slide6}>
            <Gradient6 />
            <GradientBottom />
          </CarouselSlide>
          <CarouselFooter>
            <CarouselFooterContent>
              <FooterTitle>{footerTitle}</FooterTitle>
              <FooterText>{footerText}</FooterText>
              <CarouselPipsContainer>
                <CarouselPip onClick={() => handlePipClick(0)} selected={slide === 0} />
                <CarouselPip onClick={() => handlePipClick(1)} selected={slide === 1} />
                <CarouselPip onClick={() => handlePipClick(2)} selected={slide === 2} />
                <CarouselPip onClick={() => handlePipClick(3)} selected={slide === 3} />
                <CarouselPip onClick={() => handlePipClick(4)} selected={slide === 4} />
                <CarouselPip onClick={() => handlePipClick(5)} selected={slide === 5} />
              </CarouselPipsContainer>
              <GetStartedButton onClick={() => history.push('/register')} fullWidth variant="contained" color="primary">Get Started</GetStartedButton>
              <InstallButton disabled={!installPrompt} variant="outlined" onClick={handleDownloadApp} fullWidth>Download</InstallButton>
            </CarouselFooterContent>
          </CarouselFooter>
        </CarouselContainer>
      </LandingParent>
    </MobileGrid>
  )
}

const LandingContainer = () => {
  const user = useSelector(selectCurrentUser)
  if (user && user.isFetching) {
    return <PageLoader />
  } else if (user && user.userLoggedIn) {
    return <FeedContainer />
  }
  return <UserNotLoggedIn2 />
}

export default LandingContainer
