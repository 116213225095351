import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Globals } from '../../constants/Global'

const StyledPaper = styled(Paper)`
  padding: 12px 16px;
  box-sizing: border-box;
`

const useStyles = makeStyles(theme => ({
  containerPadding: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0',
    },
  },
  fullHeightPadding: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0',
      height: '100%',
    },
  },
  container: {
    padding: 0,
  },
  fullHeight: {
    padding: 0,
    height: '100%',
  },
}))

const MobileGrid = forwardRef(({
  childStyle,
  children,
  elevation,
  enablePadding,
  onScroll,
  paper,
  style,
  fullHeight,
  paperStyle,
}, ref) => {
  const classes = useStyles()
  let gridStyles = enablePadding ? classes.containerPadding : classes.container
  let gridChildStyle = childStyle
  if (fullHeight) {
    if (enablePadding) {
      gridStyles = classes.fullHeightPadding
    } else {
      gridStyles = classes.fullHeight
    }
    gridChildStyle = { ...childStyle, height: '100%' }
  }
  return (
    <Grid
      ref={ref}
      className={gridStyles}
      container
      onScroll={onScroll}
      spacing={0}
      style={{
        ...style,
      }}
    >
      <Grid item md={2} xs={false} />
      <Grid item md={8} xs={12} style={gridChildStyle}>
        {paper ? (
          <StyledPaper
            elevation={elevation}
            square
            style={{
              ...paperStyle,
              backgroundColor: Globals.colors.gray['A100'],
              minHeight: fullHeight ? '100%' : 'initial',
            }}
          >
            {children}
          </StyledPaper>
        ) : children}
      </Grid>
      <Grid item md={2} xs={false} />
    </Grid>
  )
})

MobileGrid.propTypes = {
  elevation: PropTypes.number,
}

MobileGrid.defaultProps = {
  elevation: 1,
}

export { MobileGrid }
