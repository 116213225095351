import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const photosConst = ActionConstants.photos

export default function photos(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: {},
    errorMessage: null,
  },
  action
) {
  let index
  let newItem
  switch (action.type) {
    case photosConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case photosConst.requestAlbum:
    case photosConst.requestAlbums:
    case photosConst.requestPhoto:
    case photosConst.requestPhotos:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case photosConst.receiveAlbums:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case photosConst.receivePhotos:
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.albumId]: {
            ...state.items[action.response.albumId],
            items: action.response.items,
          },
        },
      }
    case photosConst.receiveAlbum:
    case photosConst.confirmEditAlbum:
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.id]: action.response.item,
        },
        isFetching: false,
        selectedItem: action.response,
      }
    case photosConst.receivePhoto:
    case photosConst.confirmEditPhoto:
      newItem = state.items[action.response.albumId]
      index = _.findIndex(newItem.items, {
        id: action.response.id,
      })
      newItem = { ...newItem, items: dataUtils.updateObjectInArray(newItem.items, action.response.item, index) }
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.albumId]: newItem,
        },
        isFetching: false,
        selectedItem: action.response.item,
      }
    case photosConst.sendAddAlbum:
    case photosConst.sendDeleteAlbum:
    case photosConst.sendEditAlbum:
    case photosConst.sendAddPhoto:
    case photosConst.sendDeletePhoto:
    case photosConst.sendEditPhoto:
      return {
        ...state,
        isFetching: true,
      }
    case photosConst.confirmDeleteAlbum:
      newItem = { ...state.items }
      delete newItem[action.response.id]
      return {
        ...state,
        items: newItem,
        isFetching: false,
      }
    case photosConst.confirmDeletePhoto:
      newItem = state.items[action.response.albumId]
      index = _.findIndex(newItem.items, {
        id: action.response.id,
      })
      newItem = { ...newItem, items: dataUtils.removeItem(newItem.items, index) }
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.albumId]: newItem,
        },
        isFetching: false,
        selectedItem: action.response.item,
      }
    case photosConst.confirmAddAlbum:
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.id]: action.response,
        },
        isFetching: false,
      }
    case photosConst.confirmAddPhoto:
      newItem = state.items[action.response.albumId]
      newItem = { ...newItem, items: dataUtils.insertItem(newItem.items, action.response.item, 0) }
      return {
        ...state,
        items: {
          ...state.items,
          [action.response.albumId]: newItem,
        },
        isFetching: false,
        selectedItem: action.response.item,
      }
    case photosConst.albumFailed:
    case photosConst.albumsFailed:
    case photosConst.photoFailed:
    case photosConst.photosFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
