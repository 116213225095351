import jack from '../assets/images/Jack Patel.jpg'
import sharon from '../assets/images/Sharon Edwards.jpg'

const Globals = {
  colors: {
    standard: '#5B5147',
    black: '#000000',
    primary: {
      '50': '#e0f5f3',
      '100': '#b3e7e2',
      '200': '#80d7cf',
      '300': '#4dc7bb',
      '400': '#26bbad',
      '500': '#00af9e',
      '600': '#00a896',
      '700': '#009f8c',
      '800': '#009682',
      '900': '#008670',
      '1000': '#1b4741',
      A100: '#b3fff0',
      A200: '#80ffe7',
      A400: '#4dffdd',
      A700: '#33ffd8',
      contrastDefaultColor: 'light',
    },
    secondary: {
      '50': '#f5e1ed',
      '100': '#e7b5d2',
      '200': '#d784b4',
      '300': '#c75396',
      '400': '#bb2e80',
      '500': '#af0969',
      '600': '#a80861',
      '700': '#9f0656',
      '800': '#96054c',
      '900': '#8B0954',
      A100: '#ffb3ce',
      A200: '#ff80ad',
      A400: '#af0969',
      A700: '#ff337c',
      contrastDefaultColor: 'light',
    },
    error: {
      '50': '#fbe4e5',
      '100': '#f6bcbe',
      '200': '#f09093',
      '300': '#e96467',
      '400': '#e54247',
      '500': '#e02126',
      '600': '#dc1d22',
      '700': '#d8181c',
      '800': '#d31417',
      '900': '#AF0511',
      'A100': '#fff6f7',
      'A200': '#ffc3c4',
      'A400': '#ff9091',
      'A700': '#ff7778',
      'contrastDefaultColor': 'light',
    },
    warning: {
      '50': '#f7ede0',
      '100': '#ebd1b3',
      '200': '#deb380',
      '300': '#EFA428',
      '400': '#c67d26',
      '500': '#bc6600',
      '600': '#b65e00',
      '700': '#ad5300',
      '800': '#a54900',
      '900': '#973800',
      'A100': '#ffd4c2',
      'A200': '#ffaf8f',
      'A400': '#ff8b5c',
      'A700': '#ff7942',
      'contrastDefaultColor': 'light',
      'contrastDarkColors': [
        '50',
        '100',
        '200',
        '300',
        '400',
        'A100',
        'A200',
        'A400',
        'A700',
      ],
      'contrastLightColors': [
        '500',
        '600',
        '700',
        '800',
        '900',
      ]
    },
    red1: '#BC091A',
    orange1: '#BC6B09',
    success: '#41B289',
    success2: '#188E5B',
    success3: '#ddefe8',
    warning2: '#9A3407',
    textColor: '#5b5147',
    textColorTear: '#707070',
    disabled: '#d0cecc',
    bgColor: '#efefef',
    gray: {
      '50': '#f8f7f6',
      '100': '#edeae8',
      '200': '#e1ddd9',
      '300': '#d4cfca',
      '400': '#cbc4be',
      '500': '#C3BAB2',
      '600': '#bcb3ac',
      '700': '#b4aba3',
      '800': '#aca39a',
      '900': '#808080',
      'A100': '#ffffff',
      'A200': '#ffffff',
      'A400': '#ffe4ce',
      'A700': '#ffd6b4',
    },
    highlightPast: {
      '100': '#b6c1c8',
      '200': '#8598a4',
      '300': '#546e7f',
      '400': '#2f4f63',
      '500': '#0a3048',
      '600': '#092b41',
      '700': '#072438',
      '800': '#051e30',
      '900': '#031321',
    },
    highlight: {
      0: '#4B1C3E',
      1: '#0A3048',
      2: '#4E513A',
    },
    userPictureBorder: '#3824B3',
    facebookBrand: '#3B5998',
    googleBrand: '#d62d20',
    meetupBrand: '#F64260',
    eventbriteBrand: '#f6682f',
    what3wordsBrand: '#E21F26',
    green: {
      '500': '#A9C2A9',
    },
    purple: {
      '500': '#646FB3',
    },
  },
  noTitleViews: ['Account Creation', 'Login'],
  api: {
    base: 'https://backend.doormouse.com/',
    blocks: 'blocks',
    block: 'blocks/:blockId',
    aliases: 'aliases',
    alias: 'aliases/:userId',
    removeAlias: 'aliases/:userId/remove',
    autodiscover: 'autodiscover/autodiscover.xml',
    doors: 'businesses',
    door: 'businesses/:doorId',
    mergeDoors: 'businesses/:doorId1/merge/:doorId',
    doorBan: 'businesses/:doorId/bans/:userId',
    doorBans: 'businesses/:doorId/bans',
    doorBlacklist: 'businesses/:doorId/blacklist',
    doorBlacklistUser: 'businesses/:doorId/blacklist/:userId',
    doorCategories: 'businesses/:doorId/categories',
    doorCategory: 'businesses/:doorId/categories/:categoryId',
    doorChangeGM: 'businesses/:doorId/changeGM',
    doorChangeOwner: 'businesses/:doorId/changeOwner/:newOwnerId',
    doorComments: 'businesses/:doorId/comments',
    doorComment: 'businesses/:doorId/comments/:commentId',
    doorCoverImageUrls: 'businesses/:doorId/coverImageUrls',
    doorImageUrls: 'businesses/:doorId/imageUrls',
    doorSubComment: 'businesses/:doorId/comments/:parentId',
    doorVenues: 'businesses/:doorId/venues',
    doorVenue: 'businesses/:doorId/venues/:venueId',
    externalDoors: 'businesses/:doorId/externalBusinesses',
    doorGuestLists: 'businesses/:doorId/guestLists',
    doorGuestList: 'businesses/:doorId/guestLists/:listId',
    doorGuestListGuests: 'businesses/:doorId/guestLists/:listId/guests',
    doorGuestListGuest: 'businesses/:doorId/guestLists/:listId/guests/:userId',
    doorManagers: 'businesses/:doorId/managers',
    doorManager: 'businesses/:doorId/managers/:userId',
    doorManagerGroups: 'businesses/:doorId/managerGroups',
    doorManagerGroup: 'businesses/:doorId/managerGroups/:groupId',
    doorManagerGroupMembers: 'businesses/:doorId/managerGroups/:groupId/members',
    doorManagerGroupMember: '/businesses/:doorId/managerGroups/:groupId/members/:memberId',
    doorPhotoAlbums: '/businesses/:doorId/photoAlbums',
    doorsPhotoAlbum: '/businesses/:doorId/photoAlbums/:albumId',
    doorPhotoAlbumPhotos: '/businesses/:doorId/photoAlbums/:albumId/photos',
    doorPhotoAlbumPhoto: '/businesses/:doorId/photoAlbums/:albumId/photos/:photoId',
    doorPhotoAlbumPhotoImageUrls: '/businesses/:doorId/photoAlbums/:albumId/photos/:photoId/imageUrls',
    doorPhotoAlbumPhotoMove: '/businesses/:doorId/photoAlbums/:albumId/photos/:photoId/move',
    doorPresentedEvents: 'businesses/:doorId/presentedEvents',
    doorPresenterHostRequests: 'businesses/:doorId/presenterHostRequests',
    doorPresenterHostRequest: 'businesses/:doorId/presenterHostRequests/:eventId',
    doorPresenterHostRequestOverride: 'businesses/:doorId/presenterHostRequests/:eventId/override/:status',
    doorPresenterHostRequestRespond: 'businesses/:doorId/presenterHostRequests/:eventId/respond/:status',
    doorSeriesComments: 'businesses/:doorId/series/:seriesId/comments',
    doorSeriesComment: 'businesses/:doorId/series/:seriesId/comments/:commentId',
    doorRecommendations: 'businessRecommendations',
    doorRoles: 'businesses/:doorId/roles',
    doorRole: 'businesses/:doorId/roles/:roleId',
    doorRoleCurrent: 'businesses/:doorId/roles/me',
    doorRoleAddManager: 'businesses/:doorId/managers/:managerId/role',
    doorAnarchy: 'businesses/:doorId/anarchy',
    doorDemocracy: 'businesses/:doorId/democracy',
    doorDictatorship: 'businesses/:doorId/dictatorship',
    doorSeries: 'businesses/:doorId/series',
    doorSeriesItem: 'businesses/:doorId/series/:seriesId',
    doorSeriesCronLine: 'businesses/:doorId/series/:seriesId/cronLine',
    doorSeriesCronLinePreflight: 'businesses/:doorId/series/:seriesId/preflightCronLine',
    doorSeriesEvents: 'businesses/:doorId/series/:seriesId/events',
    doorSeriesGuestLists: 'businesses/:doorId/series/:seriesId/guestLists',
    doorSeriesGuestList: 'businesses/:doorId/series/:seriesId/guestLists/:listId',
    doorSeriesHosts: 'businesses/:doorId/series/:seriesId/hosts',
    doorSeriesHost: 'businesses/:doorId/series/:seriesId/hosts/:hostId',
    doorSeriesSubscribers: 'businesses/:doorId/series/:seriesId/subscribers',
    doorSeriesSubscribersCount: 'businesses/:doorId/series/:seriesId/subscribers/count',
    doorSeriesImages: 'businesses/:doorId/series/:seriesId/imageUrls',
    doorSeriesCategories: 'businesses/:doorId/series/:seriesId/categories',
    doorSeriesCategory: 'businesses/:doorId/series/:seriesId/categories/:categoryId',
    doorSeriesSampleSchedule: '/businesses/:doorId/series/sampleSchedule',
    doorSubscribers: 'businesses/:doorId/subscribers',
    doorSubscriber: 'businesses/:doorId/subscribers/:userId',
    doorSubscribersCount: 'businesses/:doorId/subscribers/count',
    doorSubscribersHeatMap: 'businesses/:doorId/subscribers/heatMap',
    doorSubscribersWipe: 'businesses/:doorId/subscribers/wipe',
    transferDoor: 'businesses/:doorId/transfer/:newOwnerId',
    categories: 'categories',
    category: 'categories/:categoryId',
    categoryCounts: 'categories/count',
    categoryLinks: 'categories/:categoryId/links',
    categoryLink: 'categories/:categoryId/links/:categoryId2',
    categoryGame: 'categoryGame/:gameId',
    categoryGameNew: 'categoryGame',
    categoryGames: 'categoryGame',
    categoryGameHighScore: 'categoryGame/highScores/:startId/:endId',
    concreteFeed: 'concreteFeed',
    confirmEmail: 'confirmEmail',
    connectionProvider: 'connect/:provider',
    connectionsConnected: 'connect/:provider/connected',
    connectionsProviderUser: 'connect/:provider/:providerUserId',
    connectionsError: 'connect/:provider?error',
    connections: 'connections',
    connectionsFacebookFriends: 'connections/facebook/:providerUserId/facebookFriends',
    connectionsProvider: 'connections/:provider',
    connectionExternalFriends: 'connections/:provider/:providerUserId/externalFriends',
    contacts: 'friends',
    contactRequestPending: 'friendRequests/pending',
    contactRequestSent: 'friendRequests/sent',
    contactRequest: 'friendRequests/:friendId',
    contactRequestApprove: 'friendRequests/:friendId/approve',
    contactRequestDeny: 'friendRequests/:friendId/deny',
    contact: 'friends/:friendId',
    copyAliasData: 'copyAliasData',
    crashFeed: 'crashFeed',
    createdEvents: 'createdEvents',
    deactivate: 'deactivate',
    deleteMyAccount: 'deleteMyAccount',
    disconnectFacebook: 'disconnect/facebook',
    events: 'events',
    mergeEvents: 'events/:eventId1/merge/:eventId2',
    event: 'events/:eventId',
    eventCancel: 'events/:eventId/cancelled',
    eventCategories: 'events/:eventId/categories',
    eventCategory: 'events/:eventId/categories/:categoryId',
    eventCategoryHistogram: 'events/:eventId/categoryHistogram',
    eventComments: 'events/:eventId/comments',
    eventComment: 'events/:eventId/comments/:commentId',
    eventSubComment: 'events/:eventId/comments/:parentId',
    eventCreators: 'events/:eventId/creators',
    eventExternalEvents: 'events/:eventId/externalEvents',
    eventExternalHosts: 'events/:eventId/externalHosts',
    eventExternalInvites: 'events/:eventId/externalInvites',
    eventExternalInviteCounts: 'events/:eventId/externalInvites/counts',
    eventHostComments: 'events/:eventId/hostComments',
    eventHostComment: 'events/:eventId/hostComments/:commentId',
    eventHostSubComment: 'events/:eventId/hostComments/:parentId',
    eventHosts: 'events/:eventId/hosts',
    eventHost: 'events/:eventId/hosts/:userId',
    eventImageUrls: 'events/:eventId/imageUrls',
    eventInvitableFriends: 'events/:eventId/invitableFriends',
    eventInvitableManagers: 'events/:eventId/invitableManagers',
    eventInvitableSubscribers: 'events/:eventId/invitableSubscribers',
    eventInviteAllSubscribers: 'events/:eventId/inviteAllSubscribers',
    eventInviteDoorGuestList: 'events/:eventId/inviteBusinessGuestList/:doorGuestListId',
    eventInviteFriend: 'events/:eventId/inviteFriend/:userId',
    eventInviteManager: 'events/:eventId/inviteManager/:userId',
    eventInviteMe: 'events/:eventId/inviteMe',
    eventInvites: '/events/:eventId/invites',
    eventInvitesCount: 'events/:eventId/invites/counts',
    eventInvite: 'events/:eventId/invites/:userId',
    moveEvent: 'events/:eventId/invites/:userId/move',
    eventInviteSubscriber: 'events/:eventId/inviteSubscriber/:userId',
    eventInviteGuestList: 'events/:eventId/inviteUserGuestList/:guestListId',
    eventMyInvite: 'events/:eventId/myInvite',
    eventPresenterManagerRequests: 'events/:eventId/presenterManagerRequests',
    eventPresenterManagerRequest: 'events/:eventId/presenterManagerRequests/:doorId',
    eventPresenterManagerRequestRespond: 'events/:eventId/presenterManagerRequests/:doorId/respond/:status',
    eventPresenters: 'events/:eventId/presenters',
    eventPresenter: 'events/:eventId/presenters/:doorId',
    eventRevenue: 'events/:eventId/revenue',
    exchange: 'exchange',
    externalEventsProvider: 'externalEvents/:provider/:providerEventId',
    facebookFriends: '/connections/facebook/:providerUserId/facebookFriends',
    feed: 'feed',
    flares: 'flare/invites',
    gcalPush: 'gcal/push',
    hostedEvents: 'hostedEvents',
    invites: 'invites',
    login: 'login',
    logout: 'logout',
    logoutEverywhere: 'logoutEverywhere',
    me: 'me',
    meImageUrls: 'me/imageUrls',
    meCoverImageUrls: 'me/coverImageUrls',
    profile: 'profile',
    notifications: 'notifications',
    notification: 'notifications/:notificationId',
    notifyDevices: 'notifyDevices',
    notificationTallies: 'notificationTallies',
    notificationTally: 'notificationTally',
    reactivate: 'reactivate',
    realtimeSubscription: 'realtime/facebook/:subscription',
    verifySubscription: 'realtime/facebook/:subscription?hub.mode=subscribe',
    recommendDoor: 'users/:userId/businessRecommendations/:doorId',
    recommendCategoriesAttendance: 'recommendCategories/attendance',
    recommendCategoriesHistogram: 'recommendCategories/histogram',
    resetPassword: 'resetPassword',
    revivableExternalEvents: 'revivableExternalEvents/:provider/:providerUserId',
    revivableExternalEvent: 'reviveExternalEvent/:provider/:providerUserId/:providerEventId',
    resendPasswordEmail: 'sendResetPasswordMail',
    seriesEvents: 'series/:seriesId/events',
    seriesCategories: 'series/:seriesId/categories',
    settings: 'settings',
    email: 'profile/email',
    seriesSubscriptions: 'seriesSubscriptions',
    subUser: 'subUser',
    subscriptions: 'subscriptions',
    superCategories: 'superCategories',
    superSubCategories: 'superCategories/:superCategoryId/categories',
    superCategoryLink: 'superCategoryLink/:superCategoryId/:categoryId',
    switchUser: 'switchUser/:userId',
    traveledTrips: 'traveledTrips',
    trips: 'trips',
    trip: 'trips/:tripId',
    tripLegs: 'trips/:tripId/legs',
    tripLeg: 'trips/:tripId/legs/:tripLegId',
    tripLegEvents: 'trips/:tripId/legs/:tripLegId/events',
    tripLegTravelers: 'trips/:tripId/legs/:tripLegId/travelers',
    tripLegTraveler: 'trips/:tripId/legs/:tripLegId/travelers/:userId',
    tripLegViewers: 'trips/:tripId/legs/:tripLegId/viewers',
    tripLegViewer: 'trips/:tripId/legs/:tripLegId/viewers/:userId',
    tripTravelers: 'trips/:tripId/travelers',
    tripTraveler: 'trips/:tripId/travelers/:userId',
    tripTravelerLegs: 'trips/:tripId/traveler/:userId/legs',
    tripViewers: 'trips/:tripId/viewers',
    tripViewer: 'trips/:tripId/viewers/:userId',
    tripViewerLegs: 'trips/:tripId/viewer/:userId/legs',
    userCategories: 'userCategories',
    userCategory: 'userCategories/:categoryId',
    users: 'users',
    user: 'users/:userId',
    userProfile: 'users/:userId/profile',
    userGuestLists: 'guestLists',
    userGuestList: 'guestLists/:listId',
    userGuestListGuests: 'guestLists/:listId/guests',
    userGuestListGuest: 'guestLists/:listId/guests/:userId',
    userFriends: 'users/:userId/friends',
    userMutualFriends: 'users/:userId/mutualFriends',
    userSubscriptions: 'users/:userId/subscriptions',
    userUserCategories: 'users/:userId/userCategories',
    venues: 'venues',
    venue: 'venues/:venueId',
    venueEvents: 'venues/:venueId/events',
    venueCoverImageUrls: 'venues/:venueId/coverImageUrls',
    venueFloors: 'venues/:venueId/floors',
    venueFloor: 'venues/:venueId/floors/:floorId',
    venueFloorImageUrls: 'venues/:venueId/floors/:floorId/imageUrls',
    venueFloorRooms: 'venues/:venueId/floors/:floorId/rooms',
    venueFloorRoom: 'venues/:venueId/floors/:floorId/rooms/:roomId',
    viewableTrips: 'viewableTrips',
  },
}

class UserLoggedIn {
  constructor() {
    this.userLoggedIn = false
  }
  getLoggedIn() {
    return this.userLoggedIn
  }
  toggleLoggedInOff() {
    this.userLoggedIn = false
  }
  toggleLoggedInOn() {
    this.userLoggedIn = true
  }
}

let LoggedIn = new UserLoggedIn()

function getMapAvatar(latitude, longitude) {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=13&size=100x100&maptype=roadmap&style=element:labels%7Cvisibility:off&style=feature:administrative.neighborhood%7Cvisibility:off&style=feature:road.arterial%7Celement:labels%7Cvisibility:off&style=feature:road.highway%7Celement:labels%7Cvisibility:off&style=feature:road.local%7Cvisibility:off&key=AIzaSyCAep0ycor0rd4rBQ3Vll9IYhLOLyruSu8`
}

function getBase64FromImage(url, onSuccess, onError) {
  const xhr = new XMLHttpRequest()

  xhr.responseType = "arraybuffer"
  xhr.open("GET", url)

  xhr.onload = function () {
    var base64, binary, bytes, mediaType;

    bytes = new Uint8Array(xhr.response);
    //NOTE String.fromCharCode.apply(String, ...
    //may cause "Maximum call stack size exceeded"
    binary = [].map.call(bytes, function (byte) {
      return String.fromCharCode(byte);
    }).join('');
    mediaType = xhr.getResponseHeader('content-type');
    base64 = [
      'data:',
      mediaType ? mediaType + ';':'',
      'base64,',
      btoa(binary)
    ].join('');
    onSuccess(base64);
  };
  xhr.onerror = onError
  xhr.send()
}


function getMapBoxStatic(props) {
  const {
    latitude,
    longitude,
    showPin = false,
    pins = [],
    pitch = 0,
    height = '600',
    width = '600',
    zoom = '15',
    style = 'mapbox/streets-v11'
  } = props
  let encodedGeoJSON
  let beaconString = ''
  pins.filter(pin => pin.host).forEach((pin, i) => {
    getBase64FromImage(i === 0 ? sharon : jack, (uri) => {
      beaconString += `,url-${uri}(${pin.longitude},${pin.latitude})`
    })
  })
  const geojson = {
    "type": "FeatureCollection",
    "features": pins.filter(pin => !pin.host).map(pin => {
      return {
        "type": "Feature",
        "properties": {
          "marker-color": "#E02126"
        },
        "geometry": {
          "type": "Point",
          "coordinates": [pin.longitude, pin.latitude]
        }
      }
    })
  }
  if (showPin) {
    geojson.features.unshift({
      'type': 'Feature',
      "properties": {},
      'geometry': {
        'type': 'Point',
        'coordinates': [longitude, latitude]
      }
    })
  }

  if (showPin || (pins && pins.length > 0)) {
    encodedGeoJSON = `geojson(${encodeURIComponent(JSON.stringify(geojson))})`
  }

  return `https://api.mapbox.com/styles/v1/${style}/static/${encodedGeoJSON && encodedGeoJSON.length > 0 ? `${encodedGeoJSON}/` : ''}${beaconString}${longitude},${latitude},${zoom},0,${pitch}/${width}x${height}@2x?access_token=pk.eyJ1Ijoid29sZnBpZ2VvbiIsImEiOiJja2RnYmNxZ2MxenRrMzNsMHRlbnJ5Y3d1In0.gXNgMZwEGBUSYzx6ghoKgQ`
}

export { Globals, LoggedIn, getMapAvatar, getMapBoxStatic }
