import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CacheBuster from '../CacheBuster'
import history from '../history'
import styled from 'styled-components'
import { useLocation, Redirect } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { MobileGrid } from '../components/layout/layout'
import { selectCurrentUser } from '../selectors/UsersSelectors'
import { selectApp } from '../selectors/AppSelectors'
import SideMenuContainer from './SideMenuContainer'
import { DMSubHeader } from '../components/headers/HeaderComponents'
import { UserTitle } from '../components/headers/HeaderComponents'
import Routes from './routes'
import { Globals } from '../constants/Global'
import LandingContainer from './LandingContainer'
import Icon from '@material-ui/core/Icon'
import FullScreenDialogContainer from './Dialogs/FullScreenDialogContainer'
import { setUserLocation } from '../actions/UI/AppActions'
import ERROR from '../assets/images/error.svg'
import { version } from '../../package.json'

const styles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    margin: '0 auto !important',
    position: 'relative',
    overflowX: 'hidden',
    backgroundColor: Globals.colors.bgColor,
    flexGrow: 1,
  },
  toolbar: {
    width: '100%',
    margin: '0 auto !important',
    position: 'relative',
  },
  flex: {
    fontSize: 18,
  },
}))

const ErrorPage = styled.div`
  height: 100%;
  width: 100%;
  background-image: url("${ERROR}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
`

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const LogoIcon = styled(Icon)`
  margin-right: 16px;
  font-size: 2.2rem;
`

const LogoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
`

class ErrorWrapper extends React.Component {
  constructor() {
    super()
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidMount() {
    history.listen(location => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        })
      }
    })
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />
    } else {
      return this.props.children
    }
  }
}

const App = () => {
  const classes = styles()
  const user = useSelector(selectCurrentUser)
  const app = useSelector(selectApp)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      dispatch({
        type: 'STORE_INSTALL_PROMPT',
        payload: e
      })
    })
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(setUserLocation({
          latitude: position.coords.latitude.toString(),
          longitude: position.coords.longitude.toString(),
        }))
      }, () => {
        dispatch({
          type: 'USER_LOCATION_ERROR',
        })
      }, { timeout: 4000 })
    }
  }, [dispatch])

  if (user.appRefreshed) {
    return <div />
  }

  const isProduction = process.env.NODE_ENV === 'production'
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
    >
      <StyledApp className="App">
        {!user.userLoggedIn && location.pathname === '/' ? (
          <LandingContainer />
        ) : (
          <>
            <AppBar elevation={0} position="static">
              <MobileGrid enablePadding>
                <Toolbar disableGutters>
                  {user.userLoggedIn ? app.leftIcon : null}
                  <LogoContainer>
                    <LogoIcon className="icon-doormouse-key" />
                    <Typography
                      variant="h1"
                      color="inherit"
                      className={classes.flex}
                    >
                      DoorMouse
                    </Typography>
                  </LogoContainer>
                  {user.userLoggedIn && user.settings && user.settings.emailVerified && <UserTitle style={{ marginTop: 6 }} user={user} />}
                  {user.userLoggedIn && user.settings && user.settings.emailVerified ? (
                    <div style={{ float: 'right' }}>{app.rightIcons}</div>
                  ) : null}
                </Toolbar>
              </MobileGrid>
            </AppBar>
            <DMSubHeader subHeader={app.subHeader} tabs={app.tabs} />
            {user.userLoggedIn ? <SideMenuContainer /> : null}
            {!app.showLoginModal ? (
              <ErrorWrapper>
                <div className={classes.root + ' app-container'}>
                  <Routes />
                </div>
              </ErrorWrapper>
            ) : null}
            {app.showLoginModal ? <Redirect to="/login" /> : null }
          </>
        )}
        <FullScreenDialogContainer />
      </StyledApp>
    </CacheBuster>
  )
}

export default App
