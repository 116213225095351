import { createSelector } from 'reselect'

export const selectEvents = createSelector(
  state => state.events,
  propState => propState
)

export const selectHosts = createSelector(
  state => state.eventHosts,
  propState => propState
)

export const selectInvites = createSelector(
  state => state.invites,
  propState => propState
)

export const selectExternalInvites = createSelector(
  state => state.externalInvites,
  propState => propState
)

export const selectEventCategories = createSelector(
    state => state.events,
    propState => propState.categories || []
)

export const selectLoadedEvents = createSelector(
  state => state.events,
  propState => propState.loadedItems ? Object.values(propState.loadedItems) : []
)

export const selectLoadedEvent = (id) => createSelector(
  state => state.events,
  propState => propState.loadedItems[id]
)
