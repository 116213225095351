import ActionConstants from '../constants/ActionConstants'
import * as dataUtils from '../utils/dataUtils'

const commentsConst = ActionConstants.comments

function comments(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    byId: {},
    allIds: [],
    errorMessage: null,
  },
  action
) {
  let items
  let result
  let { response } = action
  let comments
  let parentComment
  switch (action.type) {
  case commentsConst.clearItems:
    return {
      ...state,
      byId: {},
      allIds: [],
    }
    case commentsConst.selectItem:
      return {
        ...state,
        selectedItems: dataUtils.insertItem(state.selectedItems, action.item, state.selectedItems.length),
      }
    case commentsConst.clearTempItems:
      return {
        ...state,
        selectedItems: [],
      }
    case commentsConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case commentsConst.requestItem:
    case commentsConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case commentsConst.receiveItems:
      items = response.entities.comments
      result = response.result
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        byId: items,
        allIds: result,
        lastUpdated: action.receivedAt,
      }
    case commentsConst.receiveChildItems:
      items = response.entities.comments
      result = response.result
      comments = { ...state.byId, ...items }
      parentComment = comments[action.item.id]
      if (parentComment) {
        parentComment.children = result
      }
      return {
        ...state,
        byId: comments,
      }
    case commentsConst.confirmAddChildItem:
      items = response.entities.comments
      result = response.result
      comments = { ...state.byId, [result]: items[result] }
      parentComment = comments[action.parent]
      if (!parentComment.children) {
        parentComment.children = [result]
      } else {
        parentComment.children = dataUtils.insertItem(parentComment.children, result, parentComment.children.length)
      }
      return {
        ...state,
        byId: comments,
        isFetching: false,
      }
    case commentsConst.sendAddItem:
    case commentsConst.sendDeleteItem:
    case commentsConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case commentsConst.itemFailed:
    case commentsConst.itemsFailed:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case commentsConst.confirmAddItem:
      items = response.entities.comments
      result = response.result
      return {
        ...state,
        byId: {
          ...state.byId,
          [result]: items[result],
        },
        allIds: dataUtils.insertItem(state.allIds, result, state.allIds.length),
        isFetching: false,
      }
    default:
      return state
  }
}

export default comments
