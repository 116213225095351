import React, { Component, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Field } from 'redux-form'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import request from '../../request'
import _ from 'underscore'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Paper from '@material-ui/core/Paper'
import Business from '@material-ui/icons/Business'
import Person from '@material-ui/icons/Person'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Globals } from '../../constants/Global'
import { insertItem, removeItem } from '../../utils/dataUtils'
import { formatLocation } from '../../utils/formatUtils'
import './Inputs.scss'
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor'
import createHashtagPlugin from 'draft-js-hashtag-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin'
import createMentionPlugin from 'draft-js-mention-plugin'
import { EditorState, convertToRaw, convertFromRaw, RichUtils } from 'draft-js'
import 'draft-js-mention-plugin/lib/plugin.css'
import 'draft-js-inline-toolbar-plugin/lib/plugin.css'
import { formatUrl } from '../../utils/RESTUtils'

const hashtagPlugin = createHashtagPlugin()
const linkifyPlugin = createLinkifyPlugin()
const inlineToolbarPlugin = createInlineToolbarPlugin()

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 1000,
    background: Globals.colors.gray['A100'],
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
  placesInput: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  icon: {
    color: Globals.colors.gray['A100'],
  },
})

const hookStyles = makeStyles(theme => ({
  chipSelected: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: Globals.colors.gray['500'],
    border: `2px solid ${Globals.colors.purple['500']}`,
  },
  chipDeleteIcon: {
    color: Globals.colors.purple['500'],
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
      color: Globals.colors.purple['500'],
    },
  },
  chipAvatar: {
    color: Globals.colors.gray['A100'],
    fontSize: '1.1rem',
  },
  userChipContainer: {
    margin: '8px 0',
  },
  userChip: {
    marginRight: 8,
  },
}))

class DMIconField extends Component {
  render() {
    const {
      icon,
      icoClass,
      iconPosition,
      meta: { error, touched },
      inputType,
      input,
      placeholder,
      autoFocus,
      classes,
      ...rest
    } = this.props
    let inp = input || ''
    if (iconPosition === 'left') {
      return (
        <div style={{ display: 'table', width: '100%' }}>
          <div style={{ display: 'table-cell', verticalAlign: 'middle', width: '30px', paddingTop: '5px' }}>
            <Icon style={{ fontSize: '20px' }} className={icoClass} />
          </div>
          <div style={{ display: 'table-cell' }}>
            <FormControl className={classes.textField} error={error && touched}>
              <Input
                classes={{
                  input: classes.placesInput,
                }}
                {...inp}
                {...rest}
                placeholder={placeholder}
                className={classes.input}
                autoFocus={autoFocus}
              />
              {error && touched ? <FormHelperText>{error}</FormHelperText> : null}
            </FormControl>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ width: '100%' }}>
          <FormControl className={classes.textField} error={error && touched}>
            <Input
              classes={{
                input: classes.placesInput,
              }}
              {...inp}
              {...rest}
              placeholder={placeholder}
              className={classes.input}
              autoFocus={autoFocus}
            />
            {error && touched ? <FormHelperText>{error}</FormHelperText> : null}
          </FormControl>
          <Icon
            style={{ position: 'absolute', top: '10px', right: '17px', color: Globals.colors.primary['500'] }}
            className={icoClass}
          />
        </div>
      )
    }
  }
}

const DMCheckBox = withStyles({
  root: {
    '&$checked': {
      color: Globals.colors.warning['500'],
    },
  },
  checked: {},
})((props) => (
  <Checkbox color="default" {...props} checkedIcon={<Icon style={{ fontSize: '1.35rem', margin: '2.5px 0 0 2.5px' }} className="fa fa-square" />} />
))

const Suggestion = props => {
  const { description, isHighlighted, query, renderSubItems, subText, suggestion } = props
  const matches = match(suggestion[description], query)
  const parts = parse(suggestion[description], matches)
  const [stateSubItems, setStateSubItems] = useState('')
  useEffect(() => {
    if (subText) {
      setStateSubItems(renderSubItems(subText(props)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ListItem button selected={isHighlighted} component="div">
      <ListItemText
        primary={parts.map((part, index) => {
          return part.highlight ? (
            <span key={index} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={index} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          )
        })}
        secondary={stateSubItems}
      />
    </ListItem>
  )
}

let typingTimer = null

class DMAutosuggest extends Component {
  constructor(props) {
    super()
    this.state = {
      value: props.value || '',
      suggestions: [],
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value) {
      this.setState({
        value: newProps.value,
      })
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(this.props.getSuggestions(value).then(items => {
      this.setState({
        suggestions: items,
      })
    }), 300)
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  handleChange = (event, { newValue }) => {
    this.props.onChange(newValue)
    this.setState({
      value: newValue,
    })
  }

  renderInput = inputProps => {
    const { classes, autoFocus, icon, iconPosition, placeholder, inputRef, error, helperText, value, readOnly, ...other } = inputProps
    return (
      <Grid container spacing={icon ? 1 : 0} alignItems="flex-end" style={{ width: '100%', margin: 0 }}>
        {iconPosition === 'left' && icon && <Grid item>{icon}</Grid>}
        <Grid item style={{ width: icon ? 'calc(100% - 32px)' : '100%' }}>
          <FormControl className={classes.textField} error={!!error}>
            <Input
              classes={{
                input: classes.placesInput,
              }}
              inputRef={inputRef}
              value={value}
              readOnly={readOnly}
              {...other}
              placeholder={placeholder}
              className={classes.input}
              autoFocus={autoFocus}
            />
            {error ? <FormHelperText>{helperText}</FormHelperText> : null}
          </FormControl>
        </Grid>
        {iconPosition === 'right' && icon && <Grid item>{icon}</Grid>}
      </Grid>
    )
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <Suggestion
        description={this.props.description}
        isHighlighted={isHighlighted}
        query={query}
        renderSubItems={this.props.renderSubItems}
        subText={this.props.subText}
        suggestion={suggestion}
      />
    )
  }

  renderSuggestionsContainer = options => {
    const { containerProps, children } = options
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    )
  }

  render() {
    const {
      autoFocus,
      classes,
      error,
      helperText,
      placeholder,
      disabled,
      disableUnderline,
      readOnly,
      renderSuggestion,
      renderSuggestionsContainer,
      icon,
      inputRef,
      iconPosition,
      startAdornment,
    } = this.props
    return (
      <span>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={this.renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer || this.renderSuggestionsContainer}
          renderSuggestion={renderSuggestion || this.renderSuggestion}
          getSuggestionValue={this.props.getSuggestionValue}
          inputProps={{
            autoFocus,
            error,
            readOnly,
            disabled,
            classes,
            disableUnderline,
            icon,
            iconPosition,
            inputRef,
            placeholder: placeholder || '',
            value: this.state.value,
            onChange: this.handleChange,
            helperText,
            startAdornment,
          }}
        />
      </span>
    )
  }
}

DMAutosuggest.defaultProps = {
  autoFocus: false,
  iconPosition: 'left',
  onChange: () => {},
  renderSubItems: () => '',
  subText: null,
  value: '',
  renderSuggestion: null,
  renderSuggestionsContainer: null,
}

DMAutosuggest = withStyles(styles)(DMAutosuggest)

const renderPlacesField = ({ input, label, meta: { touched, error }, ...custom }) => (
  <InputBox errorText={touched && error ? error : null} hintText={label} {...input} {...custom} />
)

class DMLocations extends Component {
  render() {
    const { id, name, updateLocation, label, latLong, ...rest } = this.props
    return (
      <Field
        id={id}
        name={name}
        component={renderPlacesField}
        updateLocation={updateLocation}
        placeholder={label}
        latLong={latLong}
        {...rest}
      />
    )
  }
}

export class OpenStreetMapSearch {
  constructor(options = { providerKey: null, searchBounds: [] }) {
    let { searchBounds } = options
    //Bounds are expected to be a nested array of [[sw_lat, sw_lng],[ne_lat, ne_lng]].
    // We convert them into a string of 'x1,y1,x2,y2' which is the opposite way around from lat/lng - it's lng/lat
    this.boundsUrlComponent = ''
    this.regionUrlComponent = ''
    if (searchBounds.length) {
      const reversed = searchBounds.map(el => {
        return el.reverse()
      })
      this.bounds = [].concat([], ...reversed).join(',')
      this.boundsUrlComponent = `&bounded=1&viewbox=${this.bounds}`
    }
    if ('region' in options) {
      this.regionUrlComponent = `&countrycodes=${options.region}`
    }
  }

  async search(query) {
    this.url = `https://nominatim.openstreetmap.org/search?format=json&limit=5&addressdetails=1${this.boundsUrlComponent}${
      this.regionUrlComponent
    }&q=`
    const response = await fetch(this.url + query).then(res => res.json())
    return this.formatResponse(response)
  }

  async reverse(lat, lng) {
    this.url = `https://nominatim.openstreetmap.org/reverse?format=json&addressdetails=1&lat=${lat}&lon=${lng}`
    return await fetch(this.url).then(res => res.json())
  }

  formatResponse(response) {
    const resources = response
    const count = response.length
    const info =
      count > 0
        ? resources.map(e => ({
            description: e.name,
            latitude: Number(e.lat),
            longitude: Number(e.lon),
            address: e.address,
          }))
        : 'Not Found'
    return {
      info: info,
      raw: response,
    }
  }
}

const asyncInputEvent = (asyncHandler, syncHandler) => {
  let t
  return e => {
    syncHandler && syncHandler(e)
    clearTimeout(t)
    t = setTimeout(() => {
      asyncHandler(e)
    }, 400)
  }
}

class InputBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      placeDetailsRequest: null,
      location: props.location,
      info: null,
    }
    this.provider = new OpenStreetMapSearch()
    this.responseCache = {}
    this.inputEventHandler = asyncInputEvent(this.sendToAction, this.syncInput)
    this.lastInfo = null
  }

  componentDidMount() {
    this.setState({
      placesContainer: ReactDOM.findDOMNode(this.refs.placesContainer),
    })
    if (this.props.latLong) {
      this.sendToAction(`:${this.props.latLong.lat},${this.props.latLong.lng}`).then(data => {
        this.setState({
          location: formatLocation(data, this.props.locationFormat || 2).name,
        })
      })
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.latLong && !this.props.latLong) {
      this.sendToAction(`:${newProps.latLong.lat},${newProps.latLong.lng}`).then(data => {
        this.setState({
          location: formatLocation(data, this.props.locationFormat || 2).name,
        })
      })
    }
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    let suggestions = []
    return new Promise((resolve, reject) => {
      if (this.provider && inputLength > 0) {
        let options = {
          params: { q: inputValue },
          data: {},
          query: {},
        }
        if (!this.props.skipVenues) {
          request.get(Globals.api.venues, options).then(
            response => {
              if (response.data.items.length > 0) {
                _.forEach(response.data.items, suggestion => {
                  if (
                    count < 5 &&
                    suggestion.latitude &&
                    (suggestion.name.toLowerCase().slice(0, inputLength) === inputValue ||
                      suggestion.street.toLowerCase().slice(0, inputLength) === inputValue)
                  ) {
                    count += 1
                    suggestion.description = suggestion.name
                    suggestions.push(suggestion)
                  }
                })
                if (this.props.onGetSuggestions) {
                  this.props.onGetSuggestions(suggestions)
                  resolve([])
                } else {
                  resolve(suggestions)
                }
              } else {
                this.sendToAction(inputValue).then(data => {
                  if (data && typeof data !== 'string') {
                    suggestions = data
                      .filter(suggestion => {
                        const keep = count < 5
                        if (keep) {
                          count += 1
                        }
                        return keep
                      })
                      .map(loc => {
                        return formatLocation(loc, 1)
                      })
                    if (this.props.onGetSuggestions) {
                      this.props.onGetSuggestions(suggestions)
                      resolve([])
                    } else {
                      resolve(suggestions)
                    }
                  } else {
                    if (this.props.onGetSuggestions) {
                      this.props.onGetSuggestions(suggestions)
                      resolve([])
                    } else {
                      resolve(suggestions)
                    }
                  }
                })
              }
            },
            error => {
              this.sendToAction(inputValue).then(data => {
                if (typeof data !== 'string') {
                  suggestions = data.filter(suggestion => {
                    let keep = count < 5
                    if (keep) {
                      count += 1
                    }
                    return keep
                  })
                  if (this.props.onGetSuggestions) {
                    this.props.onGetSuggestions(suggestions)
                    resolve([])
                  } else {
                    resolve(suggestions)
                  }
                } else {
                  if (this.props.onGetSuggestions) {
                    this.props.onGetSuggestions(suggestions)
                    resolve([])
                  } else {
                    resolve(suggestions)
                  }
                }
              })
            }
          )
        } else {
          this.sendToAction(inputValue).then(data => {
            if (data && typeof data !== 'string') {
              suggestions = data
                .filter(suggestion => {
                  const keep = count < 5
                  if (keep) {
                    count += 1
                  }
                  return keep
                })
                .map(loc => {
                  return formatLocation(loc, this.props.format || 1)
                })
              if (this.props.onGetSuggestions) {
                this.props.onGetSuggestions(suggestions)
                resolve([])
              } else {
                resolve(suggestions)
              }
            } else {
              if (this.props.onGetSuggestions) {
                this.props.onGetSuggestions(suggestions)
                resolve([])
              } else {
                resolve(suggestions)
              }
            }
          })
        }
      } else {
        if (this.props.onGetSuggestions) {
          this.props.onGetSuggestions(suggestions)
          resolve([])
        } else {
          resolve(suggestions)
        }
      }
    })
  }

  sendToAction = async value => {
    if (value.length >= 1) {
      let response
      if (value.startsWith(':')) {
        response = value
          .slice(1)
          .split(',')
          .filter(e => !isNaN(Number(e)))
          .map(e => Number(e ? e : 0))
        response = await this.provider.reverse(response[0], response[1])
        if (response.error) {
          return false
        }
        return response
      } else {
        response = await this.provider.search(value)
        if (response.error) {
          return false
        }
        return response.raw
      }
    }
  }

  handleLocationSelect = location => {
    if (!_.isUndefined(location.place_id) && !this.props.skipVenues) {
      request.post(Globals.api.venues, location).then(response => {
        if (response.data) {
          this.props.updateLocation(response.data)
        } else {
          this.props.updateLocation(location)
        }
      })
    } else {
      this.props.updateLocation(location)
    }
  }

  getSuggestionValue = suggestion => {
    this.handleLocationSelect(suggestion)
    return suggestion.name
  }

  render() {
    let { inputRef, placeholder, disableUnderline, helperText, disabled, override, readOnly, ...rest } = this.props
    return (
      <div>
        <DMAutosuggest
          error={this.props.error}
          readOnly={readOnly}
          onChange={this.props.onChange}
          getSuggestionValue={this.getSuggestionValue}
          getSuggestions={this.getSuggestions}
          description="description"
          placeholder={placeholder}
          inputRef={inputRef}
          disableUnderline={disableUnderline}
          helperText={helperText}
          disabled={disabled}
          value={this.state.location || override}
          {...rest}
        />
      </div>
    )
  }
}

InputBox.defaultProps = {
  updateLocation: () => {},
}

class ToggleInput extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      value: '',
    }
  }
  handleSearch = e => {
    e.preventDefault()
    let { isOpen, value } = this.state
    if (isOpen && value.length > 0) {
      this.props.handleSubmit(value)
    } else {
      this.setState({
        isOpen: true,
      })
    }
  }

  handleClose = () => {
    this.props.handleClear()
    this.setState({
      value: '',
      isOpen: false,
    })
  }

  handleChange = e => {
    let value = e.target.value
    this.setState({
      value: value,
    })
  }

  render() {
    let { isOpen, value } = this.state
    let { classes, placeholder } = this.props
    return (
      <div className="toggle-input">
        <form onSubmit={this.handleSearch}>
          {isOpen ? (
            <Input onChange={this.handleChange} value={value} placeholder={placeholder} autoFocus={true} />
          ) : null}
          {isOpen ? (
            <IconButton classes={{ root: classes.icon }} onClick={this.handleClose}>
              <Icon className="fa fa-times" />
            </IconButton>
          ) : null}
          <IconButton classes={{ root: classes.icon }} onClick={this.handleSearch}>
            {!isOpen ? <Icon className="fa fa-search" /> : <Icon className="fa fa-check" />}
          </IconButton>
        </form>
      </div>
    )
  }
}

class DMTextEditor extends Component {
  constructor(props) {
    super()
    let value = props.value
    if (props.value && props.value.indexOf('blocks":[') < 0) {
      value = createEditorStateWithText(props.value)
    } else if (!props.value) {
      value = EditorState.createEmpty()
    } else {
      value = EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
    }
    this.state = {
      editorState: value,
      suggestions: [],
    }
    this.mentionPlugin = createMentionPlugin()
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value && newProps.value !== this.props.value) {
      this.setState({
        editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(newProps.value))),
      })
    }
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  onChange = editorState => {
    this.setState({
      editorState,
    })
    this.props.editorChanged(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
  }

  onSearchChange = ({ value }) => {
    this.fetchUsers(value)
  }

  fetchUsers = async query => {
    if (!query) return
    let payload = {
      dataType: 'users',
      url: Globals.api.users,
      params: {
        name: query,
      },
    }
    let bPayload = {
      dataType: 'doors',
      url: Globals.api.doors,
      params: {
        q: query,
      },
    }
    const inputValue = query.trim().toLowerCase()
    const inputLength = inputValue.length
    let users = await this.props.fetchTempContacts(payload)
    let doors = await this.props.fetchItemsNonNormal(bPayload)
    if (users.items && doors.items) {
      let count = 0
      let suggestions = [...users.items, ...doors.items]
        .filter(suggestion => {
          const keep = count < 5 && suggestion.name.toLowerCase().slice(0, inputLength) === inputValue
          if (keep) {
            count += 1
          }
          return keep
        })
        .map(s => {
          return { name: s.name, id: s.id, avatar: s.imageUrl, isDoor: s.hasOwnProperty('owner') }
        })
      this.setState({
        suggestions: suggestions,
      })
    }
  }

  mentionComponent = props => {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line no-unused-vars
      isFocused, // eslint-disable-line no-unused-vars
      ...parentProps
    } = props
    return (
      <ListItem button {...parentProps} dense selected={isFocused}>
        {mention.avatar && (
          <ListItemAvatar>
            <Avatar alt={mention.name} src={mention.avatar} />
          </ListItemAvatar>
        )}
        {!mention.avatar && <ListItemIcon>{!mention.isDoor ? <Person /> : <Business />}</ListItemIcon>}
        <ListItemText primary={mention.name} />
      </ListItem>
    )
  }

  handleOnFocus = () => {
    this.setState({
      focused: true,
    })
  }

  handleOnBlur = () => {
    this.setState({
      focused: false,
    })
  }

  render() {
    const { MentionSuggestions } = this.mentionPlugin
    const plugins = [hashtagPlugin, linkifyPlugin, inlineToolbarPlugin, this.mentionPlugin]
    return (
      <div style={{ color: Globals.colors.standard, lineHeight: 1.5 }} className={`editor ${this.props.readOnly && 'read-only'} ${this.state.focused && 'focus'}`}>
        <Editor
          {...this.props}
          disabled={this.props.readOnly}
          editorState={this.state.editorState}
          handleKeyCommand={this.handleKeyCommand}
          onChange={this.onChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          plugins={plugins}
          ref={element => {
            this.editor = element
          }}
        />
        <MentionSuggestions
          entryComponent={this.mentionComponent}
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
        />
      </div>
    )
  }
}

/*const DMTextEditor = ({ editorChanged, readOnly, value, ...rest }) => {
  let stateValue
  if (value && value.indexOf('blocks":[') < 0) {
    stateValue = createEditorStateWithText(value)
  } else if (!value) {
    stateValue = EditorState.createEmpty()
  } else {
    stateValue = EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
  }
  const [editorState, setEditorState] = useState(stateValue)
  const [suggestions, setSuggestions] = useState([])
  const [focused, setFocused] = useState(false)
  const mentionPlugin = createMentionPlugin()
  const { InlineToolbar } = inlineToolbarPlugin
  const { MentionSuggestions } = mentionPlugin
  const plugins = [hashtagPlugin, linkifyPlugin, inlineToolbarPlugin, mentionPlugin]
  const dispatch = useDispatch()
  const editorRef = useRef()

  const fetchUsers = async query => {
    if (!query) return
    let payload = {
      dataType: 'users',
      url: Globals.api.users,
      params: {
        name: query,
      },
    }
    let bPayload = {
      dataType: 'doors',
      url: Globals.api.doors,
      params: {
        q: query,
      },
    }
    const inputValue = query.trim().toLowerCase()
    const inputLength = inputValue.length
    let users = await dispatch(fetchTempContacts(payload))
    let doors = await dispatch(fetchItemsNonNormal(bPayload))
    if (users.items && doors.items) {
      let count = 0
      let suggestions = [...users.items, ...doors.items]
        .filter(suggestion => {
          const keep = count < 5 && suggestion.name.toLowerCase().slice(0, inputLength) === inputValue
          if (keep) {
            count += 1
          }
          return keep
        })
        .map(s => {
          return { name: s.name, id: s.id, avatar: s.imageUrl, isDoor: s.hasOwnProperty('owner') }
        })
      setSuggestions(suggestions)
    }
  }

  const onChange = newEditorState => {
    setEditorState(newEditorState)
    editorChanged(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())))
  }

  const onSearchChange = ({ value }) => {
    fetchUsers(value)
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      onChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const mentionComponent = mProps => {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line no-unused-vars
      isFocused, // eslint-disable-line no-unused-vars
      ...parentProps
    } = mProps
    return (
      <ListItem button {...parentProps} dense selected={isFocused}>
        {mention.avatar && (
          <ListItemAvatar>
            <Avatar alt={mention.name} src={mention.avatar} />
          </ListItemAvatar>
        )}
        {!mention.avatar && <ListItemIcon>{!mention.isDoor ? <Person /> : <Door />}</ListItemIcon>}
        <ListItemText primary={mention.name} />
      </ListItem>
    )
  }

  const handleOnFocus = () => {
    setFocused(true)
  }

  const handleOnBlur = () => {
    setFocused(false)
  }

  return (
    <div className={`editor ${readOnly && 'read-only'} ${focused && 'focus'}`}>
      <Editor
        {...rest}
        disabled={readOnly}
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        onChange={onChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        plugins={plugins}
        ref={editorRef}
      />
      <InlineToolbar />
      <MentionSuggestions
        entryComponent={mentionComponent}
        onSearchChange={onSearchChange}
        suggestions={suggestions}
      />
    </div>
  )
}*/

class DMPasswordField extends Component {
  constructor(props) {
    super()
    this.state = {
      showPassword: false,
      error: false,
      message: props.message,
      upperError: true,
      lowerError: true,
      specialError: true,
      lengthError: true,
    }
  }

  handleChange = e => {
    let value = e.target.value
    this.props.handleChange(value)
    this.validate(value)
  }

  validate = value => {
    let upper = new RegExp(/(?=.*[A-Z])/g)
    let lower = new RegExp(/(?=.*[a-z])/g)
    let special = new RegExp(/(?=.*[\W])/g)
    let lengthTest = value.length >= 10
    this.setState({
      upperError: !upper.test(value),
      lowerError: !lower.test(value),
      specialError: !special.test(value),
      lengthError: !lengthTest,
    })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    let { value, handleChange, fullWidth, classes, ...rest } = this.props
    let { showPassword, message, upperError, lowerError, specialError, lengthError } = this.state
    return (
      <span>
        <FormControl style={{ width: fullWidth ? '100%' : 'initial' }}>
          <Input
            {...rest}
            name="any-filed-name-here-password"
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            value={value}
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{message}</FormHelperText>
        </FormControl>
        <List dense={true}>
          <ListItem className={classes.listItem}>
            <ListItemText primary="At least one lowercase letter" />
            <ListItemSecondaryAction>
              <DMCheckBox checked={!lowerError} tabIndex={-1} edge="end" />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="At least one uppercase letter" />
            <ListItemSecondaryAction>
              <DMCheckBox checked={!upperError} tabIndex={-1} edge="end" />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="At least one special character" />
            <ListItemSecondaryAction>
              <DMCheckBox checked={!specialError} tabIndex={-1} edge="end" />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="At least ten characters long" />
            <ListItemSecondaryAction>
              <DMCheckBox checked={!lengthError} tabIndex={-1} edge="end" />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </span>
    )
  }
}

const DMUserSearch = ({
  autoFocus,
  disableUnderline,
  enableFriendRequest,
  filter,
  handleRequest,
  handleSelect,
  placeholder,
  searchParams,
  searchUrl,
  storeLocal,
  type,
  ...rest
}) => {
  const [selectedUsers, setSelectedUsers] = useState([])
  const classes = hookStyles()
  const url = formatUrl(searchUrl, searchParams)
  const getSuggestionValue = suggestion => {
    let newArray
    newArray = insertItem(selectedUsers, suggestion, 0)
    if (storeLocal) {
      setSelectedUsers(newArray)
    }
    handleSelect(newArray)
    return ''
  }
  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    let suggestions = []
    return new Promise(resolve => {
      if (inputLength > 0) {
        request.get(url, { params: { name: inputValue } }).then(response => {
          const data = response && response.data && response.data.items
          if (data) {
            resolve(
              data.filter(sug => {
                const suggestion = sug.user || sug
                const userIndex = _.findIndex(selectedUsers, {
                  id: suggestion.id,
                })
                const filterIndex = _.findIndex(filter, {
                  id: suggestion.id
                })
                const filterFriends = type !== 'allUsers'
                  && (suggestion.friendStatus === 'SELF'
                  || suggestion.friendStatus === 'FRIENDS'
                  || suggestion.friendStatus === 'SENT')
                const keep = count < 5
                  && suggestion.name.toLowerCase().slice(0, inputLength) === inputValue
                  && userIndex === -1
                  && filterIndex === -1
                  && !filterFriends
                if (keep) {
                  count += 1
                }
                return keep
              }).map(item => {
                if (item.user) {
                  return item.user
                }
                return item
              })
            )
          } else {
            resolve(suggestions)
          }
        })
      } else {
        return suggestions
      }
    })
  }

  const handleOnRequest = (e, user) => {
    e.preventDefault()
    e.stopPropagation()
    handleRequest(user)
  }

  const handleRenderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <ListItem button>
        <ListItemAvatar>
          <div>
            {suggestion.imageUrl && <Avatar alt={suggestion.name} src={suggestion.imageUrl} />}
            {!suggestion.imageUrl && <Avatar>{suggestion.name.slice(0, 1)}</Avatar>}
          </div>
        </ListItemAvatar>
        <ListItemText primary={suggestion.name} />
        {enableFriendRequest && (
          <ListItemSecondaryAction>
            <Button color="primary" variant="text" onClick={(e) => handleOnRequest(e, suggestion)}>
              Send Request
            </Button>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    )
  }
  const handleUserClick = user => {}
  const handleDeleteUser = user => {
    let newArray
    const userIndex = _.findIndex(selectedUsers, {
      id: user.id,
    })
    if (userIndex > -1) {
      newArray = removeItem(selectedUsers, userIndex)
      setSelectedUsers(newArray)
      handleSelect(newArray)
    }
  }
  return (
    <>
      <div className={classes.userChipContainer}>
        {selectedUsers.map(user => {
          const avatar = user.imageUrl ? (
            <Avatar alt={user.name} src={user.imageUrl} />
          ) : (
            <Avatar>{user.name.slice(0, 1).toUpperCase()}</Avatar>
          )
          return (
            <Chip
              avatar={avatar}
              key={user.id}
              label={user.name}
              onClick={() => handleUserClick(user)}
              onDelete={() => handleDeleteUser(user)}
            />
          )
        })}
      </div>
      <DMAutosuggest
        autoFocus={autoFocus}
        description="name"
        disableUnderline={disableUnderline}
        getSuggestions={getSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={handleRenderSuggestion}
        placeholder={placeholder}
        {...rest}
      />
    </>
  )
}

DMIconField = withStyles(styles)(DMIconField)

ToggleInput = withStyles(styles)(ToggleInput)

DMPasswordField = withStyles(styles)(DMPasswordField)

DMTextEditor.defaultProps = {
  editorChanged: () => {},
  value: '',
}

DMIconField.defaultProps = {
  icoClass: '',
  inputType: 'text',
  iconPosition: 'left',
  meta: {
    error: undefined,
    touched: false,
  },
}

ToggleInput.defaultProps = {
  placeholder: 'Search...',
  handleSubmit: () => {},
}

DMPasswordField.defaultProps = {
  value: '',
  handleChange: () => {},
  message: '',
}

DMUserSearch.defaultProps = {
  filter: [],
  searchUrl: Globals.api.users,
  searchParams: {},
  handleSelect: () => null,
  type: 'allUsers',
  storeLocal: true,
}

export {
  DMIconField,
  DMCheckBox,
  InputBox,
  ToggleInput,
  DMAutosuggest,
  DMLocations,
  DMTextEditor,
  DMPasswordField,
  DMUserSearch,
}
