import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import GridListTile from '@material-ui/core/GridListTile'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import EditIcon from '@material-ui/icons/Edit'
import { Globals } from '../../constants/Global'
import _ from 'underscore'
import ColorThief from '../../utils/colorThief'
import InvitesCounts from '../views/invite/InvitesCounts'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoadedEvent } from '../../selectors/EventsSelectors'
import { getMapBoxStatic } from '../../constants/Global'
import { makeStyles } from '@material-ui/core/styles'
import { openFullScreenDialog } from '../../actions/UI/AppActions'
import { selectCurrentUser } from '../../selectors/UsersSelectors'
import { loadEvent } from '../../actions/EventDetailsActions'
import { selectCurrentLocation } from '../../selectors/AppSelectors'
import Skeleton from '@material-ui/lab/Skeleton'

const styles = makeStyles(() => ({
  cardHeader: {
    color: Globals.colors.gray['A100'],
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
  },
  cardHeaderContent: {
    overflow: 'hidden',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    padding: 0,
  },
}))

const StyledDMCard = styled(Card)`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-bottom: ${props => props.type === 'profile' ? '65px' : '0'};
  overflow: visible;
  border-radius: 3px;
`

const StyledCardFooter = styled(CardContent)`
  max-height: 100px;
  min-height: 100px;
  text-overflow: ellipsis;
  white-space: normal;
  box-sizing: border-box;
`

const OverflowText = styled(Typography)`
  position: relative;
  max-height: 70px;
  overflow: hidden;
  padding-right: 1rem;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }
`

const DMCardContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DMCardContentTypography = styled(Typography)`
  color: ${props => props.bgcolor} !important;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DMCardContentSubtitle = styled(Typography)`
  opacity: 0.75;
  font-family: "RobotoSlab", serif;
  font-size: 12px;
  color: ${props => props.bgcolor} !important;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProfileImage = styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: -10px;
  left: 15px;
  margin: 0 0 -40px 0;
  border: ${props => (!props.imageUrl ? 'none' : `3px solid ${Globals.colors.black}`)};
  background: ${props => (props.imageUrl ? `url(${props.imageUrl}) no-repeat center center` : 'none')};
  background-color: ${Globals.colors.gray['A100']};
  background-size: cover;
`

const colors = {
  1: {
    bg: Globals.colors.gray['A100'],
    tileBg: Globals.colors.primary['500'],
    title: Globals.colors.primary['700'],
  },
  2: {
    bg: Globals.colors.gray['A100'],
    tileBg: Globals.colors.error['500'],
    title: Globals.colors.error['700'],
  },
  3: {
    bg: Globals.colors.gray['A100'],
    tileBg: Globals.colors.secondary['500'],
    title: Globals.colors.secondary['700'],
  },
}

const FeedCardExpanded = ({ event, titleColor }) => {
  const dispatch = useDispatch()
  const eventId = event && event.id
  const loadedEvent = useSelector(selectLoadedEvent(eventId))
  useEffect(() => {
    if (!loadedEvent || (loadedEvent && !loadedEvent.isLoaded)) {
      dispatch(loadEvent({ eventId, type: 'feedCard' }))
    }
  }, [dispatch, eventId, loadedEvent])

  return (
    <div style={{
      backgroundColor: titleColor,
      borderBottomLeftRadius: '3px',
      borderBottomRightRadius: '3px',
    }}>
      <div style={{ padding: '0 0 0 16px' }}>
        {loadedEvent && loadedEvent.isLoaded && <InvitesCounts event={loadedEvent} showCounts={false} remainderTextColor={Globals.colors.gray['A100']} type="feedCard" />}
      </div>
    </div>
  )
}

const DMCard = props => {
  const {
    icon,
    header,
    item,
    content,
    title,
    subtitle,
    type,
    cellHeight,
    cellWidth,
    inviteContent,
    handleClick,
  } = props
  const imageUrl = type && (type === 'profile' || type === 'venue') ? item.coverImageUrl : item.imageUrl
  const style = {
    height: cellHeight || 0,
    width: cellWidth || '100%',
  }
  const [titleColor, setTitleColor] = useState(Globals.colors.black)
  const [bgColor, setBgColor] = useState(Globals.colors.gray['A100'])
  const [tileBg, setTileBg] = useState(Globals.colors.primary['500'])
  const cardRef = useRef()
  const currentUser = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  const classes = styles()

  useEffect(() => {
    if (imageUrl) {
      const colorThief = new ColorThief()
      colorThief.getColorFromUrl(imageUrl, color => {
        if (!color) {
          const pick = _.random(1, 3)
          const newColor = colors[pick]
          setTitleColor(newColor.title)
        } else {
          setTitleColor(`rgba(${color[0] * 0.8},${color[1] * 0.8},${color[2] * 0.8}, 1.0)`)
        }
      })
    } else {
      setColors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl])

  const setColors = () => {
    const pick = _.random(1, 3)
    const color = colors[pick]
    setTitleColor(color.title)
    setBgColor(color.bg)
    setTileBg(color.tileBg)
  }
  let imageSrc
  if (item.imageUrl || item.coverImageUrl) {
    if (type && (type === 'profile' || type === 'door' || type === 'venue')) {
      imageSrc = item.coverImageUrl
    } else {
      imageSrc = item.imageUrl
    }
  } else if (item.venue && item.venue.latitude && item.venue.longitude) {
    imageSrc = getMapBoxStatic({ latitude: item.venue.latitude, longitude: item.venue.longitude, showPin: true })
  }

  const handleClickCard = () => {
    if (handleClick) {
      handleClick(item)
      return
    }
    dispatch(openFullScreenDialog({ dialog: 'eventDetails' }, `/events/${item.id}`))
    /*const cardElem = cardRef.current

    if (cardElem && type === 'feedCard') {
      const boundingRect = cardElem.getBoundingClientRect()
      const dummyElem = cardElem.cloneNode(true)
      const childNodes = dummyElem.childNodes[1].childNodes[0]
      dummyElem.style.height = `${boundingRect.height}px`
      dummyElem.style.width = `${boundingRect.width}px`
      dummyElem.style.position = 'fixed'
      dummyElem.style.top = `${boundingRect.top}px`
      dummyElem.style.left = `${boundingRect.left}px`
      dummyElem.style.zIndex = '10000'
      dummyElem.style.transition = '150ms ease all'
      childNodes.style.tansition = '150ms ease all'
      document.getElementsByTagName('body')[0].append(dummyElem)
      dispatch(openFullScreenDialog({ dialog: 'eventDetails' }, `/events/${item.id}`))
      setTimeout(() => {
        if (matchXS) {
          dummyElem.style.width = '100vw'
          dummyElem.style.left = '0px'
          dummyElem.style.top = '104px'
        } else {
          dummyElem.style.width = '66.66vw'
          dummyElem.style.left = 'calc(100vw / 12 * 2)'
          dummyElem.style.top = '112px'
        }
        childNodes.setAttribute('height', '175px')

        setTimeout(() => {
          dummyElem.remove()
        }, 150)
      }, 10)
    }*/
  }

  const renderCardFooter = () => {
    let footerContent = null
    if (type && type === 'profile' && !content) {
      return footerContent
    } else if (type && type === 'door') {
      footerContent = (
        <StyledCardFooter style={{ backgroundColor: bgColor, padding: '12px 16px', color: titleColor }}>
          <OverflowText variant="body1">
            {content}
          </OverflowText>
        </StyledCardFooter>
      )
    } else if (!type || (type && type !== 'feedCard')) {
      footerContent = (
        <DMCardContent style={{ backgroundColor: titleColor, padding: '12px 16px', color: bgColor }}>
          {icon && <div style={{ marginRight: 8 }}>{icon}</div>}
          <div style={{ flexGrow: 1 }}>
            <DMCardContentTypography noWrap variant="subtitle1" component="div" bgcolor={bgColor}>
              {title}
            </DMCardContentTypography>
            <DMCardContentSubtitle noWrap variant="body2" component="div" bgcolor={bgColor}>
              {subtitle}
            </DMCardContentSubtitle>
            {inviteContent && <div className="invite-content">{inviteContent}</div>}
          </div>
          {(type === 'venue' && item.owner && item.owner.id === currentUser.info.id) && (
            <Button
              style={{
                color: Globals.colors.gray['A100'],
                textTransform: 'capitalize',
                fontSize: 12,
              }}
              variant="text"
              endIcon={<EditIcon />}
              onClick={() => dispatch(openFullScreenDialog({ dialog: 'editVenue', venue: item }))}
            >
              Edit Details
            </Button>
          )}
        </DMCardContent>
      )
    }
    return footerContent
  }
  return (
    <>
      <StyledDMCard
        type={type}
        ref={cardRef}
        style={{
          borderBottomLeftRadius: type === 'feedCard' ? '0' : '3px',
          borderBottomRightRadius: type === 'feedCard' ? '0' : '3px',
        }}
      >
        {header && (
          <CardHeader
            classes={{
              subheader: classes.cardHeader,
              content: classes.cardHeaderContent,
            }}
            style={{
              backgroundColor: titleColor,
              padding: '12px 16px',
              color: bgColor,
            }}
            title={header.title && <Typography variant="h6" noWrap style={{ color: Globals.colors.gray['A100'] }}>{header.title}</Typography>}
            action={header.action}
            subheader={header.subheader && <Typography variant="body1" noWrap style={{ color: Globals.colors.gray['A100'], fontSize: 12 }}>{header.subheader}</Typography>}
            onClick={(!header || !header.action) ? handleClickCard : undefined}
          />
        )}
        {imageSrc ? (
          <CardActionArea onClick={handleClickCard} style={{ maxHeight: 300, overflow: 'hidden' }}>
            <CardMedia
              className={classes.media}
              alt={item.name}
              image={imageSrc}
              title={item.name}
            />
          </CardActionArea>
        ) : type === 'venue' ? null : (
          <CardContent onClick={handleClickCard} style={{ backgroundColor: tileBg, height: style.height }} />
        )}
        {renderCardFooter()}
        {type && type === 'profile' && <ProfileImage imageUrl={item.imageUrl} />}
        {(type && type === 'feedCard') && (
          <FeedCardExpanded onClick={handleClickCard} event={item} titleColor={titleColor} />
        )}
      </StyledDMCard>
    </>
  )
}

const SubscribeCard = props => {
  const { event, name, frequency, description, imageUrl, handleClick, cellHeight, cellWidth, borderRadius } = props
  const [titleColor, setTitleColor] = useState(Globals.colors.black)
  const [bgColor, setBgColor] = useState(Globals.colors.gray['A100'])

  useEffect(() => {
    if (imageUrl) {
      const colorThief = new ColorThief()
      colorThief.getColorFromUrl(imageUrl, color => {
        setTitleColor(`rgba(${color[0] * 0.8},${color[1] * 0.8},${color[2] * 0.8}, 1.0)`)
      })
    } else {
      setColors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setColors = () => {
    const colors = {
      1: {
        bg: Globals.colors.primary['500'],
        title: Globals.colors.primary['700'],
      },
      2: {
        bg: Globals.colors.error['500'],
        title: Globals.colors.error['700'],
      },
    }
    const pick = _.random(1, 2)
    const color = colors[pick]
    setTitleColor(color.title)
    setBgColor(color.bg)
  }

  return (
    <>
      <GridListTile
        style={{
          cursor: 'pointer',
          width: cellWidth || '100%',
          height: cellHeight || '100%',
          marginBottom: '0',
          borderRadius: borderRadius === false ? '0' : '8px',
        }}
        onClick={() => handleClick(event)}
      >
        {imageUrl ? (
          <img alt={event.name} src={imageUrl} onError={this.setColors} />
        ) : (
          <div style={{ height: cellHeight || '100%', width: '100%', backgroundColor: bgColor }} />
        )}
      </GridListTile>
      <div style={{ backgroundColor: titleColor, padding: '8px' }}>
        <div style={{ float: 'left', width: '75%' }}>
          <Typography variant="subtitle1" style={{ color: bgColor }}>
            {name}
          </Typography>
          <Typography variant="body1" style={{ color: bgColor }}>
            {frequency}
          </Typography>
        </div>
        <div style={{ float: 'right', width: '25%' }}>
          <Button variant="outlined">Subscribe!</Button>
        </div>
        <div style={{ clear: 'both', width: '100%', paddingTop: '10px' }}>
          <Typography variant="body2" style={{ color: Globals.colors.gray['A100'] }}>
            {description}
          </Typography>
        </div>
      </div>
    </>
  )
}

const DoorCard = props => {
  const { event, name, description, imageUrl, handleClick, cellHeight, cellWidth, borderRadius } = props
  const [titleColor, setTitleColor] = useState(Globals.colors.black)
  const [bgColor, setBgColor] = useState(Globals.colors.gray['A100'])
  useEffect(() => {
    if (imageUrl) {
      const colorThief = new ColorThief()
      colorThief.getColorFromUrl(imageUrl, color => {
        setTitleColor(`rgba(${color[0] * 0.8},${color[1] * 0.8},${color[2] * 0.8}, 1.0)`)
      })
    } else {
      setColors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const setColors = () => {
    const colors = {
      1: {
        bg: Globals.colors.primary['500'],
        title: Globals.colors.primary['700'],
      },
      2: {
        bg: Globals.colors.error['500'],
        title: Globals.colors.error['700'],
      },
    }
    const pick = _.random(1, 2)
    const color = colors[pick]
    setTitleColor(color.title)
    setBgColor(color.bg)
  }

  return (
    <>
      <GridListTile
        style={{
          cursor: 'pointer',
          width: cellWidth || '100%',
          height: cellHeight || '100%',
          marginBottom: '0',
          borderRadius: borderRadius === false ? '0' : '8px',
        }}
        onClick={() => handleClick(event)}
      >
        {imageUrl ? (
          <img alt={name} src={imageUrl} onError={setColors} />
        ) : (
          <div style={{ height: cellHeight || '100%', width: '100%', backgroundColor: bgColor }} />
        )}
      </GridListTile>
      <div style={{ backgroundColor: titleColor, padding: '8px' }}>
        <div style={{ float: 'left', width: '75%' }}>
          <Typography variant="subtitle1" style={{ color: bgColor }}>
            {name}
          </Typography>
        </div>
        <div style={{ float: 'right', width: '25%' }}>
          <Button variant="outlined">Subscribe!</Button>
        </div>
        <div style={{ clear: 'both', width: '100%', paddingTop: '10px' }}>
          <Typography variant="body2" style={{ color: bgColor }}>
            {description}
          </Typography>
        </div>
      </div>
    </>
  )
}

const NearbyCard = ({ events }) => {
  const [showMap, setShowMap] = useState(false)
  const [containerWidth, setContainerWidth] = useState(null)
  const currentLocation = useSelector(selectCurrentLocation)
  const currentUser = useSelector(selectCurrentUser)
  const paperRef = useRef()
  const history = useHistory()
  let latitude
  let longitude
  if (currentLocation && currentLocation.latitude && currentLocation.longitude) {
    latitude = currentLocation.latitude
    longitude = currentLocation.longitude
  } else if (currentUser && currentUser.settings && currentUser.settings.homeLat && currentUser.settings.homeLong) {
    latitude = currentUser.settings.homeLat
    longitude = currentUser.settings.homeLong
  }

  const pins = events.filter(event =>
    event.venue && event.venue.latitude && event.venue.longitude).map(event =>
  {
    return {
      latitude: event.venue.latitude,
      longitude: event.venue.longitude,
      host: event.flare && event.hosts && event.hosts.length && event.hosts[0],
    }
  })

  useEffect(() => {
    if (events && paperRef && paperRef.current && currentLocation) {
      setContainerWidth(Math.floor(paperRef.current.getBoundingClientRect().width))
      setShowMap(true)
    }
  }, [events, paperRef, currentLocation])

  return (
    <Paper onClick={() => history.push('feed/map#0')} ref={paperRef} elevation={0} style={{ cursor: 'pointer', height: 200, marginBottom: 15 }}>
      {!showMap && <Skeleton variant="rect" height={200} width={'100%'} animation="wave" />}
      {showMap && latitude && longitude && (
        <img style={{ height: 200, width: '100%' }}
             src={
               getMapBoxStatic({
                  latitude,
                  longitude,
                  pins,
                  pitch: 60,
                  height: 200,
                  width: containerWidth,
                  zoom: 15,
                  style: 'wolfpigeon/ckdgdokex04iq1imh7lneo576',
             })}
             alt="Nearby Events"/>
      )}
    </Paper>
  )
}

DMCard.defaultProps = {
  borderRadius: true,
  disableCardPadding: false,
  handleClick: () => null,
}

export { DMCard, SubscribeCard, DoorCard, NearbyCard }
