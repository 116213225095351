import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const friendsConst = ActionConstants.eventInvitableFriends
const managersConst = ActionConstants.eventInvitableManagers
const subscribersConst = ActionConstants.eventInvitableSubscribers
const hostsConst = ActionConstants.eventHosts

function eventHosts(
  state = {
    isFetching: false,
    lastUpdated: null,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case hostsConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case hostsConst.requestItem:
    case hostsConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case hostsConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case hostsConst.receiveItem:
    case hostsConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case hostsConst.sendAddItem:
    case hostsConst.sendDeleteItem:
    case hostsConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case hostsConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case hostsConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case hostsConst.itemFailed:
    case hostsConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function eventInvitableFriends(
  state = {
    isFetching: false,
    lastUpdated: null,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case friendsConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case friendsConst.requestItem:
    case friendsConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case friendsConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case friendsConst.receiveItem:
    case friendsConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case friendsConst.sendAddItem:
    case friendsConst.sendDeleteItem:
    case friendsConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case friendsConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case friendsConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case friendsConst.itemFailed:
    case friendsConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function eventInvitableManagers(
  state = {
    isFetching: false,
    lastUpdated: null,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case managersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case managersConst.requestItem:
    case managersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case managersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case managersConst.receiveItem:
    case managersConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case managersConst.sendAddItem:
    case managersConst.sendDeleteItem:
    case managersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case managersConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case managersConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case managersConst.itemFailed:
    case managersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function eventInvitableSubscribers(
  state = {
    isFetching: false,
    lastUpdated: null,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case subscribersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case subscribersConst.requestItem:
    case subscribersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case subscribersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case subscribersConst.receiveItem:
    case subscribersConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case subscribersConst.sendAddItem:
    case subscribersConst.sendDeleteItem:
    case subscribersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case subscribersConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case subscribersConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case subscribersConst.itemFailed:
    case subscribersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export { eventHosts, eventInvitableFriends, eventInvitableManagers, eventInvitableSubscribers }
