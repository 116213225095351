import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rsvpToEvent } from '../../../actions/MapEventsActions'
import { Globals } from '../../../constants/Global'
import {
  fetchEventExternalInvites,
  fetchEventInvites,
  fetchExternalInvitesCounts,
  fetchInvitesCounts, inviteMeToPublic,
} from '../../../actions/EventDetailsActions'
import _ from 'underscore'
import { fetchUserInvite } from '../../../actions/UserActions'
import { inviteFriend } from '../../../actions/ContactsActions'
import Grid from '@material-ui/core/Grid'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Check from '@material-ui/icons/Check'
import NotInterested from '@material-ui/icons/NotInterested'
import Star from '@material-ui/icons/Star'
import FullscreenDialog from '../../../containers/Dialogs/FullscreenDialog'
import SearchInvites from '../../../containers/Search/SearchInvites'
import InviteContactItem from '../../../containers/Contacts/InviteContactItem'
import { MobileGrid } from '../../layout/layout'
import FilterBar from '../../Search/FilterBar'
import Typography from '@material-ui/core/Typography'
import { selectUserGuestListHash } from '../../../selectors/ContactsSelectors'
import { makeStyles } from '@material-ui/core/styles'
import { selectFullScreenDialog } from '../../../selectors/AppSelectors'
import { useHistory } from 'react-router-dom'
import { openFullScreenDialog } from '../../../actions/UI/AppActions'

const styles = makeStyles(theme => ({
  root: {
    [`${theme.breakpoints.down('sm')}`]: {
      paddingTop: 56,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      paddingTop: 48,
    },
    [`${theme.breakpoints.up('sm')}`]: {
      paddingTop: 64,
    },
    height: '100%',
    position: 'relative',
  }
}))

const InvitesPane = ({ event, type }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [checked, setChecked] = useState([])
  const [onlyShowChecked, setOnlyShowChecked] = useState(false)
  const [selectedListId, setSelectedListId] = useState(null)
  const friendsListsHash = useSelector(selectUserGuestListHash)
  const fullscreenDialogs = useSelector(selectFullScreenDialog)
  const selectedList = friendsListsHash[selectedListId || 'allContacts']
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = styles()
  const { userInvite, invitableFriends } = event
  let userInviteText = 'RSVP'
  if (userInvite && userInvite.status) {
    switch (userInvite.status) {
    case 'YES':
      userInviteText = "Going"
      break
    case 'NO':
      userInviteText = "Not Going"
      break
    case 'MAYBE':
      userInviteText = "Maybe"
      break
    default:
      userInviteText = 'RSVP'
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleRespondItemClick = status => {
    if (!userInvite || (userInvite && !userInvite.status)) {
      dispatch(inviteMeToPublic({ path: { eventId: event.id } })).then(() => {
        dispatch(fetchUserInvite({ eventId: event.id })).then(response => {
          dispatch(rsvpToEvent(Globals.api.eventInvites, { status }, { eventId: event.id })).then(() => {
            dispatch(fetchEventInvites({ eventId: event.id }))
            dispatch(fetchEventExternalInvites({ eventId: event.id }))
            dispatch(fetchInvitesCounts({ eventId: event.id }))
            dispatch(fetchExternalInvitesCounts({ eventId: event.id }))
            dispatch(fetchUserInvite({ eventId: event.id }))
          })
        })
      })
    } else {
      dispatch(rsvpToEvent(Globals.api.eventInvites, { status }, { eventId: event.id })).then(() => {
        dispatch(fetchEventInvites({ eventId: event.id }))
        dispatch(fetchEventExternalInvites({ eventId: event.id }))
        dispatch(fetchInvitesCounts({ eventId: event.id }))
        dispatch(fetchExternalInvitesCounts({ eventId: event.id }))
        dispatch(fetchUserInvite({ eventId: event.id }))
      })
    }

    setAnchorEl(null)
  }

  const handleMainButtonClick = () => {
    let newStatus
    if (userInvite && userInvite.status) {
      switch (userInvite.status) {
      case 'YES':
        newStatus = "Going"
        break
      case 'NO':
        newStatus = "Not Going"
        break
      case 'MAYBE':
        newStatus = "Maybe"
        break
      default:
        newStatus = 'RSVP'
      }
    }

    handleRespondItemClick(newStatus)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDrawer = () => {
    dispatch(openFullScreenDialog({ dialog: 'inviteFriends' }))
  }

  const handleCloseDrawer = () => {
    history.goBack()
    setSelectedListId(null)
    setOnlyShowChecked(false)
  }

  const submitInvites = () => {
    if (checked.length > 0) {
      for (let i = 0; i < checked.length; i++) {
        const user = checked[i]
        dispatch(inviteFriend('invites', Globals.api.eventInviteFriend, user, {
          eventId: event.id,
          userId: user.id,
        }))
      }
    }
    history.goBack()
  }

  const handleClickFriend = (friend) => {
    setChecked([...checked, friend])
  }

  const handleToggle = (user) => {
    const currentIndex = _.findIndex(checked, {
      id: user.id
    })
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(user)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const handleApplyFilters = (payload) => {
    setSelectedListId(payload.selectedListId)
    setOnlyShowChecked(payload.onlyShowChecked)
  }

  const handleDeleteFilter = (filter) => {
    if (filter.id === 'onlyShowChecked') {
      setOnlyShowChecked(false)
    }
    if (filter.id === 'selectedList') {
      setSelectedListId(null)
    }
  }

  let filters = []
  if (onlyShowChecked) {
    filters.push({ id: 'onlyShowChecked', name: 'Only Show Checked' })
  }
  if (selectedListId && selectedList.id !== 'allContacts') {
    filters.push({ id: 'selectedList', name: selectedList.name })
  }

  let inviteableFriendsFiltered = invitableFriends
  if (onlyShowChecked) {
    inviteableFriendsFiltered = inviteableFriendsFiltered.filter(friend => {
      const friendIndex = _.findIndex(checked, {
        id: friend.id
      })
      if (friendIndex > -1) {
        return friend
      } else {
        return false
      }
    })
  }
  if (selectedList && selectedList.id !== 'allContacts') {
    inviteableFriendsFiltered = inviteableFriendsFiltered.filter(friend => {
      return selectedList.friends[friend.id]
    })
  }

  return (
    <>
      <Grid container justifyContent="space-between" style={{ width: type !== 'feedCard' ? '100%' : 'initial' }}>
        <Grid item>
          <ButtonGroup
            disableElevation
            variant="contained"
            color="primary"
            style={{
              backgroundColor: Globals.colors.gray['A100'],
              borderColor: Globals.colors.gray['500'],
            }}
          >
            <Button
              color="secondary"
              variant="outlined"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMainButtonClick}
              style={{
                backgroundColor: Globals.colors.gray['A100'],
                borderColor: Globals.colors.gray['500'],
              }}
            >
              {userInviteText}
            </Button>
            <Button
              variant="outlined"
              style={{
                backgroundColor: Globals.colors.gray['A100'],
                borderColor: Globals.colors.gray['500'],
                color: Globals.colors.gray['500'],
              }}
              size="small"
              onClick={handleClick}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleRespondItemClick('YES')}>
              <Check style={{ marginRight: 8 }} /> Going
            </MenuItem>
            <MenuItem onClick={() => handleRespondItemClick('NO')}>
              <NotInterested style={{ marginRight: 8 }} /> Not Going
            </MenuItem>
            <MenuItem onClick={() => handleRespondItemClick('MAYBE')}>
              <Star style={{ marginRight: 8 }} /> Maybe
            </MenuItem>
          </Menu>
        </Grid>
        {(invitableFriends && invitableFriends.length > 0 && type !== 'feedCard') && (
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleOpenDrawer()}
              style={{
                backgroundColor: Globals.colors.gray['A100'],
                borderColor: Globals.colors.gray['500'],
              }}
            >
              send invites
            </Button>
          </Grid>
        )}
      </Grid>
      {(fullscreenDialogs && fullscreenDialogs.inviteFriends && fullscreenDialogs.inviteFriends.mounted) && (
        <FullscreenDialog
          onClose={handleCloseDrawer}
          open={!!(fullscreenDialogs && fullscreenDialogs.inviteFriends && fullscreenDialogs.inviteFriends.open)}
          staticSubheader
          subHeader={
            <SearchInvites
              event={event}
              items={invitableFriends}
              onApplyFilters={handleApplyFilters}
              onClickFriend={handleClickFriend}
              onlyShowChecked={onlyShowChecked}
              selectedListId={selectedListId}
            />}
          submitIcon={
            <Button
              onClick={submitInvites}
              variant="contained"
              color="secondary"
            >
              Done
            </Button>
          }
          title={(!checked || (checked && checked.length === 0)) ? 'Invite Friends' : `Invite ${checked.length} ${checked.length === 1 ? 'Friend' : 'Friends'}`}
        >
          <div className={classes.root}>
            {(onlyShowChecked || (selectedListId && selectedList.id !== 'allContacts')) && (
              <FilterBar filters={filters} onDelete={handleDeleteFilter} />
            )}
            <MobileGrid fullHeight paper>
              {inviteableFriendsFiltered && inviteableFriendsFiltered.map(friend => {
                const index = _.findIndex(checked, {
                  id: friend.id
                })
                return (
                  <InviteContactItem
                    friend={friend}
                    key={friend.id}
                    onChecked={handleToggle}
                    selected={index > -1}
                  />
                )
              })}
              {(!inviteableFriendsFiltered || (inviteableFriendsFiltered && inviteableFriendsFiltered.length === 0)) && (
                <Typography variant="h1">No Friends match those filters!</Typography>
              )}
            </MobileGrid>
          </div>
        </FullscreenDialog>
      )}
    </>
  )
}

export default InvitesPane
