import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const connectionConst = ActionConstants.connections

function connections(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case connectionConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case connectionConst.requestItem:
    case connectionConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case connectionConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case connectionConst.receiveItem:
    case connectionConst.confirmEditItem:
      index = _.findIndex(state.items, connection => {
        return connection.providerUserId === action.response.providerUserId
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case connectionConst.sendAddItem:
    case connectionConst.sendDeleteItem:
    case connectionConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case connectionConst.confirmDeleteItem:
      index = _.findIndex(state.items, connection => {
        return connection.providerUserId === action.response.providerUserId
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case connectionConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case connectionConst.itemFailed:
    case connectionConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export default connections
