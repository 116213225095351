import React from 'react'
import PropTypes from 'prop-types'
import { useWidth } from '../../store/providers'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { selectApp } from '../../selectors/AppSelectors'

const CardGrid = ({
  items,
  singleColumn,
}) => {
  const width = useWidth()
  const viewMode = useSelector(selectApp).viewMode

  let cols
  switch (width) {
  case 'lg':
    cols = 4
    break
  case 'md':
    cols = 6
    break
  case 'sm':
    cols = 12
    break
  case 'xs':
    cols = 12
    break
  default:
    cols = 12
  }
  if (viewMode === 'mobile' || singleColumn) {
    cols = 12
  }

  return (
    <Grid container spacing={1}>
      {items.map((item, i) => (
        <Grid item xs={cols} key={i}>
          {item}
        </Grid>
      ))}
    </Grid>
  )
}

CardGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  singleColumn: PropTypes.bool,
}

CardGrid.defaultProps = {
  singleColumn: false,
}

export default CardGrid
