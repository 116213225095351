import React, { useEffect, useState } from 'react'
import { getMapBoxStatic, Globals } from '../../constants/Global'
import { useDispatch } from 'react-redux'
import ColorThief from '../../utils/colorThief'
import { openFullScreenDialog } from '../../actions/UI/AppActions'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import { formatDate, formatTime } from '../../utils/formatUtils'
import InvitesCounts from '../../components/views/invite/InvitesCounts'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import _ from 'underscore'
import Cover0 from '../../assets/images/0-coverImage.svg'
import Cover1 from '../../assets/images/1-coverImage.svg'
import Cover2 from '../../assets/images/2-coverImage.svg'
import Cover3 from '../../assets/images/3-coverImage.svg'

const styles = makeStyles(() => ({
  cardHeaderRoot: {
    padding: '12px 16px',
    minHeight: 74,
    boxSizing: 'border-box',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  cardHeader: {
    color: Globals.colors.gray['A100'],
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
  },
  mediaMap: {
    height: 0,
    paddingTop: '56.25%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  cardHeaderContent: {
    overflow: 'hidden',
  },
}))

const NoPictureIcon = styled(Icon)`
  font-size: 60px;
`

const NoPictureText = styled(Typography)`
  color: ${Globals.colors.standard};
  font-size: 18px;
  font-family: 'RobotoSlab', serif;
`

const NoPictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 0;
  padding-top: 28.125%;
  padding-bottom: 28.125%;
  max-height: 300px;
  color: ${Globals.colors.standard};
`

const StyledDMCard = styled(Card)`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-bottom: 0;
  overflow: visible;
`

const CardLoadingFooter = styled.div`
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
`

const CardLoadingFooterLeft = styled.div`
  flex-grow: 1;
`

const bgImages = {
  0: Cover0,
  1: Cover1,
  2: Cover2,
  3: Cover3,
}

const EventCard = ({ event }) => {
  const { imageUrl, name, startTime, venue, series } = event
  const [bgColor, setBgColor] = useState(Globals.colors.primary['500'])
  const [isLoaded, setIsLoaded] = useState(false)
  const dispatch = useDispatch()
  let imageSrc = imageUrl
  if (!imageSrc && series && series.imageUrl) {
    imageSrc = series.imageUrl
  }

  const classes = styles()

  useEffect(() => {
    if (imageSrc) {
      const colorThief = new ColorThief()
      colorThief.getColorFromUrl(imageSrc, color => {
        if (color) {
          setBgColor(`rgba(${color[0] * 0.8},${color[1] * 0.8},${color[2] * 0.8}, 1.0)`)
        }
        setIsLoaded(true)
      })
    } else {
      setIsLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!imageSrc && venue && venue.latitude && venue.longitude) {
    imageSrc = imageSrc = getMapBoxStatic({ latitude: venue.latitude, longitude: venue.longitude, showPin: true })
  } else if (!imageSrc) {
    imageSrc = bgImages[_.random(0,3)]
  }

  const handleClickCard = () => {
    dispatch(openFullScreenDialog({ dialog: 'eventDetails' }, `/events/${event.id}`))
  }

  return (
    <StyledDMCard>
      <CardHeader
        classes={{
          root: classes.cardHeaderRoot,
          subheader: classes.cardHeader,
          content: classes.cardHeaderContent,
        }}
        style={{
          backgroundColor: bgColor,
        }}
        title={!isLoaded ? <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} /> : <Typography variant="h6" noWrap style={{ color: Globals.colors.gray['A100'] }}>{name}</Typography>}
        subheader={!isLoaded ? <Skeleton animation="wave" height={10} width="40%" /> : <Typography variant="body1" noWrap style={{ color: Globals.colors.gray['A100'], fontSize: 12 }}>{formatDate(startTime) + ' - ' + formatTime(startTime)}</Typography>}
        onClick={handleClickCard}
      />
      {!isLoaded ? <Skeleton animation="wave" variant="rect" className={classes.media} /> : (
        <>
          {imageSrc ? (
            <CardActionArea onClick={handleClickCard}>
              <CardMedia
                className={!imageUrl && (!series || (series && !series.imageUrl)) ? classes.mediaMap : classes.media}
                alt={event.name}
                image={imageSrc}
                title={event.name}
              />
            </CardActionArea>
          ) : (
            <NoPictureContainer onClick={handleClickCard}>
              <NoPictureIcon className="fa fa-calendar-day" />
              <NoPictureText variant="body2">This Event has no image</NoPictureText>
            </NoPictureContainer>
          )}
        </>
      )}
      <div style={{
        backgroundColor: bgColor,
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '3px',
        minHeight: 55,
      }}>
        {!isLoaded ? (
          <CardLoadingFooter>
            <CardLoadingFooterLeft>
              <Skeleton animation="wave" height={10} width="70%" />
            </CardLoadingFooterLeft>
            <Skeleton variant="rect" width={115} height={36} />
          </CardLoadingFooter>
        ) : (
          <div style={{ padding: '0 0 0 16px' }}>
            <InvitesCounts event={event} showCounts={false} remainderTextColor={Globals.colors.gray['A100']} type="feedCard" />
          </div>
        )}
      </div>
    </StyledDMCard>
  )
}

export default EventCard