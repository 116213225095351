import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Toolbar from '@material-ui/core/Toolbar'
import SearchIcon from '@material-ui/icons/Search'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputBase from '@material-ui/core/InputBase'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Globals } from '../../constants/Global'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import TuneIcon from '@material-ui/icons/Tune'

const styles = makeStyles((theme) => ({
  inputRoot: {
    color: Globals.colors.textColor,
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    boxSizing: 'border-box',
    height: 40,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  menuButton: {
    color: Globals.colors.gray['A100'],
  },
}))

const RootContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

const SearchContainer = styled.div`
  flex-grow: 1;
  align-items: flex-start;
  position: relative;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: ${Globals.colors.gray['A100']};
  width: 100%;
  box-shadow: 0 3px 6px #00000029;
  margin: 10px 0;
`

const SearchIconContainer = styled.div`
  padding: 8px 16px 0 16px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const FilterIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 6px;
`

const ResultsContainer = styled.div`
  height: ${props => `${props.height}px` };
  width: 100%;
  transition: 0.1s linear all;
`

const LoadingContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 52px;
  align-items: center;
`

const ResultsFade = styled.div`
  opacity: ${props => props.open ? 1 : 0};
  transition: 0.1s linear all;
  transition-delay: 0.1s;
`

const SearchBar = ({
  filtersEnabled,
  isSearching,
  onChange,
  onClearSearch,
  onFiltersToggle,
  placeholder,
  resultHeight,
  results,
  searchValue,
}) => {
  const classes = styles()

  const handleToggleFilters = () => {
    if (onFiltersToggle) {
      onFiltersToggle()
    }
  }

  return (
    <>
      <Toolbar disableGutters>
        <RootContainer>
          <SearchContainer>
            <SearchIconContainer>
              <SearchIcon />
            </SearchIconContainer>
            {isSearching && (
              <LoadingContainer>
                <CircularProgress color="primary" size="1rem" />
              </LoadingContainer>
            )}
            {!isSearching && (
              <InputBase
                autoFocus
                endAdornment={
                  <InputAdornment
                    onClick={onClearSearch}
                    position="end"
                  >
                    <div style={{
                      color: searchValue.length > 0 ? Globals.colors.primary['500'] : Globals.colors.gray['300'],
                      cursor: searchValue.length > 0 ? 'pointer' : 'default',
                    }}>
                      <IconButton>
                        <CancelIcon />
                      </IconButton>
                    </div>
                  </InputAdornment>
                }
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                value={searchValue}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            )}
            <ResultsContainer height={results && results.length * resultHeight}>
              <ResultsFade open={results && results.length > 0}>
                {results}
              </ResultsFade>
            </ResultsContainer>
          </SearchContainer>
          {filtersEnabled && (
            <FilterIconContainer>
              <IconButton onClick={handleToggleFilters} className={classes.menuButton}>
                <TuneIcon />
              </IconButton>
            </FilterIconContainer>
          )}
        </RootContainer>
      </Toolbar>
    </>
  )
}

SearchBar.propTypes = {
  filterContent: PropTypes.any,
  filtersEnabled: PropTypes.bool,
  filtersOpen: PropTypes.bool,
  isSearching: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onFiltersToggle: PropTypes.func,
  placeholder: PropTypes.string,
  resultHeight: PropTypes.number,
  results: PropTypes.arrayOf(PropTypes.object),
  searchValue: PropTypes.string.isRequired,
}

SearchBar.defaultProps = {
  filterContent: null,
  filtersEnabled: true,
  filtersOpen: false,
  isSearching: false,
  onFiltersToggle: null,
  placeholder: 'Search',
  resultHeight: 34,
  results: [],
}

export default SearchBar
