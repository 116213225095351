import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Avatar from '@material-ui/core/Avatar'
import Drawer from '@material-ui/core/Drawer'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Typography from '@material-ui/core/Typography'
import Settings from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core/styles'
import { fetchItemsNonNormal } from '../../actions/RESTActions'
import { Globals } from '../../constants/Global'
import history from '../../history'
import { closeSideMenu, openFullScreenDialog } from '../../actions/UI/AppActions'
import { selectCurrentUser } from '../../selectors/UsersSelectors'
import { selectApp } from '../../selectors/AppSelectors'
import { fetchAliases, fetchUserSettings, logout, logoutEverywhere, switchAlias } from '../../actions/UserActions'
import Button from '@material-ui/core/Button'
import Cover0 from '../../assets/images/0-coverImage.svg'
import Cover1 from '../../assets/images/1-coverImage.svg'
import Cover2 from '../../assets/images/2-coverImage.svg'
import Cover3 from '../../assets/images/3-coverImage.svg'
import _ from 'underscore'
import { selectNotificationTallies } from '../../selectors/NotificationsSelectors'

const styles = makeStyles(theme => ({
  SideMenuItemText: {
    color: Globals.colors.success,
  },
  userCard: {
    overflow: 'visible',
    height: 250,
  },
  profile: {
    overflow: 'visible',
    [`${theme.breakpoints.up('sm')}`]: {
      minHeight: '350px',
      height: '100%',
    },
  },
  media: {
    height: 250,
  },
  profileMedia: {
    [`${theme.breakpoints.down('sm')}`]: {
      height: 0,
      paddingTop: '56.25%',
    },
    [`${theme.breakpoints.up('sm')}`]: {
      height: '100%',
      minHeight: '350px',
      paddingTop: 0,
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  userAvatar: {
    height: 48,
    width: 48,
    border: `1px solid ${Globals.colors.userPictureBorder}`,
    cursor: 'pointer',
  },
  userAvatarLarge: {
    height: 75,
    width: 75,
    border: `2px solid ${Globals.colors.userPictureBorder}`,
    cursor: 'default',
    position: 'absolute',
    top: -5,
  },
  aliasAvatar: {
    height: 30,
    width: 30,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  popperClose: {
    pointerEvents: 'none',
  },
  menu: {
    zIndex: '31000 !important',
  }
}))

const StyledSideMenuItem = styled(ListItem)`
  border-left-color: ${Globals.colors.primary['500']};
  border-left-width: 10px;
  border-left-style: solid;
  min-height: 35px;
  line-height: 35px;
  padding-bottom: 5px;
  color: ${Globals.colors.black};
`

const StyledCardContent = styled(CardContent)`
  padding: 0 5px !important;
  background: rgba(255, 255, 255, 0.7);
  height: ${props => !props.profileCard ? '50px' : 'initial'};
`

const ProfileAndSettings = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: ${Globals.colors.black};
  opacity: 0.75;
  height: 30px;
  color: ${Globals.colors.gray['A100']};
  font-size: 12px;
  cursor: pointer;
`

const StyledIcon = styled(Icon)`
  margin-left: 8px;
  margin-right: 8px;
`


const CardContentContainer = styled.div`
  ${props => {
    const { profileCard } = props
    if (profileCard) {
      return `
        margin-top: -72px;
      `
    }
    return `
      margin-top: -50px;
    `
  }}
  position: relative;
`

const UserInfo = styled.div`
  display: inline-flex;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
`

const AliasesContainer = styled.div`
  background-color: ${Globals.colors.gray['50']};
  box-shadow: inset 0 0 15px 5px rgba(0,0,0,0.5);
  padding: 10px 25px;
  width: calc(100% + 30px);
  margin-left: -15px;
  box-sizing: border-box;
`

const NewMenuItem = styled.div`
  width: 100%;
  padding: 10px 0;
`

const NotificationDot = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 4.5px;
  background-color: ${Globals.colors.secondary['A700']};
  margin-right: 3px;
`

const MenuItemContent = styled.div`
  width: calc(100% - 20px);
  cursor: pointer;
  height: 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  
  ${props => {
    const { active } = props
    if (active) {
      return `
        background-color: ${Globals.colors.primary['500']};
        color: ${Globals.colors.gray['A100']};
        
        .material-icons {
          margin-right: 10px;
        }
      `
    } else {
      return `
        background-color: ${Globals.colors.gray['A100']};
        color: initial;
        
        .material-icons {
          margin-right: 10px;
          color: ${Globals.colors.primary['500']}
        }
      `
    }
  }}
`

const randomBgImage = _.random(0,3)

const bgImages = {
  0: Cover0,
  1: Cover1,
  2: Cover2,
  3: Cover3,
}

export const UserCard = ({
  aliasClick,
  onClose,
  onUserClick,
  user,
  profileCard,
}) => {
  const { info, aliases, profile } = user
  const classes = styles()
  const [expanded, setExpanded] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const notificationTallies = useSelector(selectNotificationTallies)
  const imageSrc = info.coverImageUrl || bgImages[randomBgImage]
  const dispatch = useDispatch()
  let tallyContent = ''
  let tallyCount = 0
  let urgentCount = 0
  let aliasCount = 0
  if (aliases) {
    Object.values(notificationTallies).forEach(account => {
      tallyCount += account.unread
      urgentCount += account.unreadUrgent
      if (account.unread > 0 || account.unreadUrgent > 0) {
        aliasCount += 1
      }
    })
    if (tallyCount + urgentCount > 0) {
      if (aliasCount > 0) {
        tallyContent = `${tallyCount + urgentCount} notifications across ${aliasCount === aliases.length ? 'all' : aliasCount} ${aliasCount === 1 && aliases.length !== 1 ? 'alias' : 'aliases'}`
      } else {
        if (urgentCount > 0) {
          tallyContent = `${urgentCount} required actions${tallyCount > 0 ? ' & ' : ''}`
        }
        if (tallyCount > 0) {
          tallyContent += `${tallyCount} notifications`
        }
      }
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleAliasLinkClick = () => {
    onClose()
    if (!aliases || aliases.length === 0) {
      dispatch(openFullScreenDialog({ dialog: 'newAlias' }))
    } else {
      history.push('/profile/aliases')
    }
  }

  return (
    <>
      <Card elevation={expanded || profileCard ? 2 : 0} square className={profileCard ? classes.profile : classes.userCard}>
        <CardMedia
          component="div"
          alt={info.name}
          className={profileCard ? classes.profileMedia : classes.media}
          image={imageSrc}
          title={info.name}
        />
        <CardContentContainer noPicture={!info.coverImageUrl} profileCard={profileCard}>
          <StyledCardContent profileCard={profileCard}>
            <UserInfo>
              <div style={{
                display: 'inline-flex',
                alignItems: 'center',
                minHeight: profileCard ? '72px' : 'initial',
                width: '100%',
              }}>
                <Avatar
                  onClick={onUserClick}
                  className={profileCard ? classes.userAvatarLarge : classes.userAvatar}
                  src={info.imageUrl}
                  alt={info.name}
                />
                <div
                  style={{
                    flexGrow: 1,
                    marginLeft: profileCard ? 90 : 12,
                    overflow: 'hidden',
                  }}
                >
                  <Typography
                    noWrap
                    variant="h1"
                    color="textPrimary"
                    style={{
                      fontSize: 18,
                    }}
                  >
                    {info.name}
                  </Typography>
                  {(profile && profile.altName && profileCard) && (
                    <Typography variant="body2" style={{ fontSize: 14, opacity: 0.75 }}>{profile.altName}</Typography>
                  )}
                  {(tallyContent && tallyContent.length > 0) && (
                    <Typography
                      component="div"
                      noWrap
                      variant="h1"
                      color="textPrimary"
                      style={{
                        fontSize: 9,
                        color: Globals.colors.standard,
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <NotificationDot />
                      {tallyContent}
                    </Typography>
                  )}
                </div>
                {aliasClick && (
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                )}
              </div>
            </UserInfo>
          </StyledCardContent>
        </CardContentContainer>
        {(!profileCard && currentUser.id === user.id) && (
          <ProfileAndSettings onClick={onUserClick}>
            Profile and Settings
            <StyledIcon>
              <Settings />
            </StyledIcon>
          </ProfileAndSettings>
        )}
      </Card>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <AliasesContainer>
          {(aliases && aliases.length > 0) && aliases.map(alias => {
            let tallyContent
            if (notificationTallies[alias.id]) {
              const aliasCounts = notificationTallies[alias.id]
              tallyContent = ''
              if (aliasCounts.unreadUrgent > 0) {
                tallyContent = `${aliasCounts.unreadUrgent} required ${aliasCounts.unreadUrgent === 1 ? 'action' : 'actions'}${aliasCounts.unread > 0 ? ' & ' : ''}`
              }
              if (aliasCounts.unread > 0) {
                tallyContent += `${aliasCounts.unread} ${aliasCounts.unread === 1 ? 'notification' : 'notifications'}`
              }
            }
            return (
              <ListItem alignItems="flex-start" button key={alias.id} onClick={() => aliasClick(alias)}>
                <ListItemAvatar>
                  <Avatar className={classes.aliasAvatar} src={alias.imageUrl} alt={alias.name} />
                </ListItemAvatar>
                <ListItemText primary={alias.name} secondary={tallyContent && (
                  <Typography
                    component="div"
                    noWrap
                    variant="h1"
                    color="textPrimary"
                    style={{
                      fontSize: 9,
                      color: Globals.colors.standard,
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <NotificationDot />
                    {tallyContent}
                  </Typography>
                )} />
              </ListItem>
            )
          })}
          <div style={{ display: 'inline-flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              startIcon={(!aliases || aliases.length === 0) ? <AddIcon /> : null}
              style={{ textTransform: 'lowercase', right: 0 }}
              variant="text"
              color="secondary"
              onClick={handleAliasLinkClick}
            >
              {(!aliases || aliases.length === 0) && 'create aliases'}
              {(aliases && aliases.length > 0) && 'manage aliases'}
            </Button>
          </div>
        </AliasesContainer>
      </Collapse>
    </>
  )
}

UserCard.propTypes = {
  onUserClick: PropTypes.func,
  aliasClick: PropTypes.func,
  onClose: PropTypes.func,
  profileCard: PropTypes.bool,
  user: PropTypes.object.isRequired,
}

UserCard.defaultProps = {
  onUserClick: () => null,
  profileCard: false,
  aliasClick: null,
  onClose: null,
}

const SubMenuItem = ({ primary, ...rest }) => {
  return (
    <StyledSideMenuItem button divider {...rest}>
      <ListItemText primary={primary} />
    </StyledSideMenuItem>
  )
}

SubMenuItem.propTypes = {
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

const SideMenuItemText = ({ primary }) => {
  const classes = styles()
  return (
    <ListItemText
      classes={{
        root: classes.SideMenuItemText,
      }}
      primary={primary}
    />
  )
}

SideMenuItemText.propTypes = {
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

const DMSideMenu = () => {
  const dispatch = useDispatch()
  const app = useSelector(selectApp)
  const user = useSelector(selectCurrentUser)
  const { pathname } = useLocation()

  useEffect(() => {
    dispatch(fetchAliases())
    const payload = {
      dataType: 'doors',
      url: Globals.api.doors,
    }
    dispatch(fetchItemsNonNormal(payload))
    dispatch(fetchUserSettings())
  }, [dispatch])

  const handleClose = () => {
    dispatch(closeSideMenu())
  }

  const handleFeedClick = () => {
    handleClose()
    history.push('/')
  }

  const handleDoorsClick = () => {
    handleClose()
    history.push('/doors')
  }

  const handleScheduleClick = () => {
    handleClose()
    history.push('/schedule')
  }

  const handleContactsClick = () => {
    handleClose()
    history.push('/contacts')
  }

  const handleAliasClick = (alias) => {
    handleClose()
    dispatch(switchAlias({ userId: alias.id }))
  }

  const handleLogout = () => {
    handleClose()
    dispatch(logout())
  }

  const handleLogoutEverywhere = () => {
    handleClose()
    dispatch(logoutEverywhere())
  }

  const handleUserClick = () => {
    handleClose()
    history.push('/profile')
  }

  const handleNearByNowClick = () => {
    handleClose()
    history.push('/feed/map')
  }

  const handleSubscriptionsClick = () => {
    handleClose()
    history.push('/subscriptions')
  }

  /* const handleTripsClick = () => {
    handleClose()
    history.push('/trips')
  } */

  /* const handleDataClick = () => {
    handleClose()
    history.push('/generateData')
  } */

  return (
    <Drawer
      anchor="left"
      onClose={handleClose}
      open={app.open}
      style={{ display: app.currentView === 'Landing' ? 'none' : 'block', zIndex: 30000 }}
    >
      <div role="button" style={{ width: '300px' }} tabIndex={0}>
        {(user && user.info) && <UserCard onClose={handleClose} aliasClick={handleAliasClick} onUserClick={handleUserClick} user={user} /> }
        <NewMenuItem onClick={handleFeedClick}>
          <MenuItemContent active={pathname === '/'}>
            <Icon className="icon-spotlight" />
            <Typography variant="body2" style={{ color: pathname === '/' ? Globals.colors.gray['A100'] : Globals.colors.textColorTear }}>
              Events Discovery
            </Typography>
          </MenuItemContent>
        </NewMenuItem>
        <NewMenuItem onClick={handleNearByNowClick}>
          <MenuItemContent active={pathname === '/feed/map'}>
            <Icon className="icon-bullseye" />
            <Typography variant="body2" style={{ color: pathname === '/feed/map' ? Globals.colors.gray['A100'] : Globals.colors.textColorTear }}>
              Nearby Now
            </Typography>
          </MenuItemContent>
        </NewMenuItem>
        <NewMenuItem onClick={handleScheduleClick}>
          <MenuItemContent active={pathname === '/schedule'}>
            <Icon className="icon-calendar" />
            <Typography variant="body2" style={{ color: pathname === '/schedule' ? Globals.colors.gray['A100'] : Globals.colors.textColorTear }}>
              My Schedule
            </Typography>
          </MenuItemContent>
        </NewMenuItem>
        <NewMenuItem onClick={handleContactsClick}>
          <MenuItemContent active={pathname === '/contacts'}>
            <Icon style={{ width: '1.5em', fontSize: '1.2rem', marginRight: 6 }} className="icon-users" />
            <Typography variant="body2" style={{ color: pathname === '/contacts' ? Globals.colors.gray['A100'] : Globals.colors.textColorTear }}>
              Friends
            </Typography>
          </MenuItemContent>
        </NewMenuItem>
        <NewMenuItem onClick={handleSubscriptionsClick}>
          <MenuItemContent active={pathname === '/subscriptions'}>
            <Icon>
              <NotificationsIcon />
            </Icon>
            <Typography variant="body2" style={{ color: pathname === '/subscriptions' ? Globals.colors.gray['A100'] : Globals.colors.textColorTear }}>
              Subscriptions
            </Typography>
          </MenuItemContent>
        </NewMenuItem>
        <NewMenuItem onClick={handleDoorsClick}>
          <MenuItemContent active={pathname === '/doors'}>
            <Icon className="icon-doors" />
            <Typography variant="body2" style={{ color: pathname === '/doors' ? Globals.colors.gray['A100'] : Globals.colors.textColorTear }}>
              Doors
            </Typography>
          </MenuItemContent>
        </NewMenuItem>
        {/*<NewMenuItem onClick={handleTripsClick}>
          <MenuItemContent active={pathname === '/trips'}>
            <Icon className="icon-globe" />
            <Typography variant="body2" style={{ color: pathname === '/trips' ? Globals.colors.gray['A100'] : Globals.colors.textColorTear }}>
              Trips
            </Typography>
          </MenuItemContent>
        </NewMenuItem>*/}
        {/*<NewMenuItem onClick={handleDataClick}>
          <MenuItemContent active={pathname === '/generateData'}>
            <Icon>
              <Storage />
            </Icon>
            <Typography variant="body2" style={{ color: pathname === '/generateData' ? Globals.colors.gray['A100'] : Globals.colors.textColorTear }}>
              Generate Data
            </Typography>
          </MenuItemContent>
        </NewMenuItem>*/}
        <NewMenuItem onClick={handleLogout}>
          <MenuItemContent>
            <Typography variant="body2" style={{ color: Globals.colors.textColorTear }}>
              Logout
            </Typography>
          </MenuItemContent>
        </NewMenuItem>
        <NewMenuItem onClick={handleLogoutEverywhere}>
          <MenuItemContent>
            <Typography variant="body2" style={{ color: Globals.colors.textColorTear }}>
              Logout everywhere
            </Typography>
          </MenuItemContent>
        </NewMenuItem>
      </div>
    </Drawer>
  )
}

export { SubMenuItem, DMSideMenu }
