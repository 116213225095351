import React from 'react'
import { useSelector } from 'react-redux'
import { Route, useLocation, useHistory, Redirect } from 'react-router-dom'
import loadable from '@loadable/component'
import { PageLoader } from '../components/loading/Loading'
import { selectFullScreenDialog } from '../selectors/AppSelectors'
import { selectCurrentUser } from '../selectors/UsersSelectors'

export const AsyncPage = loadable(props => import(`${props.page}`), {
  fallback: <PageLoader/>,
})

const SeriesDetailsDialog = loadable(() => import('./Series/SeriesDetailsDialog'))
const DoorDetailsDialog = loadable(() => import('./Doors/DoorDetailsDialog'))
const EventDetailsDialog = loadable(() => import('./Events/EventDetailsDialog'))
const FloorPlanDetailsDialog = loadable(() => import('./Venues/FloorPlanDetailsDialog'))
const VenueDetailsDialog = loadable(() => import('./Venues/VenueDetailsDialog'))
const UserProfileDialog = loadable(() => import('./Contacts/UserProfileDialog'))

export default function Routes() {
  const location = useLocation()
  const fullScreenDialogs = useSelector(selectFullScreenDialog)
  const currentUser = useSelector(selectCurrentUser)
  const history = useHistory()
  const handleOnClose = () => {
    history.goBack()
  }
  if ((currentUser
    && currentUser.settings
    && currentUser.userLoggedIn
    && !currentUser.settings.emailVerified)
    && location.pathname !== '/sync.html' && !fullScreenDialogs.interests && location.pathname.indexOf('/confirmEmail') === -1) {
    return (
      <>
        <Redirect to="/verifyEmail" render={() => <AsyncPage page={'./Profile/VerifyEmailContainer'}/>} />
        <Route exact path="/verifyEmail" render={() => <AsyncPage page={'./Profile/VerifyEmailContainer'}/> } />
      </>
    )
  }
  return (
    <>
      <Route path="/doors/:doorId/series/:seriesId">
        <SeriesDetailsDialog
          onClose={() => handleOnClose('seriesDetails')}
        />
      </Route>
      <Route path="/doors/:doorId">
        <DoorDetailsDialog
          onClose={() => handleOnClose('doorDetails')}
        />
      </Route>
      <Route path="/events/:eventId">
        <EventDetailsDialog
          onClose={() => handleOnClose('eventDetails')}
        />
      </Route>
      <Route exact path="/venues/:venueId/floorplans/:floorId">
        <FloorPlanDetailsDialog
          onClose={() => handleOnClose('floorPlanDetails')}
        />
      </Route>
      <Route exact path="/venues/:venueId">
        <VenueDetailsDialog
          onClose={() => handleOnClose('venueDetails')}
          onSubmit={fullScreenDialogs && fullScreenDialogs.venueDetails && fullScreenDialogs.venueDetails.onSubmit}
          onForgetVenue={fullScreenDialogs && fullScreenDialogs.venueDetails && fullScreenDialogs.venueDetails.onForgetVenue}
        />
      </Route>
      <Route exact path="/users/:userId">
        <UserProfileDialog
          open={fullScreenDialogs && fullScreenDialogs.userProfile && fullScreenDialogs.userProfile.open}
          onAddToList={fullScreenDialogs && fullScreenDialogs.userProfile && fullScreenDialogs.userProfile.onAddToList}
          onClose={() => {
            if (fullScreenDialogs && fullScreenDialogs.userProfile && fullScreenDialogs.userProfile.onClose) {
              fullScreenDialogs.userProfile.onClose()
            }
            handleOnClose('userProfile')
          }}
          user={fullScreenDialogs && fullScreenDialogs.userProfile && fullScreenDialogs.userProfile.user}
        />
      </Route>
      <Route path="/advancedOptions" render={() => <AsyncPage page={'./AdvancedOptionsContainer'}/>}/>
      <Route path="/aliases" render={() => <AsyncPage page={'./Aliases/AliasesContainer'}/>}/>
      <Route path="/contacts" render={() => <AsyncPage location={location} page={'./Contacts/ContactsContainer'}/>}/>
      <Route path="/confirmEmail" render={() => <AsyncPage page={'./Profile/ConfirmEmail'} />} />
      <Route path="/duplicateAlias/:aliasId" render={() => <AsyncPage page={'./Aliases/CopyAliasContainer'}/>}/>
      <Route path="/newAlias" render={() => <AsyncPage page={'./Aliases/NewAliasContainer'}/>}/>
      <Route path="/sync.html" render={() => <AsyncPage page={'./SyncsContainer'}/>} registration/>
      <Route exact path="/doors" render={() => <AsyncPage page={'./Doors/DoorsContainer'}/>}/>
      <Route exact path="/notifications" render={() => <AsyncPage page={'./Notifications/NotificationsContainer'}/>}/>
      <Route path="/register" render={() => <AsyncPage page={'./RegisterContainer'}/>}/>
      <Route path="/profile" render={() => <AsyncPage location={location} page={'./Profile/ProfileContainer'}/>}/>
      <Route path="/feed/map" render={() => <AsyncPage page={'./Events/EventsMapContainer'}/>}/>
      <Route path="/newSeries" render={() => <AsyncPage page={'./Series/NewSeriesContainer'}/>}/>
      {/*<Route exact path="/trips" render={() => <AsyncPage page={'./Trips/TripsContainer'} />} />
      <Route path="/trips/:tripId/:tripLegId" render={() => <AsyncPage page={'./Trips/TripLegView'} />} />*/}
      <Route path="/flares" render={() => <AsyncPage page={'./FlaresContainer'}/>}/>
      <Route path="/interests" render={() => <AsyncPage page={'./InterestsContainer'}/>}/>
      <Route path="/newDoor" render={() => <AsyncPage page="./Doors/NewDoorContainer"/>}/>
      <Route path="/users/:userId" render={() => <AsyncPage page="./Profile/UserProfileContainer"/>}/>
      <Route exact path="/manageCategories" render={() => <AsyncPage page={'./ManageCategoriesContainer'}/>}/>
      <Route exact path="/generateData" render={() => <AsyncPage page={'./GenerateData'}/>}/>
      <Route path="/login" render={() => <AsyncPage page={'./LoginContainer'}/>}/>
      <Route path="/schedule" render={() => <AsyncPage page={'./Schedule/ScheduleContainer'}/>}/>
      <Route exact path="/subscriptions" render={() => <AsyncPage page={'./Subscriptions/SubscriptionsContainer'}/>}/>
      <Route exact path="/verifyEmail" render={() => <AsyncPage page={'./Profile/VerifyEmailContainer'}/> } />
      <Route exact path="/forgotPassword" render={() => <AsyncPage page={'./Profile/ForgotPasswordContainer'} />}/>
      <Route exact path="/resetPassword" render={() => <AsyncPage page={'./Profile/ResetPasswordContainer'} />}/>
      <Route exact strict path="/" render={() => <AsyncPage page={'./LandingContainer'}/>}/>
    </>
  )
}