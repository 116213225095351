/* eslint-disable no-useless-escape */
import moment from 'moment'

const formatDate = (date, format, formString) => {
  let formDate = moment(date).format('MMMM, Do YYYY')
  if (format) {
    if (moment(date).isSame(moment(), 'day')) {
      formDate = 'Today ' + moment(date).format('h:mm a')
    } else {
      let diff = moment().diff(date, 'days')
      if (diff === 1) {
        formDate = 'Yesterday ' + moment(date).format('h:mm a')
      } else {
        formDate = moment(date).format('MMMM Do, h:mm a')
      }
    }
  }
  if (formString) {
    formDate = moment(date).format(formString)
  }
  return formDate
}

const formatTime = time => {
  return moment(time).format('h:mm a')
}

const formatDateRange = (start, end) => {
  if (moment(start).isSame(moment(end), 'day')) {
    return {
      date: formatDate(start),
      time: formatTime(start) + ' - ' + formatTime(end),
    }
  } else {
    return {
      date: formatDate(start) + ' - ' + formatDate(end),
      time: formatTime(start) + ' - ' + formatTime(end),
    }
  }
}

const checkDateRange = (date1, date2) => {
  return moment(date1).isSame(date2, 'day')
}

const checkTimeRange = (time1, time2) => {
  if (!time1) {
    return moment().valueOf() < moment(time2).valueOf()
  }
  return moment(time1).valueOf() < moment(time2).valueOf()
}

const mergeDateTime = (date, time) => {
  let dateFormat =
    (Number(moment(date).get('month')) + 1).toString() + '-' + moment(date).get('date') + '-' + moment(date).get('year')
  let timeFormat = moment(time).get('hour') + ':' + moment(time).get('minute') + ':' + moment(time).get('second')
  return moment(dateFormat + ' ' + timeFormat, 'MM-DD-YYYY HH:mm:ss').valueOf()
}

const getFirstAndLastOfMonth = d => {
  let date
  let y
  let m
  if (d) {
    date = new Date(d)
  } else {
    date = new Date()
  }
  y = date.getFullYear()
  m = date.getMonth()
  let firstDay = new Date(y, m, 1)
  let lastDay = new Date(y, m + 1, 0)
  let numberOfDays = lastDay.getDate()
  firstDay = moment(firstDay).format('x')
  lastDay = moment(lastDay).format('x')
  return {
    firstDay: Number(firstDay),
    lastDay: Number(lastDay),
    numberOfDays,
  }
}

const getNumberOfDays = (s, e) => {
  let start = moment(s)
  let end = moment(e)
  return end.diff(start, 'days') + 1
}

const weekOfMonth = m => {
  const dayStrings = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    5: 'fifth',
  }
  let dayNumber =
    m.week() -
    moment(m)
      .startOf('month')
      .week() +
    1
  return {
    day: dayStrings[dayNumber],
    number: dayNumber,
  }
}

const textToHTML = payload => {
  payload = payload.replace(
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
    '<a href="https://$&">$&</a>'
  )
  payload = payload.replace(/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+"){1,}/gim, 'mailto:$&')
  payload = payload.replace(/\n/gim, '<br />')
  return payload
}

/*const cronParse = payload => {
  let { dateTime, day, month, week, dayOfMonth, dayOfWeek } = payload
  let hour = moment(dateTime).hour()
  let minute = moment(dateTime).minute()
}*/

const formatLocation = (location, format) => {
  let address = location.address ? location.address : location
  let formLocation = {}
  let street = address.street || ''
  let houseNumber = address.house_number
  let road = address.road
  let city = address.city
  let state = address.state
  let country = address.country
  let zip = address.postcode

  if (houseNumber) {
    street += houseNumber
  }
  if (road || street.length) {
    if (road) {
      street += ' ' + road
    }
    formLocation.street = street
  }
  if (city) {
    formLocation.city = city
  }
  if (state) {
    formLocation.state = state
  }
  if (country) {
    formLocation.country = country
  }
  if (zip) {
    formLocation.zip = zip
  }
  formLocation.latitude = Number(location.address ? location.lat : address.latitude)
  formLocation.longitude = Number(location.address ? location.lon : address.longitude)
  formLocation.name = location.display_name
  formLocation.description = location.display_name
  formLocation.place_id = location ? location.place_id : undefined
  return formLocation
}

function isValidHttpUrl(string) {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === "http:" || url.protocol === "https:"
}

const validURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str) && isValidHttpUrl(str)
}

const reA = /[^a-zA-Z]/g
const reN = /[^0-9]/g

const sortAlphaNum = (a, b) => {
  const aN = parseInt(a.replace(reN, ''))
  const bN = parseInt(b.replace(reN, ''))
  if (aN === bN) {
    const aA = a.replace(reA, '')
    const bA = b.replace(reA, '')
    return aA === bA ? 0 : aA > bA ? 1 : -1
  }
  return aN > bN ? 1 : -1
}

const toOrdinalSuffix = num => {
  const int = parseInt(num),
    digits = [int % 10, int % 100],
    ordinals = ['st', 'nd', 'rd', 'th'],
    oPattern = [1, 2, 3, 4],
    tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3]
}

const parseHours = (hours) => {
  const hoursMap = {
    default: {
      open: null,
      close: null,
    },
    '0': {
      open: null,
      close: null,
    },
    '1': {
      open: null,
      close: null,
    },
    '2': {
      open: null,
      close: null,
    },
    '3': {
      open: null,
      close: null,
    },
    '4': {
      open: null,
      close: null,
    },
    '5': {
      open: null,
      close: null,
    },
    '6': {
      open: null,
      close: null,
    },
  }
  if (!hours) {
    return hoursMap
  }

  const hoursArray = hours.split(',')
  for (let i=0; i<hoursArray.length; i+=1) {
    let open
    let close
    const hour = hoursArray[i]
    const isDayByDay = hour.indexOf('daysOfWeek') > -1
    let dayOfWeek
    let hourString = hour
    let dayIndex = 'default'
    if (isDayByDay) {
      dayOfWeek = hour.slice(0, 12)
      dayIndex = dayOfWeek.split('.')[1]
      hourString = hour.slice(13, hour.length)
    }
    const hArray = hourString.split(' - ')
    hourString = ''
    for (let i=0; i<hArray.length; i+=1) {
      let hourS = hArray[i]
      const is12hour = hourS.indexOf('pm') > -1 || hourS.indexOf('am') > -1
      const isPm = hourS.indexOf('pm') > -1
      if (is12hour) {
        hourS = hourS.split(' ')[0]
      }
      let timeParts = hourS.split(':')
      let hourNumber = Number(timeParts[0])
      const minuteNumber = Number(timeParts[1])
      if (isPm) {
        hourNumber += 12
      }
      if (i === 0) {
        open = moment()
        open.hours(hourNumber)
        open.minutes(minuteNumber)
      }
      if (i === 1) {
        close = moment()
        close.hours(hourNumber)
        close.minutes(minuteNumber)
      }
    }
    hoursMap[dayIndex].open = open
    hoursMap[dayIndex].close = close
  }
  return hoursMap
}

export {
  formatDate,
  formatTime,
  formatDateRange,
  formatLocation,
  checkTimeRange,
  checkDateRange,
  mergeDateTime,
  getFirstAndLastOfMonth,
  getNumberOfDays,
  weekOfMonth,
  sortAlphaNum,
  textToHTML,
  toOrdinalSuffix,
  validURL,
  parseHours,
}
