import { schema } from 'normalizr'
const event = new schema.Entity('events')
const invite = new schema.Entity('invites', {}, { idAttribute: 'created' })
const venue = new schema.Entity('venues')
const user = new schema.Entity('users')
const userConnection = new schema.Entity('userConnections')
const externalUser = new schema.Entity('externalUser')
const series = new schema.Entity('serieses')
const business = new schema.Entity('businesses')

user.define({
  connections: [userConnection],
})

userConnection.define({
  user: user,
})

user.define({
  connections: [userConnection],
})

business.define({
  owner: user,
  venue: venue,
})

series.define({
  owner: business,
  venue: venue,
  creator: user,
})

event.define({
  venue: venue,
  hosts: [user],
  series: series,
  presenters: [business],
  invites: [invite],
  externalHosts: [externalUser],
})

invite.define({
  invitedByBusiness: business,
  invitedByUser: user,
  user: user,
})

export { invite }
