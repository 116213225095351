import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import SimpleReactLightbox from 'simple-react-lightbox'
import { ThemeProvider, useTheme, createTheme, alpha } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Globals } from '../constants/Global'
import configureStore from './index'
import history from '../history'
import '../GlobalStyles.scss'
import 'draft-js-hashtag-plugin/lib/plugin.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import '@sandstreamdev/react-swipeable-list/dist/styles.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import '../i18next/i18next'
import 'cropperjs/dist/cropper.css'

export function useWidth() {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

let muiTheme = createTheme()

muiTheme = createTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: Globals.colors.gray['A100'],
        color: Globals.colors.primary['500'],
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: Globals.colors.warning2,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: Globals.colors.gray['A100'],
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: `10px solid ${Globals.colors.primary['500']}`,
        fontSize: 18,
      }
    },
    MuiDivider:{
      inset: {
        marginLeft: 56,
      }
    },
    MuiSelect: {
      selectMenu: {
        height: '1.1875em',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiFormGroup: {
      root: {
        marginBottom: 15,

        '& .MuiFormControlLabel-root': {
          marginBottom: 10,

          '& .MuiRadio-root': {
            marginTop: -7
          }
        }
      },
    },
    MuiGridListTile: {
      tile: {
        borderRadius: 'inherit',
      },
    },
    MuiMenuItem: {
      root: {
        backgroundColor: Globals.colors.gray['A100'],
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: Globals.colors.secondary['300'],
        color: Globals.colors.gray['A100'],
      },
    },
    MuiChip: {
      root: {
        backgroundColor: Globals.colors.error['900'],
        color: Globals.colors.gray['A100'],

        '$clickable&:hover, $deletable:hover': {
          backgroundColor: alpha(Globals.colors.error['900'], 0.8),
        },

        '$clickable&:focus, $deletable:focus': {
          backgroundColor: Globals.colors.error['900'],
        },

        '&.MuiChip-deletable:focus': {
          backgroundColor: Globals.colors.error['900'],
        }
      },
      outlined: {
        color: Globals.colors.error['500'],
        border: `1px solid ${Globals.colors.error['500']}`,
        backgroundColor: alpha(Globals.colors.error['50'], 1),
        '& $avatar': {
          color: Globals.colors.gray['A100'],
          backgroundColor: Globals.colors.primary['500'],
        },
        '& $avatarSmall': {
          color: Globals.colors.gray['A100'],
          backgroundColor: Globals.colors.primary['500'],
        },
        '& $icon': {
          color: Globals.colors.error['500'],
        },
        '& $iconSmall': {
          color: Globals.colors.error['500'],
        },
        '& $deleteIcon': {
          color: Globals.colors.error['500'],
        },
        '& $deleteIconSmall': {
          color: Globals.colors.error['500'],
        },
        '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
          backgroundColor: alpha(Globals.colors.error['50'], 1),
          color: Globals.colors.error['500'],

          '& $icon': {
            color: alpha(Globals.colors.error['500'], 1),
          },
          '& $iconSmall': {
            color: alpha(Globals.colors.error['500'], 1),
          },
          '& $deleteIcon': {
            color: alpha(Globals.colors.error['500'], 1),

            '&:hover': {
              color: alpha(Globals.colors.error['500'], 0.6),
            },
          },
          '& $deleteIconSmall': {
            color: alpha(Globals.colors.error['500'], 1),
          },
        },
      },
      deleteIcon: {
        color: Globals.colors.gray['A100'],
        '&:hover': {
          color: Globals.colors.gray['A100'],
        },
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: Globals.colors.primary['500'],
        color: Globals.colors.gray['A100'],
      },
      scroller: {
        marginBottom: '0 !important',
      },
      indicator: {
        backgroundColor: `${Globals.colors.warning['500']} !important`,
      }
    },
    MuiTab: {
      root: {
        [`${muiTheme.breakpoints.down('sm')}`]: {
          minHeight: 56,
        },
        [`${muiTheme.breakpoints.down('sm')} and (orientation: landscape)`]: {
          minHeight: 48,
        },
        [`${muiTheme.breakpoints.up('sm')}`]: {
          minHeight: 64,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'uppercase',
        fontFamily: "'RobotoMedium', sans-serif",
        fontSize: 14,
      },
      containedPrimary: {
        color: Globals.colors.gray['A100'],
        backgroundColor: Globals.colors.primary['500'],
        '&:hover': {
          backgroundColor: Globals.colors.primary['700'],
          // Reset on mouse devices
          '@media (hover: none)': {
            backgroundColor: Globals.colors.primary['500'],
          },
        },
      },
      containedSecondary: {
        color: Globals.colors.gray['A100'],
        backgroundColor: Globals.colors.secondary['500'],
        '&:hover': {
          backgroundColor: Globals.colors.secondary['700'],
          // Reset on mouse devices
          '@media (hover: none)': {
            backgroundColor: Globals.colors.secondary['500'],
          },
        },
      },
      outlinedSecondary: {
        borderColor: Globals.colors.gray['500'],
      }
    },
    MuiFab: {
      primary: {
        backgroundColor: Globals.colors.primary['500'],
        color: Globals.colors.gray['A100'],
        '&:hover': {
          backgroundColor: Globals.colors.primary['700'],
          // Reset on mouse devices
          '@media (hover: none)': {
            backgroundColor: Globals.colors.primary['500'],
          },
        },
      },
      secondary: {
        color: Globals.colors.gray['A100'],
        backgroundColor: Globals.colors.secondary['500'],
        '&:hover': {
          backgroundColor: Globals.colors.secondary['700'],
          // Reset on mouse devices
          '@media (hover: none)': {
            backgroundColor: Globals.colors.secondary['500'],
          },
        },
      },
    },
    MuiCardHeader: {
      title: {
        fontFamily: "'RobotoSlab', serif",
        fontSize: 18,
      },
      subheader: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: 12,
      }
    },
    MuiTypography: {
      body1: {
        color: Globals.colors.standard,
      },
      body2: {
        color: Globals.colors.textColorTear,
        fontFamily: "'RobotoSlab', serif",
        fontSize: 14,
      },
      subtitle1: {
        fontFamily: "'RobotoSlab', serif",
      },
      h1: {
        fontSize: 24,
      }
    },
  },
  palette: {
    primary: Globals.colors.primary,
    secondary: Globals.colors.secondary,
    error: Globals.colors.error,
    warning: Globals.colors.warning,
    textPrimary: Globals.colors.textColor,
  },
})

const store = configureStore()

const StoreProvider = ({ children }) => (
  <Provider store={store}>
    { children }
  </Provider>
)

const LightboxProvider = ({ children }) => (
  <SimpleReactLightbox>
    { children }
  </SimpleReactLightbox>
)

const PickersProvider = ({ children }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    { children }
  </MuiPickersUtilsProvider>
)

const RouteProvider = ({ children }) => (
  <Router history={history}>
    { children }
  </Router>
)

const DMThemeProvider = ({ children }) => (
  <ThemeProvider theme={muiTheme}>
    { children }
  </ThemeProvider>
)

const MainProvider = ({ children }) => (
  <Provider store={store}>
    <Router history={history}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DMThemeProvider theme={muiTheme}>
          <LightboxProvider>
            { children }
          </LightboxProvider>
        </DMThemeProvider>
      </MuiPickersUtilsProvider>
    </Router>
  </Provider>
)

export { PickersProvider, RouteProvider, store, StoreProvider, DMThemeProvider }

export default MainProvider
