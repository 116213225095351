import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const doorRolesConst = ActionConstants.doorRoles

export default function doorRoles(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    currentRole: null,
    errorMessage: null,
  },
  action
) {
  let index
  let newItems = [...state.items]
  switch (action.type) {
    case doorRolesConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case doorRolesConst.requestItem:
    case doorRolesConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case doorRolesConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case doorRolesConst.receiveCurrentRole:
      return {
        ...state,
        currentRole: action.response,
      }
    case doorRolesConst.receiveItem:
    case doorRolesConst.confirmEditItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case ActionConstants.doorManagers.confirmEditItem:
      for (let i = 0; i < newItems.length; i++) {
        for (let m = 0; m < newItems[i].managers.length; m++) {
          const manager = newItems[i].managers[m]
          if (action.response
            && action.response.user
            && action.response.user.id
            && manager
            && manager.user
            && manager.user.id === action.response.user.id) {
            newItems[i].managers[m] = action.response
          }
        }
      }
      return {
        ...state,
        items: newItems,
      }
    case ActionConstants.doorManagers.confirmAddItem:
      for (let i = 0; i < newItems.length; i++) {
        if (action.response.role && action.response.role.id === newItems[i].id) {
          newItems[i].managers.push({ user: { ...action.response } })
        }
      }
      return {
        ...state,
        items: newItems,
      }
    case doorRolesConst.sendAddItem:
    case doorRolesConst.sendDeleteItem:
    case doorRolesConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case doorRolesConst.confirmDeleteItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case doorRolesConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, { ...action.response, managers: [] }, state.items.length),
        isFetching: false,
      }
    case doorRolesConst.itemFailed:
    case doorRolesConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
