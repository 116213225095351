const UIActionConstants = {
  header: {
    SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
    SEARCH: 'SEARCH_APP_HEADER',
    TOGGLE_SEARCH: 'TOGGLE_SEARCH',
    SUBMIT_SEARCH: 'SUBMIT_SEARCH'
  },
  sideMenu: {
    CLOSE_MENU: 'SIDE_MENU_CLOSE',
    OPEN_MENU: 'SIDE_MENU_OPEN',
    TOGGLE_MENU: 'SIDE_MENU_TOGGLE',
    TOGGLE_VIEW_MODE: 'TOGGLE_VIEW_MODE',
  },
  notifications: {
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
    CLEAR_ALL_NOTIFICATIONS: 'CLEAR_ALL_NOTIFICATIONS',
  },
};

export default UIActionConstants
