import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const contactRequestConst = ActionConstants.contactRequests
const contactRequestPendingConst = ActionConstants.contactRequestsPending
const contactRequestSentConst = ActionConstants.contactRequestsSent

function contactRequests(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case contactRequestConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case contactRequestConst.requestItem:
    case contactRequestConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case contactRequestConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case contactRequestConst.receiveItem:
    case contactRequestConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case contactRequestConst.sendAddItem:
    case contactRequestConst.sendDeleteItem:
    case contactRequestConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case contactRequestConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case contactRequestConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case contactRequestConst.itemFailed:
    case contactRequestConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function contactRequestsPending(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
    isBulkInviting: false,
  },
  action
) {
  let index
  switch (action.type) {
    case contactRequestPendingConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case contactRequestPendingConst.requestItem:
    case contactRequestPendingConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case contactRequestPendingConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case contactRequestPendingConst.receiveItem:
    case contactRequestPendingConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case contactRequestPendingConst.sendAddItem:
    case contactRequestPendingConst.sendDeleteItem:
    case contactRequestPendingConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case contactRequestPendingConst.confirmDeleteRequest:
    case contactRequestPendingConst.confirmDeclineRequest:
    case contactRequestPendingConst.confirmAcceptRequest:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case contactRequestPendingConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case contactRequestPendingConst.itemFailed:
    case contactRequestPendingConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
  case contactRequestPendingConst.sendBulkInvite:
    return {
      ...state,
      isBulkInviting: true,
    }
  case contactRequestPendingConst.confirmBulkInvite:
    return {
      ...state,
      isBulkInviting: false,
      items: [...state.items, ...action.response],
    }
    default:
      return state
  }
}

function contactRequestsSent(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case contactRequestSentConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case contactRequestSentConst.requestItem:
    case contactRequestSentConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case contactRequestSentConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case contactRequestSentConst.receiveItem:
    case contactRequestSentConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case contactRequestSentConst.sendAddItem:
    case contactRequestSentConst.sendDeleteItem:
    case contactRequestSentConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case contactRequestSentConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case contactRequestSentConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case contactRequestSentConst.itemFailed:
    case contactRequestSentConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export { contactRequests, contactRequestsPending, contactRequestsSent }
