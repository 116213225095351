import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import { alpha } from '@material-ui/core/styles'
import { Globals } from '../../constants/Global'
import { MobileGrid } from '../layout/layout'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '6px 0',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    backgroundColor: Globals.colors.bgColor,
    width: '100%',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    backgroundColor: Globals.colors.gray['A100'],
    color: Globals.colors.standard,

    '$clickable&:hover, $deletable:hover': {
      backgroundColor: Globals.colors.gray['A100'],
    },

    '$clickable&:focus, $deletable:focus': {
      backgroundColor: Globals.colors.gray['A100'],
    },

    '&.MuiChip-deletable:focus': {
      backgroundColor: Globals.colors.gray['A100'],
    },
    '& $icon': {
      color: Globals.colors.standard,
    },
    '& $iconSmall': {
      color: Globals.colors.standard,
    },
    '& $deleteIcon': {
      color: Globals.colors.standard,
    },
    '& $deleteIconSmall': {
      color: Globals.colors.standard,
    },

    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      '& $icon': {
        color: Globals.colors.standard,
      },
      '& $iconSmall': {
        color: Globals.colors.standard,
      },
      '& $deleteIcon': {
        color: Globals.colors.standard,

        '&:hover': {
          color: alpha(Globals.colors.standard, 0.8),
        },
      },
      '& $deleteIconSmall': {
        color: Globals.colors.standard,
      },
    }
  },
  icon: {
    color: Globals.colors.standard,

    '&:hovered': {
      color: alpha(Globals.colors.standard, 0.8)
    }
  },
}));

const FilterBar = ({ filters, onDelete }) => {
  const classes = styles()
  return (
    <MobileGrid enablePadding>
      <div className={classes.root}>
        {filters && filters.map(filter => {
          return (
            <Chip
              classes={{
                root: classes.chip,
                deleteIcon: classes.icon,
              }}
              key={filter.name}
              label={filter.name}
              onDelete={() => onDelete(filter)}
            />
          )
        })}
      </div>
    </MobileGrid>
  )
}

export default FilterBar
