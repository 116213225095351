import request from '../request'
import ActionConstants from '../constants/ActionConstants'
import { Globals } from '../constants/Global'
import { schemas } from '../schemas/schemas'
import { formatUrl } from '../utils/RESTUtils'
import { normalize } from 'normalizr'

const contactConst = ActionConstants.contacts
const userSchema = schemas.users

export const addToList = contact => {
  return {
    type: contactConst.sendAddToList,
    contact,
  }
}

export const fetchTempContacts = payload => dispatch => {
  let { dataType, url, path, params, data, query } = payload
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  dispatch({
    type: contactConst.requestItems,
  })
  return request.get(formatUrl(url, path), options).then(response => {
    dispatch({
      type: contactConst.receiveTempItems,
      response: response.data.items ? normalize(response.data.items, [schemas[dataType]]) : null,
      receivedAt: Date.now(),
    })
    return response.data
  })
}

export const clearTempContacts = () => dispatch => {
  dispatch({
    type: contactConst.clearTempItems,
  })
}

export const starContact = contact => dispatch => {
  dispatch({
    type: contactConst.sendStarContact,
    item: contact.id,
  })
}

export const toggleSelectContact = contact => {
  return {
    type: contactConst.toggleSelectContact,
    item: contact,
  }
}

export const inviteFriend = (dataType, url, payload, path) => dispatch => {
  dispatch({
    type: ActionConstants[dataType].sendInviteFriend,
    item: payload,
  })
  return request.post(formatUrl(url, path)).then(
    response => {
      dispatch({
        type: ActionConstants[dataType].confirmInviteFriend,
        response: payload,
        eventId: path.eventId
      })
      return payload
    },
    error => {
      dispatch({
        type: ActionConstants[dataType].itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchInvitableFriends = path => (dispatch, getState) => {
  dispatch({
    type: contactConst.requestInvitiableFriends,
  })
  return request.get(formatUrl(Globals.api.eventInvitableFriends, path)).then(
    response => {
      dispatch({
        type: contactConst.receiveInvitiableFriends,
        items: response.data.items,
        receivedAt: Date.now(),
        eventId: path.eventId,
      })
    },
    error => {
      dispatch({
        type: contactConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchInvitableManagers = path => dispatch => {
  dispatch({
    type: contactConst.requestInvitiableManagers,
  })
  return request.get(formatUrl(Globals.api.eventInvitableManagers, path)).then(
    response => {
      dispatch({
        type: contactConst.receiveInvitiableManagers,
        response: normalize(response.data.items, [userSchema]),
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: contactConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchInvitableSubscribers = path => dispatch => {
  dispatch({
    type: contactConst.requestInvitiableSubscribers,
  })
  return request.get(formatUrl(Globals.api.eventInvitableSubscribers, path)).then(
    response => {
      dispatch({
        type: contactConst.receiveInvitiableSubscribers,
        response: normalize(response.data.items, [userSchema]),
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: contactConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const selectOrder = order => {
  return {
    type: contactConst.selectOrder,
    item: order,
  }
}

export const selectFilter = filter => {
  return {
    type: contactConst.selectFilter,
    item: filter,
  }
}

export const selectProvider = provider => {
  return {
    type: contactConst.selectProvider,
    item: provider,
  }
}

export const addUserToGuestList = (payload) => dispatch => {
  const { guests, guestList } = payload

  dispatch({
    type: ActionConstants.userGuestListGuests.sendAddItems,
    item: guests,
  })

  return request.post(formatUrl(Globals.api.userGuestListGuests, { listId: guestList }), { items: guests }).then(
    () => {
      dispatch({
        type: ActionConstants.userGuestListGuests.confirmAddItems,
        response: {
          guests,
          guestList,
        }
      })
    },
    error => {
      dispatch({
        type: contactConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const getGuestListUsers = (path) => dispatch => {
  dispatch({
    type: ActionConstants.userGuestListGuests.requestItems
  })

  return request.get(formatUrl(Globals.api.userGuestList, path)).then(response => {
    dispatch({
      type: ActionConstants.userGuestListGuests.receiveItems,
      response: {
        guests: response.data && response.data.guests ? response.data.guests : [],
        guestList: path.listId,
      }
    })
  }, () => {
    dispatch({
      type: ActionConstants.userGuestListGuests.itemsFailed,
    })
  })
}

export const deleteGuestListUser = (path) => dispatch => {
  dispatch({
    type: ActionConstants.userGuestListGuests.sendDeleteItem
  })

  return request.delete(formatUrl(Globals.api.userGuestListGuest, path)).then(() => {
    dispatch({
      type: ActionConstants.userGuestListGuests.confirmDeleteItem,
      response: {
        listId: path.listId,
        userId: path.userId,
      }
    })
  })
}

async function getGuestListUsersRequest(listId) {
  const users = {
    hash: {},
    items: [],
  }
  let guestListUsers = await request.get(formatUrl(Globals.api.userGuestList, { listId }))
  if (guestListUsers.data && guestListUsers.data.guests) {
    guestListUsers = guestListUsers.data.guests
    for (let i = 0; i < guestListUsers.length; i += 1) {
      const user = guestListUsers[i]
      users.hash[user.id] = user
      users.items.push(user.id)
    }
  }
  return users
}

export async function getAliasContacts(alias, currentUser) {
  let users = {}
  const lists = {}
  await request.put(formatUrl(Globals.api.switchUser, { userId: alias.id }))
  let friends = await request.get(Globals.api.contacts)
  if (friends.data && friends.data.items) {
    friends = friends.data.items
    for (let i = 0; i < friends.length; i += 1) {
      const friend = friends[i]
      friend.checked = false
      users[friend.id] = friend
    }
  }
  let guestLists = await request.get(Globals.api.userGuestLists)
  if (guestLists.data && guestLists.data.items) {
    guestLists = guestLists.data.items
    for (let i = 0; i < guestLists.length; i += 1) {
      const list = guestLists[i]
      const guestListUsers = await getGuestListUsersRequest(list.id)
      users = {
        ...users,
        ...guestListUsers.hash,
      }
      list.friends = guestListUsers.items
      list.checked = false
      lists[list.id] = list
    }
  }
  await request.put(formatUrl(Globals.api.switchUser, { userId: currentUser.id }))
  return {
    lists,
    users,
  }
}

export const blockUser = (payload) => dispatch => {
  dispatch({
    type: ActionConstants.contacts.sendBlockUser,
    response: payload,
  })

  return request.post(formatUrl(Globals.api.block, { blockId: payload.id })).then(() => {
    dispatch({
      type: ActionConstants.contacts.confirmBlockUser,
      response: payload,
    })
  })
}

export const unBlockUser = (payload) => dispatch => {
  dispatch({
    type: ActionConstants.contacts.sendUnBlockUser,
    response: payload,
  })

  return request.delete(formatUrl(Globals.api.block, { blockId: payload.id })).then(() => {
    dispatch({
      type: ActionConstants.contacts.confirmUnBlockUser,
      response: payload,
    })
  })
}
