import { createSelector } from 'reselect'

export const selectDoors = createSelector(
  state => state.doors,
  propState => propState
)

export const selectDoor = createSelector(
  state => state.doors,
  propState => propState.selectedItem
)

export const selectDoorManagers = createSelector(
  state => state.doorManagers,
  propState => propState
)

export const selectDoorManagerGroups = createSelector(
  state => state.doorManagerGroups,
  propState => propState
)

export const selectDoorSeries = createSelector(
  state => state.doorSeries,
  propState => propState
)

export const selectDoorRoles = createSelector(
  state => state.doorRoles,
  propState => propState
)

export const selectCurrentRole = createSelector(
  state => state.doorRoles.currentRole,
  propState => propState
)

export const selectDoorSubscribers = createSelector(
  state => state.doorSubscribers,
  propState => propState
)

export const selectDoorGuestLists = createSelector(
  state => state.doorGuestLists,
  propState => propState.items ? Object.values(propState.items).map(item => item) : []
)

export const selectDoorGuestListsGuests = createSelector(
  state => state.doorGuestListGuests,
  propState => propState
)

export const selectDoorBans = createSelector(
  state => state.doorBans,
  propState => propState
)

export const selectDoorBlacklists = createSelector(
  state => state.doorBlacklists,
  propState => propState
)

export const selectDoorRecommendations = createSelector(
  state => state.doors,
  propState => propState.recommendations || []
)

export const selectLoadedDoor = (id) => createSelector(
  state => state.doors,
  propState => propState.loadedItems[id]
)
