import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const externalInviteConst = ActionConstants.eventExternalInvites

function externalInvites(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
    counts: [],
  },
  action
) {
  let index
  switch (action.type) {
    case externalInviteConst.invalidateItems:
      return {
        ...state,
        items: [],
        didInvalidate: true,
      }
    case externalInviteConst.requestItem:
    case externalInviteConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case externalInviteConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case externalInviteConst.receiveItem:
    case externalInviteConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case externalInviteConst.sendAddItem:
    case externalInviteConst.sendDeleteItem:
    case externalInviteConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case externalInviteConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case externalInviteConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case externalInviteConst.confirmAddItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: dataUtils.appendArray(state.items, action.items),
        lastUpdated: action.receivedAt,
      }
    case externalInviteConst.itemFailed:
    case externalInviteConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case externalInviteConst.receiveInvitesCount:
      return {
        ...state,
        counts: action.response,
      }
    default:
      return state
  }
}

export default externalInvites
