import ActionConstants from '../constants/ActionConstants'
import request from '../request'
import { formatUrl } from '../utils/RESTUtils'
import { Globals } from '../constants/Global'
import { convertToObject } from '../utils/dataUtils'

const doorConst = ActionConstants.doorRoles

export const getCurrentRole = path => dispatch => {
  dispatch({
    type: doorConst.requestCurrentRole,
  })
  return request.get(formatUrl(Globals.api.doorRoleCurrent, path)).then(
    response => {
      dispatch({
        type: doorConst.receiveCurrentRole,
        response: response.data,
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: doorConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const addManagerToRole = (path, role) => dispatch => {
  dispatch({
    type: ActionConstants.doorManagers.sendEditItem,
  })
  return request.put(formatUrl(Globals.api.doorRoleAddManager, path), role).then(
    response => {},
    error => {
      dispatch({
        type: ActionConstants.doorManagers.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const removeManagerFromRole = (path, role) => dispatch => {
  dispatch({
    type: ActionConstants.doorManagers.sendDeleteItem,
  })
  return request.delete(formatUrl(Globals.api.doorManager, path), role).then(
    () => {},
    error => {
      dispatch({
        type: ActionConstants.doorManagers.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const changeGM = (path, newUser) => dispatch => {
  const options = {}
  if (newUser) {
    options.params = {}
    options.params.gmId = newUser
  }
  dispatch({
    type: ActionConstants.doorManagers.sendChangeGM,
  })
  return request.put(formatUrl(Globals.api.doorChangeGM, path), null, options).then(
    () => {},
    error => {
      dispatch({
        type: ActionConstants.doorManagers.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const banSubscriber = (path, reason, user) => (dispatch, getState) => {
  dispatch({
    type: ActionConstants.doorBans.sendAddItem,
    item: user,
  })
  return request.post(formatUrl(Globals.api.doorBan, path), { params: reason }).then(
    () => {
      const me = getState().user.info
      dispatch({
        type: ActionConstants.doorBans.confirmAddItem,
        response: { ...user, bannedBy: { ...me } },
      })
    },
    error => {
      dispatch({
        type: ActionConstants.doorBans.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const blacklistSubscriber = (path, reason, user) => (dispatch, getState) => {
  dispatch({
    type: ActionConstants.doorBlacklists.sendAddItem,
    item: user,
  })
  return request.post(formatUrl(Globals.api.doorBlacklistUser, path), { params: reason }).then(
    () => {
      const me = getState().user.info
      dispatch({
        type: ActionConstants.doorBlacklists.confirmAddItem,
        response: { ...user, by: { ...me } },
      })
    },
    error => {
      dispatch({
        type: ActionConstants.doorBlacklists.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchDoorRecommendations = () => dispatch => {
  dispatch({
    type: ActionConstants.doors.requestRecommendations,
  })
  return request.get(Globals.api.doorRecommendations).then(
    response => {
      dispatch({
        type: ActionConstants.doors.receiveRecommendations,
        items: response.data.items || [],
      })
    }, () => {
      dispatch({
        type: ActionConstants.doors.itemsFailed,
      })
    }
  )
}

export const setManagementStyle = (payload) => dispatch => {
  const { url, path } = payload
  return request.put(formatUrl(url, path)).then(() => {
    dispatch({
      type: 'MANAGEMENT_STYLE_SET'
    })
  })
}

export const loadDoor = payload => dispatch => {
  const { doorId } = payload
  return request.get(formatUrl(Globals.api.door, { doorId })).then(response => {
    if (!response) {
      dispatch({
        type: ActionConstants.doors.itemFailed,
      })
    }
    let door = { ...response.data }
    let subscription
    dispatch({
      type: ActionConstants.doors.sendDoorLoading,
      response: door,
    })
    Promise.allSettled([
      request.get(formatUrl(Globals.api.doorSeries, { doorId })),
      request.get(formatUrl(Globals.api.doorGuestLists, { doorId })),
      request.get(formatUrl(Globals.api.doorCategories, { doorId })),
      request.get(formatUrl(Globals.api.doorVenues, { doorId })),
      request.get(formatUrl(Globals.api.subscriptions, { params: { q: door.name } }))
    ]).then(values => {
      door = {
        ...door,
        bans: {},
        blackLists: {},
        categories: {},
        comments: {},
        guestLists: {},
        managers: {},
        presentedEvents: {},
        presenterHostRequests: {},
        presenterRequestOverrides: {},
        roles: {},
        series: {},
        subscribers: {},
        venues: {},
      }
      door.series = values[0].value && values[0].value.data && values[0].value.data.items ? convertToObject(values[0].value.data.items) : {}
      door.guestLists = values[1].value && values[1].value.data && values[1].value.data.items ? convertToObject(values[1].value.data.items) : {}
      door.categories = values[2].value && values[2].value.data && values[2].value.data.items ? convertToObject(values[2].value.data.items) : {}
      door.venues = values[3].value && values[3].value.data && values[3].value.data.items ? convertToObject(values[3].value.data.items.map(item => item.venue)) : {}
      if (values[4].value && values[4].value.data && values[4].value.data.items) {
        values[4].value.data.items.forEach(item => {
          if (item.business.id === door.id) {
            subscription = item
          }
        })
      }
      door.subscription = subscription
      dispatch({
        type: ActionConstants.doors.confirmDoorLoaded,
        response: door,
        loaded: true,
      })
    }, error => {
      dispatch({
        type: ActionConstants.doors.doorLoadingFailed
      })
    })
  })}

export const loadDoorManagerEvent = payload => dispatch => {
  const { eventId, venueId } = payload
  dispatch({
    type: ActionConstants.doors.sendDoorEventLoading,
    response: { eventId },
  })
  const eventInfo = {
    id: eventId,
    externalInviteCounts: {},
    inviteCounts: {
      YES: 0,
      NO: 0,
      MAYBE: 0,
      WUT: 0,
    },
    venue: null,
    revenue: null,
    hosts: [],
    userInvite: {
      status: 'WUT',
    }
  }
  return Promise.all([
    request.get(formatUrl(Globals.api.eventInvitesCount, { eventId })),
    request.get(formatUrl(Globals.api.eventExternalInviteCounts, { eventId })),
    request.get(formatUrl(Globals.api.eventRevenue, { eventId })),
    request.get(formatUrl(Globals.api.venue, { venueId })),
    request.get(formatUrl(Globals.api.eventHosts, { eventId })),
    request.get(formatUrl(Globals.api.eventMyInvite, { eventId }))
  ]).then(values => {
    if (values[0] && values[0].data) {
      eventInfo.inviteCounts = values[0].data
    }
    if (values[1] && values[1].data) {
      eventInfo.externalInviteCounts = values[1].data
    }
    if (values[2] && values[2].data) {
      eventInfo.revenue = values[2].data
    }
    if (values[3] && values[3].data) {
      eventInfo.venue = values[3].data
    }
    if (values[4] && values[4].data && values[4].data.items) {
      eventInfo.hosts = values[4].data.items
    }
    if (values[5] && values[5].data) {
      eventInfo.userInvite = values[5].data
    }
    dispatch({
      type: ActionConstants.doors.confirmDoorEventLoaded,
      response: { event: { ...eventInfo, isLoaded: true }}
    })
  })
}

export const editEventRevenue = payload => async dispatch => {
  const { event, revenue, series } = payload

  await request.post(formatUrl(Globals.api.eventRevenue, { eventId: event.id }), revenue)

  if (series) {
    dispatch({
      type: ActionConstants.series.confirmEditSeriesEvent,
      response: {
        event: {
          ...event,
          revenue,
        },
        series,
      }
    })
  } else {
    dispatch({
      type: ActionConstants.events.confirmEditItem,
      response: {
        ...event,
        revenue,
      }
    })
  }
}
