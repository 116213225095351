import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Globals } from '../../constants/Global'

const SVGRefDown =
  '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 2 1" preserveAspectRatio="none"  fill="white"><polygon points="1 0 2 1 0 1"/></svg>'
const SVGRefUp =
  '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 2 2" preserveAspectRatio="none"  fill="white"><polygon points="0 0 2 0 1 1.6"/></svg>'

const Banner = styled.div`
  position: relative;
  overflow-x: hidden;
  background-color: ${Globals.colors.bgColor};
  padding-bottom: ${props => (props.direction === 'down' ? '18px' : '15px')};
  margin-bottom: 0;
  width: 100%;
`
const BannerTooth = styled.div`
  background-color: ${props => (props.direction === 'down' ? 'transparent' : Globals.colors.gray['100'])};
  bottom: 0;
  position: absolute;
  width: 100%;
  border-image: url('data:image/svg+xml,${props =>
    props.direction === 'down' ? SVGRefDown : SVGRefUp}') 0 0 100% repeat;
  border-width: 0 0 15px;
  border-style: solid;
  background-clip: padding-box;
  filter: drop-shadow(0px ${props => (props.direction === 'down' ? '3px' : '-3px')} 6px rgba(0, 0, 0, 0.15));
`

const BannerToothCover = styled.div`
  background-color: ${props => (props.direction === 'down' ? Globals.colors.gray['A100'] : Globals.colors.bgColor)};
  position: absolute;
  bottom: -5px;
  width: 100%;
  z-index: 5;
`

const BannerContainer = styled.div`
  background-color: ${props => (props.direction === 'down' ? Globals.colors.bgColor : Globals.colors.gray['A100'])};
  position: relative;
  height: 100%;
`

const MobileTearSheet = props => {
  const { children, direction } = props
  return (
    <Banner direction={direction}>
      <BannerTooth direction={direction} />
      <BannerToothCover direction={direction} />
      <BannerContainer direction={direction}>{children}</BannerContainer>
    </Banner>
  )
}

MobileTearSheet.propTypes = {
  direction: PropTypes.string,
}

MobileTearSheet.defaultProps = {
  direction: 'down',
}

export { MobileTearSheet }
