import request from '../request'
import ActionConstants from '../constants/ActionConstants'
import { formatUrl } from '../utils/RESTUtils'

const eventConst = ActionConstants.feed

export const hoverEvent = event => dispatch => {
  dispatch({
    type: eventConst.hoverEvent,
    response: event,
  })
}

export const unHoverEvent = event => dispatch => {
  dispatch({
    type: eventConst.unHoverEvent,
    response: event,
  })
}

export const rsvpToEvent = (url, event, path) => dispatch => {
  dispatch({
    type: eventConst.sendRSVPToEvent,
    response: event,
  })
  let newUrl = formatUrl(url, path) + '?status=' + event.status
  return request.put(newUrl).then(
    response => {
      dispatch({
        type: eventConst.confirmRSVPToEvent,
        response: event,
        path,
      })
      return response.data
    },
    error => {
      dispatch({
        type: eventConst.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}
