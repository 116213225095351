import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const doorManagersConst = ActionConstants.doorManagers
const doorManagerGroupsConst = ActionConstants.doorManagerGroups
const doorManagerGroupMembersConst = ActionConstants.doorManagerGroupMembers

function doorManagers(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case doorManagersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case doorManagersConst.requestItem:
    case doorManagersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case doorManagersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case doorManagersConst.receiveItem:
    case doorManagersConst.confirmEditItem:
      console.log(action)
      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].user.id === action.response.user.id) {
          index = i
        }
      }
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case doorManagersConst.sendAddItem:
    case doorManagersConst.sendDeleteItem:
    case doorManagersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case doorManagersConst.confirmDeleteItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case doorManagersConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, { user: action.response }, 0),
        isFetching: false,
      }
    case doorManagersConst.itemFailed:
    case doorManagersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function doorManagerGroups(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case doorManagerGroupsConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case doorManagerGroupsConst.requestItem:
    case doorManagerGroupsConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case doorManagerGroupsConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case doorManagerGroupsConst.receiveItem:
    case doorManagerGroupsConst.confirmEditItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case doorManagerGroupsConst.sendAddItem:
    case doorManagerGroupsConst.sendDeleteItem:
    case doorManagerGroupsConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case doorManagerGroupsConst.confirmDeleteItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case doorManagerGroupsConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case doorManagerGroupsConst.itemFailed:
    case doorManagerGroupsConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function doorManagerGroupMembers(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    items: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case doorManagerGroupMembersConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case doorManagerGroupMembersConst.requestItem:
    case doorManagerGroupMembersConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case doorManagerGroupMembersConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case doorManagerGroupMembersConst.receiveItem:
    case doorManagerGroupMembersConst.confirmEditItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case doorManagerGroupMembersConst.sendAddItem:
    case doorManagerGroupMembersConst.sendDeleteItem:
    case doorManagerGroupMembersConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case doorManagerGroupMembersConst.confirmDeleteItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case doorManagerGroupMembersConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case doorManagerGroupMembersConst.itemFailed:
    case doorManagerGroupMembersConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

export { doorManagers, doorManagerGroups, doorManagerGroupMembers }
