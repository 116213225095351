import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const venueConst = ActionConstants.venues

function venues(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    errorMessage: null,
    loadedItems: {},
  },
  action
) {
  let index
  let newVenues
  let newFloors
  let newRooms
  let hash
  switch (action.type) {
    case venueConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case venueConst.requestItem:
    case venueConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case venueConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case venueConst.receiveItem:
    case venueConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
        loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: true, isError: false } }
      }
    case venueConst.sendAddItem:
    case venueConst.sendDeleteItem:
    case venueConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case venueConst.confirmDeleteItem:
      newVenues = { ...state.loadedItems }
      if (newVenues[action.response.id]) {
        delete newVenues[action.response.id]
      }
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        loadedItems: newVenues,
        isFetching: false,
      }
    case venueConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case venueConst.itemFailed:
    case venueConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
  case ActionConstants.venues.sendVenueLoading:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.venueId]: { isLoaded: false, isError: false } },
    }
  case ActionConstants.venues.confirmVenueLoaded:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.venue.id]: { ...action.response.venue, isLoaded: true, isError: false } },
    }
  case ActionConstants.floors.confirmAddItem:
  case ActionConstants.floors.confirmEditItem:
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.path.venueId]: {
          ...state.loadedItems[action.path.venueId],
          floors: {
            ...state.loadedItems[action.path.venueId].floors,
            [action.response.id]: action.response,
          },
        },
      },
    }
  case ActionConstants.floors.confirmDeleteItem:
    if (state.loadedItems[action.path.venueId]
    && state.loadedItems[action.path.venueId].floors
    && state.loadedItems[action.path.venueId].floors[action.path.floorId]) {
      newFloors = { ...state.loadedItems[action.path.venueId].floors }
      delete newFloors[action.path.floorId]

      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.path.venueId]: {
            ...state.loadedItems[action.path.venueId],
            floors: newFloors,
          },
        },
      }
    }
    return state
  case ActionConstants.rooms.receiveItems:
    newVenues = { ...state.loadedItems }
    if (newVenues[action.path.venueId]
      && newVenues[action.path.venueId].floors
      && newVenues[action.path.venueId].floors[action.path.floorId]) {
      hash = {}
      action.items.forEach(item => {
        hash[item.id] = item
      })
      newVenues[action.path.venueId].floors[action.path.floorId].rooms = hash
    }
    return {
      ...state,
      loadedItems: newVenues,
    }
  case ActionConstants.rooms.confirmEditItem:
  case ActionConstants.rooms.confirmAddItem:
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.path.venueId]: {
          ...state.loadedItems[action.path.venueId],
          floors: {
            ...state.loadedItems[action.path.venueId].floors,
            [action.path.floorId]: {
              ...state.loadedItems[action.path.venueId].floors[action.path.floorId],
              rooms: {
                ...state.loadedItems[action.path.venueId].floors[action.path.floorId].rooms,
                [action.response.id]: action.response,
              }
            },
          },
        },
      },
    }
  case ActionConstants.rooms.confirmDeleteItem:
    if (state.loadedItems[action.path.venueId]
      && state.loadedItems[action.path.venueId].floors
      && state.loadedItems[action.path.venueId].floors[action.path.floorId]
      && state.loadedItems[action.path.venueId].floors[action.path.floorId].rooms
      && state.loadedItems[action.path.venueId].floors[action.path.floorId].rooms[action.path.roomId]) {
      newRooms = { ...state.loadedItems[action.path.venueId].floors[action.path.floorId].rooms }
      delete newRooms[action.path.roomId]

      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.path.venueId]: {
            ...state.loadedItems[action.path.venueId],
            floors: {
              ...state.loadedItems[action.path.venueId].floors,
              [action.path.floorId]: {
                ...state.loadedItems[action.path.venueId].floors[action.path.floorId],
                rooms: newRooms,
              }
            },
          },
        },
      }
    }
    return state
    default:
      return state
  }
}

export default venues
