import { Globals } from '../constants/Global'
import request from '../request'
import ActionConstants from '../constants/ActionConstants'

const notificationConst = ActionConstants.notifications

export const markAll = (status) => (dispatch) => {
  dispatch({
    type: notificationConst.sendMarkAll,
  })
  let newUrl = Globals.api.notifications + '?read=' + status
  return request.put(newUrl).then(response => {
    dispatch({
      type: notificationConst.confirmMarkAll,
      response: status,
    })
  })
}

export const getNotificationTallies = () => dispatch => {
  dispatch({
    type: notificationConst.requestNotificationTallies,
  })
  return request.get(Globals.api.notificationTallies).then(response => {
    dispatch({
      type: notificationConst.receiveNotificationTallies,
      response: response.data
    })
  })
}
