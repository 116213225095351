import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useDebounce from '../../hooks/useDebounce'
import TextField from '@material-ui/core/TextField'
import { OpenStreetMapSearch } from './InputComponents'
import _ from 'underscore'
import request from '../../request'
import { Globals } from '../../constants/Global'
import { formatLocation } from '../../utils/formatUtils'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'

const StyledPaper = styled(Paper)`
  display: flex;
  align-items: center;
  width: 100%;
`

const Suggestion = props => {
  const { query, suggestion, onClick } = props
  const matches = match(suggestion.name, query)
  const parts = parse(suggestion.name, matches)
  const handleClick = () => {
    onClick(suggestion)
  }
  return (
    <ListItem button component="div" onClick={handleClick}>
      <ListItemText
        primary={parts.map((part, index) => {
          return part.highlight ? (
            <span key={index} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={index} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          )
        })}
      />
    </ListItem>
  )
}

const LocationSearch = (props) => {
  const {
    disabled,
    disableUnderline,
    error,
    format,
    helperText,
    inputRef,
    onChange,
    onGetSuggestions,
    override,
    placeholder,
    skipVenues,
    updateLocation,
    ...rest
  } = props
  const [isSearching, setIsSearching] = useState(false)
  const [searchValue, setSearchValue] = useState(props.value || '')
  const [locations, setLocations] = useState([])
  const [inFocus, setInFocus] = useState(false)

  const debouncedSearchTerm = useDebounce(searchValue)
  const provider = new OpenStreetMapSearch()

  const sendToAction = async value => {
    if (value.length >= 1) {
      let response
      if (value.startsWith(':')) {
        response = value
          .slice(1)
          .split(',')
          .filter(e => !isNaN(Number(e)))
          .map(e => Number(e ? e : 0))
        response = await provider.reverse(response[0], response[1])
        if (response.error) {
          return false
        }
        return response
      } else {
        response = await provider.search(value)
        if (response.error) {
          return false
        }
        return response.raw
      }
    }
  }

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    let suggestions = []
    return new Promise((resolve, reject) => {
      if (provider && inputLength > 0) {
        let options = {
          params: { q: inputValue },
          data: {},
          query: {},
        }
        if (!skipVenues) {
          request.get(Globals.api.venues, options).then(
            response => {
              if (response.data.items.length > 0) {
                _.forEach(response.data.items, suggestion => {
                  if (
                    count < 5 &&
                    suggestion.latitude &&
                    (suggestion.name.toLowerCase().slice(0, inputLength) === inputValue ||
                      suggestion.street.toLowerCase().slice(0, inputLength) === inputValue)
                  ) {
                    count += 1
                    suggestion.description = suggestion.name
                    suggestions.push(suggestion)
                  }
                })
                if (onGetSuggestions) {
                  onGetSuggestions(suggestions)
                  resolve([])
                } else {
                  resolve(suggestions)
                }
              } else {
                sendToAction(inputValue).then(data => {
                  if (data && typeof data !== 'string') {
                    suggestions = data
                      .filter(suggestion => {
                        const keep = count < 5
                        if (keep) {
                          count += 1
                        }
                        return keep
                      })
                      .map(loc => {
                        return formatLocation(loc, format || 1)
                      })
                    if (onGetSuggestions) {
                      onGetSuggestions(suggestions)
                      resolve([])
                    } else {
                      resolve(suggestions)
                    }
                  } else {
                    if (onGetSuggestions) {
                      onGetSuggestions(suggestions)
                      resolve([])
                    } else {
                      resolve(suggestions)
                    }
                  }
                })
              }
            },
            error => {
              sendToAction(inputValue).then(data => {
                if (typeof data !== 'string') {
                  suggestions = data.filter(suggestion => {
                    let keep = count < 5
                    if (keep) {
                      count += 1
                    }
                    return keep
                  })
                  if (onGetSuggestions) {
                    onGetSuggestions(suggestions)
                    resolve([])
                  } else {
                    resolve(suggestions)
                  }
                } else {
                  if (onGetSuggestions) {
                    onGetSuggestions(suggestions)
                    resolve([])
                  } else {
                    resolve(suggestions)
                  }
                }
              })
            }
          )
        } else {
          sendToAction(inputValue).then(data => {
            if (data && typeof data !== 'string') {
              suggestions = data
                .filter(suggestion => {
                  const keep = count < 5
                  if (keep) {
                    count += 1
                  }
                  return keep
                })
                .map(loc => {
                  return formatLocation(loc, format || 1)
                })
              if (onGetSuggestions) {
                onGetSuggestions(suggestions)
                resolve([])
              } else {
                resolve(suggestions)
              }
            } else {
              if (onGetSuggestions) {
                onGetSuggestions(suggestions)
                resolve([])
              } else {
                resolve(suggestions)
              }
            }
          })
        }
      } else {
        if (onGetSuggestions) {
          onGetSuggestions(suggestions)
          resolve([])
        } else {
          resolve(suggestions)
        }
      }
    })
  }

  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm.length > 0) {
        setIsSearching(true)
        getSuggestions(debouncedSearchTerm).then(results => {
          setLocations(results)
          setIsSearching(false)
        })
      } else {
        setLocations([])
        setIsSearching(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm]
  )

  const handleLocationSelect = location => {
    if (!_.isUndefined(location.place_id) && !skipVenues) {
      request.post(Globals.api.venues, location).then(response => {
        if (response.data) {
          updateLocation(response.data)
        } else {
          updateLocation(location)
        }
      })
    } else {
      updateLocation(location)
    }
  }

  const getSuggestionValue = suggestion => {
    handleLocationSelect(suggestion)
  }
  
  const renderSuggestions = () => {
    return locations.map(loc => (
      <Suggestion
        key={loc.id || loc.place_id}
        onClick={getSuggestionValue}
        query={searchValue}
        suggestion={loc}
      />
    ))
  }

  const handleOnBlur = () => {
    setTimeout(() => {
      setInFocus(false)
    }, 100)
  }

  const handleChange = (e) => {
    setSearchValue(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        disabled={disabled}
        disableUnderline={disableUnderline}
        error={error}
        helperText={helperText}
        onChange={handleChange}
        onFocus={() => setInFocus(true)}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        value={searchValue || override}
        {...rest}
      />
      {(isSearching || (locations && locations.length && locations.length > 0 && inFocus)) ? (
        <StyledPaper>
          {(locations && locations.length && locations.length > 0) ? <List>{renderSuggestions()}</List> : null}
        </StyledPaper>
      ) : null}
    </div>
  )
}

export default LocationSearch
