import * as commentSchema from './CommentSchemas'
import * as userSchema from './UserSchemas'

const schemas = {
  comments: commentSchema.comment,
  contacts: userSchema.user,
  contactRequests: userSchema.user,
  contactRequestsSent: userSchema.user,
  users: userSchema.user,
  userCategories: userSchema.userCategory,
  userGuestLists: userSchema.userGuestList,
  userNotifications: userSchema.userNotification,
  userSettings: userSchema.userSettings,
}

export { schemas }
