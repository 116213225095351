import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const invitesConst = ActionConstants.invites

function invites(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: null,
    canInvite: false,
    items: [],
    tempItems: [],
    counts: [],
    userInvite: {
      status: null,
    },
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case invitesConst.invalidateItems:
      return {
        ...state,
        items: [],
        didInvalidate: true,
      }
    case invitesConst.invalidateTempItems:
    case ActionConstants.eventExternalInvites.invalidateTempItems:
      return {
        ...state,
        tempItems: [],
        didInvalidate: true,
      }
    case ActionConstants.users.receiveInvite:
      return {
        ...state,
        userInvite: action.response,
      }
    case invitesConst.requestItem:
    case invitesConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case invitesConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case ActionConstants.eventExternalInvites.confirmAddItems:
    case invitesConst.confirmAddItems:
      let aggItems = dataUtils.appendArray(state.items, action.items)
      aggItems.sort(function(a, b) {
        let nameA = a.user ? a.user.name.toUpperCase() : a.externalUser.name
        let nameB = b.user ? b.user.name.toUpperCase() : b.externalUser.name
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      return {
        ...state,
        items: aggItems,
        lastUpdated: action.receivedAt,
      }
    case ActionConstants.eventExternalInvites.confirmAddTempItems:
    case invitesConst.confirmAddTempItems:
      let aggTempItems = dataUtils.appendArray(state.tempItems, action.items)
      aggTempItems.sort(function(a, b) {
        let nameA = a.user ? a.user.name.toUpperCase() : a.externalUser.name
        let nameB = b.user ? b.user.name.toUpperCase() : b.externalUser.name
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
      return {
        ...state,
        tempItems: aggTempItems,
        lastUpdated: action.receivedAt,
      }
    case invitesConst.receiveItem:
    case invitesConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case invitesConst.sendAddItem:
    case invitesConst.sendDeleteItem:
    case invitesConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case invitesConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case invitesConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case invitesConst.receiveInvitesCount:
      return {
        ...state,
        counts: action.response,
      }
    case invitesConst.itemFailed:
    case invitesConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.eventInvitableFriends.receiveItems:
      return {
        ...state,
        canInvite: action.items && action.items.length > 0,
      }
    case ActionConstants.feed.confirmRSVPToEvent:
      return {
        ...state,
        userInvite: action.response,
      }
    default:
      return state
  }
}

export default invites
