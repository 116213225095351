import ActionConstants from '../constants/ActionConstants'

const imagesConst = ActionConstants.images

function images(
  state = {
    isFetchingImageUrls: false,
    isFetchingCoverImageUrls: false,
    isUploading: false,
    didInvalidate: false,
    coverImageUrls: {
      downloadUrl: null,
      uploadUrl: null,
    },
    imageUrls: {
      downloadUrl: null,
      uploadUrl: null,
    },
    stagedImage: {
      file: null,
      fileName: '',
      fileType: null,
      preview: null,
    },
    stagedCoverImage: {
      file: null,
      fileName: '',
      fileType: null,
      preview: null,
    },
    errorMessageImageUrls: null,
    errorMessageCoverImageUrls: null,
  },
  action
) {
  switch (action.type) {
    case imagesConst.requestImageUrls:
      return {
        ...state,
        isFetchingImageUrls: true,
        errorMessageImageUrls: null,
      }
    case imagesConst.requestCoverImageUrls:
      return {
        ...state,
        isFetchingCoverImageUrls: true,
        errorMessageCoverImageUrls: null,
      }
    case imagesConst.receiveImageUrls:
      return {
        ...state,
        isFetchingImageUrls: false,
        imageUrls: action.response,
        errorMessageImageUrls: null,
      }
    case imagesConst.receiveCoverImageUrls:
      return {
        ...state,
        isFetchingCoverImageUrls: false,
        coverImageUrls: action.response,
        errorMessageCoverImageUrls: null,
      }
    case imagesConst.imageUrlsFailed:
      return {
        ...state,
        imageUrls: {
          downloadUrl: null,
          uploadUrl: null,
        },
        errorMessageImageUrls: action.errorMessage,
      }
    case imagesConst.coverImageUrlsFailed:
      return {
        ...state,
        coverImageUrls: {
          downloadUrl: null,
          uploadUrl: null,
        },
        errorMessageCoverImageUrls: action.errorMessage,
      }
    case imagesConst.stageImage:
      return {
        ...state,
        stagedImage: {
          file: action.response.file,
          fileName: action.response.fileName,
          fileType: action.response.fileType,
          preview: action.response.preview,
        },
      }
    case imagesConst.clearStagedImage:
      return {
        ...state,
        stagedImage: {
          file: null,
          fileName: '',
          fileType: null,
          preview: null,
        },
      }
    case imagesConst.stageCoverImage:
      return {
        ...state,
        stagedCoverImage: {
          file: action.response.file,
          fileName: action.response.fileName,
          fileType: action.response.fileType,
          preview: action.response.preview,
        },
      }
    case imagesConst.clearStagedCoverImage:
      return {
        ...state,
        stagedCoverImage: {
          file: null,
          fileName: '',
          fileType: null,
          preview: null,
        },
      }
    default:
      return state
  }
}

export default images
