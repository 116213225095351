import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const subscriptionConst = ActionConstants.subscriptions

function subscriptions(
  state = {
    isFetching: false,
    didInvalidate: false,
    selectedItem: null,
    items: [],
    seriesSubscriptions: [],
    otherUserItems: [],
    errorMessage: null,
  },
  action
) {
  let index
  switch (action.type) {
    case subscriptionConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case subscriptionConst.requestItem:
    case subscriptionConst.requestItems:
    case subscriptionConst.requestSeriesSubscriptions:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case subscriptionConst.confirmAddItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: dataUtils.appendArray(state.items, action.items),
        lastUpdated: action.receivedAt,
      }
    case subscriptionConst.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      }
    case subscriptionConst.receiveSeriesSubscriptions:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        seriesSubscriptions: action.items,
        lastUpdated: action.receivedAt,
      }
    case subscriptionConst.receiveItem:
    case subscriptionConst.confirmEditItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
        isFetching: false,
        selectedItem: action.response,
      }
    case subscriptionConst.sendAddItem:
    case subscriptionConst.sendDeleteItem:
    case subscriptionConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case subscriptionConst.confirmDeleteItem:
      index = _.findIndex(state.items, e => {
        return e.id === action.response.id
      })
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        isFetching: false,
      }
    case subscriptionConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case subscriptionConst.itemFailed:
    case subscriptionConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.otherUserSubscriptions.receiveItems:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        otherUserItems: action.items,
        lastUpdated: action.receivedAt,
      }
  case ActionConstants.doorSubscribers.confirmEditItem:
    index = _.findIndex(state.items, e => {
      if (e.business && e.business.id === action.path.doorId) {
        return e
      }
    })
    if (index > -1) {
      return {
        ...state,
        items: dataUtils.updateObjectInArray(state.items, action.response, index),
      }
    }
    return state
  case ActionConstants.doorSubscribers.confirmAddItem:
    return {
      ...state,
      items: [...state.items, action.response.item],
    }
  case ActionConstants.doorSubscribers.confirmDeleteItem:
    index = _.findIndex(state.items, e => {
      if (e.business && e.business.id === action.path.doorId) {
        return e
      }
    })
    if (index > -1) {
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
      }
    }
    return state
    default:
      return state
  }
}

export default subscriptions
