import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { DMCheckBox } from '../../components/inputs/InputComponents'

export default function InviteContactItem({
  friend,
  onChecked,
  selected,
}) {
  return (
    <ListItem disableGutters button onClick={() => onChecked(friend)}>
      <ListItemAvatar>
        <Avatar src={friend.imageUrl} alt={friend.name} />
      </ListItemAvatar>
      <ListItemText primary={friend.name} />
      <ListItemSecondaryAction>
        <DMCheckBox
          edge="end"
          checked={selected}
          tabIndex={-1}
          disableRipple
          onChange={() => onChecked(friend)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}