import React from 'react'
import Icon from '@material-ui/core/Icon'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import { Globals } from '../../constants/Global'
import UIActionConstants from '../../constants/UIActionConstants'
import ActionConstants from '../../constants/ActionConstants'
import _ from 'underscore'
import Menu from '@material-ui/icons/Menu'
import { store } from '../../store/providers'
import { removeItem } from '../../utils/dataUtils'
import Badge from '@material-ui/core/Badge'
import MoveToInbox from '@material-ui/icons/MoveToInbox'
import { selectNotificationTallies } from '../../selectors/NotificationsSelectors'
import history from '../../history'
import { openFullScreenDialog } from '../../actions/UI/AppActions'
import { selectCurrentUser } from '../../selectors/UsersSelectors'

const DefaultRightIcons = () => {
  const tallies = useSelector(selectNotificationTallies)
  const currentUser = useSelector(selectCurrentUser)
  let tally
  if (currentUser && currentUser.info && tallies && tallies[currentUser.info.id]) {
    tally = tallies[currentUser.info.id]
  }
  const dispatch = useDispatch()

  return (
    (
      <>
        <IconButton color="primary" onClick={() => history.push('/notifications')}>
          <Badge badgeContent={tally && tally.unread} color="secondary" variant="dot" invisible={!tally || (tally && tally.unread === 0)}>
            <MoveToInbox />
          </Badge>
        </IconButton>
        <IconButton color="primary" onClick={() => dispatch(openFullScreenDialog({ dialog: 'search' }))}>
          <Icon className="fa fa-search" />
        </IconButton>
      </>
    )
  )
}

const fullScreenDialogs = {}

function app(
  state = {
    currentView: null,
    showMenuIcon: true,
    responsive: false,
    leftIcon: (
      <IconButton
        edge="start"
        color="primary"
        aria-label="Menu"
        onClick={() => {
          store.dispatch({ type: UIActionConstants.sideMenu.TOGGLE_MENU })
        }}
      >
        <Menu />
      </IconButton>
    ),
    fullScreenDialogs,
    subHeader: null,
    open: false,
    tabs: false,
    displaySearch: false,
    search: '',
    showLoginModal: false,
    showSearch: false,
    rightIcons: <DefaultRightIcons />,
    notifications: [],
    pendingRequests: null,
    viewMode: 'desktop',
    currentLocation: null,
  },
  action
) {
  let notifications
  let index
  let newDialogs
  switch (action.type) {
    case UIActionConstants.header.SET_CURRENT_VIEW:
      let { currentView, tabs, rightMenu, leftIcon, responsive, subHeader } = action.payload
      let showMenuIcon = true
      if (Globals.noTitleViews.indexOf(currentView) > -1) {
        showMenuIcon = false
      }
      return {
        ...state,
        currentView: currentView,
        tabs: tabs,
        rightIcons: rightMenu || <DefaultRightIcons />,
        leftIcon: leftIcon || (
          <IconButton
            edge="start"
            color="primary"
            aria-label="Menu"
            onClick={() => {
              store.dispatch({ type: UIActionConstants.sideMenu.TOGGLE_MENU })
            }}
          >
            <Menu />
          </IconButton>
        ),
        showMenuIcon: showMenuIcon,
        responsive: _.isUndefined(responsive) ? false : responsive,
        subHeader: subHeader || null,
      }
    case UIActionConstants.sideMenu.CLOSE_MENU:
      return {
        ...state,
        open: false,
      }
    case UIActionConstants.sideMenu.OPEN_MENU:
      return {
        ...state,
        open: true,
      }
    case UIActionConstants.sideMenu.TOGGLE_MENU:
      return {
        ...state,
        open: !state.open,
      }
    case UIActionConstants.header.SEARCH:
      return {
        ...state,
        search: action.searchText,
      }
    case UIActionConstants.header.TOGGLE_SEARCH:
      return {
        ...state,
        displaySearch: action.payload,
      }
    case UIActionConstants.header.SUBMIT_SEARCH:
      return {
        ...state,
        displaySearch: false,
        search: '',
      }
    case ActionConstants.users.showLoginModal:
      return {
        ...state,
        showLoginModal: true,
      }
    case ActionConstants.users.hideLoginModal:
      return {
        ...state,
        showLoginModal: false,
      }
    case UIActionConstants.notifications.SET_NOTIFICATIONS:
      notifications = action.notifications.map((note, i) => {
        return { id: i, ...note }
      })
      return {
        ...state,
        notifications,
      }
    case UIActionConstants.notifications.DISMISS_NOTIFICATION:
      index = _.findIndex(state.notifications, {
        id: action.notification.id,
      })
      return {
        ...state,
        notifications: removeItem(state.notifications, index),
      }
    case UIActionConstants.notifications.CLEAR_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      }
  case UIActionConstants.sideMenu.TOGGLE_VIEW_MODE:
    return {
      ...state,
      viewMode: state.viewMode === 'mobile' ? 'desktop' : 'mobile',
    }
  case 'SET_PENDING_REQUEST_COUNT':
    return {
      ...state,
      pendingRequests: action.payload,
    }
  case 'OPEN_FULL_SCREEN_DIALOG':
    let { dialog, ...rest } = action.payload
    return {
      ...state,
      fullScreenDialogs: {
        ...state.fullScreenDialogs,
        [dialog]: {
          mounted: true,
          open: true,
          ...rest,
        }
      }
    }
  case 'CLOSE_FULL_SCREEN_DIALOG':
    newDialogs = { ...state.fullScreenDialogs }
    if (newDialogs[action.payload.dialog]) {
      delete newDialogs[action.payload.dialog]
    }
    return {
      ...state,
      lastOpened: null,
      fullScreenDialogs: newDialogs,
    }
  case 'DISMOUNT_FULL_SCREEN_DIALOG':
    return {
      ...state,
      fullScreenDialogs: {
        ...state.fullScreenDialogs,
        [action.payload.dialog]: {
          mounted: false,
          open: false,
        }
      }
    }
  case 'SET_USER_LOCATION':
    return {
      ...state,
      currentLocation: action.payload,
    }
  case 'USER_LOCATION_ERROR':
    return {
      ...state,
      currentLocation: {
        error: 'Could not find your current location!',
      }
    }
  case 'STORE_INSTALL_PROMPT':
    return {
      ...state,
      installPrompt: action.payload,
    }
    default:
      return state
  }
}

export default app
