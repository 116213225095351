import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import * as dataUtils from '../utils/dataUtils'

const doorConst = ActionConstants.doors

function doors(
  state = {
    isFetching: false,
    didInvalidate: false,
    lastUpdated: null,
    selectedItem: {
      id: null,
      owner: null,
      name: null,
      unlisted: false,
      venue: null,
      websiteUrl: null,
      phone: null,
      photos: [],
      imageUrl: null,
      coverImageUrl: null,
      description: null,
      series: [],
      events: [],
    },
    isFetchingImageUrls: false,
    items: [],
    loadedItems: {},
    errorMessage: null,
    recommendations: [],
  },
  action
) {
  let index
  let newDoors
  let newSeries
  let newHosts
  switch (action.type) {
    case doorConst.selectItem:
      return {
        ...state,
        selectedItem: action.item,
      }
    case doorConst.invalidateItems:
      return {
        ...state,
        didInvalidate: true,
      }
    case doorConst.requestItem:
    case doorConst.requestItems:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      }
    case doorConst.receiveItems:
      const loadedDoors = { ...state.loadedItems }
      action.items.forEach(item => {
        let oldDoor = loadedDoors[item.id]
        if (!oldDoor) {
          oldDoor = {
            bans: {},
            blackLists: {},
            categories: {},
            comments: {},
            guestLists: {},
            managers: {},
            presentedEvents: {},
            presenterHostRequests: {},
            presenterRequestOverrides: {},
            roles: {},
            series: {},
            subscribers: {},
            venues: {},
          }
        }
        loadedDoors[item.id] = {
          ...loadedDoors[item.id],
          bans: item.bans || (oldDoor && oldDoor.bans) || {},
          blackLists: item.blackLists || (oldDoor && oldDoor.blackLists) || {},
          categories: item.categories || (oldDoor && oldDoor.categories) || {},
          comments: item.comments || (oldDoor && oldDoor.comments) || {},
          guestLists: item.guestLists || (oldDoor && oldDoor.guestLists) || {},
          managers: item.managers || (oldDoor && oldDoor.managers) || {},
          presentedEvents: item.presentedEvents || (oldDoor && oldDoor.presentedEvents) || {},
          presenterHostRequests: item.presenterHostRequests || (oldDoor && oldDoor.presenterHostRequests) || {},
          presenterRequestOverrides: item.presenterRequestOverrides || (oldDoor && oldDoor.presenterRequestOverrides) || {},
          roles: item.roles || (oldDoor && oldDoor.roles) || {},
          series: item.series || (oldDoor && oldDoor.series) || {},
          subscribers: item.subscribers || (oldDoor && oldDoor.subscribers) || {},
          venues: item.venues || (oldDoor && oldDoor.venues) || {},
          isLoaded: true,
          ...item,
        }
      })
      return {
        ...state,
        loadedItems: loadedDoors,
      }
    case doorConst.receiveItem:
    case doorConst.confirmEditItem:
      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.response.id]: { ...state.loadedItems[action.response.id], ...action.response },
        }
      }
    case doorConst.sendAddItem:
    case doorConst.sendDeleteItem:
    case doorConst.sendEditItem:
      return {
        ...state,
        isFetching: true,
      }
    case doorConst.confirmDeleteItem:
      index = _.findIndex(state.items, {
        id: action.response.id,
      })
      newDoors = { ...state.loadedItems }
      if (newDoors[action.response.id]) {
        delete newDoors[action.response.id]
      }
      return {
        ...state,
        items: dataUtils.removeItem(state.items, index),
        loadedItems: newDoors,
        isFetching: false,
      }
    case doorConst.confirmAddItem:
      return {
        ...state,
        items: dataUtils.insertItem(state.items, action.response, 0),
        isFetching: false,
      }
    case doorConst.confirmAddItems:
      return {
        ...state,
        items: dataUtils.appendArray(state.items, action.items),
        isFetching: false,
      }
    case ActionConstants.doorSeries.receiveItems:
      let selectedSeries = state.selectedItem
      selectedSeries.series = action.items
      return {
        ...state,
        selectedItem: selectedSeries,
      }
    case doorConst.itemFailed:
    case doorConst.itemsFailed:
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    case ActionConstants.images.requestDoorImageUrls:
    case ActionConstants.images.sendUploadDoorImage:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          isFetchingImageUrls: true,
        },
      }
    case ActionConstants.images.receiveDoorImageUrls:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          isFetchingImageUrls: false,
          imageUrls: action.response,
        },
      }
  case ActionConstants.doors.receiveRecommendations:
    return {
      ...state,
      recommendations: action.items,
    }
  case ActionConstants.doors.sendDoorLoading:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: false, isError: false } },
    }
  case ActionConstants.doors.confirmDoorLoaded:
    return {
      ...state,
      loadedItems: { ...state.loadedItems, [action.response.id]: { ...action.response, isLoaded: true, isError: false } },
    }
  case ActionConstants.series.confirmAddItem:
  case ActionConstants.series.confirmEditItem:
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.response.owner.id]: {
          ...state.loadedItems[action.response.owner.id],
          series: {
            ...state.loadedItems[action.response.owner.id].series,
            [action.response.id]: action.response,
          }
        }
      }
    }
  case ActionConstants.series.confirmDeleteItem:
    if (state.loadedItems[action.path.doorId]
      && state.loadedItems[action.path.doorId].series
      && state.loadedItems[action.path.doorId].series[action.path.seriesId]) {
      newSeries = { ...state.loadedItems[action.path.doorId].series }
      delete newSeries[action.path.seriesId]

      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.path.doorId]: {
            ...state.loadedItems[action.path.doorId],
            series: newSeries,
          },
        },
      }
    }
    return state
  case ActionConstants.doorSeriesHosts.confirmAddItem:
  case ActionConstants.doorSeriesHosts.confirmEditItem:
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.path.doorId]: {
          ...state.loadedItems[action.path.doorId],
          series: {
            ...state.loadedItems[action.path.doorId].series,
            [action.path.seriesId]: {
              ...state.loadedItems[action.path.doorId].series[action.path.seriesId],
              hosts: {
                ...state.loadedItems[action.path.doorId].series[action.path.seriesId].hosts,
                [action.response.id]: action.response,
              }
            },
          }
        }
      }
    }
  case ActionConstants.doorSeriesHosts.confirmDeleteItem:
    if (state.loadedItems[action.path.doorId]
      && state.loadedItems[action.path.doorId].series
      && state.loadedItems[action.path.doorId].series[action.path.seriesId]
      && state.loadedItems[action.path.doorId].series[action.path.seriesId].hosts
      && state.loadedItems[action.path.doorId].series[action.path.seriesId].hosts[action.path.hostId]) {
      newHosts = { ...state.loadedItems[action.path.doorId].series[action.path.seriesId].hosts }
      delete newHosts[action.path.hostId]

      return {
        ...state,
        loadedItems: {
          ...state.loadedItems,
          [action.path.doorId]: {
            ...state.loadedItems[action.path.doorId],
            series: {
              ...state.loadedItems[action.path.doorId].series[action.payload.seriesId],
              hosts: newHosts,
            },
          },
        },
      }
    }
    return state
    default:
      return state
  }
}

export default doors
