import ActionConstants from '../constants/ActionConstants'
import _ from 'underscore'
import moment from 'moment'
import request from '../request'
import { Globals } from '../constants/Global'

export const loadFeed = (payload) => dispatch => {
  const { q, startTime, endTime, radius, latitude, longitude, categoryIds, offset, limit, skipFeed } = payload
  let start
  let days
  const feedParams = {}
  const eventsParams = {}

  if (q) {
    eventsParams.name = q
  }
  if (limit) {
    feedParams.limit = limit
    eventsParams.limit = limit
  }
  if (offset !== undefined && offset !== null) {
    feedParams.offset = offset
    eventsParams.offset = offset
  }
  if (startTime && endTime) {
    start = startTime
    days = moment(endTime).diff(moment(startTime), 'days')
    eventsParams.start = startTime
    eventsParams.end = endTime
  }
  if (start && days) {
    feedParams.start = startTime
    feedParams.days = days
  }
  if (latitude && longitude) {
    feedParams.latitude = latitude
    eventsParams.latitude = latitude
    feedParams.longitude = longitude
    eventsParams.longitude = longitude
  }
  if (radius) {
    feedParams.radius = Math.ceil(radius)
    eventsParams.radius = Math.ceil(radius)
  }
  if (categoryIds) {
    feedParams.categoryIds = categoryIds
    eventsParams.categoryIds = categoryIds
  }

  return Promise.allSettled([
    request.get(Globals.api.feed, { params: feedParams }),
    request.get(Globals.api.events, { params: eventsParams }),
    request.get(Globals.api.invites, { params: { limit: 1000 }})
  ]).then(values => {
    let feed = values[0] && values[0].status && values[0].status === 'fulfilled' ? values[0].value : null
    let events = values[1] && values[1].status && values[1].status === 'fulfilled' ? values[1].value : null
    let invites = values[2] && values[2].status && values[2].status === 'fulfilled' ? values[2].value : null
    const hasMoreFeed = skipFeed ? false : feed && feed.data && feed.data.hasNextPage
    const hasMoreEvents = events && events.data && events.data.hasNextPage
    feed = feed && feed.data && feed.data.items ? feed.data.items.map(event => ({ ...event.event })) : null
    events = events && events.data && events.data.items ? events.data.items : null
    invites = invites && invites.data && invites.data.items ? invites.data.items: null
    let eventsList = []
    if (feed && feed.length && feed.length > 0 && !skipFeed) {
      eventsList = [...feed]
    }
    if (events && events.length && events.length > 0) {
      eventsList = [...eventsList, ...events]
    }
    eventsList = _.uniq(eventsList, false, (event) => {
      return event.id
    }).sort((a, b) => {
      if (a.startTime < b.startTime) {
        return -1
      }
      if (a.startTime > b.startTime) {
        return 1
      }
      return 0
    }).map(event => {
      const eventItem = { event, status: null }
      const eventInvite = _.find(invites, (invite) => {
        if (invite && invite.event && invite.event.id === eventItem.event.id) {
          return invite
        }
      })
      if (eventInvite) {
        eventItem.status = eventInvite.status
      }
      return eventItem
    })
    dispatch({
      type: ActionConstants.feed.receiveItems,
      items: eventsList,
    })
    return {
      eventsList,
      hasNextPage: hasMoreEvents || hasMoreFeed,
    }
  })
}
