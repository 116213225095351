import ActionConstants from '../constants/ActionConstants'
import request from '../request'
import { formatUrl } from '../utils/RESTUtils'
import { Globals } from '../constants/Global'

const catConst = ActionConstants.categories

export const addSupercategoryLink = (path, cat) => dispatch => {
  dispatch({
    type: catConst.sendLinkSupercategory,
  })
  return request.post(formatUrl(Globals.api.superCategoryLink, path)).then(
    response => {
      dispatch({
        type: catConst.receiveLinkSupercategory,
        response: cat,
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: catConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const deleteSupercategoryLink = (path, cat) => dispatch => {
  dispatch({
    type: catConst.sendUnlinkSupercategory,
  })
  return request.delete(formatUrl(Globals.api.superCategoryLink, path)).then(
    response => {
      dispatch({
        type: catConst.receiveUnlinkSupercategory,
        response: cat,
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: catConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const setAliasOf = payload => dispatch => {
  dispatch({
    type: catConst.sendAliasOf,
  })
  let path = {
    categoryId: payload.categoryId.id,
  }
  let options = {
    ...payload.categoryId,
    aliasOf:
      payload.otherCategoryId === 'null'
        ? null
        : { id: payload.otherCategoryId.id, name: payload.otherCategoryId.name },
  }
  return request.put(formatUrl(Globals.api.category, path), options).then(
    response => {
      let newCat = {
        ...payload.categoryId,
        aliasOf:
          payload.otherCategoryId === 'null'
            ? null
            : { id: payload.otherCategoryId.id, name: payload.otherCategoryId.name },
      }
      dispatch({
        type: catConst.receiveAliasOf,
        response: newCat,
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: catConst.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const setCategoryVisited = (payload) => dispatch => {
  dispatch({
    type: catConst.sendToggleRevisit
  })
  let path = {
    categoryId: payload.categoryId.id,
  }
  return request.put(formatUrl(Globals.api.category, path), payload.categoryId).then(response => {
    dispatch({
      type: catConst.confirmToggleVisit,
      response: payload.categoryId,
    })
  })
}

export const toggleColumns = () => dispatch => {
  dispatch({
    type: catConst.toggleColumns,
  })
}

export const toggleRevisitFiltered = () => dispatch => {
  dispatch({
    type: catConst.toggleRevisitFiltered,
  })
}

export const toggleDarkMode = () => dispatch => {
  dispatch({
    type: catConst.toggleDarkMode,
  })
}

export const toggleRevisit = payload => dispatch => {
  dispatch({
    type: catConst.sendToggleRevisit,
  })
  let path = {
    categoryId: payload.id,
  }
  return request.put(formatUrl(Globals.api.category, path), payload).then(
    response => {
      dispatch({
        type: catConst.confirmToggleRevisit,
        response: payload,
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: catConst.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const toggleSoftDelete = payload => dispatch => {
  dispatch({
    type: catConst.sendToggleRevisit,
  })
  let path = {
    categoryId: payload.id,
  }
  let options = {
    ...payload,
    deleted: !payload.deleted,
  }
  return request.put(formatUrl(Globals.api.category, path), options).then(
    response => {
      let newCat = {
        ...payload,
        deleted: !payload.deleted,
      }
      dispatch({
        type: catConst.confirmToggleRevisit,
        response: newCat,
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: catConst.itemFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const highlightCell = payload => dispatch => {
  let row = payload.row
  let rowIndex = payload.rowIndex
  let lastRow = payload.lastRow || null
  let lastIndex = payload.lastIndex || null
  localStorage.setItem('lastCatRow', row.rowNumber.toString())
  dispatch({
    type: catConst.highlightCell,
    response: {
      row,
      rowIndex,
      lastRow,
      lastIndex,
    },
  })
}

export const fetchTempCategories = payload => (dispatch, getState) => {
  let { dataType, url, path, params } = payload
  const { itemsPerPage } = getState().categories
  dispatch({
    type: ActionConstants.categories.requestItems,
  })
  let options = {
    params: { ...params, limit: itemsPerPage },
  }
  return request.get(Globals.api.categoryCounts, options).then(response => {
    dispatch({
      type: ActionConstants.categories.receiveCategoryCounts,
      response: response.data.value,
    })
    return request.get(formatUrl(url, path), options).then(
      response => {
        dispatch({
          type: ActionConstants.categories.receiveTempItems,
          items: response.data.items,
          hasNextPage: response.data.hasNextPage,
          receivedAt: Date.now(),
        })
        return response.data
      },
      error => {
        dispatch({
          type: ActionConstants[dataType].itemsFailed,
          message: error.message || 'Something went wrong.',
        })
      }
    )
  })
}

export const fetchCategoryLinks = payload => dispatch => {
  const { path, params, data, query } = payload
  let options = {
    params: params || {},
    data: data || {},
    query: query || {},
  }
  dispatch({
    type: ActionConstants.categories.requestCategoryLinks,
  })
  return request.get(formatUrl(Globals.api.categoryLinks, path), options).then(response => {
    dispatch({
      type: ActionConstants.categories.receiveCategoryLinks,
      items: response.data.items,
      receivedAt: Date.now(),
    })
    return response.data.items
  })
}

export const clearTempCategories = payload => (dispatch, getState) => {
  const { itemsPerPage, savedPage } = getState().categories
  dispatch({
    type: ActionConstants.categories.requestItems,
  })
  let options = {
    params: { q: '', offset: savedPage * itemsPerPage, limit: itemsPerPage },
  }
  return request.get(Globals.api.categoryCounts, options).then(response => {
    dispatch({
      type: ActionConstants.categories.receiveCategoryCounts,
      response: response.data.value,
    })
    return request.get(Globals.api.categories, options).then(
      response => {
        dispatch({
          type: ActionConstants.categories.receiveItems,
          items: response.data.items,
          hasNextPage: response.data.hasNextPage,
          receivedAt: Date.now(),
        })
        return response.data
      },
      error => {
        dispatch({
          type: ActionConstants['categories'].itemsFailed,
          message: error.message || 'Something went wrong.',
        })
      }
    )
  })
}

export const clearCategoryLinks = () => dispatch => {
  dispatch({
    type: ActionConstants.superSubCategories.clearCategoryLinks,
  })
}

export const deleteCategoryLink = (path, item, silent) => dispatch => {
  if (!silent) {
    dispatch({
      type: catConst.sendDeleteCategoryLink,
      item,
    })
  }
  return request.delete(formatUrl(Globals.api.categoryLink, path)).then(() => {
    dispatch({
      type: catConst.confirmDeleteCategoryLink,
      item,
    })
  })
}

export const addCategoryLink = (path, item, silent) => dispatch => {
  if (!silent) {
    dispatch({
      type: catConst.sendAddCategoryLink,
      item,
    })
  }
  return request.post(formatUrl(Globals.api.categoryLink, path)).then(() => {
    dispatch({
      type: catConst.confirmAddCategoryLink,
      item,
    })
  })
}

export const changePage = payload => (dispatch, getState) => {
  const limit = getState().categories.itemsPerPage
  const offset = payload * limit
  dispatch({
    type: ActionConstants.categories.changePage,
    page: payload,
  })
  let options = {
    params: {
      offset,
      limit,
    },
    data: {},
    query: {},
  }
  return request.get(formatUrl(Globals.api.categories), options).then(
    response => {
      dispatch({
        type: ActionConstants.categories.receiveItems,
        items: response.data.items,
        hasNextPage: response.data.hasNextPage,
        receivedAt: Date.now(),
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants['categories'].itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const changeItemsPerPage = payload => (dispatch, getState) => {
  const offset = getState().categories.currentPage * getState().categories.itemsPerPage
  dispatch({
    type: 'CHANGE_ITEMS_PER_PAGE',
    itemsPerPage: payload,
  })
  let options = {
    params: {
      offset,
      limit: payload,
    },
    data: {},
    query: {},
  }
  return request.get(formatUrl(Globals.api.categories), options).then(
    response => {
      dispatch({
        type: ActionConstants.categories.receiveItems,
        items: response.data.items,
        hasNextPage: response.data.hasNextPage,
        receivedAt: Date.now(),
        limit: payload,
      })
      return response.data
    },
    error => {
      dispatch({
        type: ActionConstants['categories'].itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchCategoryCounts = payload => dispatch => {
  const { url } = payload
  dispatch({
    type: ActionConstants.categories.requestCategoryCounts,
  })
  return request.get(formatUrl(url)).then(response => {
    dispatch({
      type: ActionConstants.categories.receiveCategoryCounts,
      response: response.data.value,
    })
  })
}

export const selectCatGameCat = (payload, number) => dispatch => {
  dispatch({
    type: ActionConstants.catGames.selectCatGameCat,
    number: number,
    payload: payload,
  })
}

export const removeCatGameCat = number => dispatch => {
  dispatch({
    type: ActionConstants.catGames.removeCatGameCat,
    number: number,
  })
}

export const startCatGame = (startId, endId) => dispatch => {
  dispatch({
    type: ActionConstants.catGames.sendAddItem,
  })
  return request.post(Globals.api.categoryGameNew + `?startId=${startId}&endId=${endId}`).then(
    response => {
      dispatch({
        type: ActionConstants.catGames.confirmAddItem,
        response: response.data,
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: catConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const getCatGame = path => dispatch => {
  dispatch({
    type: ActionConstants.catGames.requestItem,
  })
  return request.get(formatUrl(Globals.api.categoryGame, path)).then(
    response => {
      dispatch({
        type: ActionConstants.catGames.receiveItem,
        response: response,
        receivedAt: Date.now(),
      })
    },
    error => {
      dispatch({
        type: catConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const makeCatGameMove = (gameId, moveId) => dispatch => {
  dispatch({
    type: ActionConstants.catGames.makeMove,
  })
  return request.put(formatUrl(Globals.api.categoryGame, { gameId: gameId }) + `/?moveId=${moveId}`).then(
    response => {
      dispatch({
        type: ActionConstants.catGames.confirmMove,
        response: response.data,
        receivedAt: Date.now(),
      })
      return response.data
    },
    error => {
      dispatch({
        type: catConst.itemsFailed,
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const resumeGame = game => dispatch => {
  dispatch({
    type: ActionConstants.catGames.selectItem,
    response: game,
  })
}

export const storeItem = item => dispatch => {
  dispatch({
    type: ActionConstants.categories.storeItem,
    response: item,
  })
}

export const removeStoredItem = item => dispatch => {
  dispatch({
    type: ActionConstants.categories.removeStoredItem,
    response: item,
  })
}

export const clearStoredItems = () => dispatch => {
  dispatch({
    type: ActionConstants.categories.clearStoredItems,
  })
}

export const toggleFilterOnEmpty = () => dispatch => {
  dispatch({
    type: ActionConstants.categories.toggleFilterOnEmpty,
  })
}
