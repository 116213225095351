import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import EventListsContainer from './EventListsContainer'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { MobileGrid } from '../components/layout/layout'
import { openFullScreenDialog, setCurrentView } from '../actions/UI/AppActions'
import { NearbyCard } from '../components/cards/CardComponents'
import { Globals } from '../constants/Global'
import { PageLoader } from '../components/loading/Loading'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { fetchDoorRecommendations } from '../actions/DoorActions'
import { selectCurrentLocation } from '../selectors/AppSelectors'
import Logo from '../assets/images/logo/Doormouse_Final_BW.svg'
import { MobileTearSheet } from '../components/decorators/DecoratorComponents'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '@material-ui/core/Icon'
import ListItemText from '@material-ui/core/ListItemText'
import AddIcon from '@material-ui/icons/Add'
import { getNotificationTallies } from '../actions/NotificationsActions'
import { loadFeed } from '../actions/FeedActions'
import SearchFilters from './Search/SearchFilters'
import useDebounce from '../hooks/useDebounce'
import { selectCurrentUser } from '../selectors/UsersSelectors'
import Fab from '@material-ui/core/Fab'
import TuneIcon from '@material-ui/icons/Tune'
import CloseIcon from '@material-ui/icons/Close'
import Drawer from '@material-ui/core/Drawer'

const BetaContainer = styled.div`
  padding: 16px;
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
`

const BetaIcon = styled(Icon)`
  font-size: 60px;
  width: 65px;
  color: ${Globals.colors.primary['500']};
  margin-right: 16px;
`

const StyledPaper = styled(Paper)`
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  
  div {
    overflow: hidden;
  }
`

const FeedRoot = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: stretch;
  position: relative;
  flex-direction: column;
`

const StyledFab = styled(Fab)`
  background-color: ${Globals.colors.gray['A100']};
  color: ${Globals.colors.primary['500']};
  position: absolute;
  top: 16px;
  right: 32px;
  z-index: 1;
`

const FeedContainer = () => {
  const dispatch = useDispatch()
  const [feedLoaded, setFeedLoaded] = useState(false)
  const [dateRange, setDateRange] = useState({ startTime: moment().valueOf(), endTime: moment().add(30, 'days').valueOf() })
  const [venue, setVenue] = useState(null)
  const [range, setRange] = useState(25)
  const [value, setValue] = useState('')
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [offset, setOffset] = useState(25)
  const [endOfList, setEndOfList] = useState(false)
  const [events, setEvents] = useState([])
  const [filtersOpen, setFiltersOpen] = useState(false)
  const limit = 25
  const [dateText, setDateText] = useState('This Month')
  const debouncedSearchTerm = useDebounce(value)
  const currentLocation = useSelector(selectCurrentLocation)
  const currentUser = useSelector(selectCurrentUser)
  const seenBeta = localStorage.getItem('seenBeta')

  let latitude
  let longitude
  let radius = 80467.2
  if (venue && venue.latitude && venue.longitude) {
    latitude = venue.latitude
    longitude = venue.longitude
    radius = Math.ceil(range * 1609.344)
  } else if (currentLocation && currentLocation.latitude && currentLocation.longitude) {
    latitude = currentLocation.latitude
    longitude = currentLocation.longitude
  } else if (currentUser
    && currentUser.settings
    && currentUser.settings.homeLat
    && currentUser.settings.homeLong
    && currentUser.settings.defaultRadius) {
    latitude = currentUser.settings.homeLat
    longitude = currentUser.settings.homeLong
    radius = currentUser.settings.defaultRadius
  }

  useEffect(() => {
    dispatch(setCurrentView({ currentView: null, tabs: false }))
    dispatch(loadFeed({
      q: value,
      limit,
      offset: 0,
      startTime: dateRange.startTime,
      endTime: dateRange.endTime,
      radius,
      latitude,
      longitude,
    })).then((response) => {
      const { eventsList, hasNextPage } = response
      setEvents(eventsList)
      setEndOfList(!hasNextPage)
      setOffset(25)
      setFeedLoaded(true)
    })
    dispatch(fetchDoorRecommendations())
    dispatch(getNotificationTallies())
    return () => {
      localStorage.setItem('seenBeta', 'true')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(
    () => {
      if (feedLoaded) {
        setFiltersLoading(true)
        dispatch(loadFeed({
          q: value,
          limit: 25,
          offset: 0,
          startTime: dateRange.startTime,
          endTime: dateRange.endTime,
          radius,
          latitude,
          longitude,
        })).then((response) => {
          const { eventsList, hasNextPage } = response
          setEvents(eventsList)
          setEndOfList(!hasNextPage)
          setOffset(25)
          setFiltersLoading(false)
        }, () => {
          setFiltersLoading(false)
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm]
  )

  const handleOpenNewEvent = () => {
    dispatch(openFullScreenDialog({ dialog: 'newEvent' }))
  }

  const handleInfiniteLoad = () => {
    dispatch(loadFeed({
      q: value,
      startTime: dateRange.startTime,
      endTime: dateRange.endTime,
      limit,
      offset,
      radius,
      latitude,
      longitude,
      skipFeed: true,
    })).then((response) => {
      const { eventsList, hasNextPage } = response
      setEvents([...events, ...eventsList])
      setEndOfList(!hasNextPage)
      setOffset(offset + limit)
    })
  }

  const handleApplyFilters = (payload) => {
    const { newDateRange, newVenue, newSearch, newRange } = payload
    let latitude
    let longitude
    let radius = 80467.2
    if (newVenue && newVenue.latitude && newVenue.longitude) {
      latitude = newVenue.latitude
      longitude = newVenue.longitude
      radius = Math.ceil(newRange * 1609.344)
      setVenue(newVenue)
      setRange(newRange)
    } else if (venue && venue.latitude && venue.longitude) {
      latitude = venue.latitude
      longitude = venue.longitude
      radius = Math.ceil(range * 1609.344)
    } else if (currentLocation && currentLocation.latitude && currentLocation.longitude) {
      latitude = currentLocation.latitude
      longitude = currentLocation.longitude
    } else if (currentUser
      && currentUser.settings
      && currentUser.settings.homeLat
      && currentUser.settings.homeLong
      && currentUser.settings.defaultRadius) {
      latitude = currentUser.settings.homeLat
      longitude = currentUser.settings.homeLong
      radius = currentUser.settings.defaultRadius
    }
    if (newDateRange) {
      setDateRange(newDateRange)
    }
    if (newVenue) {
      setVenue(newVenue)
    }
    if (newSearch !== null) {
      setValue(newSearch)
    }
    setFiltersLoading(true)
    setFiltersOpen(false)
    dispatch(loadFeed({
      q: newSearch || value,
      startTime: newDateRange ? newDateRange.startTime : dateRange.startTime,
      endTime: newDateRange ? newDateRange.endTime: dateRange.endTime,
      radius,
      latitude,
      longitude,
    })).then(() => {
      setFiltersLoading(false)
    })
  }

  return (
    <>
      {feedLoaded === false && <PageLoader />}
      {feedLoaded === true && events.length === 0 && (
        <MobileGrid
          style={{
            height: '100%',
            padding: 0,
          }}
          childStyle={{
            position: 'relative',
            height: '100%',
            backgroundImage: `url("${Logo}")`,
            backgroundSize: '65%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%',
          }}
        >
          <MobileTearSheet direction="up">
            <div style={{ padding: 16 }}>
              <ListItem disableGutters alignItems="flex-start">
                <ListItemIcon>
                  <Icon style={{ color: Globals.colors.primary['500'], fontSize: 50 }} className="fa fa-calendar-alt" />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography variant="body1" style={{ color: Globals.colors.primary['500'] }}>Add Event</Typography>}
                  secondary={(
                    <>
                      <Typography variant="body1">No events found.</Typography>
                      <Typography variant="body1">Reach people by adding them.</Typography>
                      <Button style={{ marginTop: 8 }} variant="contained" color="secondary" onClick={handleOpenNewEvent} startIcon={<AddIcon />}>Add Event</Button>
                    </>
                  )}
                />
              </ListItem>
            </div>
          </MobileTearSheet>
        </MobileGrid>
      )}
      {feedLoaded === true && events.length > 0 && (
        <FeedRoot>
          {(!seenBeta || (seenBeta && seenBeta === 'false')) && (
            <MobileGrid>
              <BetaContainer>
                <BetaIcon className="icon-doormouse" />
                <Typography variant="body1">
                  Welcome to our Beta! While we get some aspects of account synch up to speed we need your help. Make friends with your fellow beta testers and add categories of interest to your account so we can recommend new events to you.
                </Typography>
              </BetaContainer>
            </MobileGrid>
          )}
          <MobileGrid
            style={{
              flexGrow: 1,
              position: 'relative',
              overflow: 'hidden',
            }}
            childStyle={{
              height: '100%',
            }}
          >
            <Hidden mdUp>
              <StyledFab onClick={() => setFiltersOpen(!filtersOpen)}>
                {!filtersOpen && <TuneIcon />}
                {filtersOpen && <CloseIcon />}
              </StyledFab>
            </Hidden>
            <Grid container spacing={2} style={{ height: '100%', position: 'relative', marginTop: 0 }}>
              <Grid item lg={6} md={5} sm={false} xs={false} style={{ paddingTop: 0, paddingBottom: 0  }}>
                <Hidden smDown>
                  <StyledPaper style={{ height: 'initial' }}>
                    <SearchFilters
                      dateRange={dateRange}
                      dateText={dateText}
                      value={value}
                      showCategories={false}
                      showSearch
                      onChangeDate={(value) => setDateText(value)}
                      onChangeDateRange={(value) => setDateRange(value)}
                      onApply={handleApplyFilters}
                      onChangeSearch={(value) => setValue(value)}
                    />
                  </StyledPaper>
                </Hidden>
              </Grid>
              <Grid item lg={6} md={7} sm={12} xs={12} style={{ height: '100%', position: 'relative', paddingTop: 0, paddingBottom: 0 }}>
                <StyledPaper id="scrollable-container">
                  <Typography gutterBottom variant="h1" color="textSecondary">Nearby Now</Typography>
                  <NearbyCard events={events} />
                  {filtersLoading ? <PageLoader /> : (
                    <InfiniteScroll
                      scrollThreshold={0.7}
                      dataLength={events.length}
                      next={handleInfiniteLoad}
                      hasMore={!endOfList}
                      loader={<h4>Loading...</h4>}
                      scrollableTarget="scrollable-container"
                    >
                      <EventListsContainer stateCards={events} singleColumn />
                    </InfiniteScroll>
                  )}
                </StyledPaper>
              </Grid>
            </Grid>
          </MobileGrid>
          <Drawer keepMounted anchor="bottom" open={filtersOpen} onClose={() => setFiltersOpen(false)}>
            <StyledPaper elevation={0}>
              <SearchFilters
                dateRange={dateRange}
                dateText={dateText}
                value={value}
                showCategories={false}
                showSearch
                onChangeDate={(value) => setDateText(value)}
                onChangeDateRange={(value) => setDateRange(value)}
                onApply={handleApplyFilters}
                onChangeSearch={(value) => setValue(value)}
              />
            </StyledPaper>
          </Drawer>
        </FeedRoot>
      )}
    </>
  )
}

export default FeedContainer
