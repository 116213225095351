import { createSelector } from 'reselect'

export const selectSuperCategories = createSelector(
  state => state.superCategories,
  propState => propState
)

export const selectSuperSubCategories = createSelector(
  state => state.superSubCategories,
  propState => propState
)

export const selectCategories = createSelector(
  state => state.categories,
  propState => propState
)

export const selectUserCategories = createSelector(
  state => state.userCategories,
  propState => propState
)
